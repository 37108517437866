import { CancelTokenSource } from 'axios';
import { Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { User, UserService } from '../shared/authentication';
import GlobalizedText from '../shared/globalization';
import { PortalContent } from '../shared/portalcontent';
import { ajax, getContextPath, isEmptyStr, trim } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/login.css';

interface Step {
    next: () => void;
    previous: () => void;
    step: () => number;
}
interface FirstPageProps extends ViewComponentProps {
    step: Step;
    hint: any;
    showForgotUserId: boolean;
    showForgotPassword: boolean;
    showRegister: boolean;
    secImgIsOpen: boolean;
}
interface SecondPageProps extends ViewComponentProps {
    step: Step;
    hint: any;
    userService: UserService;
    showForgotUserId: boolean;
    showForgotPassword: boolean;
    showRegister: boolean;
    secImgIsOpen: boolean;
    userID: string
}
interface LoginDirectlyProps extends ViewComponentProps {

    hint: any;
    userService: UserService;
    showForgotUserId: boolean;
    showForgotPassword: boolean;
    showRegister: boolean;
    secImgIsOpen: boolean;
}

function FirstPage(props: FirstPageProps) {
    let userID: string = trim(props.getQueryParam("userID"));
    let showRetriveSuc: string = trim(props.getQueryParam("showRetriveSuc"));
    const { values } = useFormikContext<any>();
    const [hasUserID, sethasUserID] = useState<boolean>(true);
    if (!isEmptyStr(userID)) {
        values.username = userID;
        if (showRetriveSuc === "yes") {
            props.showMessage("success", props.getGlobalizedText("msg.info.retrieve.success"));
        }
    }
    useEffect(() => {
        if (values.username.trim() !== "") {
            sethasUserID(false);
        }
    }, [values.username]);


    const validateUID = (e: any) => {
        if (e.target.value.trim() !== '') {
            sethasUserID(false);
        }
        if (e.target.value.trim() === '') {
            sethasUserID(true);
        }

    }
    let next = (e: any) => {
        props.step.next();
        ajax({
            url: '/api/loginView/hint/' + values.username,
            success: function (hint: any) {
                props.hint.setHint(hint);
            }
        });

    }

    return (
        <props.Div id="login_panel" className="panel panel-default login-panel-body">
            <props.Div className="login-panel-content">
                <props.TextControl onChange={(e: any) => validateUID(e)} name="username" id="username" label="groupAdmin.userID" ></props.TextControl>
                <props.Div className="signUpText">
                    {props.showRegister && <>
                        <props.Link className="gwp-bold" to="/registration"><GlobalizedText message="login.lbl.signup" /></props.Link>{" | "}
                    </>}
                    {props.showForgotUserId && <>
                        <props.Link className="gwp-bold" to="/forgotuserid"><GlobalizedText message="common.lbl.forgotUserID" /></props.Link>{" | "}
                    </>}
                    {props.showForgotPassword && <>
                        <props.Link className="gwp-bold" to={`/forgotpassword?userID=${values.username}`}><GlobalizedText message="login.lbl.forgotpassword" /></props.Link>
                    </>}
                </props.Div>
                <props.Button disabled={hasUserID} className="nextBtn" type="submit" onClick={next} ><GlobalizedText message="common.button.next" /> </props.Button>
            </props.Div>
        </props.Div>
    );
}

function SecondPage(props: SecondPageProps) {
    const { isSubmitting } = useFormikContext();

    return (
        <props.Div id="login_panel" className="panel panel-default login-panel-body2">
            <props.Div className="login-panel-content">
                <props.Div className="form-group" style={{ display: 'none' }}>
                    <props.TextControl name="username" label="groupAdmin.userID"></props.TextControl>
                </props.Div>
                <props.Div>
                    <p className="security-caption"><GlobalizedText message="login.lbl.securitycaption" /> </p>
                    <p ><GlobalizedText message="login.lbl.securitycaption.note" /></p>
                    <props.Div id="gias-sec">
                        <props.Div id="gias-sec-img-div">{props.hint.hint !== null && <img src={process.env.PUBLIC_URL + "/static/themes/blue/images/security_image/" + props.hint.hint.secImg.category.name + "/" + props.hint.hint.secImg.name + ".jpg"} alt={props.hint.hint.secImg.name} width="76" height="76" />}</props.Div>
                        <span className="gwp-label-ro">
                            {props.hint.hint === null ? "" : props.hint.hint.secCaption}
                        </span>
                    </props.Div>
                    <props.PasswordControl name="password" label="login.lbl.password"></props.PasswordControl>
                </props.Div>
                <props.Div className="signUpText">
                    {props.showRegister && <>
                        <props.Link className="gwp-bold" to="/registration"><GlobalizedText message="login.lbl.signup" /></props.Link>{" | "}
                    </>}
                    {props.showForgotUserId && <>
                        <props.Link className="gwp-bold" to="/forgotuserid"><GlobalizedText message="common.lbl.forgotUserID" /></props.Link>{" | "}
                    </>}
                    {props.showForgotPassword && <>
                        <props.Link className="gwp-bold" to={`/forgotpassword?userID=${props.userID}`}><GlobalizedText message="login.lbl.forgotpassword" /></props.Link>
                    </>}
                </props.Div>
                <props.Button className="previousBtn" disabled={isSubmitting} onClick={() => { props.hint.setHint(null); props.step.previous() }} ><GlobalizedText message="common.button.previous" /> </props.Button>
                <props.Button className="loginBtn" type="submit" disabled={isSubmitting}><GlobalizedText message="login.button.login" /> </props.Button>

            </props.Div>
        </props.Div>
    );
}

function LoginDirectly(props: LoginDirectlyProps) {
    const { isSubmitting } = useFormikContext();

    return (
        <props.Div id="login_panel" className="panel panel-default login-panel-body3">
            <props.Div className="login-panel-content">
                <props.TextControl name="username" label="groupAdmin.userID"></props.TextControl>
                <props.PasswordControl name="password" label="login.lbl.password"></props.PasswordControl>
                <props.Div className="signUpText">
                    <props.Link condition={props.showRegister} className="gwp-bold" to="/signup"><GlobalizedText message="login.lbl.signup" /></props.Link>
                    <props.Link condition={props.showForgotUserId} className="gwp-bold" to="/forgotuserid"><GlobalizedText message="common.lbl.forgotUserID" /></props.Link>
                    <props.Link condition={props.showForgotPassword} className="gwp-bold" to="/forgotpassword"><GlobalizedText message="login.lbl.forgotpassword" /></props.Link>
                </props.Div>
                <props.Button className="loginDirectlyBtn" type="submit" disabled={isSubmitting}><GlobalizedText message="login.button.login" /></props.Button>
            </props.Div>

        </props.Div>

    )


}

function useStep(props: any) {
    const [step, setStep] = useState(1);
    return {
        next: function () {
            setStep(2);
            props.clearMessage();
        },
        previous: function () {
            setStep(1);
            props.clearMessage();
        },
        step: function () {
            return step;
        }
    }
}

function useHint() {
    const [hint, setHint] = useState(null);
    return {
        hint: hint,
        setHint: setHint
    }
}


export const LoginComponent = withView((props: ViewComponentProps) => {
    const step: Step = useStep(props);
    const hint = useHint();
    const [config, setConfig] = useState<any>();
    const needInit = useRef(config === undefined || props.isForceRefresh());
    const formPropsRef= useRef<any>();
    if (props.isForceRefresh()) {
        needInit.current = true
    }

    useEffect(() => {
        if (window.history.state?.isRedirect) {
            props.showMessage("error", "msg.login.timeout");
            window.history.pushState(null, "", getContextPath() + "/login");
        }
    }, []);

    useEffect(() => {
        let cancelToken: CancelTokenSource;
        if (needInit.current === true) {
            needInit.current = false;
            cancelToken = ajax({
                url: '/api/loginView/view',
                success: (viewConfig) => {
                    setConfig(viewConfig);
                    if (props.isForceRefresh()) {
                        // refresh the component to initial status
                        step.previous();
                        props.clearMessage();
                        if(formPropsRef.current!==undefined){
                            props.resetForm(formPropsRef.current);
                        }
                        props.refreshed();
                    }
                }
            });
        }
        return () => {
            if (cancelToken !== undefined) {
                cancelToken.cancel();
            }
        }
    }, [config, props.isForceRefresh()]);
    if (config === undefined) {
        return <></>
    }
    return (
        <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={(values, { setSubmitting }) => {
                props.userService.authenticate(values.username, values.password, (user: User) => {
                    if (user.needFirstUpdate === true || user.needUpdatePwd === true) {
                        props.next("/firstLogUpdate");
                    } else {
                        let from = props.getQueryParam("from");
                        if (from !== undefined) { from = decodeURIComponent(from.replace(process.env.PUBLIC_URL, "")) }
                        props.next(from !== undefined ? from : user.defaultHome ? user.defaultHome : "/");
                    }
                }, (data: any, message: string) => {
                    step.previous();
                    values.password = "";
                    setSubmitting(false);
                    props.showMessage("error", props.getGlobalizedText(message));
                    if (message === 'msg.login.locked') { //Account locked.
                        props.next(`/forgotpassword?userID=${values.username}&loginError=${message}`)
                    }
                });
            }}
        >{formProps =>{
            formPropsRef.current = formProps;
            return <Form className="login">
                <props.Div>
                    <PortalContent {...props} className="max-portal-content" component="login" position="L" />
                </props.Div>
                <props.Row>
                    <props.Col>
                        <props.Div id="loginTitle" className={'gwp-page-title ' + (step.step() === 1 ? 'login-page-title' : 'login-page-title2')}>
                            <span><GlobalizedText message="login.lbl.loginheading.left" /></span>
                            <span className="gwp-icon gwp-icon-lock gwp-icon-xs"></span>
                        </props.Div>
                    </props.Col>
                </props.Row>
                <props.Row>
                    <props.Col xs="12" md="4" sm="3">
                        <PortalContent {...props} className="min-portal-content" component="login" position="L" />
                    </props.Col>
                    {config.secImgIsOpen === true &&
                        <props.Col>
                            {step.step() === 1 &&
                                <FirstPage {...props} {...config} step={step} hint={hint} />
                            }
                            {step.step() === 2 &&
                                <SecondPage {...props} {...config} userID={formProps.values.username} step={step} hint={hint} userService={props.userService} />
                            }
                        </props.Col>
                    }
                    {config.secImgIsOpen === false &&
                        <props.Col>
                            <LoginDirectly {...props} {...config} hint={hint} userService={props.userService} />
                        </props.Col>
                    }

                </props.Row>
            </Form>
            }}

        </Formik>
    );
})
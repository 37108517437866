import React, { useEffect, useState } from 'react'
import './css/carriers.css';
import { ajax } from '../shared/utils';
import { withView } from '../shared/viewcomponent';
import { AccountFilter } from '../shared/components';

export const Carriers = withView((props: any) => {
    const [listItem, setListItem] = useState<any>();
    const companyCode: string = props.getParam("companyCode");
    const groupNumber: string = props.getParam("groupNumber");
    const participantIDNumber: string = props.getParam("participantIDNumber");
	const clientNumber: string = props.getParam("clientNumber");
	
	function getCategory(item: any) {
		return item.parameters["category"];
	}

	const filterData = (data: any[]) => {

        const filterArray: any = [];
        data.forEach((item) => {
            let category = getCategory(item);
            if (!filterArray.includes(category)) {
                filterArray[category] = [];
            }
        })
        data.forEach((item) => {
            let category = getCategory(item);
            filterArray[category].push(item)
        })
        return filterArray;
    };
	const buildCategorizedItemTree = (res: any[]) => {
		const data = filterData(res);
		const newArray: any = [];
		Object.keys(data).length > 0 && Object.keys(data).map((key) => {
			newArray[key] = { items: [] }
			data[key].map((item: any) => {
				if (newArray[key] !== undefined) {
					newArray[key].items.push(item)
				}
			});
		});		
		return newArray;
	};
    useEffect(() => {
        if (listItem === undefined) {
			if(companyCode && groupNumber && participantIDNumber) {
				ajax({
					url: `/api/affiliate/products/${companyCode}/${groupNumber}/${participantIDNumber}`,
					params: {},
					success: (res: any) => {
						setListItem(buildCategorizedItemTree(res));
					}
				});
			}
        }
    }, [listItem]);


function PPOList(props: any) {
    const { item } = props;

	const openInNewTab = (url: string): void => {
  		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  		if (newWindow) newWindow.opener = null
	}
		
	const onClickHandler = (ev: any) => {
		
		if (item.sso == false){
			   openInNewTab(item.site);
		} else {
        const headers1 = Object.assign({
            'Content-Type': 'text/html',
            'Accept': 'text/html',
        });
        const idpSsoSiteName = ev;

        ajax({
            url: '/api/idp/singleSignOnService',
            method: 'POST',
            headers: headers1,
            params: { idpSsoSiteName: item.name, 
					  companyCode: companyCode,
					  groupNumber: groupNumber,
    				  participantIDNumber: participantIDNumber,
					  clientNumber: clientNumber
					},
            success: () => { },
            callback: (res) => {
                const data = res.data;
                window.onload = function () { document.forms[0].continue(); }

                const doc = document.createElement("div");
                doc.innerHTML = data;
                const form = doc.querySelectorAll("form")[0]
                const url = form.action;
                const SAMLResponse = form.elements[0].getAttribute('value');
                const paramsIdp = [{ idpSsoSiteName }, { SAMLResponse }, { url }];

                const headers2 = Object.assign({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                });
                fetch('/api/idp/auditIdpResponse', {
                    method: 'POST',
                    headers: headers2,
                    body: JSON.stringify({ clientName: item.name, samlResponse: SAMLResponse, url: url })
                })
                    .then((res) => res.json())
                    .then((data) => console.log(data))
                    .catch((er) => console.log(er));



                post(url, SAMLResponse);

                function post(path: any, params: any, method = 'post') {

                    // The rest of this code assumes you are not using a library.
                    // It can be made less verbose if you use one.
                    const form = document.createElement('form');
                    form.target = "_blank";
                    form.method = method;
                    form.action = path;
                    const hiddenField = document.createElement('input');
                    hiddenField.type = 'hidden';
                    hiddenField.name = 'SAMLResponse';
                    hiddenField.value = params;
                    form.appendChild(hiddenField);

                    document.body.appendChild(form);
                    form.submit();
                }

            }, error: (error: any) => {
                error.showMessage('error', 'SSO login not successful');
            },
            fail: (error: any) => {
            }, showMask: false
			
        });
	}
    };

    return <>
		<hr/>
        <div key={item.id} className="row-item">
                <div className="item"> <h4>{item.name}</h4></div>
                <div className="item">
						<img src={process.env.PUBLIC_URL + item.icon} className="img-responsive" onClick={() => onClickHandler(item)}/>				                    
                </div>
                <div className="item"> {item.description}</div>
        </div>
    </>
}
	function onAccountChange(company:string, group:string, account:string) {
		ajax({
			url: '/api/affiliate/products',
			params: {
                companyCode: company,
                groupNumber: group,
                accountNumber: account
			},
			success: (res: any) => {
				setListItem(buildCategorizedItemTree(res));
			}
		});
	}
    return (
        <React.Fragment>
            <props.Col className="pponetwork-list">
				{ !participantIDNumber && <AccountFilter onChange={onAccountChange} /> }
                <props.Col className='divider'></props.Col>
                <props.Condition><props.Information message="common.carriers.message" /></props.Condition>
				{ listItem &&
					<div className="img-list">
						<props.Col md="12">
							<props.Row>
								{Object.keys(listItem).map((data: any, index) => (
									<div key={index} className="category">
										<div className="gwp-bold item-title">
											<h3>{data.replace("-", " ")}</h3>
										</div>
										<div className="items">
										{
											listItem[data].items.map((list: any) => (
												<PPOList key={list.id} item={list} parent={data} />
											))
										}
										</div>
									</div>
								)
								)}
							</props.Row>
						</props.Col>
					</div>
				}
            </props.Col >
        </React.Fragment>
    )


})

import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { TableCell, TableColumn } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ajax, formatNumber, getFromStorage, isEmptyStr, trim } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/claimDetails.css';

interface ChargeDetail {
    serviceFromDate: string
    serviceThruDate: string
    typeOfService: string
    amountBilled: number
    excludedAmount: number
    benefitLineNumber: number
    benefitAmount: number
    amountAllowed: number
    copayment: number
    deductibleApplied: number
    coinsurance: number
    sumAmountExcluded: number
    remarkCode: string
    remarkCode1: string
    benefitLineExcludedVO: BenefitLineExcludedVO
}

interface Option {
    name: string
    value: string
}

interface PayeeVO {
    checkAmount: number
    name: string
    address1: string
    address2: string
    address3: string
    city: string
    state: string
    zipCode: string
}

interface ProviderPaymentVO {
    providerPaymentStatus: string
    paymentPaidDateYear: number
    paymentPaidDateMonth: number
    paymentPaidDateDay: number
    statusDate: string
    patientResponsibility: number
    overpaymentRecoveredAmount: number
    offsetAmountIndicator: string
    totalOffsetAmount: number
    assignmentIndicator: string
    userId: string
    providerAddJulianYear: number
    providerAddJulianDay: number
    providerAddNumber: number
    employeeCheckAmount: number
    providerCheckAmount: number
}

interface ClaimDetailsVO {
    claimStatusMstrVO: ClaimStatusMstrVO
    claimSummaryVO: ClaimSummaryVO
    payeeList: Array<PayeeVO>
    providerPaymentVO: ProviderPaymentVO
}

interface ClaimStatusMstrVO {
    companyCode: string
    groupNumber: string
    accountNumber: number
    participantIDNumber: string
    dependentSequenceNumber: number
    fullName: string
    groupName: string
    accountName: string
    currencyDec: string
    claimStatusDesc: string
}

interface ClaimSummaryVO {
    otherCarrierPaid?: number
    amountBilledClaimSum?: number
    amountExcludedClaimSum?: number
    carveOutAmountClaimSum?: number
    deductibleApplied?: number
    deductibleApplied1?: number
    deductibleApplied2?: number
    excludedAmount?: number
    coinsuranceCopay?: number
    benefitAmount?: number
    benefitAmount1?: number
    benefitAmount2?: number
    amountPayable?: number
    totalAmountPaid?: number
    productId?: string
    productName?: string
    cobSavingsAmount?: number
    mclAppliedAmount?: number
}

interface ClaimDetailViewResponse {
    chargeDetailsList: Array<ChargeDetail>
    claimDetailsVO: ClaimDetailsVO
    planYearList: Array<Option>
    patientList: Array<Option>
    displayBenefitLimitsFlag: boolean
    planCode: string
    planCodeDesc: string
    totalAmountPaidFlag: boolean
    claimNumber: string
    adjustmentNumber: string
    providerPaymentNumber: number
    dependent: boolean
}

interface BenefitLineExcludedVO {
    amountExcluded: number
    remarkCode: string
    amountExcluded1: number
    remarkCode1: string
    amountExcluded2: number
    remarkCode2: string
    amountExcluded3: number
    remarkCode3: string
    amountExcluded4: number
    remarkCode4: string
    amountExcluded5: number
    remarkCode5: string
}

export const ClaimDetailComponent = withView((props: ViewComponentProps) => {
    const [config, setConfig] = useState<ClaimDetailViewResponse>();
    const [annualData, setAnnualData] = useState<Array<any>>();
    const [familyData, setFamilyData] = useState<Array<any>>();
    const [lifetimeData, setLifetimeData] = useState<Array<any>>();
    const [otherData, setOtherData] = useState<Array<any>>();
    const [remarkCode, setState] = useState<BenefitLineExcludedVO>();
    const [sumAmountExcluded, setResult] = useState<any>({ sumAmountExcluded: 0 });

    

    const columns: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "4%" },
        { title: "common.lbl.description", name: "description", width: "40%", className: "fontBold" },
        { title: "common.lbl.limit", name: "limit", width: "8%", type: 'numeric' },
        { title: "common.lbl.claim.accum.amt", name: "accumulatedAmount", width: "20%", type: 'numeric' },
        { title: "common.lbl.percent.met", name: "Percent Met", width: "32%", type: 'progressBar', className: "text-center" },
    ];

    const otherColumns: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "4%" },
        { title: "common.lbl.description", name: "type", width: "40%", className: "fontBold" },
        { title: "common.lbl.limit", name: "limit", width: "6%", type: 'numeric' },
        { title: "", name: "", width: "50%" },
    ];
    const handleUndefined = (value: any): any => {
        if (isEmptyStr(value))
            return "-999";
        else
            return value;
    };

    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/claimdetail/view',
                params: {
                    companyCode: props.getQueryParam("companyCode"),
                    groupNumber: props.getQueryParam("groupNumber"),
                    accountNumber: props.getQueryParam("accountNumber"),
                    participantIDNumber: props.getQueryParam("participantIDNumber"),
                    julianYear: props.getQueryParam("julianYear"),
                    julianDay: props.getQueryParam("julianDay"),
                    sequenceNumber: props.getQueryParam("sequenceNumber"),
                    suffix: props.getQueryParam("suffix"),
                    claimNumber: props.getQueryParam("claimNumber"),
                    providerPaymentNumber: props.getQueryParam("providerPaymentNumber"),
                    dependentSequenceNumber: props.getQueryParam("dependentSequenceNumber"),
                    adjustmentNumber: props.getQueryParam("adjustmentNumber")
                },
                success: (res: ClaimDetailViewResponse) => {
                    setConfig(res);
                }
            });
        }
    }, [config]);

    const chargeDetailsInfoTableObj = useMemo(() => {
        if (config !== undefined) {
            return props.useTable({
                id: 'chargeDetailsInfo',
                option: {
                    retrieve: true,
                    bPaginate: false,
                    bSort: false,
                    bFilter: false,
                    bLengthChange: false,
                    language: { emptyTable: " " }
                },
                onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element) => {
                    childNode?.querySelector("#showRemark")?.addEventListener("click", (e: any) => {
                        if (config.chargeDetailsList.length > 0) {
                            viewNotCovered(config.chargeDetailsList[rowNumber]);
                        }
                    });
                },
                children: [
                    <thead key={0}>
                        <tr>
                            <th scope="col" style={{ width: "5%" }}></th>
                            <th scope="col" style={{ width: "20%" }}><GlobalizedText message="common.lbl.date.of.serve" /></th>
                            <th scope="col" style={{ width: "20%" }}><GlobalizedText message="common.lbl.type.of.serve" /></th>
                            <th scope="col" style={{ width: "20%" }} data-type="numeric"><GlobalizedText message="common.lbl.amt.bill" /></th>
                            <th scope="col" style={{ width: "20%" }} data-type="numeric"><GlobalizedText message="common.lbl.exd.amt" /></th>
                            <th scope="col" style={{ width: "15%" }} data-type="numeric"><GlobalizedText message="common.lbl.bene.amt" /></th>
                            <th className="gwp-dt-child" style={{ width: "0%" }}></th>
                        </tr>
                    </thead>,
                    <tbody key={1}>
                        {config.chargeDetailsList.map((chargeDetails: ChargeDetail, index: number) => {
                            return <tr key={index}>
                                {
                                    //@ts-ignore
                                    <td id={"chargeDetailId_" + chargeDetails.benefitLineNumber} className="gwp-details-control" style={{ width: "1%", paddingLeft: "15px" }}></td>
                                }
                                {chargeDetails.serviceFromDate !== chargeDetails.serviceThruDate ?
                                    <td>{chargeDetails.serviceFromDate} - {chargeDetails.serviceThruDate}</td> :
                                    <td>{chargeDetails.serviceFromDate}</td>
                                }
                                <td>{chargeDetails.typeOfService}</td>
                                <td align="right">{formatNumber(chargeDetails.amountBilled)}</td>
                                <td align="right">{formatNumber(chargeDetails.excludedAmount)}</td>
                                <td align="right">{formatNumber(chargeDetails.benefitAmount)}</td>
                                <td className="gwp-dt-child">
                                    <props.Row className="extInfo">
                                        <props.Col xs="12" sm="4" md="4" className="chargeDetailsAmountAllowed">
                                            <props.DL className="gwp-dl">
                                                <props.DLI title="common.lbl.claim.amt.allow" desc={() => {
                                                    return <>{formatNumber(chargeDetails.amountAllowed)}</>
                                                }} />
                                                <props.DLI title="common.lbl.claim.app.to.ded" desc={() => {
                                                    return <>{formatNumber(chargeDetails.deductibleApplied)}</>
                                                }} />
                                                <props.DLI title="common.lbl.Copay.amt" desc={() => {
                                                    return <>{formatNumber(chargeDetails.copayment)}</>
                                                }} />
                                                <props.DLI title="common.lbl.Coinsurance.amt" desc={() => {
                                                    return <>{formatNumber(chargeDetails.coinsurance)}</>
                                                }} />
                                            </props.DL>
                                        </props.Col>
                                        <props.Col xs="12" sm="4" md="4" className="chargeDetailsAmountAllowed">
                                            <props.DL className="gwp-dl">
                                                <props.DLI title="common.lbl.oth.car.pad" desc={() => {
                                                    return <>{formatNumber(chargeDetails.copayment)}</>
                                                }} />
                                                <props.DLI title="common.lbl.not.coverd" desc={() => {
                                                    return <>{formatNumber(chargeDetails.sumAmountExcluded)}</>
                                                }} />
                                                <props.DLI title="common.lbl.dst.amt" desc={() => {
                                                    return <>{formatNumber(chargeDetails?.benefitLineExcludedVO?.amountExcluded4 ?? 0)}</>

                                                }} />
                                            </props.DL>
                                        </props.Col>

                                        <props.Col xs="12" sm="4" md="4" className="chargeDetailsAmountAllowed">
                                            <props.DL className="gwp-dl">
                                                <props.DLI title="common.lbl.claim.remarks" desc={() => {
                                                    return <>
                                                        {chargeDetails.remarkCode}
                                                        <br />
                                                        {chargeDetails.remarkCode1}
                                                    </>
                                                }} />
                                                <props.DLI condition={chargeDetails.benefitLineExcludedVO !== undefined} title="" desc={() => {
                                                    return <a id="showRemark" className="showRemark"><props.Icon type="view-all" label="common.lbl.view.all.not.coverd"></props.Icon></a>;
                                                }}></props.DLI>
                                            </props.DL>
                                        </props.Col>
                                    </props.Row>
                                </td>
                            </tr>
                        })}
                    </tbody>
                ]
            });
        }
    }, [config]);

    if (config === undefined) {
        return <></>
    }



    let displayBotton = "";
    let claimresultlist = getFromStorage("claimresultlist", true);
    let claimNumber = config.claimNumber;
    let adjustmentNumber = config.adjustmentNumber;
    let providerPaymentNumber = config.providerPaymentNumber;
    let preClaimUrl = {};
    let nextClaimUrl = {};
    if (claimresultlist !== null && claimresultlist.length > 1) {
        for (var i = 0; i < claimresultlist.length; i++) {
            let storeclaimNum = claimresultlist[i].claimNumber;
            let storeadjustmentNumber = claimresultlist[i].adjustmentNumber;
            let storeproviderPaymentNumber = claimresultlist[i].providerPaymentNumber;
            if (storeclaimNum.trim() === claimNumber.trim() &&
                (
                    (storeadjustmentNumber === undefined && adjustmentNumber === "-999" && storeproviderPaymentNumber === undefined && providerPaymentNumber == -999) ||
                    (adjustmentNumber === storeadjustmentNumber && providerPaymentNumber === storeproviderPaymentNumber)
                )
            ) {
                if (i === 0) {
                    displayBotton = "next";
                    nextClaimUrl = {
                        companyCode: claimresultlist[i + 1].companyCode,
                        groupNumber: claimresultlist[i + 1].groupNumber,
                        accountNumber: claimresultlist[i + 1].accountNumber,
                        participantIDNumber: claimresultlist[i + 1].memberID,
                        julianYear: claimresultlist[i + 1].julianYear,
                        julianDay: claimresultlist[i + 1].julianDay,
                        sequenceNumber: claimresultlist[i + 1].sequenceNumber,
                        suffix: claimresultlist[i + 1].suffix,
                        claimNumber: claimresultlist[i + 1].claimNumber,
                        providerPaymentNumber: handleUndefined(claimresultlist[i + 1].providerPaymentNumber),
                        dependentSequenceNumber: claimresultlist[i + 1].dependentSequenceNumber,
                        adjustmentNumber: handleUndefined(claimresultlist[i + 1].adjustmentNumber)
                    }
                } else if (i === claimresultlist.length - 1) {
                    displayBotton = "previous";
                    preClaimUrl = {
                        companyCode: claimresultlist[i - 1].companyCode,
                        groupNumber: claimresultlist[i - 1].groupNumber,
                        accountNumber: claimresultlist[i - 1].accountNumber,
                        participantIDNumber: claimresultlist[i - 1].memberID,
                        julianYear: claimresultlist[i - 1].julianYear,
                        julianDay: claimresultlist[i - 1].julianDay,
                        sequenceNumber: claimresultlist[i - 1].sequenceNumber,
                        suffix: claimresultlist[i - 1].suffix,
                        claimNumber: claimresultlist[i - 1].claimNumber,
                        providerPaymentNumber: handleUndefined(claimresultlist[i - 1].providerPaymentNumber),
                        dependentSequenceNumber: claimresultlist[i - 1].dependentSequenceNumber,
                        adjustmentNumber: handleUndefined(claimresultlist[i - 1].adjustmentNumber)
                    }
                } else {
                    displayBotton = "all";
                    nextClaimUrl = {
                        companyCode: claimresultlist[i + 1].companyCode,
                        groupNumber: claimresultlist[i + 1].groupNumber,
                        accountNumber: claimresultlist[i + 1].accountNumber,
                        participantIDNumber: claimresultlist[i + 1].memberID,
                        julianYear: claimresultlist[i + 1].julianYear,
                        julianDay: claimresultlist[i + 1].julianDay,
                        sequenceNumber: claimresultlist[i + 1].sequenceNumber,
                        suffix: claimresultlist[i + 1].suffix,
                        claimNumber: claimresultlist[i + 1].claimNumber,
                        providerPaymentNumber: handleUndefined(claimresultlist[i + 1].providerPaymentNumber),
                        dependentSequenceNumber: claimresultlist[i + 1].dependentSequenceNumber,
                        adjustmentNumber: handleUndefined(claimresultlist[i + 1].adjustmentNumber)
                    };
                    preClaimUrl = {
                        companyCode: claimresultlist[i - 1].companyCode,
                        groupNumber: claimresultlist[i - 1].groupNumber,
                        accountNumber: claimresultlist[i - 1].accountNumber,
                        participantIDNumber: claimresultlist[i - 1].memberID,
                        julianYear: claimresultlist[i - 1].julianYear,
                        julianDay: claimresultlist[i - 1].julianDay,
                        sequenceNumber: claimresultlist[i - 1].sequenceNumber,
                        suffix: claimresultlist[i - 1].suffix,
                        claimNumber: claimresultlist[i - 1].claimNumber,
                        providerPaymentNumber: handleUndefined(claimresultlist[i - 1].providerPaymentNumber),
                        dependentSequenceNumber: claimresultlist[i - 1].dependentSequenceNumber,
                        adjustmentNumber: handleUndefined(claimresultlist[i - 1].adjustmentNumber)
                    }
                }
            }
        }
    }
    let preNextClaim;
    if (displayBotton === "previous") {
        preNextClaim = (
            <>
                <props.LinkButton onClick={() => {
                    ajax({
                        url: '/api/claimdetail/view',
                        params: preClaimUrl,
                        success: (res: ClaimDetailViewResponse) => {
                            setConfig(res);
                        }
                    });
                }}>
                    &nbsp;&lt;&nbsp; <GlobalizedText message="common.lbl.claim.prev.claim" />
                </props.LinkButton>
                &nbsp;|&nbsp;
                <GlobalizedText message="common.lbl.claim.next.claim" />&nbsp;&gt;&nbsp;
            </>
        )
    } else if (displayBotton === "all") {
        preNextClaim = (
            <>
                <props.LinkButton onClick={() => {
                    ajax({
                        url: '/api/claimdetail/view',
                        params: preClaimUrl,
                        success: (res: ClaimDetailViewResponse) => {
                            setConfig(res);
                        }
                    });
                }}>
                    &nbsp;&lt;&nbsp; <GlobalizedText message="common.lbl.claim.prev.claim" />
                </props.LinkButton>
                &nbsp;|&nbsp;
                <props.LinkButton onClick={() => {
                    ajax({
                        url: '/api/claimdetail/view',
                        params: nextClaimUrl,
                        success: (res: ClaimDetailViewResponse) => {
                            setConfig(res);
                        }
                    });
                }}>
                    <GlobalizedText message="common.lbl.claim.next.claim" />&nbsp;&gt;&nbsp;
                </props.LinkButton>
            </>
        )
    } else if (displayBotton === "next") {
        preNextClaim = (
            <>
                &nbsp;&lt;&nbsp; <GlobalizedText message="common.lbl.claim.prev.claim" />
                &nbsp;|&nbsp;
                <props.LinkButton onClick={() => {
                    ajax({
                        url: '/api/claimdetail/view',
                        params: nextClaimUrl,
                        success: (res: ClaimDetailViewResponse) => {
                            setConfig(res);
                        }
                    });
                }}>
                    <GlobalizedText message="common.lbl.claim.next.claim" />&nbsp;&gt;&nbsp;
                </props.LinkButton>
            </>
        )
    }
    let displayBenefitLimitsFlag = config.displayBenefitLimitsFlag;
    let benefitLimits;
    if (displayBenefitLimitsFlag) {
        benefitLimits = (
            <>
                <span id="benefitLimits" className="benefitLimits">
                    <props.LinkButton id="viewBenefitLimits" onClick={() => {
                        blDialogShow();
                    }}>
                        <GlobalizedText message="common.lbl.pln.ytd.bft.lmt" />
                    </props.LinkButton>
                </span>
            </>
        );
    }

    let totalAmountPaidFlag = config.totalAmountPaidFlag;
    let totalAmountPaid;
    if (totalAmountPaidFlag) {
        totalAmountPaid = (
            <>
                <props.LinkButton containerClassName="marginTop0px" id="viewTotalAmountPaid" onClick={(e) => {
                    openTotalAmountPaidDialog();
                    e.preventDefault();
                }}>
                    {formatNumber(config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO !== undefined && config.claimDetailsVO.claimSummaryVO.totalAmountPaid !== undefined ? config.claimDetailsVO.claimSummaryVO.totalAmountPaid : 0)}
                </props.LinkButton>
            </>
        );
    } else {
        totalAmountPaid = <props.Span>
            {formatNumber((config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO !== undefined && config.claimDetailsVO.claimSummaryVO.totalAmountPaid !== undefined) ? config.claimDetailsVO.claimSummaryVO.totalAmountPaid : 0)}
        </props.Span>
    }

    let Payee;
    if (config.claimDetailsVO.payeeList.length === 1) {
        Payee = (
            <>
                <dd>{config.claimDetailsVO.payeeList[0].name}</dd>
                <dd>{config.claimDetailsVO.payeeList[0].address1}</dd>
                <dd>{config.claimDetailsVO.payeeList[0].address2}</dd>
                <dd>{config.claimDetailsVO.payeeList[0].address3}</dd>
                <dd>{config.claimDetailsVO.payeeList[0].city}, {config.claimDetailsVO.payeeList[0].state} {config.claimDetailsVO.payeeList[0].zipCode}</dd>
            </>
        );
    } else if (config.claimDetailsVO.payeeList.length === 0) {
        Payee = (
            <>
                <dd></dd>
            </>
        );
    } else if (config.claimDetailsVO.payeeList.length > 1) {
        Payee = (
            <>
                <props.LinkButton id="viewMultiplePayees" onClick={(e) => {
                    openMultiplePayeesDialog();
                    e.preventDefault();
                }}>
                    <props.Icon type="view-all" label="common.lbl.multi.payee"></props.Icon>
                </props.LinkButton>
            </>
        );
    }



    let tableOption = {
        retrieve: true,
        bPaginate: false,
        bSort: false,
        bFilter: false,
        bLengthChange: false,
        language: { emptyTable: props.getGlobalizedText("common.no.data") }
    };

    let limitTableOption = {
        bPaginate: false,
        bSort: false,
        bInfo: false,
        language: { emptyTable: props.getGlobalizedText("common.no.data") }
    };

    function viewNotCovered(chargeDetailRow: ChargeDetail) {
        setState(chargeDetailRow.benefitLineExcludedVO);
        setResult(chargeDetailRow.sumAmountExcluded);
        props.showModal('#notCoveredDialog');
    }

    function openTotalAmountPaidDialog() {
        props.showModal('#totalAmountPaidDialog');
    }

    function openMultiplePayeesDialog() {
        props.showModal('#multiplePayeesDialog');
    }

    function blDialogShow() {
        let sequenceNumber = "0";
        if (config !== undefined && config.patientList.length > 0) {
            sequenceNumber = config.patientList[0].value;
        }
        ajax({
            url: '/api/claimdetail/fetchBenefitLimits',
            maskOn: '#blDialog',
            params: {
                companyCode: config?.claimDetailsVO.claimStatusMstrVO.companyCode,
                groupNumber: config?.claimDetailsVO.claimStatusMstrVO.groupNumber,
                accountNumber: config?.claimDetailsVO.claimStatusMstrVO.accountNumber,
                productId: config?.claimDetailsVO.claimSummaryVO.productId,
                planCode: config?.planCode,
                participantIDNumber: config?.claimDetailsVO.claimStatusMstrVO.participantIDNumber,
                effectiveYear: new Date().getFullYear(),
                dependentSequenceNumber: sequenceNumber
            },
            success: (res) => {
                setAnnualData(res.annualLimits);
                setFamilyData(res.familyAnnualLimits);
                setLifetimeData(res.lifetimeLimits);
                setOtherData(res.otherLimits);
                props.showModal('#blDialog');
            }
        });

    }

    function patientChangeHandler(e: any, formProps: any) {
        let patient = e.target.value;
        ajax({
            url: '/api/claimdetail/fetchBenefitLimits',
            maskOn: '#blDialog .modal-dialog',
            params: {
                companyCode: config?.claimDetailsVO.claimStatusMstrVO.companyCode,
                groupNumber: config?.claimDetailsVO.claimStatusMstrVO.groupNumber,
                accountNumber: config?.claimDetailsVO.claimStatusMstrVO.accountNumber,
                productId: config?.claimDetailsVO.claimSummaryVO.productId,
                planCode: config?.planCode,
                participantIDNumber: config?.claimDetailsVO.claimStatusMstrVO.participantIDNumber,
                effectiveYear: formProps.values.effectiveYear,
                dependentSequenceNumber: patient
            },
            success: (res) => {
                setAnnualData(res.annualLimits);
                setFamilyData(res.familyAnnualLimits);
                setLifetimeData(res.lifetimeLimits);
                setOtherData(res.otherLimits);
            }
        });
    }

    function yearChangeHandler(e: any, formProps: any) {
        let year = e.target.value;
        ajax({
            url: '/api/claimdetail/fetchBenefitLimits',
            maskOn: '#blDialog .modal-dialog',
            params: {
                companyCode: config?.claimDetailsVO.claimStatusMstrVO.companyCode,
                groupNumber: config?.claimDetailsVO.claimStatusMstrVO.groupNumber,
                accountNumber: config?.claimDetailsVO.claimStatusMstrVO.accountNumber,
                productId: config?.claimDetailsVO.claimSummaryVO.productId,
                planCode: config?.planCode,
                participantIDNumber: config?.claimDetailsVO.claimStatusMstrVO.participantIDNumber,
                effectiveYear: year,
                dependentSequenceNumber: formProps.values.dependentSequenceNumber
            },
            success: (res) => {
                setAnnualData(res.annualLimits);
                setFamilyData(res.familyAnnualLimits);
                setLifetimeData(res.lifetimeLimits);
                setOtherData(res.otherLimits);
            }
        });
    }
    // To calculate all individual Co-Pay, Coinsurance and Discount Values.


    const {copay, coinsurance, discount} = config?.chargeDetailsList.reduce((acc, list)=> {

        acc.copay += list.copayment
        acc.coinsurance += list.coinsurance
        acc.discount += list?.benefitLineExcludedVO?.amountExcluded4 ?? 0
        return acc
    }, {copay:0 , coinsurance:0, discount: 0 })

    return <div id="claimDetailComp">
        <props.Row>
            <props.Col xs="12" sm="8" md="9">
                <div className="gwp-page-header">
                    <div className="gwp-page-header-block"></div>
                    <div className="gwp-page-header-label gwp-label"><GlobalizedText message="common.lbl.claim" /> {claimNumber} - {config.claimDetailsVO?.claimStatusMstrVO?.fullName}</div>
                </div>
            </props.Col>
            <props.Col xs="12" sm="4" md="3">
                <div className="cm_preNextClaim">
                    {preNextClaim}
                </div>
            </props.Col>
        </props.Row>

        <props.Panel>
            <props.PanelBody className="grpHgt">
                <props.Row>
                    <props.Col xs="12" sm="12" md="12">
                        <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                            <props.DLI title="common.lbl.group" desc={() => {
                                return <>
                                    {config.claimDetailsVO?.claimStatusMstrVO?.groupNumber} - {config.claimDetailsVO?.claimStatusMstrVO?.groupName}
                                </>
                            }} />
                            <props.DLI title="common.lbl.account" desc={() => {
                                return <>
                                    {config.claimDetailsVO?.claimStatusMstrVO?.accountNumber} - {config.claimDetailsVO?.claimStatusMstrVO?.accountName}
                                </>
                            }} />
                            {config.dependent?
                                <props.DLI title="dependent_title" desc={() => {
                                    return <>
                                        {`${config.claimDetailsVO?.claimStatusMstrVO?.participantIDNumber}-${config.claimDetailsVO?.claimStatusMstrVO?.dependentSequenceNumber}`} - {config.claimDetailsVO?.claimStatusMstrVO?.fullName}
                                    </>
                                }} />
                                :
                                <props.DLI title="common.lbl.member" desc={() => {
                                    return <>
                                        {config.claimDetailsVO?.claimStatusMstrVO?.participantIDNumber} - {config.claimDetailsVO?.claimStatusMstrVO?.fullName}
                                    </>
                                }} />
                            }
                        </props.DL>
                    </props.Col>
                </props.Row>
            </props.PanelBody>
        </props.Panel>
        <props.Row className="currencyRow">
            <props.Col xs="12" sm="6" md="12">
                <GlobalizedText message="common.lbl.allcurrencyvalue" />&nbsp;{config.claimDetailsVO?.claimStatusMstrVO?.currencyDec}
            </props.Col>
        </props.Row>
        <props.Panel>
            <props.PanelHeading>
                <props.Row>
                    <props.Col xs="12" sm="9" md="9">
                        <div className="claim-title">
                            <GlobalizedText message="common.lbl.claim.summary" />
                        </div>
                    </props.Col>
                    <props.Col xs="12" sm="3" md="3">
                        {benefitLimits}
                    </props.Col>
                </props.Row>
            </props.PanelHeading>
            <props.PanelBody replaceClass={true} className="coverage-panel-body">
                <props.Table option={tableOption} id="claimDetailsInfo"
                    onResponsiveDisplay={(rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element) => {
                        childNode?.querySelector("#viewMultiplePayees")?.addEventListener("click", (e: any) => {
                            openMultiplePayeesDialog();
                        });
                    }}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: "2%" }}></th>
                            <th scope="col" style={{ width: "18%" }}><GlobalizedText message="common.lbl.status" /></th>
                            <th scope="col" style={{ width: "20%" }}><GlobalizedText message="common.lbl.status.date" /></th>
                            <th scope="col" data-type="numeric" style={{ width: "20%" }}><GlobalizedText message="common.lbl.oth.car.pad" /></th>
                            <th scope="col" data-type="numeric" style={{ width: "20%" }}><GlobalizedText message="common.lbl.tot.amt.pad" /></th>
                            <th scope="col" style={{ width: "20%" }}><GlobalizedText message="common.lbl.pad.to" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>{(config.claimDetailsVO !== undefined && config.claimDetailsVO.claimStatusMstrVO !== undefined) ? config.claimDetailsVO.claimStatusMstrVO.claimStatusDesc : (config.claimDetailsVO !== undefined && config.claimDetailsVO.providerPaymentVO !== undefined ? config.claimDetailsVO.providerPaymentVO.providerPaymentStatus : "")}</td>
                            <td>{(config.claimDetailsVO !== undefined && config.claimDetailsVO.providerPaymentVO !== undefined) ? config.claimDetailsVO.providerPaymentVO.statusDate : ""}</td>
                            <td>{formatNumber((config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO != undefined && config.claimDetailsVO.claimSummaryVO.otherCarrierPaid != undefined) ? config.claimDetailsVO.claimSummaryVO.otherCarrierPaid : 0)}</td>
                            <td>{totalAmountPaid}</td>
                            <td>{Payee}</td>
                        </tr>
                    </tbody>
                </props.Table>
                <props.Table option={tableOption} id="claimDetailsInfo2" >
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: "2%" }}></th>
                            <th scope="col" style={{ width: "14%" }} data-type="numeric"><GlobalizedText message="common.lbl.amt.bill" /></th>
                            <th scope="col" style={{ width: "14%" }} data-type="numeric"><GlobalizedText message="common.lbl.dst.amt" /></th>
                            <th scope="col" style={{ width: "14%" }} data-type="numeric"><GlobalizedText message="common.lbl.exd.amt" /></th>
                            <th scope="col" style={{ width: "14%" }} data-type="numeric"><GlobalizedText message="common.lbl.ded.app" /></th>
                            <th scope="col" style={{ width: "14%" }} data-type="numeric"><GlobalizedText message="common.lbl.Copay.amt" /></th>
                            <th scope="col" style={{ width: "14%" }} data-type="numeric"><GlobalizedText message="common.lbl.Coinsurance.amt" /></th>
                            <th scope="col" style={{ width: "14%" }} data-type="numeric"><GlobalizedText message="common.lbl.pat.resp" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>{formatNumber((config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO !== undefined && config.claimDetailsVO.claimSummaryVO.amountBilledClaimSum !== undefined) ? config.claimDetailsVO.claimSummaryVO.amountBilledClaimSum : 0)}</td>
                            <td>{formatNumber(discount ?? 0)}</td>
                            <td>{formatNumber((config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO !== undefined && config.claimDetailsVO.claimSummaryVO.excludedAmount !== undefined) ? config.claimDetailsVO.claimSummaryVO.excludedAmount : 0)}</td>
                            <td>{formatNumber((config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO !== undefined && config.claimDetailsVO.claimSummaryVO.deductibleApplied !== undefined) ? config.claimDetailsVO.claimSummaryVO.deductibleApplied : 0)}</td>                            
                            <td>{formatNumber(copay ?? 0)}</td>
                            <td>{formatNumber(coinsurance ?? 0)}</td>
                            <td>{formatNumber((config.claimDetailsVO !== undefined && config.claimDetailsVO.providerPaymentVO !== undefined) ? config.claimDetailsVO.providerPaymentVO.patientResponsibility : 0)}</td>
                        </tr>
                    </tbody>
                </props.Table>
            </props.PanelBody>
        </props.Panel>
        <props.Panel>
            <props.PanelHeading>
                <div className="claim-title"><GlobalizedText message="common.lbl.cha.dets" /></div>
            </props.PanelHeading>
            <props.PanelBody replaceClass={true} className="coverage-panel-body">
                {chargeDetailsInfoTableObj !== undefined &&
                    <chargeDetailsInfoTableObj.Component />
                }

            </props.PanelBody>
        </props.Panel>

        {/* Modal for Total Amount Paid Dialog */}
        <props.Modal title="common.lbl.tot.amt.pad" id="totalAmountPaidDialog" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }} >
            <props.Row>
                <props.Col xs="12" sm="12" md="12">
                    <props.DL className="dl-horizontal gwp-dl">
                        <props.DLI condition={(config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO !== undefined && config.claimDetailsVO.claimSummaryVO.mclAppliedAmount !== undefined && config.claimDetailsVO.claimSummaryVO.mclAppliedAmount > 0) || (config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO !== undefined && config.claimDetailsVO.claimSummaryVO.cobSavingsAmount !== undefined && config.claimDetailsVO.claimSummaryVO.cobSavingsAmount > 0)} titleClass="totalAmtDt" title={() => {
                            return formatNumber(config.claimDetailsVO.claimSummaryVO.amountPayable != undefined ? config.claimDetailsVO.claimSummaryVO.amountPayable : 0);
                        }} desc={() => {
                            return <GlobalizedText message="com.lbl.amountPayable" />;
                        }}></props.DLI>
                        <props.DLI condition={config.claimDetailsVO !== undefined && config.claimDetailsVO.providerPaymentVO !== undefined && config.claimDetailsVO.providerPaymentVO.overpaymentRecoveredAmount !== undefined && config.claimDetailsVO.providerPaymentVO.overpaymentRecoveredAmount > 0} titleClass="totalAmtDt" title={() => {
                            return formatNumber(config.claimDetailsVO.providerPaymentVO.overpaymentRecoveredAmount);
                        }} desc={() => {
                            return <GlobalizedText message="com.lbl.overpaymentRecovery" />;
                        }}></props.DLI>
                        <props.DLI condition={config.claimDetailsVO !== undefined && config.claimDetailsVO.providerPaymentVO !== undefined && config.claimDetailsVO.providerPaymentVO.totalOffsetAmount !== undefined && config.claimDetailsVO.providerPaymentVO.totalOffsetAmount > 0} titleClass="totalAmtDt" title={() => {
                            return formatNumber(config.claimDetailsVO.providerPaymentVO.totalOffsetAmount);
                        }} desc={() => {
                            return config.claimDetailsVO.providerPaymentVO.offsetAmountIndicator;
                        }}></props.DLI>
                        <props.DLI condition={config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO !== undefined && config.claimDetailsVO.claimSummaryVO.mclAppliedAmount != undefined && config.claimDetailsVO.claimSummaryVO.cobSavingsAmount != undefined && config.claimDetailsVO.claimSummaryVO.mclAppliedAmount + config.claimDetailsVO.claimSummaryVO.cobSavingsAmount > 0} titleClass="totalAmtDt" title={() => {
                            return formatNumber((config.claimDetailsVO.claimSummaryVO.mclAppliedAmount != undefined ? config.claimDetailsVO.claimSummaryVO.mclAppliedAmount : 0) + (config.claimDetailsVO.claimSummaryVO.cobSavingsAmount != undefined ? config.claimDetailsVO.claimSummaryVO.cobSavingsAmount : 0));
                        }} desc={() => {
                            return <GlobalizedText message="com.lbl.adjustment.due.to.coordOfBenefits" />;
                        }}></props.DLI>
                        <props.DLI condition={config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO !== undefined && config.claimDetailsVO.claimSummaryVO.totalAmountPaid != undefined && config.claimDetailsVO.claimSummaryVO.totalAmountPaid > 0} titleClass="totalAmtDt" title={() => {
                            return formatNumber(config.claimDetailsVO.claimSummaryVO.totalAmountPaid != undefined ? config.claimDetailsVO.claimSummaryVO.totalAmountPaid : 0);
                        }} desc={() => {
                            return <GlobalizedText message="common.lbl.tot.amt.pad" />;
                        }}></props.DLI>
                    </props.DL>
                </props.Col>
            </props.Row>
        </props.Modal>

        {/* Modal for Multiple Payees Dialog */}
        <props.Modal title="common.lbl.multi.payee" id="multiplePayeesDialog" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }} >
            <props.Row>
                <props.Col xs="12" sm="12" md="12">
                    <props.DL className="dl-horizontal gwp-dl">
                        {config.claimDetailsVO.payeeList.map((payee: PayeeVO, index: number) => {
                            return <React.Fragment key={index}>
                                <props.DLI title={() => {
                                    return formatNumber(payee.checkAmount);
                                }} desc={() => {
                                    return <span className="multiplepayeetitle">{payee.name !== undefined ? payee.name : ""}</span>;
                                }}></props.DLI>
                                <props.DLI condition={payee.address1 !== undefined && payee.address1 !== ""} title="" desc={() => {
                                    return payee.address1 !== undefined ? payee.address1 : "";
                                }}></props.DLI>
                                <props.DLI condition={payee.address2 !== undefined && payee.address2 !== ""} title="" desc={() => {
                                    return payee.address2 !== undefined ? payee.address2 : "";
                                }}></props.DLI>
                                <props.DLI condition={payee.address3 !== undefined && payee.address3 !== ""} title="" desc={() => {
                                    return payee.address3 !== undefined ? payee.address3 : "";
                                }}></props.DLI>
                                <props.DLI title="" desc={() => {
                                    return payee.city !== undefined ? payee.city + ", " + payee.state + " " + payee.zipCode : "";
                                }}></props.DLI>
                            </React.Fragment>
                        })}
                    </props.DL>
                </props.Col>
            </props.Row>
        </props.Modal>
        {/* Modal for Not Covered Dialog */}
        <props.Modal title="common.lbl.not.coverd" id="notCoveredDialog" footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }} >
            <props.Row>
                <props.Col xs="12" sm="12" md="12">
                    <props.DL className="dl-horizontal gwp-dl">
                        <props.DLI condition={remarkCode !== undefined && remarkCode.amountExcluded > 0} titleClass="totalAmtDt" title={() => {
                            return formatNumber(remarkCode === undefined ? 0 : remarkCode.amountExcluded);
                        }} desc={() => {
                            return remarkCode?.remarkCode;
                        }}></props.DLI>
                        <props.DLI condition={remarkCode !== undefined && remarkCode.amountExcluded1 > 0} titleClass="totalAmtDt" title={() => {
                            return formatNumber(remarkCode === undefined ? 0 : remarkCode.amountExcluded1);
                        }} desc={() => {
                            return remarkCode?.remarkCode1;
                        }}></props.DLI>
                        <props.DLI condition={remarkCode !== undefined && remarkCode.amountExcluded2 > 0} titleClass="totalAmtDt" title={() => {
                            return formatNumber(remarkCode === undefined ? 0 : remarkCode.amountExcluded2);
                        }} desc={() => {
                            return remarkCode?.remarkCode2;
                        }}></props.DLI>
                        <props.DLI condition={remarkCode !== undefined && remarkCode.amountExcluded3 > 0} titleClass="totalAmtDt" title={() => {
                            return formatNumber(remarkCode === undefined ? 0 : remarkCode.amountExcluded3);
                        }} desc={() => {
                            return remarkCode?.remarkCode3;
                        }}></props.DLI>
                        <props.DLI condition={remarkCode !== undefined && remarkCode.amountExcluded4 > 0} titleClass="totalAmtDt" title={() => {
                            return formatNumber(remarkCode === undefined ? 0 : remarkCode.amountExcluded4);
                        }} desc={() => {
                            return remarkCode?.remarkCode4;
                        }}></props.DLI>
                        <props.DLI condition={remarkCode !== undefined && remarkCode.amountExcluded5 > 0} titleClass="totalAmtDt" title={() => {
                            return formatNumber(remarkCode === undefined ? 0 : remarkCode.amountExcluded5);
                        }} desc={() => {
                            return remarkCode?.remarkCode5;
                        }}></props.DLI>
                        <props.DLI titleClass="totalAmtDt" title={() => {
                            return formatNumber(sumAmountExcluded);
                        }} desc={() => {
                            return <GlobalizedText message="claimdetail.lbl.total.not.coverd" />;
                        }}></props.DLI>
                    </props.DL>
                </props.Col>
            </props.Row>
        </props.Modal>
        <React.Fragment>
            <Formik initialValues={{
                companyCode: config.claimDetailsVO?.claimStatusMstrVO?.companyCode,
                groupNumber: config.claimDetailsVO?.claimStatusMstrVO?.groupNumber,
                accountNumber: config.claimDetailsVO?.claimStatusMstrVO?.accountNumber,
                productId: config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO !== undefined && config.claimDetailsVO.claimSummaryVO.productId !== undefined ? config.claimDetailsVO.claimSummaryVO.productId : "",
                planCode: config.planCode,
                participantIDNumber: config?.claimDetailsVO?.claimStatusMstrVO?.participantIDNumber,
                effectiveYear: new Date().getFullYear(),
                dependentSequenceNumber: config.patientList.length > 0 ? config.patientList[0].value : "0"
            }}
                onSubmit={blDialogShow}
            >
                {formProps => {
                    return <Form>
                        {/* Modal for Benefit Limits Dialog */}
                        <props.Modal title="common.lbl.claim.benefit.limits" id="blDialog" onClose={()=> formProps.resetForm()} footer={() => {
                            return <>
                                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                            </>
                        }} >
                            <props.Row>
                                <props.Col xs="12" sm="12" md="12">
                                    <props.DL className="dl-horizontal gwp-dl">
                                        <props.DLI title="common.lbl.group" desc={() => {
                                            return config.claimDetailsVO?.claimStatusMstrVO?.groupNumber + "-" + config.claimDetailsVO?.claimStatusMstrVO?.groupName;
                                        }}></props.DLI>
                                        <props.DLI title="common.lbl.product" desc={() => {
                                            return <>{config.claimDetailsVO !== undefined && config.claimDetailsVO.claimSummaryVO !== undefined && config.claimDetailsVO.claimSummaryVO.productName !== undefined ? config.claimDetailsVO.claimSummaryVO.productName : ""}</>;
                                        }}></props.DLI>
                                        <props.DLI title="common.lbl.plan" desc={() => {
                                            return <>{config.planCodeDesc !== undefined ? config.planCodeDesc : ""}</>;
                                        }}></props.DLI>
                                        <props.SelectControl className="gwp-inline" sort={false} name="effectiveYear" onChange={(e) => { yearChangeHandler(e, formProps) }} label="common.lbl.claim.plan.year" options={config.planYearList} />
                                        <props.SelectControl className="gwp-inline" sort={false} name="dependentSequenceNumber"
                                            onChange={(e) => { patientChangeHandler(e, formProps) }} label="common.lbl.patient" options={config.patientList} />
                                    </props.DL>
                                </props.Col>
                            </props.Row>
                            <props.Row>
                                <props.Col xs="12" sm="10" md="10">
                                    <props.Div className="benefitMsg"><GlobalizedText message="common.lbl.claim.benft.show.not.guart.elig" /></props.Div>
                                </props.Col>
                                <props.Col xs="12" sm="2" md="2">
                                </props.Col>
                            </props.Row>
                            <props.Panel>
                                <props.PanelHeading>
                                    <div className="claim-title"><GlobalizedText message="common.lbl.annual.limits" /></div>
                                </props.PanelHeading>
                                <props.PanelBody className="coverage-panel-body">
                                    <props.Table modal="blDialog" id="annual" option={limitTableOption} table={{
                                        data: annualData,
                                        columns: columns
                                    }}></props.Table>
                                </props.PanelBody>
                            </props.Panel>
                            <props.Panel>
                                <props.PanelHeading>
                                    <div className="claim-title"><GlobalizedText message="common.lbl.family.annual.limits" /></div>
                                </props.PanelHeading>
                                <props.PanelBody className="coverage-panel-body">
                                    <props.Table modal="blDialog" id="family" option={limitTableOption} table={{
                                        data: familyData,
                                        columns: columns
                                    }}></props.Table>
                                </props.PanelBody>
                            </props.Panel>
                            <props.Panel>
                                <props.PanelHeading>
                                    <div className="claim-title"><GlobalizedText message="common.lbl.lifetime.limits" /></div>
                                </props.PanelHeading>
                                <props.PanelBody className="coverage-panel-body">
                                    <props.Table modal="blDialog" id="lifetime" option={limitTableOption} table={{
                                        data: lifetimeData,
                                        columns: columns
                                    }}></props.Table>
                                </props.PanelBody>
                            </props.Panel>
                            <props.Panel>
                                <props.PanelHeading>
                                    <div className="claim-title"><GlobalizedText message="common.lbl.other.limits" /></div>
                                </props.PanelHeading>
                                <props.PanelBody className="coverage-panel-body">
                                    <props.Table modal="blDialog" id="otherLimits" option={limitTableOption} table={{
                                        data: otherData,
                                        columns: otherColumns
                                    }}></props.Table>
                                </props.PanelBody>
                            </props.Panel>
                        </props.Modal>
                    </Form>
                }}
            </Formik>
        </React.Fragment>
    </div >
});


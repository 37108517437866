import { CancelTokenSource } from 'axios';
import { Form, Formik } from 'formik';
import { useContext } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { UserContext } from '../shared/authentication';
import { ProgressBarPagesConfig } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import PolicyNumber from '../shared/components/policyNumberRegistrationComponent';
import OrComp from '../shared/components/orComponent'
import { ajax, copyObjectExcept, isEmptyObject, isEmptyStr, mergeObject, trimFields, validate, trim, getFromStorage } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { INVALID_MESSAGES, YupSchema } from '../shared/yupschema';
import './css/registernewusers.css';
import './css/registration.css';
import RegistrationUser from '../shared/interfaces/registrationUser';
import Step from '../shared/interfaces/step';
import PageProps from '../shared/interfaces/pageProps';
import { VALIDATION_REGEX } from '../shared/regex';

const cfgProcessBar3: ProgressBarPagesConfig = {
    pages: [
        { name: 'common.lbl.personalInformation', status: "unset" },
        { name: 'regist.label.login.and.security.information', status: "unset" },
        { name: 'regist.label.preference.information', status: "current" }
    ]
};
const cfgProcessBar1: ProgressBarPagesConfig = {
    pages: [
        { name: 'common.lbl.personalInformation', status: "current" },
        { name: 'regist.label.login.and.security.information', status: "unset" },
        { name: 'regist.label.preference.information', status: "unset" }
    ]
};
const cfgProcessBar2: ProgressBarPagesConfig = {
    pages: [
        { name: 'common.lbl.personalInformation', status: "unset" },
        { name: 'regist.label.login.and.security.information', status: "current" },
        { name: 'regist.label.preference.information', status: "unset" }
    ]
};

let initialUser: RegistrationUser = {
    "userTypeRegCode": "",
    "nameType": "",
    "firstName": "",
    "lastName": "",
    "orgName": "",
    "birthDate": "",
    "govtID": "",
    "polNumberOrMemberID": "",
    "policyNumber": "",
    "memberID": "",
    //"orgName": "",
    "groupNumber": "",
    "clientNumber": "",
    "nationProvideID": "",
    "providerInternalKey": "",
    "email": "",
    "phoneNumber": "",
    "jobTitle": "",
    "companyCode": "",
    "agencyName": "",
    "agencyNumber": "",
    "agentNumber": "",
    "userID": "",
    "password": "",
    "secImg": {
        "category": {
            "msgKey": "",
            "name": ""
        },
        "msgKey": "",
        "name": ""
    },
    "secCaption": "",
    "securityQuestion1": "",
    "securityQuestion2": "",
    "securityQuestion3": "",
    "answer1": "",
    "answer2": "",
    "answer3": "",
    "dateFormat": "",
    "languageCode": "",
    "step": 1
}

export const RegistrationComponent = withView((props: ViewComponentProps) => {
    const step: Step = useStep(props);
    const [registrationUser, setRegistrationUser] = useState<RegistrationUser>(initialUser);
    const [config, setConfig] = useState<any>();
    //const { messageService, validateProps } = useMessage("registration");
    const needInit = useRef(config === undefined || props.isForceRefresh());
    if (props.isForceRefresh()) {
        needInit.current = true
    }
    useEffect(() => {
        let user: RegistrationUser = props.getInitParams();
        if (user !== undefined && !isEmptyObject(user)) {
            setRegistrationUser(user);
            step.setStep(user.step);
        }
        let cancelToken: CancelTokenSource;
        if (needInit.current === true) {
            needInit.current = false;
            cancelToken = ajax({
                url: '/api/registrationSingle/view',
                success: (viewConfig) => {
                    setConfig(viewConfig);
                    if (viewConfig.userTypeRegCodes.length === 0) {
                        props.showMessage("error", props.getGlobalizedText("regist.label.no.register"));
                    }
                    if (props.isForceRefresh()) {
                        // refresh the component to initial status
                        props.setInitParams(undefined);
                        setRegistrationUser(initialUser);
                        step.first();
                        props.refreshed();
                    }

                }
            });
        }
        return () => {
            if (cancelToken !== undefined) {
                cancelToken.cancel();
            }
        }
    }, [config, props.isForceRefresh()]);

    if (config === undefined || step.step() !== registrationUser.step) {
        return <></>
    }
    return <>
        {config.userTypeRegCodes.length !== 0 && <>
            <props.Row>
                <props.Col sm='12'>
                    {step.step() === 1 && (config.userTypeRegCodes ? (config.userTypeRegCodes[0].value ? true : (config.userTypeRegCodes.value ? true : false)) : false) &&
                        <props.ProgressBar id="registrationProgressBar1" config={cfgProcessBar1}></props.ProgressBar>
                    }
                    {step.step() === 2 &&
                        <props.ProgressBar id="registrationProgressBar2" config={cfgProcessBar2}></props.ProgressBar>
                    }
                    {step.step() === 3 &&
                        <props.ProgressBar id="registrationProgressBar3" config={cfgProcessBar3}></props.ProgressBar>
                    }
                    {step.step() === 4 &&
                        <props.Panel>
                            <props.PanelBody>
                                <div className="h4">
                                    <span className="glyphicon glyphicon-ok" style={{ margin: '0 10px 0 0' }}></span>
                                    <GlobalizedText message="regist.label.register.complete" />
                                </div>
                            </props.PanelBody>
                        </props.Panel>
                    }
                </props.Col>
            </props.Row>
            <props.Row>
                <props.Col>
                    <props.Container>
                        {step.step() === 1 &&

                            <FirstPage {...props} regConfig={config} step={step} registrationUser={registrationUser} setRegistrationUser={setRegistrationUser} />
                        }
                        {step.step() === 2 &&
                            <SecondPage {...props} regConfig={config} step={step} registrationUser={registrationUser} setRegistrationUser={setRegistrationUser} />
                        }
                        {step.step() === 3 &&
                            <ThirdPage {...props} regConfig={config} step={step} registrationUser={registrationUser} setRegistrationUser={setRegistrationUser} />
                        }
                    </props.Container>
                </props.Col>
            </props.Row>
        </>
        }
    </>
});

const FirstPage = (props: PageProps) => {

    const [payeeAddress, setPayeeAddress] = useState<Array<any>>();
    const isLogin = useContext(UserContext);
    const [companyList, setCompanyList] = useState<any>();
    const clearPayeeAddress = (formProps: any) => {
        props.setRegistrationUser({ ...props.registrationUser, ...{ providerInternalKey: '' } })
        formProps.setFieldValue("providerInternalKey", '');
        setPayeeAddress(undefined);
    }

    useEffect(() => {
        if (payeeAddress === undefined && !isEmptyStr(props.registrationUser.providerInternalKey)) {
            ajax({
                url: '/api/registrationSingle/pageOneValidate',
                data: copyObjectExcept(props.registrationUser, false, "providerInternalKey"),
                method: 'post',
                success: (res: any | null) => {
                    if (res.providerAddresses) {
                        setPayeeAddress(res.providerAddresses);
                        props.step.first();
                    }
                },
                fail: (res: any) => {
                    props.showMessage("error", res.data.message);
                }, error: (error: any) => {
                    props.showMessage("error", error.response.data.message);
                }
            });
        }
    })

    let byPageOne021 = yup.object().shape({
        firstName: YupSchema.registration.firstName,
        lastName: YupSchema.registration.lastName,
        email: YupSchema.email,
        birthDate: YupSchema.birthDate(props.regConfig.dateformat, props.getGlobalizedText)
    });

    let byPageOne022 = yup.object().shape({
        orgName: YupSchema.registration.companyName,
        govtID: props.regConfig.indMemGovtIdIsRequired && YupSchema.govtID("EIN", props.getGlobalizedText),
        polNumberOrMemberID:YupSchema.registration.policyNumberOrMemberId
    });

    let byProvider = (values: any) => {
        let res: any = {};
        if ((isEmptyStr(values.firstName) && isEmptyStr(values.lastName) && isEmptyStr(values.orgName)) || ((!isEmptyStr(values.firstName) || !isEmptyStr(values.lastName)) && !isEmptyStr(values.orgName))) {
            res = { ...res, ...{ firstName: INVALID_MESSAGES.FIRSTNAME_LASTNAME_OR_INSTITUION_NAME_IS_REQUIRED, lastName: INVALID_MESSAGES.FIRSTNAME_LASTNAME_OR_INSTITUION_NAME_IS_REQUIRED, orgName: INVALID_MESSAGES.FIRSTNAME_LASTNAME_OR_INSTITUION_NAME_IS_REQUIRED } };
        } else {
            if (isEmptyStr(values.orgName)) {
                if (isEmptyStr(values.firstName)) {
                    res = { ...res, ...{ firstName: INVALID_MESSAGES.FIRSTNAME_IS_EMPTY } }
                }
                if (isEmptyStr(values.lastName)) {
                    res = { ...res, ...{ lastName: INVALID_MESSAGES.LASTNAME_IS_EMPTY } }
                }
                if (!isEmptyStr(values.firstName) && values.firstName.trim().length > 20) {
                    res = { ...res, ...{ firstName: INVALID_MESSAGES.FIRSTNAME_IS_TOO_LONG } }
                }
                if (!isEmptyStr(values.lastName) && values.lastName.trim().length > 40) {
                    res = { ...res, ...{ lastName: INVALID_MESSAGES.LASTNAME_IS_TOO_LONG } }
                }
            }
            else if (values.orgName.trim().length > 80) {
                res = { ...res, ...{ orgName: INVALID_MESSAGES.COMPANY_NAME_IS_TOO_LONG } }
            }
        }

        if ((isEmptyStr(values.govtID) || values.govtID.trim() === "0") && (isEmptyStr(values.nationProvideID) || values.nationProvideID.trim() === "0")) {
            res = { ...res, ...{ govtID: INVALID_MESSAGES.NATIONAL_PROVIDER_ID_OR_PROVIDER_GOVERNMENT_ID_IS_REQUIRED, nationProvideID: INVALID_MESSAGES.NATIONAL_PROVIDER_ID_OR_PROVIDER_GOVERNMENT_ID_IS_REQUIRED } }
        }
        return res;
    }

    let byMember = (values: any) => {
        let res: any = {};
        res = validatePersonalDetailsForMemberAndDependent(values, res, props.regConfig.indMemEmailIsRequired);
        if ((isEmptyStr(values.memberID) && isEmptyStr(values.groupNumber) && isEmptyStr(values.policyNumber)) || ((!isEmptyStr(values.memberID) || !isEmptyStr(values.groupNumber)) && !isEmptyStr(values.policyNumber))) {
            res = { ...res, ...{ memberID: INVALID_MESSAGES.MEMBERID_GROUP_NUMBER_OR_POLICY_NUMBER_REQUIRED, groupNumber: INVALID_MESSAGES.MEMBERID_GROUP_NUMBER_OR_POLICY_NUMBER_REQUIRED, policyNumber: INVALID_MESSAGES.MEMBERID_GROUP_NUMBER_OR_POLICY_NUMBER_REQUIRED } };
        } else {
            if (isEmptyStr(values.policyNumber)) {
                if (isEmptyStr(values.memberID)) {
                    res = { ...res, ...{ memberID: INVALID_MESSAGES.MEMBERID_OR_POLICYNUMBER_IS_EMPTY } }
                }
                if (!isEmptyStr(values.memberID) && values.memberID.trim().length > 10) {
                    res = { ...res, ...{ memberID: INVALID_MESSAGES.MEMBERID_POLICYNUMBER_TOO_LONG } }
                }
                res = validateGroupNumber(values, res);
            }
            else if (values.policyNumber.trim().length > 10) {
                res = { ...res, ...{ policyNumber: INVALID_MESSAGES.MEMBERID_POLICYNUMBER_TOO_LONG } }
            }
        }

		if (isEmptyStr(values.govtID) && props.regConfig.indMemGovtIdIsRequired) {
        res = { ...res, ...{ govtID: INVALID_MESSAGES.GOVT_ID_IS_EMPTY } };
    	}
        return res;
    }

    let byDependent = (values: any) => {
        let res: any = {};
        res = validatePersonalDetailsForMemberAndDependent(values, res, props.regConfig.depEmailIsRequired);
        if ((isEmptyStr(values.memberID) && isEmptyStr(values.groupNumber) && isEmptyStr(values.policyNumber)) || ((!isEmptyStr(values.memberID) || !isEmptyStr(values.groupNumber)) && !isEmptyStr(values.policyNumber))) {
            res = { ...res, ...{ memberID: INVALID_MESSAGES.MEMBERID_GROUP_NUMBER_OR_POLICY_NUMBER_REQUIRED, groupNumber: INVALID_MESSAGES.MEMBERID_GROUP_NUMBER_OR_POLICY_NUMBER_REQUIRED, policyNumber: INVALID_MESSAGES.MEMBERID_GROUP_NUMBER_OR_POLICY_NUMBER_REQUIRED } };
        } else {
            if (isEmptyStr(values.policyNumber)) {
                if (isEmptyStr(values.memberID)) {
                    res = { ...res, ...{ memberID: INVALID_MESSAGES.MEMBERID_OR_POLICYNUMBER_IS_EMPTY } }
                }
                if (!isEmptyStr(values.memberID) && values.memberID.trim().length > 13) {
                    res = { ...res, ...{ memberID: INVALID_MESSAGES.DEPENDENTID_TO_LONG } }
                }
                res = validateGroupNumber(values, res);
            }
            else if (values.policyNumber.trim().length > 10) {
                res = { ...res, ...{ policyNumber: INVALID_MESSAGES.MEMBERID_POLICYNUMBER_TOO_LONG } }
            }
        }
        return res;
    }
    let byPageOne06 = yup.object().shape({
        firstName: YupSchema.registration.firstName,
        lastName: YupSchema.registration.lastName,
        birthDate: YupSchema.birthDate(props.regConfig.dateformat, props.getGlobalizedText),
        govtID: YupSchema.govtID("SSN", props.getGlobalizedText),
        agentNumber: YupSchema.registration.agentNumber
    });
    let byPageOne08 = yup.object().shape({
        agencyName: YupSchema.registration.agencyName,
        govtID: YupSchema.govtID("EIN", props.getGlobalizedText),
        agencyNumber: YupSchema.registration.agencyNumber
    });
    // 09 && 05
    let byPageOne09 = yup.object().shape({
        firstName: YupSchema.registration.firstName,
        lastName: YupSchema.registration.lastName,
        email: YupSchema.email,
        phoneNumber: YupSchema.phoneNumber,
        jobTitle: YupSchema.registration.jobTitle
    });
    let byPageOne091 = yup.object().shape({
        firstName: YupSchema.registration.firstName,
        lastName: YupSchema.registration.lastName,
        email: YupSchema.email,
        phoneNumber: YupSchema.phoneNumber
    });
    let byPageOne092 = yup.object().shape({
        firstName: YupSchema.registration.firstName,
        lastName: YupSchema.registration.lastName,
        email: YupSchema.email,
        jobTitle: YupSchema.registration.jobTitle
    });
    let byPageOne093 = yup.object().shape({
        firstName: YupSchema.registration.firstName,
        lastName: YupSchema.registration.lastName,
        email: YupSchema.email,
    });

    let defaultOptions = {
        userTypeRegCode: props.regConfig.userTypeRegCodes[0].value,
        nameType: props.regConfig.nameTypes[0].value,
        companyCode: props.regConfig.defaultCompanyCode,
    }

    let initialValues = {
        ...{
            ...props.registrationUser,
            userTypeRegCode: props.registrationUser.userTypeRegCode !== '' ? props.registrationUser.userTypeRegCode : props.regConfig.userTypeRegCodes[0].value,
            nameType: props.registrationUser.nameType !== '' ? props.registrationUser.nameType : props.regConfig.nameTypes[0].value,
            companyCode: props.registrationUser.companyCode !== '' ? props.registrationUser.companyCode : props.regConfig.defaultCompanyCode,
        }
    }

    const convertErrorMessages = (errorMessages: Array<any>): any => {
        let result: any = {};
        errorMessages.forEach((e: any) => {
            result[e.fieldName] = e.message;
        });
        return result;
    }
    const isProviderRegistered = (erroMessages: Array<any>): boolean => {
        let result: boolean = false;
        if (erroMessages !== undefined && erroMessages.length >= 1) {
            erroMessages.forEach((e: any) => {
                if (trim(e.message).toUpperCase().indexOf("BEEN REGISTERED") !== -1) {
                    result = true;
                }
            });
        }
        return result;
    }
    function toHomePage() {
        let landingpage = getFromStorage("gwp.config").landingpage;
        props.next(`${landingpage}`);
    }
    return (<React.Fragment>
        <Formik initialValues={initialValues}
            {...{ ...props.generateNoramlFormikProps(), ...{ onReset: props.clearMessage } }}
            validate={(values: any) => {
                if (values.userTypeRegCode === props.regConfig.individual || !values.userTypeRegCode) {
                    //userType 02
                    if (values.nameType === props.regConfig.nameTypePerson) {
                        //nameType 1
                        return validate(byMember, values, props);
                    } else if (values.nameType === props.regConfig.nameTypeCompany) {
                        //nameType 2
                        return validate(byPageOne022, values, props);
                    }
                } else if (values.userTypeRegCode === props.regConfig.provider) {
                    if (values.orgName !== "") {
                        values.nameType=props.regConfig.nameTypeCompany;
                    }
                    return validate(byProvider, values, props);
                } else if (values.userTypeRegCode === props.regConfig.csr || values.userTypeRegCode === props.regConfig.groupadmin) {
                    //userType 05 || 09
                    if (values.jobTitle && values.phoneNumber) {
                        return validate(byPageOne09, values, props);
                    } else if (values.phoneNumber) {
                        return validate(byPageOne091, values, props);
                    } else if (values.jobTitle) {
                        return validate(byPageOne092, values, props);
                    } else {
                        return validate(byPageOne093, values, props);
                    }
                    //return validate(byPageOne05, values, props);
                } else if (values.userTypeRegCode === props.regConfig.agent) {
                    //userType 06
                    return validate(byPageOne06, values, props);
                } else if (values.userTypeRegCode === props.regConfig.agency) {
                    values.nameType=props.regConfig.nameTypeCompany;
                    //userType 08
                    return validate(byPageOne08, values, props);
                } else if (values.userTypeRegCode === props.regConfig.dependent) {
                    //userType 13
                    values.nameType = props.regConfig.nameTypePerson;
                    return validate(byDependent, values, props);
                }
            }}

            onSubmit={(values: any, formikHelpers: any) => {
                if (values.userTypeRegCode === props.regConfig.provider) {
                    //userType 04                
                    ajax({
                        url: '/api/registrationSingle/pageOneValidate',
                        data: { ...trimFields(values), ...{ govtID: trim(values.govtID.replaceAll("-", "")) } },
                        method: 'post',
                        success: (res: any | null) => {
                            let user = { ...props.registrationUser, ...values };
                            updateUser(props, user);
                            if (res.providerAddresses) {
                                setPayeeAddress(res.providerAddresses);
                                formikHelpers.setFieldValue("providerInternalKey", res.providerAddresses[0].value);
                                if (res.providerAddresses.length == 1) {
                                    props.step.second();
                                    user = mergeObject(user, { "providerInternalKey": res.providerAddresses[0].value })
                                    updateUser(props, { ...user, ...{ step: 2 } });
                                }
                            } else {
                                props.step.second();
                                updateUser(props, { ...user, ...{ step: 2 } });
                            }
                        },
                        fail: (res: any) => {
                            if (isProviderRegistered(res.errorMessages)) {
                                setPayeeAddress(res.providerAddresses);
                            } else {
                                setPayeeAddress(undefined);
                            }
                            props.showFieldError("homepage.errorFields", convertErrorMessages(res.errorMessages), formikHelpers);
                        }
                    });

                } else {
                    const newValues = {...values,policyNumber:values.policyNumber?.toUpperCase()}                
                    ajax({
                        url: '/api/registrationSingle/pageOneValidate',
                        data: { ...trimFields(newValues), ...{ govtID: values.govtID.replaceAll("-", "") } },
                        method: 'post',
                        success: (res: any) => {
                            let user = { ...props.registrationUser, ...newValues, ...{ step: 2 },
                                ...{polNumberOrMemberID: !isEmptyStr(newValues.memberID) && !isEmptyStr(newValues.groupNumber) ? newValues.memberID : newValues.policyNumber}
                            };
                            if (newValues.nameType === props.regConfig.nameTypeCompany && !isEmptyStr(newValues.polNumberOrMemberID))
                                user = {...user, polNumberOrMemberID: newValues.polNumberOrMemberID};
                            updateUser(props, user);
                            props.step.second();
                        },
                        fail: (res: any) => {
                            props.showFieldError("homepage.errorFields", convertErrorMessages(res.errorMessages), formikHelpers);
                        }
                    });
                }

            }}>
            {(formProps: any) => <Form>
                <Title {...props} title="common.lbl.personalInformation"></Title>
                <props.Row>
                    <props.Col md="6" sm="6" xs="12">
                        <props.SelectControl name="userTypeRegCode" onChange={(e: any) => {
                            setPayeeAddress(undefined);
                            props.resetForm(formProps, { ...initialUser, ...defaultOptions, ...{ userTypeRegCode: e.target.value, } });
                            props.setRegistrationUser(mergeObject(props.registrationUser, { providerInternalKey: '' }));
                            props.setInitParams(undefined);
                            if (e.target.value != props.regConfig.csr) {
                                let arr = new Array();
                                props.regConfig.companyList.map((item: any) => {
                                    if (item.value != '0') {
                                        arr.push(item);
                                    }
                                });
                                setCompanyList(arr);
                            } else {
                                setCompanyList(props.regConfig.companyList);
                            }

                        }} label="regist.label.chooserole" required={true} options={props.regConfig.userTypeRegCodes} />

                        {//Individual &amp; Member 02
                            (formProps.values.userTypeRegCode === props.regConfig.individual || !formProps.values.userTypeRegCode) && <>
                                <props.SelectControl name="nameType" onChange={(e: any) => { props.resetForm(formProps, { ...initialUser, ...defaultOptions, ...{ userTypeRegCode: formProps.values.userTypeRegCode, nameType: e.target.value } }); props.setInitParams(undefined); }} label="addbene.lbl.choseType" required={true} options={props.regConfig.nameTypes} />

                                {//Name type: Person 1
                                    formProps.values.nameType === props.regConfig.nameTypePerson && <>
                                        <props.TextControl id="firstName" name="firstName" required={true} label="common.lbl.firstName" />
                                        <props.TextControl id="lastName" name="lastName" required={true} label="common.lbl.lastName" />
                                        <props.TextControl id="email" name="email" required={props.regConfig.indMemEmailIsRequired} label="regist.label.email" />
                                        <props.DateTimePickerControl htmlFor="birthDate_input" id="birthDate" name="birthDate" label="common.lbl.dob" dateformat={props.regConfig.dateformat} required={true} />
                                        <props.AutoFormatControl formatType="SSN" condition={props.regConfig.indMemGovtIdIsRequired} autoFormat={false} id="govtID" name="govtID" required={props.regConfig.indMemGovtIdIsRequired} label="common.lbl.govID" valueWithFormat={false} />
                                    </>}
                                {//Name type: Company 2
                                    formProps.values.nameType === props.regConfig.nameTypeCompany && <>
                                        <props.TextControl id="orgName" name="orgName" required={true} label="changeow.lbl.companyName" />
                                        <props.AutoFormatControl formatType="EIN" condition={props.regConfig.indMemGovtIdIsRequired} autoFormat={false} id="govtID" name="govtID" required={props.regConfig.indMemGovtIdIsRequired} label="common.lbl.govID" valueWithFormat={false} />
                                    </>
                                }
                            </>}
                        {//Dependent 13
                            (formProps.values.userTypeRegCode === props.regConfig.dependent || !formProps.values.userTypeRegCode) && <>

                                {//Name type: Person 1
                                    formProps.values.nameType === props.regConfig.nameTypePerson && <>
                                        <props.TextControl id="firstName" name="firstName" required={true} label="common.lbl.firstName" />
                                        <props.TextControl id="lastName" name="lastName" required={true} label="common.lbl.lastName" />
                                        <props.TextControl id="email" name="email" required={props.regConfig.depEmailIsRequired} label="regist.label.email" />
                                        <props.DateTimePickerControl htmlFor="birthDate_input" id="birthDate" name="birthDate" label="common.lbl.dob" dateformat={props.regConfig.dateformat} required={true} />
                                        <props.AutoFormatControl formatType="SSN" condition={props.regConfig.depGovtIdIsRequired} autoFormat={false} id="govtID" name="govtID" required={props.regConfig.depGovtIdIsRequired} label="common.lbl.govID" valueWithFormat={false} />
                                    </>}
                            </>}
                        {//Provider 04
                            formProps.values.userTypeRegCode === props.regConfig.provider && <>
                                <props.Information message="regist.msg.info.first_last.name.or.institution.name.required" />
                                <props.TextControl onChange={() => { clearPayeeAddress(formProps) }} id="firstName" name="firstName" label="common.lbl.firstName" />
                                <props.TextControl onChange={() => { clearPayeeAddress(formProps) }} id="lastName" name="lastName" label="common.lbl.lastName" />
                                <Or {...props} />
                                <props.TextControl onChange={() => { clearPayeeAddress(formProps) }} id="orgName" name="orgName" label="regist.label.national.company.name" />
                            </>}
                        {//Customer Service Representative 05 ||Group Administrator 09
                            (formProps.values.userTypeRegCode === props.regConfig.csr || formProps.values.userTypeRegCode === props.regConfig.groupadmin) && <>
                                <props.TextControl id="firstName" name="firstName" required={true} label="common.lbl.firstName" />
                                <props.TextControl id="lastName" name="lastName" required={true} label="common.lbl.lastName" />
                                <props.TextControl id="email" name="email" required={formProps.values.userTypeRegCode === props.regConfig.csr && props.regConfig.csrEmailIsRequired} label="regist.label.email" />
                                <props.TextControl id="phoneNumber" name="phoneNumber" label="regist.label.phone" />
                            </>}
                        {//Agent &amp; Broker 06 
                            formProps.values.userTypeRegCode === props.regConfig.agent && <>
                                <props.TextControl id="firstName" name="firstName" required={true} label="common.lbl.firstName" />
                                <props.TextControl id="lastName" name="lastName" required={true} label="common.lbl.lastName" />
                                <props.DateTimePickerControl htmlFor="birthDate_input" id="birthDate" name="birthDate" label="common.lbl.dob" dateformat={props.regConfig.dateformat} required={true} />
                                <props.AutoFormatControl formatType="SSN" id="govtID" name="govtID" required={true} label="common.lbl.govID" valueWithFormat={false} />
                            </>}
                        {//Agency 08
                            formProps.values.userTypeRegCode === props.regConfig.agency && <>
                                <props.TextControl id="agencyName" name="agencyName" required={true} label="regist.label.agency.name" />
                                <props.AutoFormatControl formatType="EIN" id="govtID" name="govtID" required={true} label="common.lbl.govID" valueWithFormat={false} />
                                <props.TextControl id="agencyNumber" name="agencyNumber" required={true} label="regist.label.agency.number" />
                            </>}

                    </props.Col>
                    <props.Col md="6" sm="6" xs="12">
                        {/* Member OR Dependent 2nd Column in UI */}
                        {(formProps.values.userTypeRegCode === props.regConfig.individual && formProps.values.nameType === props.regConfig.nameTypeCompany) && <>
                            <props.TextControl id="polNumberOrMemberID" name="polNumberOrMemberID" required={true} label="common.lbl.memberIdentifierPolicyNumber" />
                        </>}
                        {((formProps.values.userTypeRegCode === props.regConfig.individual && formProps.values.nameType !== props.regConfig.nameTypeCompany) || formProps.values.userTypeRegCode === props.regConfig.dependent) && <>
                                <props.Div>
                                    <props.Div>
                                        <props.Label><GlobalizedText message="regist.msg.member.regist.instruction.title" /></props.Label>
                                    </props.Div>
                                    <props.Span>
                                        <ul>
                                            <li><GlobalizedText message="regist.msg.member.regist.instruction1" /></li>
                                            <li><GlobalizedText message="regist.msg.member.regist.instruction2" /></li>
                                            {formProps.values.userTypeRegCode === props.regConfig.dependent &&
                                                <li><GlobalizedText message="regist.msg.dep.regist.instruction3" /></li>
                                            }
                                        </ul>
                                    </props.Span>
                                </props.Div>
                                <props.Information message={INVALID_MESSAGES.MEMBERID_GROUP_NUMBER_OR_POLICY_NUMBER_REQUIRED} className="marginNationalInfo" />
                        </>}
                        {(formProps.values.userTypeRegCode === props.regConfig.individual && formProps.values.nameType !== props.regConfig.nameTypeCompany  && props?.userService?.rootComponent?.state?.version !== 1) && <>
                        	<props.TextControl id="memberID" name="memberID" label="common.lbl.memberIdentifier" />
                        </>}
                        {(formProps.values.userTypeRegCode === props.regConfig.dependent  && props?.userService?.rootComponent?.state?.version !==1) &&
                            <props.TextControl id="memberID" name="memberID" label="common.lbl.dependentID" />
                        }
                        {((formProps.values.userTypeRegCode === props.regConfig.individual && formProps.values.nameType !== props.regConfig.nameTypeCompany && props?.userService?.rootComponent?.state?.version !== 1) || formProps.values.userTypeRegCode === props.regConfig.dependent && props?.userService?.rootComponent?.state?.version !== 1) && <>
                            <props.TextControl id="groupNumber" name="groupNumber" label="common.lbl.groupNumber" />
                        </>}

                        {((formProps.values.userTypeRegCode === props.regConfig.individual && formProps.values.nameType !== props.regConfig.nameTypeCompany && props?.userService?.rootComponent?.state?.version === 3) || formProps.values.userTypeRegCode === props.regConfig.dependent && props?.userService?.rootComponent?.state?.version === 3) && <>
                            <OrComp {...props} />
                        </>}
                        
                        {((formProps.values.userTypeRegCode === props.regConfig.individual && formProps.values.nameType !== props.regConfig.nameTypeCompany && (props?.userService?.rootComponent?.state?.version === 1 || props?.userService?.rootComponent?.state?.version === 3)) || formProps.values.userTypeRegCode === props.regConfig.dependent && (props?.userService?.rootComponent?.state?.version === 1 || props?.userService?.rootComponent?.state?.version === 3)) && <>
                            <PolicyNumber props = {props} />
                        </>}
                        {/* Provider 2nd Column in UI */}
                        {formProps.values.userTypeRegCode === props.regConfig.provider && <>

                            <props.Div>
                                <props.Div>
                                    <props.Label><GlobalizedText message="regist.msg.provider.regist.instruction.title" /></props.Label>
                                </props.Div>
                                <props.Span>
                                    <GlobalizedText message="regist.msg.provider.regist.instruction" />
                                    <ul>
                                        <li><GlobalizedText message="regist.msg.indiprovider.regist.instruction" /></li>
                                        <li><GlobalizedText message="regist.msg.groupprovider.regist.instruction" /></li>
                                    </ul>
                                </props.Span>
                            </props.Div>
                            <props.Information message="regist.msg.info.national.provider.or.gov.id.required" className="marginNationalInfo" />
                            <props.TextControl onChange={() => { clearPayeeAddress(formProps) }} id="nationProvideID" name="nationProvideID" label="regist.label.national.provider.id" maxLength={10} />
                            <Or {...props} />
                            <props.AutoFormatControl showFormat={false} formatType="PGI" onValueChange={() => { clearPayeeAddress(formProps); }} id="govtID" name="govtID" label="regist.label.provider.government.id" valueWithFormat={false} />
                            {payeeAddress !== undefined && <>
                                <props.SelectControl labelClass={(payeeAddress !== undefined && payeeAddress !== null && payeeAddress.length === 1 ? "hide" : "")} name="providerInternalKey" required={(payeeAddress !== undefined && payeeAddress !== null && payeeAddress.length === 1 ? false : true)} label="regist.label.provider.address" options={payeeAddress} className={(payeeAddress !== undefined && payeeAddress !== null && payeeAddress.length === 1 ? "hide" : "")} />
                            </>}

                        </>}
                        {(formProps.values.userTypeRegCode === props.regConfig.groupadmin) && <>
                            <props.TextControl id="jobTitle" name="jobTitle" label="regist.label.jobtitle" />
                            <props.SelectControl name="companyCode" required={true} sort={false} label="common.lbl.companyAuthorization" options={companyList} />
                        </>}
                        {(formProps.values.userTypeRegCode === props.regConfig.csr) && <>
                            <props.TextControl id="jobTitle" name="jobTitle" label="regist.label.jobtitle" />
                        </>}
                        {formProps.values.userTypeRegCode === props.regConfig.agent && <>
                            <props.TextControl id="agentNumber" name="agentNumber" required={true} label="common.lbl.agentNumber" />
                        </>}
                    </props.Col>
                </props.Row>
                <props.Row>
                    <props.Col sm='12'>
                        <props.Button type="submit" ><GlobalizedText message="common.button.next" /> </props.Button>
                        <props.Button condition={
                            //props.regConfig.admin
                            isLogin !== null
                        } className="cancelBtn" onClick={() => { props.back() }}><GlobalizedText message="common.lbl.cancel" /> </props.Button>
                        <props.Button condition={
                            //!props.regConfig.admin
                            isLogin === null
                        } className="cancelBtn" onClick={() => { toHomePage() }}><GlobalizedText message="common.lbl.cancel" /> </props.Button>
                    </props.Col>
                </props.Row>
            </Form>
            }
        </Formik>
    </React.Fragment >
    );
}

export const updateUser = (props: PageProps, user: RegistrationUser) => {
    props.setRegistrationUser(user);
    props.setInitParams(user);
}

export const SecondPage = (props: PageProps) => {
    const captcha = props.useCaptcha("securityCode");
    let previous = (e: any) => {
        updateUser(props, { ...props.registrationUser, ...{ step: 1 } });
        props.step.first();
    };
    let byPageTwoOption = {
        userID: YupSchema.registration.userID,
        password: YupSchema.registration.password(props.regConfig.validationRule, props.regConfig.pwdMinLength, props.regConfig.pwdMaxLength, props.getGlobalizedText),
        cfmPassword: YupSchema.registration.confirmPassword("password"),
        secImage: YupSchema.registration.securityImage,
        secCaption: YupSchema.registration.securityImageCaption,
        securityCode: captcha.fieldSchema,
    };

    let byPageTwo = yup.object().shape(props.regConfig.admin ? byPageTwoOption : { ...byPageTwoOption, ...YupSchema.registration.securityQuestions });


    let initialValues = {
        ...props.registrationUser,
        category: props.registrationUser.secImg.category.name !== '' ? props.registrationUser.secImg.category.name : props.regConfig.categorys[0].name,
        secImage: props.registrationUser.secImg.name !== '' ? props.registrationUser.secImg.name : '',
        cfmPassword: '',
        securityCode: ''
    }
    return (<React.Fragment>
        <Formik initialValues={initialValues}
            {...{ ...props.generateNoramlFormikProps(), ...{ onReset: props.clearMessage } }}
            validate={(values: any) => {
                return validate(byPageTwo, values, props);
            }}
            onSubmit={(values: any, formikHelpers: any) => {
                let data = {
                    ...values,
                    ...{ secImg: { category: { name: values.category }, name: values.secImage } },
                }
                ajax({
                    url: '/api/registrationSingle/pageTwoValidate',
                    data: trimFields(data),
                    method: 'post',
                    success: (res: any) => {
                        let user = { ...props.registrationUser, ...data, ...{ step: 3 } };
                        updateUser(props, user);
                        props.step.third();
                    },
                    fail: (res: any) => {
                        props.showMessage("error", res.data.message);
                    },
                    error: (error: any) => {
                        props.showMessage("error", error.response.data.message);
                    }
                });
            }}>
            {formProps => <Form>
                <Title {...props} title="regist.label.login.and.security.information"></Title>
                <props.Row>
                    <props.Col md="6" sm="6" xs="12">
                        <props.TextControl id="userID" name="userID" required={true} label="groupAdmin.userID" />
                        <props.PasswordControl id="password" name="password" label="login.lbl.password" required={true} />
                        <props.PasswordControl id="cfmPassword" name="cfmPassword" label="regist.label.confirm.password" required={true} />
                        <props.SecurityImagesControl imageName="secImage" categoryName="category" securityPhaseName="secCaption" categories={props.regConfig.categorys} images={props.regConfig.securityImages} {...props}></props.SecurityImagesControl>
                    </props.Col>
                    <props.Col md="6" sm="6" xs="12">
                        <props.SelectControl name="securityQuestion1" label="regist.label.question1" required={!props.regConfig.admin} sort={false} options={addFirstOption(props.regConfig.securityQuestions.map((item: any) => { if (item.questionID !== formProps.values.securityQuestion2 && item.questionID !== formProps.values.securityQuestion3) return { name: item.question, value: item.questionID } }), props.getGlobalizedText)} />
                        <props.TextControl id="answer1" name="answer1" label="regist.label.answer1" required={!props.regConfig.admin} />
                        <props.SelectControl name="securityQuestion2" label="regist.label.question2" required={!props.regConfig.admin} sort={false} options={addFirstOption(props.regConfig.securityQuestions.map((item: any) => { if (item.questionID !== formProps.values.securityQuestion1 && item.questionID !== formProps.values.securityQuestion3) return { name: item.question, value: item.questionID } }), props.getGlobalizedText)} />
                        <props.TextControl id="answer2" name="answer2" label="regist.label.answer2" required={!props.regConfig.admin} />
                        <props.SelectControl name="securityQuestion3" label="regist.label.question3" required={!props.regConfig.admin} sort={false} options={addFirstOption(props.regConfig.securityQuestions.map((item: any) => { if (item.questionID !== formProps.values.securityQuestion1 && item.questionID !== formProps.values.securityQuestion2) return { name: item.question, value: item.questionID } }), props.getGlobalizedText)} />
                        <props.TextControl id="answer3" name="answer3" label="regist.label.answer3" required={!props.regConfig.admin} />
                        <props.TextControl id="securityCode" name="securityCode" required={true} label="regist.label.enter.captcha" />
                        <captcha.Component></captcha.Component>
                    </props.Col>
                </props.Row>
                <props.Row>
                    <props.Col sm='12'>
                        <props.Button className="previousBtn" onClick={previous}><GlobalizedText message="common.button.previous" /> </props.Button>
                        <props.Button type="submit" ><GlobalizedText message="common.button.next" /> </props.Button>
                        <props.Button className="cancelBtn" onClick={() => { props.back() }}><GlobalizedText message="common.lbl.cancel" /> </props.Button>
                    </props.Col>
                </props.Row>
            </Form>
            }
        </Formik>
    </React.Fragment >
    );
}
const ThirdPage = (props: PageProps) => {
    let previous = (e: any) => {
        updateUser(props, { ...props.registrationUser, ...{ step: 2, password: '' } });
        props.step.second();
    };
    let byPageThree = yup.object().shape({
        languageCode: YupSchema.registration.languageCode,
        dateFormat: YupSchema.registration.dateFormat
    });
    useEffect(() => {
        //document.querySelector("[name=password]").focus();
    });
    return (
        <>
            <Formik initialValues={{
                "dateFormat": props.registrationUser.dateFormat !== '' ? props.registrationUser.dateFormat : props.regConfig.prefDateFormats[0].value,
                "languageCode": props.registrationUser.languageCode !== '' ? props.registrationUser.languageCode : props.regConfig.languageCode[0].value
            }}
                validateOnChange={false}
                validateOnBlur={false}
                validate={(values) => {
                    return validate(byPageThree, values, props);
                }}
                onSubmit={(values) => {
                    let resetValues: any = {};
                    if (!(props.registrationUser.userTypeRegCode === '09' || props.registrationUser.userTypeRegCode === '05')) { resetValues = { ...resetValues, ...{ companyCode: '' } } }
                    if (props.registrationUser.userTypeRegCode !== '02') { resetValues = { ...resetValues, ...{ nameType: '' } } }
                    updateUser(props, { ...props.registrationUser, ...resetValues });
                    ajax({
                        url: '/api/registrationSingle/register',
                        data: { ...trimFields(props.registrationUser), ...{ govtID: props.registrationUser.govtID.replaceAll("-", "") }, "dateFormat": values.dateFormat, "languageCode": values.languageCode },
                        method: 'post',
                        success: (res: any) => {
                            updateUser(props, { ...props.registrationUser, ...{ step: 4 } });
                            props.step.forth();
                        },
                        fail: (res: any) => {
                            props.showMessage("error", res.data.message);
                        },
                        error: (error: any) => {
                            props.showMessage("error", error.response.data.message);
                        }
                    });
                }}>
                {formProps => <Form>
                    <Title {...props} title="regist.label.preference.information"></Title>
                    <props.Row>
                        <props.Col md="6" sm="6" xs="12">
                            <props.SelectControl name="languageCode" label="regist.label.pref.language" required={true} options={props.regConfig.languageCode} />
                        </props.Col>
                        <props.Col md="6" sm="6" xs="12">
                            <props.SelectControl name="dateFormat" label="regist.label.pref.date.format" required={true} options={props.regConfig.prefDateFormats} />
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col sm='12'>
                            <props.Button className="previousBtn" onClick={previous}><GlobalizedText message="common.button.previous" /> </props.Button>
                            <props.Button type="submit"><GlobalizedText message="common.button.submit" /> </props.Button>
                            <props.Button className="cancelBtn" onClick={() => { props.back() }}><GlobalizedText message="common.lbl.cancel" /> </props.Button>
                        </props.Col>
                    </props.Row>
                </Form>
                }
            </Formik>
        </>
    );
}

const Or = (props: ViewComponentProps) => {
    return <props.Div style={{ marginTop: '10px' }}><GlobalizedText message="common.lbl.or" /></props.Div>
}

interface TitleProps extends ViewComponentProps {
    title: string;
}

const Title = (props: TitleProps) => {
    return <><props.Row>
        <props.Col sm='12'>
            <props.Span><props.Span className="reginnertitle"><GlobalizedText message={props.title} /></props.Span>(<props.Span className="gwp-required">* </props.Span><GlobalizedText message="common.lbl.requiredField" />)</props.Span>
        </props.Col>
    </props.Row>

    </>;
}
const useStep = (props: any) => {
    const [step, setStep] = useState<number>(1);
    return {
        forth: function () {
            this.setStep(4);
            props.clearMessage();
        },
        third: function () {
            this.setStep(3);
            props.clearMessage();
        },
        second: function () {
            this.setStep(2);
            props.clearMessage();
        },
        first: function () {
            this.setStep(1);
            props.clearMessage();
        },
        step: function () {
            return step;
        },
        setStep: function (s: number) {
            if (s !== undefined) {
                setStep(s);
                window.scrollTo({ left: 0, top: 0 });
            }
        }
    }
}

const addFirstOption = (array: Array<any>, getGlobalizedText: (key: string) => string): Array<any> => {
    let result = new Array<any>();
    result.push({ "name": getGlobalizedText("common.lbl.pleaseselect"), "value": "" })
    for (let a of array) {
        if (a !== undefined && a.name !== undefined)
            result.push(a);
    }
    return result;
}

function validateGroupNumber(values: any, res: any) {
    if (isEmptyStr(values.groupNumber)) {
        res = { ...res, ...{ groupNumber: INVALID_MESSAGES.GROUPNUMBER_IS_REQUIRED } };
    }
    if (!isEmptyStr(values.groupNumber) && values.groupNumber.trim().length > 10) {
        res = { ...res, ...{ groupNumber: INVALID_MESSAGES.GROUPNUMBER_IS_TO_LONG } };
    }
    return res;
}

function validatePersonalDetailsForMemberAndDependent(values: any, res: any, emailAddressRequired: boolean) {
    if (isEmptyStr(values.firstName)) {
        res = { ...res, ...{ firstName: INVALID_MESSAGES.FIRSTNAME_IS_EMPTY } };
    }
    else if (trim(values.firstName).length > 20) {
        res = { ...res, ...{ firstName: INVALID_MESSAGES.FIRSTNAME_IS_TOO_LONG } };
    }
    if (isEmptyStr(values.lastName)) {
        res = { ...res, ...{ lastName: INVALID_MESSAGES.LASTNAME_IS_EMPTY } };
    }
    else if (trim(values.lastName).length > 40) {
        res = { ...res, ...{ lastName: INVALID_MESSAGES.LASTNAME_IS_TOO_LONG } };
    }
    if (isEmptyStr(values.email) && emailAddressRequired) {
        res = { ...res, ...{ email: INVALID_MESSAGES.EMAIL_IS_REQUIRED } };
    }
    if (!isEmptyStr(values.email) && VALIDATION_REGEX.EMAIL.test(trim(values.email)) === false) {
        res = { ...res, ...{ email: INVALID_MESSAGES.EMAIL_IS_INVALID } };
    }
    if (isEmptyStr(values.birthDate)) {
        res = { ...res, ...{ birthDate: INVALID_MESSAGES.BIRTHDATE_IS_EMPTY } };
    }
    else if (new Date(values.birthDate).getTime() > new Date().getTime()) {
        res = { ...res, ...{ birthDate: INVALID_MESSAGES.BIRTHDATE_IS_INVALID } };
    }
    return res;
}

import { Form, Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as yup from 'yup';
import { User } from "../../../../shared/authentication";
import { Button, closeModal, Col, Modal, Row, showModal, TextareaControl, TextControl } from "../../../../shared/components";
import GlobalizedText, { GlobalizationContext } from "../../../../shared/globalization";
import { useMessage } from "../../../../shared/message";
import { ajax, getGlobalizedText, resetForm, validate } from "../../../../shared/utils";
import { YupSchema } from "../../../../shared/yupschema";

const QUESTION_MAX_LENGTH: number = 100;
const textWidthStyle = {
    maxWidth: '66%'
}

export interface SubmitYourInquiryProps {
    user: User | null,
    supportedFileTypes: String
}

const isUserChanged = (ori: User | null, newUser: User | null): boolean => {
    if (ori !== null && newUser !== null) {
        if (ori.firstName !== newUser.firstName && ori.lastName !== newUser.lastName) {
            return true;
        } else {
            return false;
        }
    }
    if (ori !== newUser) {
        return true;
    } else {
        return false;
    }

}

export const SubmitYourInquiry = (props: SubmitYourInquiryProps) => {
    const { messageService, validateProps } = useMessage("submitinquiry");
    const globalizatin = useContext(GlobalizationContext);
    const [config, setConfig] = useState<any>();
    const user = useRef<User | null>(null);
    useEffect(() => {
        if (isUserChanged(user.current, props.user)) {
            setConfig(undefined);
        }
    }, [props.user])
    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/contactForm/view',
                success: (res: any) => {
                    setConfig(res);
                    user.current = props.user;
                },
                showMask: false
            });
        }
    }, [config]);

    if (config === undefined) {
        return <></>
    }

    const submitInuqirySchema = yup.object().shape({
        s_firstName: YupSchema.submitInquiry.firstName,
        s_lastName: YupSchema.submitInquiry.lastName,
        s_contactEmail: YupSchema.submitInquiry.email,
        s_contactPhone: YupSchema.submitInquiry.phoneNumber,
        s_question: YupSchema.submitInquiry.question
    });

    let initialValues = {
        ...{ s_firstName: '', s_lastName: '', s_contactEmail: '', s_contactPhone: '', s_question: '' },
        ...config !== undefined ? {
            s_firstName: config.contactForm.firstName === undefined ? '' : config.contactForm.firstName,
            s_lastName: config.contactForm.lastName === undefined ? '' : config.contactForm.lastName,
            s_contactPhone: config.contactForm.contactPhone === undefined ? '' : config.contactForm.contactPhone,
            s_contactEmail: config.contactForm.contactEmail === undefined ? '' : config.contactForm.contactEmail
        } : {}
    }
    let fp = {
        initialValues: initialValues,
        onSubmit: (values: any, formikHelpers: any) => {
            ajax({
                url: '/api/contactForm/submit',
                method: 'POST',
                data: {
                    firstName: values.s_firstName,
                    lastName: values.s_lastName,
                    contactEmail: values.s_contactEmail,
                    contactPhone: values.s_contactPhone,
                    question: values.s_question
                },
                success: () => {
                    formikHelpers.resetForm();
                    closeModal("#theme_inquiry_popup", () => showModal("#theme_inquiry_success_popup"));
                }, error: (error: any) => {
                    messageService.showMessage("error", error.response.data.message);
                }, showMask: false
            });
        },
        validate: (values: any) => {
            return validate(submitInuqirySchema, values, validateProps);
        },
        validateOnBlur: false,
        validateOnChange: false
    };

    return <>
        <Formik onReset={() => { messageService.clearMessage() }} {...fp}>
            {formProps =>
                <Form>
                    <Modal title="common.lbl.yourInquiry" id="theme_inquiry_popup" footer={() => {
                        return <>
                            <Button type="submit"><GlobalizedText message="common.lbl.submit" /></Button>
                            <Button onClick={() => { resetForm(formProps) }} data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></Button>
                        </>
                    }} messageService={messageService}>
                        <Row>
                            <Col sm="12">
                                <TextControl containerClassName="formGroup-noMargin" style={textWidthStyle} id="s_firstName" name="s_firstName" label="common.lbl.firstName" required={true}></TextControl>
                            </Col>
                            <Col sm="12">
                                <TextControl containerClassName="formGroup-noMargin" style={textWidthStyle} id="s_lastName" name="s_lastName" label="common.lbl.lastName" required={true}></TextControl>
                            </Col>
                            <Col sm="12">
                                <TextControl containerClassName="formGroup-noMargin" style={textWidthStyle} id="s_contactPhone" name="s_contactPhone" label="pd.lbl.phone" required={true}></TextControl>
                            </Col>
                            <Col sm="12">
                                <TextControl containerClassName="formGroup-noMargin" style={textWidthStyle} id="s_contactEmail" name="s_contactEmail" label="pi.email.address" required={true}></TextControl>
                            </Col>
                            <Col sm="12">
                                <TextareaControl styleOfRemaining={{ width: "66%" }} showRemaining={true} containerClassName="formGroup-noMargin" maxLength={QUESTION_MAX_LENGTH} style={textWidthStyle} id="s_question" name="s_question" label="common.lbl.question" required={true}></TextareaControl>
                            </Col>
                        </Row>
                    </Modal>

                </Form>
            }
        </Formik>
        <Modal id="theme_inquiry_success_popup" title="common.msg.submitted.successfully" footer={() => {
            return <Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></Button>
        }}>
            <GlobalizedText message="homepage.lbl.thx.for.submit" />
        </Modal>
    </>
}

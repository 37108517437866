import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SharedComponents, showModal, closeModal, CaptchaObject, INIT_PARAM_PREFIX_TABLE, KEY_NEED_RELOAD, clearCache, useCatpcha } from './components';
import GlobalizedText, { tryReload as tryReloadGlobalization } from './globalization';
import { HelpBanner } from './helpbanner';
import { OnlineHelp } from './onlinehelp';
import { FieldsMessages, Message, MessageService, MessageType } from './message';
import { RouteComponentProps } from './routers';
import { getContextPath, $, getGlobalizedText as getGT, movePageToTop, resetForm, validateProps, print as gwpprint, PrintOption, isEmptyStr, removeModalFadeIn, hideNavBarForSmallScreen, isEmptyObject } from './utils';
import { ThemeState } from './theme';
import { UserContext } from './authentication';
import { FormikProps } from 'formik';
import { Config, loadConfiguration } from '../config/config';
import { AnalyticsObj } from './analytics/analytics'

export interface ViewComponentProps extends RouteComponentProps, SharedComponents, validateProps {
    /**
     * Set Page Title, will affect the tab title, page title and breadcrumb must be called in useEffect.
     */
    setTitle: (title: string) => void,
    /**
     * Set Page title
     */
    setPageTitle: (pageTitle: string) => void,
    /**
     * Return page name
     */
    getName: () => string,
    /**
     * Return page url
     */
    getURL: () => string,
    /**
     * Return specific query parameter
     */
    getParam: (name: string) => string,
    /**
     * Render the next url
     */
    next: (to: string) => void,
    /**
     * Put initial parameters for page re-rendering
     */
    setInitParams: (params: any) => void,
    /**
     * Get initial parameters
     */
    getInitParams: () => any,
    /**
     * Back to the previous page.
     */
    back: () => void,
    /**
     * Replay page with the callback function 
     */
    replay: (callback: Function) => void,
    /**
     * Show Modal Dialog
     */
    showModal: (selector: string, callback?: () => void) => void,

    /**
     * Close the modal dialog
     */
    closeModal: (selector: string, callback?: () => void) => void,

    /**
     * Use the specific theme
     */
    setTheme: (themeName: string) => void,

    /**
     * Use the specific page layout
     */
    setLayout: (layout: string) => void,

    /**
     * Return query parameter
     */
    getQueryParam: (paramName: string) => string,

    useCaptcha: (name: string) => CaptchaObject,

    /**
     * High light the fields and show error messages.
     * formProps could be either formProps or formikHelper
     */
    showFieldError: (pageLevelErrorMessage: string, fields: FieldsMessages, formProps: any, messageService?: MessageService) => void,

    /**
     * Return date format of the current login user.
     */
    getDateFormat: () => string,

    /**
     * Rest form 
     * @params formProps Formik formProps
     * @values replace the values into initialValues and do reset.
     */
    resetForm: (formProps: FormikProps<any>, values?: any) => void

    /**
     * Generate the noraml formik props used by <Formik >
     */
    generateNoramlFormikProps: () => any

    /**
     * Go to the home page
     */
    toHomePage: () => void

    /**
     * move to top of the page.
     */
    movePageToTop: () => void

    /**
     * return whether the component need to be force-refreshed or not.
     * should be used along with LinkProps.forceRefresh
     */
    isForceRefresh: () => boolean

    /**
     * update history state to refreshed.
     */
    refreshed: () => void

    /**
     * print the current page except the headers and footers.
     */
    print: (option?: PrintOption) => void

    /**
     * Hide the breadcrumb
     */
    hideBreadcrumbs: () => void

    /**
     * Show the breadcrumb
     */
    showBreadcrumbs: () => void

    showTitle: () => void

    hideTitle: () => void

    /**
     * get default homepage if logged
     */
    getDefaultHome: () => string

    isFeatureEnabled: (featureName: string) => boolean
}

function loadCustomizedComponents(profiles: Array<string>) {
    let components = require(`./components`);
    for (let profile of profiles) {
        if (profile === '') {
            continue;
        }
        try {
            components = { ...components, ...require(`../customized/${profile}/components`) };
        } catch (e) {
            console.error(e);
        }
    }
    return components;
}

export function withView(WrapperedComponent: (viewProps: ViewComponentProps) => JSX.Element): (viewProps: ViewComponentProps) => JSX.Element {


    return function (props: RouteComponentProps): JSX.Element {
        let history = useHistory();
        let urlParams: any = useParams();

        /**
         * Got back to the previous page in breadcrumb
         */
        function back() {
            let last = props.breadcrumb.last();
            let previous = props.breadcrumb.back();
            if (previous === undefined) {
                // to the last page visited.
                history.go(-1);
            } else {
                let to = { pathname: previous.pathname, search: previous.search }
                if (previous.name === last.name) {
                    toHomePage();
                } else {
                    let initParam = getInitParams();
                    if (initParam !== undefined) {
                        if (initParam[INIT_PARAM_PREFIX_TABLE] !== undefined) {
                            for (let tableId in initParam[INIT_PARAM_PREFIX_TABLE]) {
                                initParam[INIT_PARAM_PREFIX_TABLE][tableId][KEY_NEED_RELOAD] = true;
                            }
                        }
                        setInitParams(initParam);
                    }
                    history.push(to);
                }
            }
        }
        function setInitParams(params: any) {
            props.breadcrumb.setInitParams(params);
        }
        function getInitParams() {
            return props.breadcrumb.getInitParams();
        }
        function replay(callback: Function) {
            callback(props.breadcrumb.getInitParams());
        }
        function getGlobalizedText(messageKey: string) {
            return getGT(props.globalization, messageKey);
        }

        /**
         * Move to the next page.
         * @param {*} url 
         */
        function next(url: string) {
            history.push(url.startsWith(getContextPath()) ? url : getContextPath() + url);
            movePageToTop();
        }

        function toLandingPage() {
            // for unauthorized user, navigate to landing page
            if (isEmptyObject(props.rootComponent.state.user)) {
                next(props.rootComponent.state.landingpage ? props.rootComponent.state.landingpage : "/welcome");
            } else {
                // for user authenticated, navigate to the default homepage of the current user.
                // if default home is not setup, then navigate to welcome page.
                next(props.rootComponent.state.user.defaultHome ? props.rootComponent.state.user.defaultHome : "/welcome");
            }
        }

        function toHomePage() {
            next("/")
        }

        /**
         * Get page url, should be defined in route-config.js
         */
        function getURL() {
            return props['url'];
        }

        /**
         * Get page name, should be defined in route-config.js
         */
        function getName() {
            return props['name'];
        }
        function getParam(name: string) {
            return queryParams[name] ? queryParams[name] : urlParams[name] ? urlParams[name] : "";
        }
        function showMessage(type: MessageType, message: string | JSX.Element) {
            return props.messageService.showMessage(type, message);
        }
        function showFieldError(pageLevelErrorMessage: string, fields: FieldsMessages, formProps: any, messageService: MessageService): void {
            if (formProps === null) {
                throw new Error("formProps can not be null.");
            }
            //MessageService
            if (messageService !== undefined) {
                messageService.showMessage("error", pageLevelErrorMessage);
            } else {
                props.messageService.showMessage("error", pageLevelErrorMessage);
            }
            for (let field in fields) {
                // for formProps
                if (formProps.getFieldHelpers !== undefined) {
                    formProps.getFieldHelpers(field).setError(fields[field]);
                }
                // for formikHelper
                if (formProps.setFieldError !== undefined) {
                    formProps.setFieldError(field, fields[field]);
                }
            }
        }

        function refreshed(): void {
            history.push(history.location.pathname, { forceRefresh: false });
        }

        function isForceRefresh(): boolean {
            if (window.history.state === null || window.history.state.state === undefined) {
                return false;
            } else {
                return window.history.state.state.forceRefresh === true;
            }
        }

        function clearMessage() {
            return props.messageService.clearMessage();
        }
        function setTheme(themeState: ThemeState) {
            props.rootComponent.setTheme(themeState);
        }
        function getTheme(): ThemeState {
            return props.rootComponent.getTheme();
        }
        function isFeatureEnabled(featureID: string): boolean {
            if (userContext !== null) {
                for (let ff of userContext.features) {
                    if (ff.featureID === featureID) {
                        return ff.enabled;
                    }
                }
            }
            return false;
        }
        function generateNoramlFormikProps(): any {
            return {
                validateOnBlur: false,
                validateOnChange: false,
                onReset: clearMessage
            }
        }
        function initQueryParam(): any {
            let result: any = {};
            if (location.search !== "") {
                let search = location.search.substring(1);
                let keyValPairs = search.split("&");
                for (let keyValPair of keyValPairs) {
                    let obj = keyValPair.split("=");
                    result[obj[0]] = obj[1];
                }
            }
            return result;
        }
        function getQueryParam(paramName: string): string {
            return queryParams[paramName]
        }
        function print(option?: PrintOption): void {
            gwpprint(`${props.id}`, option);
        }
        function siteAnalytics() {
            loadConfiguration(props.rootComponent, (conf: any) => {
                AnalyticsObj.pageView(conf);
            });
        }
        const location = useLocation();
        const queryParams = initQueryParam();
        const [title, setTitle] = useState(getName());
        const [pageTitle, setPageTitle] = useState<string>("");
        const [showTitle, setShowTitle] = useState(true);
        const userContext = useContext(UserContext);
        const [preTitle, setPreTitle] = useState<string>("");
        props.breadcrumb.setShowBreadcrumb(props.showBreadcrumb !== undefined ? props.showBreadcrumb : true);
        (() => {
            let pos = props.breadcrumb.findBreadcrumb(location);
            if (pos !== -1 && pos !== props.breadcrumb.length() - 1) {
                // if the breadcurmb has already been recorded, then try to move to this one.
                props.breadcrumb.moveTo(pos);
            } else {
                if (props.hasBreadcrumb !== false) {
                    props.breadcrumb.record({ name: title, pathname: window.location.pathname, search: window.location.search });
                } else {
                    props.breadcrumb.clear();
                }
            }
            document.title = title;
        })();
        props.breadcrumb.readjust();

        useEffect(() => {
            // if the current location path is "/", then try to navigate to the landing page
            if (history.location.pathname === "/") {
                toLandingPage();
            }
            if (Config.TRY_RELOAD_MESSAGE_ON_PAGE_RERENDERING === true) {
                tryReloadGlobalization(props.rootComponent);
            }
            removeModalFadeIn();
            hideNavBarForSmallScreen();
            let components = props.rootComponent.state.components;
            let themeName = null;
            let layout = null;
            if (components !== null && components[props.id] !== undefined) {
                themeName = props.rootComponent.state.components[props.id].theme === undefined ? props.rootComponent.state.theme : props.rootComponent.state.components[props.id].theme;
                layout = props.rootComponent.state.components[props.id].layout === undefined ? props.rootComponent.state.layout : props.rootComponent.state.components[props.id].layout;
            } else {
                themeName = props.rootComponent.state.defaultTheme;
                layout = props.rootComponent.state.defaultLayout;
            }

            props.breadcrumb.refresh()
            if (props.hasBackTo) {
                if (props.breadcrumb.previous() !== undefined) {
                    setPreTitle(props.breadcrumb.previous().name);
                }
            }
            if ((getTheme() !== null && (themeName !== getTheme().themeName || layout !== getTheme().layout)) || getTheme() === null) {
                setTheme({ themeName: themeName, layout: layout, themeConfigs: props.rootComponent.themeConfigs });
            }
            try {
                if (props.rootComponent.state.themeConfigs[props.rootComponent.state.theme].component && props.rootComponent.state.themeConfigs[props.rootComponent.state.theme].component?.afterHeaderRendered) {
                    props.rootComponent.state.themeConfigs[props.rootComponent.state.theme].component?.afterHeaderRendered();
                }
            } catch (error) {

            }
            siteAnalytics();
        }, [props.breadcrumb, title, props.hasBackTo, props.rootComponent.state.components, props.rootComponent.state.theme, props.rootComponent.state.layout, props.id, getTheme, setTheme])
        const comps = loadCustomizedComponents(props.profiles);
        let newProps: ViewComponentProps = {
            setTitle: (newTitle: string) => { setTitle(getGlobalizedText(newTitle)) },
            getName: getName,
            getURL: getURL,
            showMessage: showMessage,
            clearMessage: clearMessage,
            getParam: getParam,
            next: next,
            setInitParams: setInitParams,
            getInitParams: getInitParams,
            replay: replay,
            back: back,
            setPageTitle: setPageTitle,
            getGlobalizedText: getGlobalizedText,
            showModal: showModal,
            closeModal: closeModal,
            getQueryParam: getQueryParam,
            useCaptcha: useCatpcha,
            showFieldError: showFieldError,
            resetForm: resetForm,
            isForceRefresh: isForceRefresh,
            refreshed: refreshed,
            generateNoramlFormikProps: generateNoramlFormikProps,
            getDateFormat: () => { return userContext === null ? "MM/dd/yyyy" : userContext.dateFormat },
            toHomePage: toHomePage,
            print: print,
            isFeatureEnabled: isFeatureEnabled,
            showBreadcrumbs: () => { props.breadcrumb.show() },
            hideBreadcrumbs: () => { props.breadcrumb.hide() },
            showTitle: () => { setShowTitle(true) },
            hideTitle: () => setShowTitle(false),
            getDefaultHome: () => { return userContext === null ? "/" : userContext.defaultHome },
            ...comps,
            ...props,
            ...{ rootComponent: undefined }
        };
        clearCache();

        return <>
            <comps.Row>
                {(title !== null && props.hasTitle !== false) &&
                    <comps.Col xs="12" sm="8" md="9">
                        <comps.PageTitle style={{ display: showTitle ? 'block' : 'none' }}>
                            <GlobalizedText message={!isEmptyStr(pageTitle) ? pageTitle : title} /> {props.hasOnlineHelp !== false && <OnlineHelp {...newProps} componentId={props.id}></OnlineHelp>}
                        </comps.PageTitle>
                    </comps.Col>
                }
                {(((userContext === null && props.isProtected === false) || (userContext !== null && props.isProtected !== false)) && title !== null && props.hasTitle !== false) &&
                    <>
                        {(props.hasBackTo === true && props.breadcrumb.previous() !== undefined && props.breadcrumb.previous().pathname !== undefined) &&
                            <comps.Col id="backto" xs="12" sm="4" md="3">
                                <comps.Button className="gwp-btn-backto" data-print-visible="false" onClick={back}><GlobalizedText message="common.lbl.backTo" />{" " + preTitle}</comps.Button>
                            </comps.Col>
                        }
                    </>
                }
                <Message messageService={props.rootComponent.siteMessageService} />
                <Message messageService={props.messageService} />
                {(props.hasHelpBanner === true) &&
                    <>
                        <comps.Col xs="12" sm="12" md="8" />
                        <comps.Col xs="12" sm="12" md="4" className="helpbanner">
                            <HelpBanner {...newProps} />
                        </comps.Col>
                    </>
                }
            </comps.Row>
            <WrapperedComponent {...newProps} />
        </>
    }
}
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SecurityContext } from '../../context/securityContext';
import { UserContext } from '../../shared/authentication';
import { Link } from '../../shared/components';
import GlobalizedText from '../../shared/globalization';
import { loadCategories, loadMyWorkspace } from '../../shared/theme';
import { $ } from '../../shared/utils';
export default function Menu(props: any) {

    const [categories, setCategories] = useState<Array<any> | null>(null);
    const [isMenuLoaded, setMenuLoaded] = useState<boolean>(false);
    const [isPortalCategoryLoaded, setPortalCategoryLoaded] = useState<boolean>(false);
    const user = useContext(UserContext);
    const oldUser = useRef<any>();
    const cates = useMemo(() => {
        if (categories === null) {
            return <span></span >
        }
        return <PortalCategories tier={0} categories={categories} />
    }, [categories]);


    const menu = useMemo(() => {
        if (user !== oldUser.current && user !== null) {
            return <Workspace setMenuLoaded={setMenuLoaded} user={user} breadcrumb={props.breadcrumb} />
        }
    }, [user]);

    useEffect(() => {
        if (categories === null || user !== oldUser.current) {
            oldUser.current = user;
            loadCategories((result: Array<any>) => {
                setCategories(result);
                setPortalCategoryLoaded(true);
            }, false);
        }
    }, [categories, user]);

    useEffect(() => {
        if ((user === null && isPortalCategoryLoaded === true) ||
            (isMenuLoaded === true && isPortalCategoryLoaded === true)
        ) {
            showPortalCategories();
        }
    })
    return <>
        <div id="menubar" className="col-xs-12 col-md-8 col-sm-7">
            <ul className="nav navbar-nav">
                {props?.isShowHome ? (
                    <li className="active"><Link onClick={() => { props.breadcrumb.clear() }} to="/"><GlobalizedText message="common.lbl.home" /></Link></li>
                ) : null}
                {cates}
                {menu}
            </ul>
            <span id='menumore' style={{ display: 'none' }}></span>
        </div>
        <div id="secmenubar" className="col-xs-12" style={{ display: 'none' }}>
            <ul className={`nav navbar-nav`}>
                {cates}
            </ul>
        </div>
    </>
}

function recalculatePortalCategories() {
    var navWidth = 0;
    $("#menubar>.nav.navbar-nav>li").each(function (i: number, el: any) {
        navWidth += $(el).width();
    });
    var totalWidth = $("#navbar #menubar").width();
    var bodyWidth = $("body").width();
    var culWidth = ($("#theme_dd_myworkspace").width() === undefined ? 0 : $("#theme_dd_myworkspace").width()) + ($($("#menubar>.nav.navbar-nav>li").get(0)).hasClass("gwp-pc") ? 0 : $($("#menubar>.nav.navbar-nav>li").get(0)).width());
    var result: Array<any> = [];
    $("#menubar .gwp-pc").each(function (index: number, el: any) {
        if (bodyWidth < 743 || navWidth < totalWidth) {
            result.push(true);
        } else {
            culWidth += $(el).width();
            if (culWidth > totalWidth) {
                result.push(false);
            } else {
                result.push(true);
            }
        }
    });
    return result;
}

function showPortalCategories() {
    var result = recalculatePortalCategories();
    $("#menubar .gwp-pc").each(function (i: number, el: any) {
        if (result[i] === true) {
            $(el).show();
        } else {
            $(el).hide();
        }
    });
    var showMore = false;
    $("#secmenubar .gwp-pc").each(function (i: number, el: any) {
        if (result[i] === true) {
            $(el).hide();
        } else {
            $(el).show();
            showMore = true;
        }
    });
    if (showMore) {
        $("#menumore").show();
    } else {
        $("#menumore").hide();
    }

}

function clickShowMore() {
    $("#secmenubar").slideToggle();
    $("#menumore").toggleClass("menu-collapse");
    if ($("#menumore").hasClass("menu-collapse")) {
        $("#menumore").attr("title", "Collapse");
    } else {
        $("#menumore").attr("title", "More");
    }
}

function showMenuForSmallScreen(e: any) {
    $(e.target).parent().find(".dropdown-menu").first().toggle();
    $(e.target).parent().find(".dropdown-menu").first().toggleClass("gwp-pc-toggled");
}

function mouseEnter1(this: any, e: any) {
    var $li = $(this).parent();
    var cddm = $li.find(".dropdown-menu").first();
    $li.parent().find(".dropdown-menu.gwp-pc-opened").each(function (i: number, ee: any) {
        var $e = $(ee);
        if ($e !== cddm) {
            $e.removeClass("gwp-pc-opened");
        }
    });
    cddm.find(".gwp-pc-dd").show();
    cddm.addClass("gwp-pc-opened");
}

function mouseOut1(this: any, e: any) {
    var $li = $(this).parent();
    var cddm = $li.find(".dropdown-menu.gwp-pc-opened");
    var imi = isMouseIn(e, $li);
    $li.find(".dropdown-menu.gwp-pc-opened").each(function (i: number, ddm: any) {
        let imi1 = isMouseIn(e, $(ddm));
        imi = imi || imi1;
    });
    if (!imi) {
        cddm.removeClass("gwp-pc-opened");
    }
}

function mouseOut2(this: any, e: any) {
    var $this = $(this);
    var imi = isMouseIn(e, $this);
    $this.find(".dropdown-menu.gwp-pc-opened").each(function (i: number, ddm: any) {
        let imi1 = isMouseIn(e, $(ddm));
        imi = imi || imi1;
    });
    if (!imi) {
        $this.removeClass("gwp-pc-opened");
    }
}


function PortalCategories(props: any) {
    useEffect(() => {
        $(".gwp-pc-dd>.hidden-xs").off("mouseenter", mouseEnter1);
        $(".gwp-pc-dd>.hidden-xs").on("mouseenter", mouseEnter1);
        $(".gwp-pc-dd>.hidden-xs").off("mouseout", mouseOut1);
        $(".gwp-pc-dd>.hidden-xs").on("mouseout", mouseOut1);
        $(".gwp-pc-dd").off("mouseout", ".gwp-pc-opened", mouseOut2);
        $(".gwp-pc-dd").on("mouseout", ".gwp-pc-opened", mouseOut2);
        $(".gwp-pc-dd>.visible-xs.dropdown-toggle").off("click", showMenuForSmallScreen);
        $(".gwp-pc-dd>.visible-xs.dropdown-toggle").on("click", showMenuForSmallScreen);
        setTimeout(showPortalCategories, 100);
        $("#menumore").off("click", clickShowMore);
        $("#menumore").on("click", clickShowMore);
        $(window).off("resize", showPortalCategories);
        $(window).on("resize", showPortalCategories);
    })
    return <>
        {props.categories && props.categories.map((item: any, index: number) => {
            return <React.Fragment key={index}>
                {props.tier > 1 &&
                    <div className="gwp-pc-dd-arrow hidden-xs" style={{ top: `calc(${(100 / (props.categories.length + 1))}% + 3px)` }}></div>
                }
                <li className={`dropdown gwp-pc gwp-pc-dd`}>
                    <Link className={'hidden-xs'} to={`${item.contentID ? `/content?id=${item.contentID}` : '#'}`}>{item.name}
                        {(props.tier === 0 && item.children && item.children.length > 0) && <b className="caret"></b>}
                        {(props.tier > 0 && item.children && item.children.length > 0) && <b className="glyphicon glyphicon-menu-right"></b>}
                    </Link>
                    <Link to={`${item.contentID ? `/content?id=${item.contentID}` : '#'}`} className="visible-xs dropdown-toggle" data-id={item.contentID}>{item.name} {(item.children !== undefined && item.children.length > 0) && <b className="caret"></b>}</Link>
                    {(item.children && item.children.length > 0) &&
                        <ul className={`dropdown-menu dropdown-menu-collapsed ${props.tier > 0 ? 'gwp-pc-submenu' : ''}`}>
                            <PortalCategories tier={props.tier + 1} categories={item.children} />
                        </ul>
                    }
                </li></React.Fragment>

        })
        }
    </>;
}

function isMouseIn(e: any, el: any): boolean {
    var offset = el.offset();
    if (e.pageX < offset.left || e.pageX > offset.left + el.width() || e.pageY < offset.top || e.pageY > offset.top + el.height()) {
        return false;
    }
    return true;
}
function Workspace(props: any) {
    const { setSecureLinks } = useContext(SecurityContext);
    const [menuGroups, setMenuGroups] = useState<Array<any> | null>(null);
    const location = useLocation();
    useEffect(() => {
        if (props.user === null) {
            setMenuGroups(null);
        }
        //showPortalCategories();
    })
    if (props.user === null) {
        return <></>;
    }
    if (menuGroups === null) {
        loadMyWorkspace((result: Array<any>) => {
            setMenuGroups(result);
            setSecureLinks(result);
            props.setMenuLoaded(true);
        });
    }
    function clickMenuItem(url: string) {
        if (location.pathname !== url) {
            props.breadcrumb.clear();
        }
    }
    return <>
        {(menuGroups !== null && menuGroups.length > 0) &&
            <li className="dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span><GlobalizedText message="common.my.work.space" /></span> <span className="caret"></span></a>
                <ul id="theme_dd_myworkspace" className="dropdown-menu container gwp-header-menubar-myworkspace" style={{ maxWidth: 245 * (menuGroups !== null ? menuGroups.length : 0) + "px" }}>
                    {menuGroups && menuGroups.map((menuGroup: any, i: number) => {
                        console.log({ menuGroups })
                        return <li key={i} className="gwp-header-menubar-menugroup">
                            {menuGroup.menus && menuGroup.menus.map((menu: any, j: number) => {
                                return <React.Fragment key={j}>
                                    <dt><GlobalizedText message={menu.msgKey.trim()} /></dt>
                                    {menu.children.map((children: any, k: number) => {
                                        return <dd key={k}><Link onClick={() => { clickMenuItem(children.url) }} to={children.url}><GlobalizedText message={children.msgKey.trim()} /></Link></dd>
                                    })}
                                </React.Fragment>
                            })}
                        </li>
                    })
                    }
                </ul>
            </li>
        }
    </>
}

import React, { useEffect, useState } from "react";
import { ajax, convertDate, formatNumber, getFromStorage, isEmptyStr, removeFromStorage, saveToStorage, trim } from "../../shared/utils";
import Enrollment from '../types/enrollmenttypes';
import GlobaliziedText from '../../shared/globalization';
import GlobalizedText from "../../shared/globalization";
import './css/transactionReview.css';
import { TableColumn } from "../../shared/components";
import { withView, ViewComponentProps } from '../../shared/viewcomponent';

interface ConfirmationViewResponse {
    formObject: EnrollmentFormObject
    securityMap: any,
    transactionList: Array<string>,
    currentTransactionID: string,
    nextTransactionID: string,
    lastTransactionID: string,
    formTypeName: string,
    formTypeNameDesc: string
}

interface EnrollmentFormObject {
    transactionID: string
    currentTransactionId: string
    formAction: string
    formType: string
    companyCode: string
    groupNumber: string
    accountNumber: string
    participantIDNumber: string
    anniversaryDate: string
    memberInfo: EnrollmentMemberInformation
    dependents: Array<EnrollmentDependent>
    cobList: Array<Cob>
    beneficiaryInfoList: Array<EnrollmentBeneficiary>
    covList: Array<EnrollmentCoverage>
    languageCode: string
    clientNumber: string
    lifeEvent: LifeEvent
    transactionTypeCode: string
    productSetId: string
    lifeEventEffectiveDate: string
    transactionNumber: string
    statusCode: number
    hasProductSetID: string
    page31TerminationDate: string
    productSetIndicator: string
    countMemberOnly: boolean
    hasCob: boolean
    hasLifePeriod: boolean
}

interface EnrollmentMemberInformation {
    companyCode: string
    groupNumber: string
    accountNumber: string
    participantIDNumber: string
    transactionNumber: string
    firstName: string
    lastName: string
    streetAddress: string
    streetAddress2: string
    streetAddress3: string
    city: string
    state: string
    stateDesc: string
    countryCode: string
    zip: string
    phone: string
    maritalStatus: string
    maritalStatusDesc: string
    gender: string
    genderDesc: string
    birthDate: string
    smoker: string
    smokerDesc: string
    salary: number
    salaryMode: string
    salaryModeDesc: string
    salaryEffectiveDate: string
    hireDate: string
    terminationDate: string
    terminationReason: string
    terminationReasonDesc: string
    eftDisclaimer: string
    bankAccountNumber: string
    accountType: string
    accountTypeDesc: string
    transitNumber: string
    eftStartDate: string
    eftStopDate: string
    location: string
    locationDesc: string
    locationEffectiveDate: string
    govtID: string
    maskedGovtID: string
    bankBranchNumber: string
    hoursWeek: string
    payrollIdentifier: string
    payrollDefinition: string
    futurePayrollEffectiveDate: string
    eftexists: string
    clientNumber: string
}

interface EnrollmentDependent {
    dependentIdx: number
    dependentSequenceNumber: number
    firstName: string
    lastName: string
    fullName: string
    birthDate: string
    gender: string
    genderDesc: string
    govtID: string
    relation: string
    relationDesc: string
    medicareIndicator: string
    medicareIndicatorDesc: string
    medicareEffectiveDate: string
    employeeIDNumber: string
    inBenefitDate: string
    benefitOutDateCheck: boolean
    benefitOutDate: string
    smoker: string
    smokerDesc: string
    hasOtherInsCheck: boolean
    effectiveDate: string
    terminationDate: string
    isLoadFromDB: string
    isStudent: string
}

interface Cob {
    dependentIdx: number
    dependentSequenceNumber: number
    fullName: string
    cobSelections: Array<EnrollmentCOBCoverageSection>
    hasDisplayItem: boolean
}

interface EnrollmentCoverage {
    benefitClassificationChecked: string
    benefitClassificationIndex: number
    productSetID: string
    productSetDesc: string
    coverageSelections: Array<EnrollmentCoverageSelection>
}

interface EnrollmentCoverageSelection {
    benefitClassificationCheck: string
    benefitClassificationIndex: number
    coverageSelectionIdx: number
    productSetID: string
    productSetDesc: string
    productId: string
    productName: string
    productLinkIndicator: string
    requiredInGIAS: string
    oyourDecision: string
    yourdecision: string
    ocoverageOption: string
    coverageOption: string
    giasCoveredSalary: string
    giasGiAmount: string
    giasOriginalIssueDate: string
    giasOriginalUnits: string
    giasOverrideBenefitMax: string
    giasOverrideSalaryMax: string
    giasSalaryCalcDate: string
    giasUnitFreezeInd: string
    productType: string
    orequestedVolume: string
    requestedVolume: number
    orequestedEffDate: string
    requestedEffDate: string
    ocalculatedEffDate: string
    calculatedEffDate: string
    oterminationDate: string
    terminationDate: string
    oterminationReason: string
    terminationReason: string
    oplanCode: string
    planCode: string
    oplanDesc: string
    planDesc: string
    oplanVolumeType: string
    planVolumeType: string
    oplanUnitValue: string
    planUnitValue: string
    opremiumOption: string
    premiumOption: string
    hasCoverageOption: string
    coverageOptionDesc: string
    terminationReasonDesc: string
    premiumOptionDesc: string
    indiCovSelection: Array<EnrollentDependentCovSelectionDto>
    linkCovSelection: Array<EnrollentDependentCovSelectionDto>
    displayVolume: boolean
}

interface EnrollentDependentCovSelectionDto {
    fullName: string
    linkStatusCode: string
    linkStatusDesc: string
    requestedEffDate: string
    calculatedEffDate: string
    planDesc: string
    requestedVolume: string
    terminationDate: string
    terminationReasonDesc: string
    relationShipDesc: string
}


interface EnrollmentCOBCoverageSection {
    benefitClassifiCationCheck: string
    benefitClassificationIndex: string
    cobCoverage: string
    cobEffdate: string
    cobFollowupdate: string
    cobOthercarrier: string
    cobOthercarrierbenefit: string
    terminationDate: string
    cobVerifydate: string
    coverageType: string
    sectionDisplay: boolean
    cobOthercarrierDesc: string
    cobOthercarrierbenefitDesc: string
}

interface EnrollmentBeneficiary {
    beneficiaryIdx: number
    beneficiaryId: string
    beneficiaryName: string
    contingent: string
    coverage: string
    dateOfBirth: string
    dateRevoke: string
    effdate: string
    irrevocable: string
    percent: string
    relationship: string
    terminationDate: string
    trustee: string
    isOldBeneficiary: string
    coverageDesc: string
    relationshipDesc: string
}

interface LifeEvent {
    companyCode: string
    groupNumber: string
    accountNumber: string
    transactionNumber: string
    participantIDNumber: string
    effectiveDate: string
    eventType: Array<any>
    eventTypeDesc: Array<any>
    dateFormat: string
    targetDateFormat: string
}
interface EnrollmentSubmitViewResponse {
    transactionID: string
    result: string
    memberName: string
    memberID: string
    errorMsg: string
}
export const TransactionReview = withView(function (props: ViewComponentProps) {
    const [transactionIDList, setTransactionList] = useState<Array<string>>(props.getParam("transactionIDList").split(","));
    const [submitResult, setResult] = useState<EnrollmentSubmitViewResponse>();
    const editActionSessionKey: string = "editAction";
    const pdfViewer = props.usePDFViewer({ ...props });
    const [config, setConfig] = useState<ConfirmationViewResponse>();
    const FormTypeEnum = {
        ADDNEWMEMBER: "addMember",
        UPDATEENROLLMENT: "updateEnrollment",
        OPENENROLLMENT: "openEnrollment",
        EDITENROLLMENTM: "editEnrollmentM",
        LIFEEVENT: "lifeEvent",
        INITBENE: "initBenefitSelection",
        UPDATEPAYROLL: "updatePayroll"
    };
    useEffect(() => {
        if (getFromStorage(editActionSessionKey) !== null && getFromStorage(editActionSessionKey) !== undefined) {
            removeFromStorage(editActionSessionKey);

        }
        if (config === undefined) {
            let data = {
                "transactionIDList": transactionIDList
            };
            ajax({
                method: 'post',
                url: '/api/enrollment/reviewTransaction',
                data: data,
                success: (res: ConfirmationViewResponse) => {
                    setConfig(res);
                }
            });
        }
    }, [config])

    if (config === undefined) {
        return <></>;
    }
    let submitModalTitle: JSX.Element;
    if (submitResult?.result === 'ok') {
        submitModalTitle = <props.BootstrapIcon type="ok" label="open.enrollment.enrolSubmit" />
    } else {
        submitModalTitle = <props.Icon type="warning" label="open.enrollment.enrolSubmit"></props.Icon>
    }
    let tableOption = {
        bPaginate: false,
        bSort: false,
        bInfo: false,
        language: { emptyTable: " " }
    };

    const columnsAll: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "4%" },
        { title: "confirmation.label.covered", name: "fullName", width: "10%" },
        { title: "common.lbl.status", name: "linkStatusDesc", width: "6%" },
        { title: "requestedeffdate", name: "requestedEffDate", width: "15%" },
        { title: "calculatedeffdate", name: "calculatedEffDate", width: "15%" },
        { title: "common.lbl.plan", name: "planDesc", width: "10%" },
        { title: "requestedvolume", name: "requestedVolume", width: "10%", type: 'numeric' },
        { title: "common.lbl.terminationDate", name: "terminationDate", width: "15%" },
        { title: "terminationreason", name: "terminationReasonDesc", width: "15%" },
    ];
    const columnsNoStatus: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "4%" },
        { title: "confirmation.label.covered", name: "fullName", width: "10%" },
        { title: "requestedeffdate", name: "requestedEffDate", width: "15%" },
        { title: "calculatedeffdate", name: "calculatedEffDate", width: "15%" },
        { title: "common.lbl.plan", name: "planDesc", width: "10%" },
        { title: "requestedvolume", name: "requestedVolume", width: "10%", type: 'numeric' },
        { title: "common.lbl.terminationDate", name: "terminationDate", width: "15%" },
        { title: "terminationreason", name: "terminationReasonDesc", width: "21%" },
    ];

    const columnsNoVolume: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "4%" },
        { title: "confirmation.label.covered", name: "fullName", width: "10%" },
        { title: "common.lbl.status", name: "linkStatusDesc", width: "6%" },
        { title: "requestedeffdate", name: "requestedEffDate", width: "15%" },
        { title: "calculatedeffdate", name: "calculatedEffDate", width: "15%" },
        { title: "common.lbl.plan", name: "planDesc", width: "10%" },
        { title: "common.lbl.terminationDate", name: "terminationDate", width: "15%" },
        { title: "terminationreason", name: "terminationReasonDesc", width: "25%" },
    ];

    const columnsNone: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "4%" },
        { title: "confirmation.label.covered", name: "fullName", width: "10%" },
        { title: "requestedeffdate", name: "requestedEffDate", width: "15%" },
        { title: "calculatedeffdate", name: "calculatedEffDate", width: "15%" },
        { title: "common.lbl.plan", name: "planDesc", width: "10%" },
        { title: "common.lbl.terminationDate", name: "terminationDate", width: "15%" },
        { title: "terminationreason", name: "terminationReasonDesc", width: "31%" },
    ];
    const linkColumns: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "4%" },
        { title: "common.lbl.status", name: "linkStatusDesc", width: "16%" },
        { title: "common.lbl.name", name: "fullName", width: "20%" },
        { title: "pi.relation", name: "relationShipDesc", width: "20%" },
        { title: "requestedeffdate", name: "requestedEffDate", width: "20%" },
        { title: "common.lbl.terminationDate", name: "terminationDate", width: "20%" },
    ];
    const skip = (): void => {
        let data = {
            "transactionIDList": config.transactionList,
            "lastTransactionID": config.lastTransactionID
        };
        ajax({
            method: 'post',
            url: '/api/enrollment/reviewTransactionSkip',
            data: data,
            success: (res: ConfirmationViewResponse) => {
                setTransactionList(config.transactionList);
                setConfig(res);
            }
        });

    }

    const edit = (): void => {
        let editFlag: any = { "edit": true };
        saveToStorage(editActionSessionKey, editFlag, true);
        let transactionIDList: Array<string> = [];
        transactionIDList.push(config.currentTransactionID);
        let fromTransactionList: boolean = true;
        let formType: string = config.formTypeName;
        let formAction: string = "editTransaction";
        props.next(`/enrollment?formAction=${formAction}&fromTransactionList=${fromTransactionList}&transactionIDList=${transactionIDList}&formType=${formType}`);
    }
    const exit = (): void => {
        props.showModal("#discardModal");

    }
    const download = (): void => {
        let data: any = {};
        data.htmlText = document.getElementById('PDFHTMLContainer')?.innerHTML;
        data.transactionID = config.currentTransactionID;
        ajax({
            method: 'post',
            url: `/api/enrollment/confirmation/createPDF`,
            data: data,
            success: (res) => {
                pdfViewer.showPDF('/api/enrollment/confirmation/download/' + config.currentTransactionID);
            }
        });

    }
    const approve = (): void => {
        ajax({
            method: 'post',
            url: `/api/enrollment/confirmation/approve`,
            data: { "transactionID": config.currentTransactionID },
            success: (res) => {
                setResult(res);
                props.showModal("#submitModal");
            }
        });
    }
    return <React.Fragment>


        <props.Row>
            <props.Col xs="12" sm="12" md="12">
                <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                    <props.DLI title={props.getGlobalizedText("benefit.transaction") + ":"} desc={config.currentTransactionID}></props.DLI>
                    <props.Condition condition={!isEmptyStr(config.nextTransactionID)}>
                        <props.DLI title={props.getGlobalizedText("common.lbl.nextTransaction") + ":"} desc={config.nextTransactionID}></props.DLI>
                    </props.Condition>
                    <props.Condition condition={isEmptyStr(config.nextTransactionID)}>
                        <props.DLI title={props.getGlobalizedText("common.lbl.nextTransaction") + ":"} desc={props.getGlobalizedText("common.lbl.none")}></props.DLI>
                    </props.Condition>

                </props.DL>
            </props.Col>

            <props.Col xs="12" sm="12" md="12">

                <props.Button onClick={(e: any) => {
                    approve();
                }}

                ><GlobalizedText message="common.button.submit" /></props.Button>
                <props.Condition condition={!isEmptyStr(config.nextTransactionID)}>
                    <props.Button onClick={(e: any) => {
                        skip();
                    }}><GlobalizedText message="common.button.skip" /></props.Button>
                </props.Condition>
                <props.Button onClick={(e: any) => {
                    edit();
                }}><GlobalizedText message="common.lbl.edit" /></props.Button>
                <props.Button onClick={(e: any) => {
                    exit();
                }}><GlobalizedText message="common.button.exit" /></props.Button>
                <props.Button onClick={(e: any) => {
                    download();
                }}><GlobalizedText message="common.lbl.download" /></props.Button>
            </props.Col>

        </props.Row>
        <div id="HTMLContainer">
            <props.Row>
                <props.Col xs="12" sm="12" md="12" className="gwp-page-title">
                    {config.formTypeNameDesc}

                </props.Col>
                <props.Col xs="12" sm="12" md="12" >
                    <p><GlobalizedText message="confirmation.label.transactionconfirmationmessage" /></p>
                </props.Col>
            </props.Row>
            <props.Panel condition={config.formObject.lifeEvent !== undefined}>
                <props.PanelHeading>
                    <props.Row>
                        <props.Span className="paddingleft15">
                            <GlobaliziedText message="lifeevent_title" />
                        </props.Span>
                    </props.Row>
                </props.PanelHeading>
                <props.PanelBody>
                    <props.Row>
                        <props.Col sm="3" md="3" xs="12">
                            <props.DL className="gwp-dl">
                                <props.DLI title="common.lbl.group" desc={config.formObject.memberInfo.groupNumber} />
                            </props.DL>
                        </props.Col>
                        <props.Col sm="3" md="3" xs="12">
                            <props.DL className="gwp-dl">
                                <props.DLI title="common.lbl.account" desc={config.formObject.memberInfo.accountNumber + ""} />
                            </props.DL>
                        </props.Col>
                        <props.Col sm="3" md="3" xs="12">
                            <props.DL className="gwp-dl">
                                <props.DLI title="common.lbl.memberID" desc={config.formObject.memberInfo.participantIDNumber} />
                            </props.DL>
                        </props.Col>
                        <props.Col sm="3" md="3" xs="12">
                            <props.DL className="gwp-dl">
                                <props.DLI title="common.lbl.transNumber" desc={config.currentTransactionID} />
                            </props.DL>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col sm="6" md="6" xs="12">
                            <props.DL>
                                <props.DLI title="confirmation.label.lifeeventreason" desc={
                                    config.formObject.lifeEvent?.eventTypeDesc.map((eventTypeDesc: string, index: number) => {
                                        return <>
                                            <props.DLI title="" desc={eventTypeDesc}></props.DLI>
                                        </>
                                    })} />
                            </props.DL>
                        </props.Col>
                        <props.Col sm="6" md="6" xs="12">
                            <props.DL>
                                <props.DLI title="common.lbl.effectiveDate" desc={config.formObject.lifeEvent?.effectiveDate} />
                            </props.DL>
                        </props.Col>
                    </props.Row>
                </props.PanelBody>
            </props.Panel>

            <props.Panel>
                <props.PanelHeading>
                    <props.Row>
                        <props.Span className="paddingleft15">
                            <GlobaliziedText message="common.lbl.personalInformation" />
                        </props.Span>
                    </props.Row>
                </props.PanelHeading>
                <props.PanelBody>
                    <props.Row condition={config.formObject.formType !== 'addMember' && config.formObject.lifeEvent === undefined}>
                        <props.Col sm="3" md="3" xs="12">
                            <props.DL className="gwp-dl">
                                <props.DLI title="common.lbl.group" desc={config.formObject.memberInfo.groupNumber} />
                            </props.DL>
                        </props.Col>
                        <props.Col sm="3" md="3" xs="12">
                            <props.DL className="gwp-dl">
                                <props.DLI title="common.lbl.account" desc={config.formObject.memberInfo.accountNumber + ""} />
                            </props.DL>
                        </props.Col>
                        <props.Col sm="3" md="3" xs="12">
                            <props.DL className="gwp-dl">
                                <props.DLI title="common.lbl.memberID" desc={config.formObject.memberInfo.participantIDNumber} />
                            </props.DL>
                        </props.Col>
                        <props.Col sm="3" md="3" xs="12">
                            <props.DL className="gwp-dl">
                                <props.DLI title="common.lbl.transNumber" desc={config.currentTransactionID} />
                            </props.DL>
                        </props.Col>
                    </props.Row>
                    <props.Row condition={config.formObject.formType === 'addMember'}>
                        <props.Col sm="2" md="2" xs="12">
                            <props.DL className="gwp-dl">
                                <props.DLI title="common.lbl.group" desc={config.formObject.memberInfo.groupNumber} />
                            </props.DL>
                        </props.Col>
                        <props.Col sm="2" md="2" xs="12">
                            <props.DL className="gwp-dl">
                                <props.DLI title="common.lbl.account" desc={config.formObject.memberInfo.accountNumber + ""} />
                            </props.DL>
                        </props.Col>
                        <props.Col sm="2" md="2" xs="12">
                            <props.DL className="gwp-dl">
                                <props.DLI title="common.lbl.memberID" desc={config.formObject.memberInfo.participantIDNumber} />
                            </props.DL>
                        </props.Col>
                        <props.Condition condition={!isEmptyStr(config.formObject.memberInfo.payrollDefinition)}>
                            <props.Col xs="12" md="2" sm="2">
                                <props.DL>
                                    <props.DLI title="common.lbl.payrolldefinition" desc={config.formObject.memberInfo.payrollDefinition} />
                                </props.DL>
                            </props.Col>
                            <props.Condition condition={config.securityMap.maskGovermentId === false}>
                                <props.Col xs="12" md="2" sm="2">
                                    <props.DL>
                                        <props.DLI title="common.lbl.govID" desc={config.formObject.memberInfo.govtID} />
                                    </props.DL>
                                </props.Col>
                            </props.Condition>
                            <props.Condition condition={config.securityMap.maskGovermentId === true}>
                                <props.Col xs="12" md="2" sm="2">
                                    <props.DL>
                                        <props.DLI title="common.lbl.govID" desc={config.formObject.memberInfo.maskedGovtID} />
                                    </props.DL>
                                </props.Col>
                            </props.Condition>
                            <props.Col xs="12" md="2" sm="2">
                                <props.DL>
                                    <props.DLI title="common.lbl.transNumber" desc={config.formObject.memberInfo.transactionNumber} />
                                </props.DL>
                            </props.Col>
                        </props.Condition>
                        <props.Condition condition={isEmptyStr(config.formObject.memberInfo.payrollDefinition)}>
                            <props.Condition condition={config.securityMap.maskGovermentId === false}>
                                <props.Col xs="12" md="3" sm="3">
                                    <props.DL>
                                        <props.DLI title="common.lbl.govID" desc={config.formObject.memberInfo.govtID} />
                                    </props.DL>
                                </props.Col>
                            </props.Condition>
                            <props.Condition condition={config.securityMap.maskGovermentId === true}>
                                <props.Col xs="12" md="3" sm="3">
                                    <props.DL>
                                        <props.DLI title="common.lbl.govID" desc={config.formObject.memberInfo.maskedGovtID} />
                                    </props.DL>
                                </props.Col>
                            </props.Condition>
                            <props.Col xs="12" md="3" sm="3">
                                <props.DL>
                                    <props.DLI title="common.lbl.transNumber" desc={config.formObject.memberInfo.transactionNumber} />
                                </props.DL>
                            </props.Col>
                        </props.Condition>
                    </props.Row>
                    <props.Row>
                        <props.Col sm="6" md="6" xs="12">
                            <props.DL>
                                <props.DLI condition={config.formObject.memberInfo.firstName !== ''} title="common.lbl.firstName" desc={config.formObject.memberInfo.firstName} />
                                <props.DLI condition={config.formObject.memberInfo.lastName !== ''} title="common.lbl.lastName" desc={config.formObject.memberInfo.lastName} />
                                <props.DLI condition={config.formObject.memberInfo.streetAddress !== ''} title="common.lbl.streetAddress" desc={config.formObject.memberInfo.streetAddress} />
                                <props.DLI condition={config.formObject.memberInfo.streetAddress2 !== ''} title="" desc={config.formObject.memberInfo.streetAddress2} />
                                <props.DLI condition={config.formObject.memberInfo.streetAddress3 !== ''} title="" desc={config.formObject.memberInfo.streetAddress3} />
                                <props.DLI condition={config.formObject.memberInfo.city !== ''} title="common.lbl.city" desc={config.formObject.memberInfo.city} />
                                <props.DLI condition={config.formObject.memberInfo.stateDesc !== ''} title="state" desc={config.formObject.memberInfo.stateDesc} />
                                <props.DLI condition={config.formObject.memberInfo.zip !== ''} title="pi.zip.code" desc={config.formObject.memberInfo.zip} />
                                <props.DLI condition={config.formObject.memberInfo.phone !== ''} title="pd.lbl.phone" desc={config.formObject.memberInfo.phone} />
                                <props.DLI condition={config.formObject.memberInfo.maritalStatusDesc !== ''} title="maritalstatus" desc={config.formObject.memberInfo.maritalStatusDesc} />
                                <props.DLI condition={config.formObject.memberInfo.genderDesc !== ''} title="pi.gender" desc={config.formObject.memberInfo.genderDesc} />
                                <props.DLI condition={config.formObject.memberInfo.birthDate !== ''} title="common.lbl.dob" desc={config.formObject.memberInfo.birthDate} />
                                <props.DLI condition={config.formObject.memberInfo.smokerDesc !== ''} title="smoker" desc={config.formObject.memberInfo.smokerDesc} />
                            </props.DL>
                        </props.Col>
                        <props.Col sm="6" md="6" xs="12">
                            <props.DL>
                                <props.DLI condition={config.securityMap.displaySalary === true && config.formObject.memberInfo.salary > 0} title="salary" desc={() => {
                                    return <>
                                        {formatNumber(config.formObject.memberInfo.salary)} / {config.formObject.memberInfo.salaryModeDesc}
                                    </>
                                }} />
                                <props.DLI condition={config.securityMap.displaySalary === true && config.formObject.memberInfo.salaryModeDesc === 'Hourly'} title="hoursweek" desc={config.formObject.memberInfo.hoursWeek} />
                                <props.DLI condition={config.securityMap.displaySalary === true && config.formObject.memberInfo.salaryEffectiveDate !== ''} title="salaryeffdate" desc={config.formObject.memberInfo.salaryEffectiveDate} />
                                <props.DLI condition={config.formObject.memberInfo.hireDate !== ''} title="addmember.issue.hire.date" desc={config.formObject.memberInfo.hireDate} />
                                <props.DLI condition={config.securityMap.displayMemberAccountTerminationDateReason === true && (config.formObject.memberInfo.terminationDate !== '' && config.formObject.memberInfo.terminationReason !== '')} title="" desc={() => {
                                    return <props.Div>
                                        <props.Col sm="5" md="5" xs="12" className="subtableStyle">
                                            <props.DL>
                                                <props.DLI condition={config.formObject.memberInfo.terminationDate !== ''} title="common.lbl.terminationDate" desc={config.formObject.memberInfo.terminationDate} />
                                            </props.DL>
                                        </props.Col>
                                        <props.Col sm="7" md="7" xs="12" className="subtableStyle">
                                            <props.DL>
                                                <props.DLI condition={config.formObject.memberInfo.terminationReasonDesc !== ''} title="terminationreason" desc={config.formObject.memberInfo.terminationReasonDesc} />
                                            </props.DL>
                                        </props.Col>
                                    </props.Div>
                                }} />

                                <props.DLI condition={config.formObject.memberInfo.eftexists === 'Y' && config.formObject.memberInfo.bankAccountNumber !== '' && config.formObject.memberInfo.accountType !== ''} title="confirmation.label.eftinformation" desc={() => {
                                    return <props.Div>
                                        <props.Col sm="4" md="4" xs="12" className="subtableStyle">
                                            <props.DL>
                                                <props.DLI condition={config.formObject.memberInfo.bankAccountNumber !== ''} title="bankaccountnumber" desc={config.formObject.memberInfo.bankAccountNumber} />
                                                <props.DLI condition={config.formObject.memberInfo.eftStartDate !== ''} title="eftstartdate" desc={config.formObject.memberInfo.eftStartDate} />
                                            </props.DL>
                                        </props.Col>
                                        <props.Col sm="4" md="4" xs="12" className="subtableStyle">
                                            <props.DL>
                                                <props.DLI condition={config.formObject.memberInfo.transitNumber !== ''} title="transitnumber" desc={config.formObject.memberInfo.transitNumber} />
                                                <props.DLI condition={config.formObject.memberInfo.eftStopDate !== ''} title="eftstopdate" desc={config.formObject.memberInfo.eftStopDate} />
                                            </props.DL>
                                        </props.Col>
                                        <props.Col sm="4" md="4" xs="12" className="subtableStyle">
                                            <props.DL>
                                                <props.DLI condition={config.formObject.memberInfo.accountTypeDesc !== ''} title="payment.lbl.accountType" desc={config.formObject.memberInfo.accountTypeDesc} />
                                            </props.DL>
                                        </props.Col>
                                    </props.Div>
                                }} />

                                <props.DLI condition={config.formObject.memberInfo.locationDesc !== undefined || config.formObject.memberInfo.locationEffectiveDate !== ''} title="" desc={() => {
                                    return <props.Row>
                                        <props.Col sm="5" md="5" xs="12" >
                                            <props.DL>
                                                <props.DLI condition={config.formObject.memberInfo.locationDesc !== undefined} title="location" desc={config.formObject.memberInfo.locationDesc} />
                                            </props.DL>
                                        </props.Col>
                                        <props.Col sm="7" md="7" xs="12" >
                                            <props.DL>
                                                <props.DLI condition={config.formObject.memberInfo.locationEffectiveDate !== ''} title="locationeffdate" desc={config.formObject.memberInfo.locationEffectiveDate} />
                                            </props.DL>
                                        </props.Col>
                                    </props.Row>
                                }} />

                            </props.DL>
                        </props.Col>
                    </props.Row>
                </props.PanelBody>
            </props.Panel>
            <props.Panel condition={config.securityMap.displayDependentInformation === true && config.formObject.dependents.length > 0}>
                <props.PanelHeading>
                    <props.Row>
                        <props.Span className="paddingleft15">
                            <GlobaliziedText message="dependent_title" />
                        </props.Span>
                    </props.Row>
                </props.PanelHeading>
                <props.PanelBody>
                    {config.formObject.dependents.map((dependent: EnrollmentDependent, index: number) => {
                        return <>
                            <props.Row>
                                <props.Col sm="6" md="6" xs="12">
                                    <props.DL>
                                        <props.DLI condition={dependent.firstName !== ''} title="common.lbl.firstName" desc={dependent.firstName} />
                                        <props.DLI condition={dependent.lastName !== ''} title="common.lbl.lastName" desc={dependent.lastName} />
                                        <props.DLI condition={dependent.birthDate !== ''} title="common.lbl.dob" desc={dependent.birthDate} />
                                        <props.DLI condition={dependent.genderDesc !== ''} title="pi.gender" desc={dependent.genderDesc} />
                                        <props.DLI condition={dependent.relationDesc !== ''} title="pi.relation" desc={dependent.relationDesc} />
                                        <props.DLI condition={config.securityMap.maskGovermentId === true && dependent.govtID !== ''} title="common.lbl.govID" desc={dependent.govtID} />
                                        <props.DLI condition={config.securityMap.maskGovermentId === false && dependent.govtID !== ''} title="common.lbl.govID" desc={dependent.govtID} />
                                        <props.DLI condition={dependent.medicareIndicator !== '' || dependent.medicareEffectiveDate !== ''} title="" desc={() => {
                                            return <props.Div>
                                                <props.Col sm="5" md="5" xs="12" className="subtableStyle">
                                                    <props.DL>
                                                        <props.DLI condition={dependent.medicareIndicatorDesc !== ''} title="medicareindicator" desc={dependent.medicareIndicatorDesc} />
                                                    </props.DL>
                                                </props.Col>
                                                <props.Col sm="7" md="7" xs="12" className="subtableStyle">
                                                    <props.DL>
                                                        <props.DLI condition={dependent.medicareEffectiveDate !== ''} title="medicareeffdate" desc={dependent.medicareEffectiveDate} />
                                                    </props.DL>
                                                </props.Col>
                                            </props.Div>
                                        }} />
                                    </props.DL>
                                </props.Col>
                                <props.Col sm="6" md="6" xs="12">
                                    <props.DL>
                                        <props.DLI condition={dependent.employeeIDNumber.trim() !== ''} title="employeeidnumber" desc={dependent.employeeIDNumber} />
                                        <props.DLI condition={dependent.inBenefitDate !== '' || dependent.benefitOutDate !== ''} title="" desc={() => {
                                            return <props.Row>
                                                <props.Col sm="5" md="5" xs="12" condition={dependent.inBenefitDate !== ''}>
                                                    <props.DL>
                                                        <props.DLI title="inbenebitdate" desc={dependent.inBenefitDate} />
                                                    </props.DL>
                                                </props.Col>
                                                <props.Col sm="7" md="7" xs="12">
                                                    <props.DL>
                                                        <props.DLI condition={dependent.benefitOutDate !== ''} title="benefitoutdate" desc={dependent.benefitOutDate} />
                                                    </props.DL>
                                                </props.Col>
                                            </props.Row>
                                        }} />
                                        <props.DLI condition={dependent.smokerDesc !== ''} title="smoker" desc={dependent.smokerDesc} />
                                        <props.DLI condition={"" + dependent.hasOtherInsCheck === 'true'} title="lb_otherinscheck" desc={'Yes'} />
                                        <props.DLI condition={config.securityMap.displayStudentInformation === true && dependent.effectiveDate !== ''} title="confirmation.label.student" desc={() => {
                                            return <props.Div>
                                                <props.Col sm="5" md="5" xs="12" className="subtableStyle">
                                                    <props.DL>
                                                        <props.DLI condition={dependent.effectiveDate !== ''} title="common.lbl.effectiveDate" desc={dependent.effectiveDate} />
                                                    </props.DL>
                                                </props.Col>
                                                <props.Col sm="7" md="7" xs="12" className="subtableStyle">
                                                    <props.DL>
                                                        <props.DLI condition={dependent.terminationDate !== ''} title="common.lbl.terminationDate" desc={dependent.terminationDate} />
                                                    </props.DL>
                                                </props.Col>
                                            </props.Div>
                                        }} />
                                    </props.DL>
                                </props.Col>
                            </props.Row>
                            <props.HR />
                        </>
                    })}
                </props.PanelBody>
            </props.Panel>

            <props.Panel condition={config.formObject.covList !== undefined && config.formObject.formType !== 'editEnrollment' && config.formObject.covList.length > 0}>
                <props.PanelHeading>
                    <props.Row>
                        <props.Span className="paddingleft15">
                            <GlobaliziedText message="lb_coverage" />
                        </props.Span>
                    </props.Row>
                </props.PanelHeading>
                <props.PanelBody>
                    {config.formObject.covList && config.formObject.covList.map((Coverage: EnrollmentCoverage, index: number) => {
                        return <>
                            <props.Div condition={Coverage.benefitClassificationChecked === 'true'} >
                                <props.Row>
                                    <props.Col xs="12" md="12" sm="12" className="panel-title">
                                        <GlobalizedText message="billpay.premiumBilled.benefitClassification" />-&nbsp;{Coverage.productSetDesc}
                                    </props.Col>
                                </props.Row>
                                {Coverage.coverageSelections.map((coverageSelection: EnrollmentCoverageSelection, j: number) => {
                                    return <>
                                        <props.Row condition={coverageSelection.yourdecision === 'D'}>
                                            <props.Col xs="12" md="12" sm="12">
                                                <props.DL>
                                                    <props.DLI condition={coverageSelection.productId !== ''} title="common.lbl.product" desc={() => {
                                                        return coverageSelection.productName + "- Declined"
                                                    }} />
                                                </props.DL>
                                            </props.Col>
                                        </props.Row>
                                        <props.Row condition={coverageSelection.yourdecision !== 'D'}>
                                            <props.Col sm="6" md="6" xs="12">
                                                <props.DL>
                                                    <props.DLI condition={coverageSelection.productName !== ''} title="common.lbl.product" desc={coverageSelection.productName} />
                                                    <props.DLI condition={coverageSelection.planDesc !== ''} title="common.lbl.plan" desc={coverageSelection.planDesc} />
                                                    <props.DLI condition={coverageSelection.planVolumeType === 'I' && coverageSelection.coverageOption !== 'I'} title="requestedvolume" desc={() => {
                                                        return formatNumber(coverageSelection.requestedVolume);
                                                    }} />
                                                    <props.Condition condition={trim(config.formObject.formType) === FormTypeEnum.ADDNEWMEMBER || trim(config.formObject.formType) === FormTypeEnum.UPDATEENROLLMENT}>
                                                        <props.DLI condition={coverageSelection.premiumOption !== ''} title="common.coverage.premiumTracking" desc={coverageSelection.premiumOptionDesc} />
                                                    </props.Condition>
                                                    <props.DLI condition={coverageSelection.coverageOption !== ''} title="billpay.premiumBilled.coverageOption" desc={coverageSelection.coverageOptionDesc} />
                                                </props.DL>
                                            </props.Col>
                                            <props.Col sm="6" md="6" xs="12">
                                                <props.DL>
                                                    <props.DLI condition={coverageSelection.requestedEffDate !== ''} title="requestedeffdate" desc={coverageSelection.requestedEffDate} />
                                                    <props.DLI condition={coverageSelection.calculatedEffDate !== ''} title="calculatedeffdate" desc={coverageSelection.calculatedEffDate} />
                                                    <props.DLI condition={coverageSelection.terminationDate !== '' && coverageSelection.terminationReason !== ''} title="" desc={() => {
                                                        return <props.Div>
                                                            <props.Col sm="5" md="5" xs="12" className="subtableStyle">
                                                                <props.DL>
                                                                    <props.DLI condition={coverageSelection.terminationDate !== ''} title="common.lbl.terminationDate" desc={coverageSelection.terminationDate} />
                                                                </props.DL>
                                                            </props.Col>
                                                            <props.Col sm="7" md="7" xs="12" className="subtableStyle">
                                                                <props.DL>
                                                                    <props.DLI condition={coverageSelection.terminationReason !== ''} title="terminationreason" desc={coverageSelection.terminationReasonDesc} />
                                                                </props.DL>
                                                            </props.Col>
                                                        </props.Div>
                                                    }} />
                                                </props.DL>
                                            </props.Col>
                                        </props.Row>

                                        <props.Panel condition={coverageSelection.coverageOption === 'I' && coverageSelection.indiCovSelection !== undefined && coverageSelection.indiCovSelection.length > 0 && coverageSelection.displayVolume && (coverageSelection.productLinkIndicator == '1' || coverageSelection.productLinkIndicator == '2' || coverageSelection.productLinkIndicator == '3')}>
                                            <props.PanelBody>
                                                <props.Col>
                                                    <label><GlobalizedText message="confirmation.lbl.covfamilymembers" /></label>
                                                </props.Col>
                                                <props.Table id={`indiCovSelection_${j}`} option={tableOption} table={{
                                                    data: coverageSelection.indiCovSelection,
                                                    columns: columnsAll
                                                }} delayAdjust={true}></props.Table>
                                            </props.PanelBody>
                                        </props.Panel>
                                        <props.Panel condition={coverageSelection.coverageOption === 'I' && coverageSelection.indiCovSelection !== undefined && coverageSelection.indiCovSelection.length > 0 && !coverageSelection.displayVolume && (coverageSelection.productLinkIndicator == '1' || coverageSelection.productLinkIndicator == '2' || coverageSelection.productLinkIndicator == '3')}>
                                            <props.PanelBody>
                                                <props.Col>
                                                    <label><GlobalizedText message="confirmation.lbl.covfamilymembers" /></label>
                                                </props.Col>
                                                <props.Table id={`indiCovSelection_${j}`} option={tableOption} table={{
                                                    data: coverageSelection.indiCovSelection,
                                                    columns: columnsNoVolume
                                                }} delayAdjust={true}></props.Table>
                                            </props.PanelBody>
                                        </props.Panel>
                                        <props.Panel condition={coverageSelection.coverageOption === 'I' && coverageSelection.indiCovSelection !== undefined && coverageSelection.indiCovSelection.length > 0 && coverageSelection.displayVolume && (coverageSelection.productLinkIndicator != '1' && coverageSelection.productLinkIndicator != '2' && coverageSelection.productLinkIndicator != '3')}>
                                            <props.PanelBody>
                                                <props.Col>
                                                    <label><GlobalizedText message="confirmation.lbl.covfamilymembers" /></label>
                                                </props.Col>
                                                <props.Table id={`indiCovSelection_${j}`} option={tableOption} table={{
                                                    data: coverageSelection.indiCovSelection,
                                                    columns: columnsNoStatus
                                                }} delayAdjust={true}></props.Table>
                                            </props.PanelBody>
                                        </props.Panel>
                                        <props.Panel condition={coverageSelection.coverageOption === 'I' && coverageSelection.indiCovSelection !== undefined && coverageSelection.indiCovSelection.length > 0 && !coverageSelection.displayVolume && (coverageSelection.productLinkIndicator != '1' && coverageSelection.productLinkIndicator != '2' && coverageSelection.productLinkIndicator != '3')}>
                                            <props.PanelBody>
                                                <props.Col>
                                                    <label><GlobalizedText message="confirmation.lbl.covfamilymembers" /></label>
                                                </props.Col>
                                                <props.Table id={`indiCovSelection_${j}`} option={tableOption} table={{
                                                    data: coverageSelection.indiCovSelection,
                                                    columns: columnsNone
                                                }} delayAdjust={true}></props.Table>
                                            </props.PanelBody>
                                        </props.Panel>

                                        <props.Panel condition={coverageSelection.coverageOption !== 'I' && coverageSelection.linkCovSelection !== undefined && coverageSelection.linkCovSelection.length > 0}>
                                            <props.PanelBody>
                                                <props.Col>
                                                    <label><GlobalizedText message="confirmation.lbl.covfamilymembers" /></label>
                                                </props.Col>
                                                <props.Table id={`linkCovSelection_${j}`} option={tableOption} table={{
                                                    data: coverageSelection.linkCovSelection,
                                                    columns: linkColumns
                                                }} delayAdjust={true}></props.Table>
                                            </props.PanelBody>
                                        </props.Panel>
                                        <props.HR />
                                    </>
                                })}
                            </props.Div>
                        </>
                    })}
                </props.PanelBody>
            </props.Panel>

            <props.Panel condition={config.formObject.cobList !== undefined && config.securityMap.displayCobInformation === true && config.formObject.cobList.length > 0 && config.formObject.hasCob}>
                <props.PanelHeading>
                    <props.Row>
                        <props.Span className="paddingleft15">
                            <GlobaliziedText message="cob_title" />
                        </props.Span>
                    </props.Row>
                </props.PanelHeading>
                <props.PanelBody>
                    {config.formObject.cobList && config.formObject.cobList.map((cob: Cob, index: number) => {
                        return <>
                            <props.Row>
                                <props.Col xs="12" md="12" sm="12" className="panel-title">
                                    <GlobalizedText message="cob_title" />&nbsp;<GlobalizedText message="confirmation.label.otherinsurance" />-&nbsp;{cob.fullName}
                                </props.Col>
                            </props.Row>
                            {cob.cobSelections.map((cobSelection: EnrollmentCOBCoverageSection, j: number) => {
                                return <>
                                    <props.Row>
                                        <props.Col sm="6" md="6" xs="12">
                                            <props.DL>
                                                <props.DLI condition={cobSelection.cobCoverage !== ''} title="lb_coverage" desc={cobSelection.cobCoverage} />
                                                <props.DLI condition={cobSelection.cobOthercarrierDesc !== ''} title="othercarrier" desc={cobSelection.cobOthercarrierDesc} />
                                                <props.DLI condition={cobSelection.cobOthercarrierbenefitDesc !== ''} title="othercarrierbenefitorder" desc={cobSelection.cobOthercarrierbenefitDesc} />
                                                <props.DLI condition={cobSelection.cobVerifydate !== '' && config.securityMap.cobVerifyFollowUpDate === true} title="verifydate" desc={cobSelection.cobVerifydate} />
                                            </props.DL>
                                        </props.Col>
                                        <props.Col sm="6" md="6" xs="12">
                                            <props.DL>
                                                <props.DLI condition={cobSelection.cobEffdate !== ''} title="common.lbl.effectiveDate" desc={cobSelection.cobEffdate} />
                                                <props.DLI condition={cobSelection.terminationDate !== ''} title="common.lbl.terminationDate" desc={cobSelection.terminationDate} />
                                                <props.DLI condition={cobSelection.cobFollowupdate !== '' && config.securityMap.cobVerifyFollowUpDate === true} title="followupdate" desc={cobSelection.cobFollowupdate} />
                                            </props.DL>
                                        </props.Col>
                                    </props.Row>
                                    <props.HR />
                                </>
                            })}
                        </>
                    })}
                </props.PanelBody>
            </props.Panel>

            <props.Panel condition={config.formObject.beneficiaryInfoList !== undefined && config.securityMap.displayBeneficiaryInformation === true && config.formObject.beneficiaryInfoList.length > 0 && config.formObject.hasLifePeriod}>
                <props.PanelHeading>
                    <props.Row>
                        <props.Span className="paddingleft15">
                            <GlobaliziedText message="beneficiary_title" />
                        </props.Span>
                    </props.Row>
                </props.PanelHeading>
                <props.PanelBody>
                    {config.formObject.beneficiaryInfoList && config.formObject.beneficiaryInfoList.map((beneficiary: EnrollmentBeneficiary, index: number) => {
                        return <>
                            <props.Row>
                                <props.Col sm="6" md="6" xs="12">
                                    <props.DL>
                                        <props.DLI condition={beneficiary.coverageDesc !== ''} title="lb_coverage" desc={beneficiary.coverageDesc} />
                                        <props.DLI condition={beneficiary.beneficiaryName !== ''} title="beneficiaryname" desc={beneficiary.beneficiaryName} />
                                        <props.DLI condition={beneficiary.beneficiaryId !== ''} title="beneficiaryid" desc={beneficiary.beneficiaryId} />
                                        <props.DLI condition={beneficiary.relationshipDesc !== ''} title="pi.relation" desc={beneficiary.relationshipDesc} />
                                        <props.DLI condition={beneficiary.dateOfBirth !== ''} title="common.lbl.dob" desc={beneficiary.dateOfBirth} />
                                        <props.DLI condition={beneficiary.contingent === 'true'} title="contingent" desc={"Yes"} />
                                    </props.DL>
                                </props.Col>
                                <props.Col sm="6" md="6" xs="12">
                                    <props.DL>
                                        <props.DLI condition={beneficiary.effdate !== ''} title="common.lbl.effectiveDate" desc={beneficiary.effdate} />
                                        <props.DLI condition={beneficiary.terminationDate !== ''} title="common.lbl.terminationDate" desc={beneficiary.terminationDate} />
                                        <props.DLI condition={beneficiary.trustee.trim() !== ''} title="trustee" desc={beneficiary.trustee} />
                                        <props.DLI condition={beneficiary.irrevocable === 'true'} title="irrevocable" desc={'Yes'} />
                                        <props.DLI condition={beneficiary.dateRevoke !== ''} title="daterevoked" desc={beneficiary.dateRevoke} />
                                        <props.DLI condition={beneficiary.percent !== ''} title="percent" desc={beneficiary.percent + "%"} />
                                    </props.DL>
                                </props.Col>
                            </props.Row>
                            <props.HR />
                        </>
                    })}
                </props.PanelBody>
            </props.Panel>
        </div >
        <pdfViewer.Component></pdfViewer.Component>
        <props.Modal id="discardModal" title={() => {
            return <props.BootstrapIcon type="ok" label="enrollment.lbl.discardTitle" />
        }} footer={() => {
            return <>
                <props.Button data-dismiss="modal"><GlobalizedText message="common.button.continue" /></props.Button>
                <props.Button data-dismiss="modal" onClick={() => {
                    {
                        ajax({
                            method: 'post',
                            data: {
                                "fromTransactionList": "true"
                            },
                            url: `/api/enrollment/exit`,
                            success: (res) => {
                                props.back();
                            }
                        });
                    }
                }}><GlobalizedText message="common.button.exit" /></props.Button>
            </>
        }}>
            <p><GlobalizedText message="enrollment.continue.exited" /></p>
        </props.Modal>
        <props.Modal id="submitModal" title={() => {
            return submitModalTitle
        }} footer={() => {
            return <>
                <props.Condition condition={!isEmptyStr(config.nextTransactionID)}>
                    <props.Button data-dismiss="modal" onClick={(e: any) => {
                        skip();
                    }}><GlobalizedText message="common.lbl.nextTransaction" /></props.Button>
                </props.Condition>
                <props.Button data-dismiss="modal" onClick={props.back}><GlobalizedText message="common.button.exit" /></props.Button>
            </>
        }}>
            <props.Row>
                <props.Col sm="12" md="12" xs="12">
                    <props.DL className="dl-horizontal gwp-dl gwp-align-left">
                        <props.DLI condition={submitResult?.memberName !== ''} title="common.lbl.memberName" desc={submitResult?.memberName} />
                        <props.DLI condition={submitResult?.memberID !== ''} title="common.lbl.memberID" desc={submitResult?.memberID} />
                        <props.DLI condition={submitResult?.transactionID !== ''} title="common.lbl.transNumber" desc={submitResult?.transactionID} />
                        <props.DLI condition={submitResult?.errorMsg !== ''} title="" desc={submitResult?.errorMsg} />
                    </props.DL>
                </props.Col>
            </props.Row>
        </props.Modal>
    </React.Fragment >
});
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { FORMAT_MAPPER, FORMAT_TYPE, validateFormat } from './components';
import { VALIDATION_REGEX } from './regex';
import { ajaxTestForYup, convertDate, getGlobalizedText, isEmptyStr } from './utils';

export const INVALID_MESSAGES = {
    BIRTHDATE_IS_EMPTY: "regist.msg.err.date.of.birth.empty",
    BIRTHDATE_IS_INVALID: "common.lbl.birthdateValidate",
    LASTNAME_IS_EMPTY: "com.lastname.req",
    POLICY_NUMBER_IS_EMPTY: "com.policynum.req",
    GOVT_ID_IS_EMPTY: "common.msg.govtIdEmpty",
    GOVT_ID_IS_INVALID: "common.msg.govtInvalidFormat",
    PHONE_NUMBER_ID_INVALID: "regist.msg.err.phone.number.irregular",
    EMAIL_IS_INVALID: "regist.msg.err.email.irregular",
    EMAIL_IS_REQUIRED: "common.msg.emailReq",
    GRUOP_IS_EMPTY: "common.msg.grouprequired",
    GROUPNUMBER_IS_REQUIRED: "regist.msg.err.group.number.required",
    ACCOUNT_IS_EMPTY: "common.msg.accountrequired",
    MEMBERID_OR_POLICYNUMBER_IS_EMPTY: "regist.msg.info.memberID.and.groupNumber.or.polN.required",
    GROUPNUMBER_IS_TO_LONG: "regist.msg.err.group.number.too.long",
    FIRSTNAME_LASTNAME_OR_MEMBERID_IS_REQUIRED: "common.msg.memid.name.required",
    FIRSTNAEM_LASTANEM_OR_MEMBERID_BOTH_ENTERED: "billpay_jumpto_entereitherml",
    MEMBERID_GROUP_NUMBER_OR_POLICY_NUMBER_REQUIRED: "regist.msg.info.memberID.and.groupNumber.or.polN.required",
    FIRSTNAME_LASTNAME_OR_INSTITUION_NAME_IS_REQUIRED: "regist.msg.info.first_last.name.or.institution.name.required",
    LASTNAME_OR_MEMBERID_IS_REQUIRED: "billpay_jumpto_mlr",
    LASTNAME_OR_MEMBERID_BOTH_ENTERED: "billpay_jumpto_entereitherml",
    LASTNAME_CONTAINS_2_CHARACTERS: "common.msg.lastName2characters",
    FIRSTNAME_IS_EMPTY: "regist.msg.err.first.name.empty",
    FIRSTNAME_IS_TOO_LONG: "changeow.msg.info.firstNameTooLong",
    LASTNAME_IS_TOO_LONG: "changeow.msg.info.lastNameTooLong",
    COMPANY_NAME_IS_TOO_LONG: "regist.msg.err.company.name.too.long",
    MEMBERID_POLICYNUMBER_TOO_LONG:"regist.msg.err.policy.number.too.long",
    DEPENDENTID_TO_LONG:"regist.msg.err.dep.id.too.long",
    COMPANY_CONTAINS_2_CHARACTERS: "common.msg.orgName2characters",
    NATIONAL_PROVIDER_ID_OR_PROVIDER_GOVERNMENT_ID_IS_REQUIRED: "regist.msg.info.national.provider.or.gov.id.required",
    PREFERED_LANGUAGE_IS_EMPTY: "com.perLanguages.req",
    PREFERED_DATE_FORMAT_IS_EMPTY: "com.prefDateFormats.req",
    FOLLOWUPACTION_IS_EMPTY: "common.msg.statusActionAreRequired",
    MY_ANSWER_IS_EMPTY: "forguid.msg.myanswer.req",
    PRE_SEND_METHOD_IS_EMPTY: "forguid.msg.preMethod",
    ORG_NAME_IS_EMPTY: "forguid.msg.institutionname.req",
    NATIONAL_ID_MUST_BEDIGIT: "forguid.msg.nationid.number",
    DATES_WRONG: "billpay.datewrong",
    CURRENT_DATE_WRONG: " Through Date can't be later than current date.",
    FROMDATE_IS_EMPTY: "common.msg.from.date.is.required",
    FROMDATE_IS_INVALID: "common.msg.invalidFromDate",
    THROUGHDATE_IS_EMPTY: "common.msg.through.date.is.required",
    THROUGHDATE_IS_INVALID: "common.msg.invalidThroughDate",
    COMPANY_NAME_IS_EMPTY: "changeow.msg.info.companyNameReq",
    STARTEND_DATE_WRONG: "audittrail.msg.err.dateCompare",
    STARTDATE_IS_EMPTY: "audittrail.msg.err.emptyStartDate",
    STARTDATE_IS_INVALID: "audittrail.msg.err.invalidStartDate",
    ENDDATE_IS_EMPTY: "audittrail.msg.err.emptyEndDate",
    ENDDATE_IS_INVALID: "audittrail.msg.err.invalidEndDate",
    BILLPAY_DATE_WRONG: "billpay.datewrong",
    BILLPAY_FROM_DATE_EMPTY: "billpay.fromdate.empty",
    BILLPAY_THROUGH_DATE_EMPTY: "billpay.throughdate.empty",
    GROUP_NUMBER_REQUIRED: "coverage.msg.patientgroupnumber.required",
    PATIENT_DOB_REQUIRED: "coverage.lbl.patientdateofbirth.required",
    PATIENT_LAST_NAME_REQUIRED: "coverage.msg.patientlastname.required",
    PATIENT_FIRST_NAME_REQUIRED: "coverage.lbl.patientfirstname.required",
    NEED_ONE_OF_THEM_NOT_EMPTY: "homepage.all.lbl.required",
    ENTER_IDENTIFIER: "common.msg.enterIdentifier",
    PAGE_FIELDS_INSURANCE_EMPTY: "hidefields.msg.insuranceTypeRequired",
    PAGE_FIELDS_PRODUCT_EMPTY: "hidefields.msg.productRequired",
    AFTER_EARLIER_BEFORE_DATE: "common.msg.afterDateEarlierBeforeDate",
    IS_REQUIRED: "message_information_required",
    ZIP_IS_INVALID: "common.lbl.zip.invalid",
    LIFEEVENT_SELECT: "lifeevent_select",
    EFFECTIVE_DATE: "common.lbl.effectiveDate",
    LATER_THAN: "common.lbl.laterThan",
    HIRE_DATE: "addmember.issue.hire.date",
    ERM_DECISION_EMPTY: "enrollment.yourDecision.empty",
    ERM_REQUEFF_EMPTY: "enrollment.requestedEff.empty",
    FORGOT_PASSWORD_USERID_EMPTY: "forgpass.msg.err.user.id.empty",
    FORGOT_PASSWORD_ANSWER_EMPTY: "forgpass.msg.err.answer.emtpy",
    FORGOT_PASSWORD_USERID_NOTFOUND: "forgpass.msg.err.user.id.not.found",
    FORGOT_PASSWORD_ANSWER_INCORRECT: "forgpass.msg.err.answer.incorrect",
    FORGOT_PASSWORD_FORBIDDEN: "forgpass.msg.err.forbidden.try.again",
    FORGOT_PASSWORD_QUESTION_NOT_SETUP: "forgpass.msg.err.questionnotsetup",
    FORGOT_PASSWORD_SERVER_ERROR: "forgpass.msg.server.err",
    FORGOT_PASSWORD_RESET_SUCCESS: "forgpass.msg.suc.reset.password.complete",
    HOMEPAGE_ERROR_FIELDS: "homepage.errorFields",
    FORGOT_PASSWORD_IN_HISTORY: "forgpass.msg.err.password.in.history",
    FORGOT_PASSWORD_IN_HISTORY_SUFFIX: "forgpass.msg.err.password.in.history.suffix",
    DOCUMENT_TYPE_MUST_SELEECT: "common.msg.documenttypemustselect",
    CURRENT_PASSWORD_INCORRECT: "regist.msg.err.password.incorrect"
}
function doHandleDate() {
    var myDate = new Date();
    var tYear = myDate.getFullYear();
    var tMonth = myDate.getMonth();

    var m = tMonth + 1 + "";
    if (m.toString().length === 1) {
        m = "0" + m;
    }
    return tYear + '-' + m;
}
export const YupSchema = {
    birthDate: (format: string, getGlobalizedText: (messageKey: string) => void) => {
        let requiredMsg = getGlobalizedText(INVALID_MESSAGES.BIRTHDATE_IS_EMPTY) + " " + format.toLowerCase() + ".";
        return yup.date().required(requiredMsg).max(new Date(), INVALID_MESSAGES.BIRTHDATE_IS_INVALID).typeError(requiredMsg);
    },
    lastName: yup.string().trim().required(INVALID_MESSAGES.LASTNAME_IS_EMPTY),
    companyName: yup.string().trim().required(INVALID_MESSAGES.COMPANY_NAME_IS_EMPTY),
    policyNumber: yup.string().trim().required(INVALID_MESSAGES.POLICY_NUMBER_IS_EMPTY),
    govtID: (govtIDType: FORMAT_TYPE, getGlobalizedText: (messageKey: string) => void) => {
        let format = FORMAT_MAPPER[govtIDType];
        return yup.string().trim().transform((v: string) => { return v.replaceAll("-", "") }).matches(VALIDATION_REGEX.GOVT_ID, getGlobalizedText(INVALID_MESSAGES.GOVT_ID_IS_INVALID) + " " + format.toLowerCase() + ".").required(INVALID_MESSAGES.GOVT_ID_IS_EMPTY)
    },
    profile: {
        phoneNumber: yup.string().trim(),
        jobTitle: yup.string().trim()
    },
    registration: {
        agentNumber: yup.string().trim().required("regist.msg.err.agent.number.empty").max(10, "regist.msg.err.agent.number.too.long"),
        agencyNumber: yup.string().trim().required("regist.msg.err.agency.number.empty").max(10, "regist.msg.err.agent.number.too.long"),
        agencyName: yup.string().trim().required("regist.msg.err.agency.name.empty").max(60, "regist.msg.err.agency.name.too.long"),
        firstName: yup.string().trim().required("regist.msg.err.first.name.empty").max(20, "changeow.msg.info.firstNameTooLong"),
        lastName: yup.string().trim().required("com.lastname.req").max(40, "changeow.msg.info.lastNameTooLong"),
        policyNumberOrMemberId: yup.string().trim().required("regist.msg.info.member.id.or.policy.number.required").max(10, "regist.msg.err.policy.number.too.long"),
        memberId: yup.string().trim().required("regist.msg.info.member.id.or.policy.number.required").max(10, "regist.msg.err.policy.number.too.long"),
        policyNumber: yup.string().trim().required("regist.msg.info.member.id.or.policy.number.required").max(10, "regist.msg.err.policy.number.too.long"),
        groupNumber: yup.string().trim().required("regist.msg.err.group.number.required").max(10, "regist.msg.err.group.number.too.long"),
        companyName: yup.string().trim().required("changeow.msg.info.companyNameReq").max(60, "regist.msg.err.org.name.too.long"),
        dependentId: yup.string().trim().required("regist.err.dep.id.required").max(13, "regist.msg.err.dep.id.too.long"),
        userID: yup.string().trim().test("testUserID", "regist.msg.err.user.exists", (v: any): Promise<boolean | yup.ValidationError> => {
            return ajaxTestForYup({
                url: `/api/registrationSingle/isExistUserId/${v}`
            });
        }).max(20, "regist.msg.err.user.id.too.long").min(6, "regist.msg.err.user.id.too.short").required("forgpass.msg.err.user.id.empty"),
        password: (rule: RegExp, min: number, max: number, getGlobalizedText: (messageKey: string) => void) => {
            return yup.string().trim()
                .matches(rule, "regist.msg.err.password.format.irregular")
                .max(max, `${getGlobalizedText("regist.msg.err.password.too.long")} ${max} ${getGlobalizedText("regist.msg.err.password.suffix")}`)
                .min(min, `${getGlobalizedText("regist.msg.err.password.too.short")} ${min} ${getGlobalizedText("regist.msg.err.password.suffix")}`)
                .required("forgpass.msg.err.password.empty")
        },
        confirmPassword: (password: string) => {
            return yup.string().trim().equals([yup.ref(password)], "regist.msg.err.password.confirm.failured").required("forgpass.msg.err.confirm.password.empty")
        },
        securityImage: yup.string().trim().required("regist.msg.err.sec.img.empty"),
        securityImageCaption: yup.string().trim().max(30, "regist.msg.err.sec.phrase.too.long").required("regist.msg.err.sec.phrase.empty"),
        securityQuestions: {
            securityQuestion1: yup.string().trim().required("regist.msg.err.question1.empty"),
            securityQuestion2: yup.string().trim().required("regist.msg.err.question2.empty"),
            securityQuestion3: yup.string().trim().required("regist.msg.err.question3.empty"),
            answer1: yup.string().trim().max(100, "regist.msg.err.answer1.too.long").required("regist.msg.err.answer1.empty"),
            answer2: yup.string().trim().max(100, "regist.msg.err.answer1.too.long").required("regist.msg.err.answer2.empty"),
            answer3: yup.string().trim().max(100, "regist.msg.err.answer1.too.long").required("regist.msg.err.answer3.empty"),
        },
        jobTitle: yup.string().trim().max(50, "regist.msg.err.job.title.too.long").min(3, "regist.msg.err.job.title.too.short"),
        languageCode: yup.string().trim().required(INVALID_MESSAGES.PREFERED_LANGUAGE_IS_EMPTY),
        dateFormat: yup.string().trim().required(INVALID_MESSAGES.PREFERED_DATE_FORMAT_IS_EMPTY)
    },
    directDeposit: {
        bankName: yup.string().trim().required("diredep.msg.err.bank.name.empty").test(
            "bankName",
            "diredep.msg.err.wrong.input.fullwidth",
            (str) => {
                let bankName = str === undefined || str === null ? '' : str;
                let strCode = 0;
                for (var i = 0; i < bankName.length; i++) {
                    strCode = bankName.charCodeAt(i);
                    if ((strCode > 65248) || (strCode === 12288)) {
                        return false;
                    }
                }
                return true;
            }
        ),
        routingNumber: yup.string().trim().length(9, "diredep.msg.err.routing.number.wrong.length").required("diredep.msg.err.routing.number.empty"),
        accountNumber: yup.string().trim().max(20, "diredep.msg.err.account.number.wrong.length").required("diredep.msg.err.account.number.empty"),
        agentFilter: yup.string().trim().required("diredep.chooseagent")
    },
    auditTrail: {
        startDate: yup.string().trim().required("common.msg.inputValidDate"),
        endDate: yup.string().trim().required("common.msg.inputValidDate")
    },
    email: yup.string().trim().email(INVALID_MESSAGES.EMAIL_IS_INVALID),
    email1: yup.string().trim().required("common.msg.emailRequired"),
    cellNum: yup.string().trim().required("common.msg.mobileRequired"),
    phoneNumber: yup.string().trim().matches(VALIDATION_REGEX.PHONE_NUMBER, INVALID_MESSAGES.PHONE_NUMBER_ID_INVALID),
    followUpAction: yup.string().trim().required(INVALID_MESSAGES.FOLLOWUPACTION_IS_EMPTY),
    billingpayment: {
        fromDate: yup.string().trim().required(INVALID_MESSAGES.BILLPAY_FROM_DATE_EMPTY),
        throughDate: yup.string().trim().required(INVALID_MESSAGES.BILLPAY_THROUGH_DATE_EMPTY),
        dateWrong: yup.string().trim().required(INVALID_MESSAGES.BILLPAY_DATE_WRONG)
    },
    inforceBusiness: {
        groupSelect: yup.string().trim().required("addmember.group.required").notOneOf(["0"], "addmember.group.required"),
        accountSelect: yup.string().trim().required("addmember.account.required").notOneOf(["0"], "addmember.account.required"),
        govtID: yup.string().trim().required("common.msg.govtIdEmpty"),
        selectDPagent: yup.string().trim().required("diredep.chooseagent"),
        myAgent: yup.string().trim().required("common.msg.agentRequired"),
        dialogName: yup.string().trim().required("common.msg.fieldCanNotEmpty")
    },
    addNewMember: {
        group: yup.string().trim().required("addmember.group.required").notOneOf(["0"], "addmember.group.required"),
        account: yup.string().trim().required("addmember.account.required").notOneOf(["0"], "addmember.account.required"),
        hireDate: yup.string().trim().required("addmember.issuehiredate.required"),
        memberID: yup.string().trim()
            .test("memberID", "addmember.memberid.input.error", (v: any) => {
                let reg = /^\d+$/;
                if (reg.test(v.toString().trim())) {
                    return true;
                }
                return false;
            }).test("memberID", "addmember.memberidallzeros.input.error", (v) => {
                let tmp = Number(v);
                if (tmp !== 0) {
                    return true;
                }
                return false;
            }).required("addmember.memberid.required"),
        govtID: (getGlobalizedText: any, format: FORMAT_TYPE) => {
            return yup.string().trim().test("govtID", "addmember.duplicated.governmentid",
                (v: any): Promise<boolean | yup.ValidationError> => {
                    if (isEmptyStr(v)) {
                        return new Promise<boolean | yup.ValidationError>((resolve) => {
                            resolve(true);
                        });
                    }
                    if (!validateFormat(v, format)) {
                        return new Promise<boolean | yup.ValidationError>((resolve) => {
                            resolve(new ValidationError(getGlobalizedText(INVALID_MESSAGES.GOVT_ID_IS_INVALID) + " " + FORMAT_MAPPER[format].toLowerCase() + ".", "", "govtID"));
                        });
                    } else {
                        return ajaxTestForYup({
                            url: '/api/addmember/checkGovtId',
                            params: {
                                govtID: v
                            },
                            handleResult: (res: any) => {
                                return !res.govtIDFlag
                            }
                        });
                    }
                })
        },
        payrollDefinion: yup.string().trim().required("common.msg.payrolldefinitionrequired")
    },
    enrollment: {
        personalInformation: {
            streetAddress: (streetAddressRequired: boolean, getGlobalizedText: (messageKey: string) => void) => {
                if (streetAddressRequired) {
                    let requiredMsg = getGlobalizedText("common.lbl.streetAddress") + " " + getGlobalizedText("message_information_required");
                    return yup.string().trim().required(requiredMsg);
                } else {
                    return yup.string().trim();
                }
            },
            city: (getGlobalizedText: (messageKey: string) => void) => {
                let requiredMsg = getGlobalizedText("common.lbl.city") + " " + getGlobalizedText("message_information_required");
                return yup.string().trim().required(requiredMsg);
            },
            state: (getGlobalizedText: (messageKey: string) => void) => {
                let requiredMsg = getGlobalizedText("state") + " " + getGlobalizedText("message_information_required");
                return yup.string().trim().required(requiredMsg);
            },
            zip: (zipRequired: boolean, getGlobalizedText: (messageKey: string) => void) => {
                if (zipRequired) {
                    let requiredMsg = getGlobalizedText("pi.zip.code") + " " + getGlobalizedText("message_information_required");
                    return yup.string().trim().test("zip", INVALID_MESSAGES.ZIP_IS_INVALID, (v) => {
                        let reg = VALIDATION_REGEX.ZIP;
                        if (v != null && v.toString() !== "") {
                            if (v !== "" && reg.test(v.toString().trim())) {
                                return true;
                            }
                            return false;
                        } else {
                            return true;
                        }
                    }).required(requiredMsg);
                } else {
                    return yup.string().trim().test("zip", "common.lbl.zip.empty", (v) => {
                        if (v == null || v.trim() === "") {
                            return true;
                        }
                        return false;
                    })
                }
            },
            maritalStatus: (getGlobalizedText: (messageKey: string) => void) => {
                let requiredMsg = getGlobalizedText("maritalstatus") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                return yup.string().trim().required(requiredMsg);
            },
            gender: (getGlobalizedText: (messageKey: string) => void) => {
                let requiredMsg = getGlobalizedText("pi.gender") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                return yup.string().trim().required(requiredMsg);
            },
            birthDate: (getGlobalizedText: (messageKey: string) => void) => {
                let requiredMsg = getGlobalizedText("common.lbl.dob") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                return yup.string().trim().required(requiredMsg);
            },
            smoker: (getGlobalizedText: (messageKey: string) => void) => {
                let requiredMsg = getGlobalizedText("smoker") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                return yup.string().trim().required(requiredMsg);
            },
            salary: (requireSalary: boolean, getGlobalizedText: (messageKey: string) => void) => {
                if (requireSalary) {
                    let requiredMsg = getGlobalizedText("salary") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                    return yup.string().trim().required(requiredMsg);
                } else {
                    return yup.string().trim();
                }
            },
            hoursWeek: (requireSalary: boolean, getGlobalizedText: (messageKey: string) => void) => {
                if (requireSalary) {
                    let requiredMsg = getGlobalizedText("hoursweek") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                    return yup.string().trim().required(requiredMsg);
                } else {
                    return yup.string().trim();
                }
            },
            salaryEffectiveDate: (requireSalary: boolean, getGlobalizedText: (messageKey: string) => void) => {
                if (requireSalary) {
                    let requiredMsg = getGlobalizedText("salaryeffdate") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                    return yup.string().trim().required(requiredMsg);
                } else {
                    return yup.string().trim();
                }
            },
            hireDate: (updateIssueHireDate: boolean, getGlobalizedText: (messageKey: string) => void) => {
                if (updateIssueHireDate) {
                    let requiredMsg = getGlobalizedText("addmember.issue.hire.date") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                    return yup.string().trim().required(requiredMsg);
                } else {
                    return yup.string().trim();
                }
            },
            terminationDate: (terminationReason: string, terminationDateLimit: string, updateTerminationDateSecurity: boolean, getGlobalizedText: (messageKey: string) => void) => {
                let msg = getGlobalizedText("common.lbl.terminationDate") + " " + getGlobalizedText("common.lbl.laterThan") + " " + getGlobalizedText("addmember.issue.hire.date");
                let hireDate = document.getElementById("hireDate_input")?.getAttribute("value");
                let endDate = new Date();
                endDate.setMonth(endDate.getMonth() - Number(terminationDateLimit));
                let endDateMinus = new Date(endDate.getTime() - 24 * 60 * 60 * 1000);
                let msg2 = getGlobalizedText("common.limit.terminationDate.months.back") + " " + endDate.toLocaleDateString();
                if (updateTerminationDateSecurity) {
                    return yup.string().trim().test("terminationDate", "coverage_text3", (v) => {
                        if ((v == null || v.trim() === "") && terminationReason != null && terminationReason.trim() !== "") {
                            return false;
                        }
                        return true;
                    }).test("terminationDate", msg2, (v) => {
                        if (v != null && v.trim() !== "" && new Date(v).getTime() <= endDateMinus.getTime()) {
                            return false;
                        }
                        return true;
                    }).test("terminationDate", msg, (v) => {
                        if (v != null && v.trim() !== "") {
                            if (hireDate != null && new Date(v).getTime() < new Date(hireDate).getTime()) {
                                return false;
                            }
                            return true;
                        }
                        return true;
                    });
                } else {
                    return yup.string().trim();
                }
            },
            terminationReason: (updateTerminationDateSecurity: boolean, getGlobalizedText: (messageKey: string) => void) => {
                let terminationDate = document.getElementById("terminationDate_input")?.getAttribute("value");
                if (terminationDate != null && terminationDate.trim() !== "" && updateTerminationDateSecurity) {
                    return yup.string().trim().required("coverage_text2");
                } else {
                    return yup.string().trim();
                }
            },
            bankAccountNumber: (eftRequired: boolean, getGlobalizedText: (messageKey: string) => void) => {
                if (eftRequired) {
                    let requiredMsg = getGlobalizedText("bankaccountnumber") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                    return yup.string().trim().required(requiredMsg);
                } else {
                    return yup.string().trim();
                }
            },
            accountType: (eftRequired: boolean, getGlobalizedText: (messageKey: string) => void) => {
                if (eftRequired) {
                    let requiredMsg = getGlobalizedText("payment.lbl.accountType") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                    return yup.string().trim().required(requiredMsg);
                } else {
                    return yup.string().trim();
                }
            },
            transitNumber: (eftRequired: boolean, transactionID: string, setError: any, getGlobalizedText: (messageKey: string) => void) => {
                let validTransitNumber = yup.string().trim().test("transitNumber", "enrollment.msg.transitNumberInvalid",
                    (v: any): Promise<boolean | yup.ValidationError> => {
                        return ajaxTestForYup({
                            url: '/api/enrollment/personalInformation/validate',
                            params: {
                                transitNumber: v,
                                transactionID: transactionID
                            },
                            handleResult: (res: any) => {
                                if (setError !== undefined) {
                                    setError(false);
                                }
                                return true
                            },
                            handleFail: (res: any) => {
                                if (setError !== undefined) {
                                    setError(true);
                                }
                                return false
                            }
                        });
                    });
                if (eftRequired) {
                    let requiredMsg = getGlobalizedText("transitnumber") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                    return validTransitNumber.required(requiredMsg);
                } else {
                    let transitNumber = document.getElementById("transitNumber_input")?.getAttribute("value");
                    if (transitNumber != null && transitNumber.trim() !== "") {
                        return validTransitNumber;
                    } else {
                        return yup.string().trim();
                    }
                }
            },
            eftStartDate: (eftRequired: boolean, getGlobalizedText: (messageKey: string) => void) => {
                if (eftRequired) {
                    let requiredMsg = getGlobalizedText("eftstartdate") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                    return yup.string().trim().required(requiredMsg);
                } else {
                    return yup.string().trim();
                }
            },
            eftStopDate: (eftRequired: boolean, getGlobalizedText: (messageKey: string) => void) => {
                if (eftRequired) {
                    let eftStartDate = document.getElementById("eftStartDate_input")?.getAttribute("value");
                    let msg = getGlobalizedText("eftstopdate") + " " + getGlobalizedText("common.lbl.laterThan") + " " + getGlobalizedText("eftstartdate");
                    return yup.string().trim().test("eftStopDate", msg, (v) => {
                        if (v != null && v.trim() !== "") {
                            if (eftStartDate != null && eftStartDate.trim() !== "" && new Date(eftStartDate).getTime() > new Date(v).getTime()) {
                                return false;
                            }
                            return true;
                        }
                        return true;
                    });
                } else {
                    return yup.string().trim();
                }
            },
            locationEffectiveDate: (location: string, getGlobalizedText: (messageKey: string) => void) => {
                if (location != null && location.trim() !== "") {
                    let requiredMsg = getGlobalizedText("locationeffdate") + " " + getGlobalizedText(INVALID_MESSAGES.IS_REQUIRED);
                    return yup.string().trim().required(requiredMsg);
                } else {
                    return yup.string().trim();
                }
            }
        }
    },
    newBusinessApplication: {
        groupNumber: yup.string().trim().required("addmember.group.required").notOneOf(["0"], "addmember.group.required"),
        accountNumber: yup.string().trim().required("addmember.account.required"),
        applicationNumber: yup.string().trim().required("nbusiness.msg.applicationnum.required"),
        govtID: yup.string().trim().required("common.msg.govtIdEmpty"),
        receivedDate: yup.string().trim().required("common.msg.receivedDateRequired"),
        dpAgent: yup.string().trim().required("diredep.chooseagent"),
        myAgent: yup.string().trim().required("common.msg.agentRequired")

    },
    managePortalCategories: {
        name: yup.string().trim().required("common.msg.name.empty"),
        userTypes: yup.array().required("User is required"),
    },
    searchSection: {
        firstName: yup.string().trim().required("regist.msg.err.first.name.empty").max(19, "changeow.msg.info.firstNameTooLong"),
        lastName: yup.string().trim().required("com.lastname.req").max(39, "changeow.msg.info.lastNameTooLong"),
        birthDate: yup.string().trim().required("changeow.msg.info.dobReq"),
        govtID: yup.string().trim().required("changeow.msg.info.govtReq"),
        companyName: yup.string().trim().required("changeow.msg.info.companyNameReq").max(59, "changeow.msg.info.compNameTooLong")
    },
    changePaymentMethod: {
        newPaymentFrequency: (changePaymentFrequency: boolean, getGlobalizedText: (messageKey: string) => void) => {
            if (changePaymentFrequency) {
                return yup.string().trim().required("payment.msg.frequencyReq")
            } else {
                return yup.string().trim();
            }
        },
        firstName: yup.string().trim().required("regist.msg.err.first.name.empty"),
        lastName: yup.string().trim().required("com.lastname.req"),
        companyName: yup.string().trim().required("changeow.msg.info.companyNameReq"),
        bankName: yup.string().trim().required("diredep.msg.err.bank.name.empty"),
        routingNumber: yup.string().trim().length(9, "diredep.msg.err.routing.number.wrong.length").required("diredep.msg.err.routing.number.empty"),
        accountNumber: yup.string().trim().max(20, "diredep.msg.err.account.number.wrong.length").required("diredep.msg.err.account.number.empty"),
        nextPaymentDate: (startDate: string, endDate: string, val: string, dateFormat: string, getGlobalizedText: (messageKey: string) => void) => {
            let requiredMsg = getGlobalizedText("common.msg.npdInvalid") + "(" + startDate + " to " + endDate + ")";
            startDate = convertDate(startDate, dateFormat, "yyyy-MM-dd");
            endDate = convertDate(endDate, dateFormat, "yyyy-MM-dd");
            val = convertDate(val, dateFormat, "yyyy-MM-dd")
            return yup.string().trim().test("nextPaymentDate", requiredMsg, (v) => {
                if (val < startDate || val > endDate) {
                    return false;
                } else {
                    return true;
                }
            }).required(getGlobalizedText("common.msg.next.pay.date.req") + " " + dateFormat?.toLowerCase())
        },
        accountType: yup.string().trim().required("common.msg.acct.type.is.req"),
    },
    mocksavecard: {
        firstName: yup.string().trim().required("regist.msg.err.first.name.empty"),
        lastName: yup.string().trim().required("com.lastname.req"),
        creditCardType: yup.string().trim().required("payment.msg.ccTypeReq"),
        ccNumber: yup.string().trim().test(
            "ccNumber",
            "method.crenum.error",
            (str) => {
                let ccNumber = str === undefined || str === null ? '' : str;
                if (ccNumber.length !== 16 || /^\d{16}$/.test(ccNumber) === false) {
                    return false;
                }
                return true;
            }
        ),
        expirationDate: yup.string().trim().test(
            "expirationDate",
            "method.expiration.error",
            (str) => {
                let expirationDate = str === undefined || str === null ? '' : str;
                if (expirationDate.length !== 7 || /^\d{2}\/\d{4}$/.test(expirationDate) === false) {
                    return false;
                } else {
                    var month = expirationDate.split("/")[0];
                    var year = expirationDate.split("/")[1];
                    if (parseInt(month) <= 0 || parseInt(month) > 12) {
                        return false;
                    }
                    if (year + month < doHandleDate()) {
                        return false;
                    }
                }
                return true;
            }
        ),
        clientProfileID: yup.string().trim().required("msg.error.clientProfileId.empty"),
        creditCardToken: yup.string().trim().required("msg.error.creditCardToken.empty"),
        vendor: yup.string().trim().required("msg.error.MerchantId.empty"),
        cvv2: yup.string().trim().required("CVV2 is required"),
    },
    personalInfomation: {
        streetAddress0: yup.string().trim().required("common.msg.addrLineOneRequired"),
        email1: yup.string().trim().required("common.msg.emailRequired"),
        cellNum: yup.string().trim().required("common.msg.mobileRequired"),
        city: yup.string().trim().required("common.msg.cityRequired"),
        stateContry: yup.string().trim().required("changeow.msg.info.stateCountryReq"),
        phoneNumber: yup.string().trim().matches(VALIDATION_REGEX.PHONE_NUMBER_PERSONALINFO, INVALID_MESSAGES.PHONE_NUMBER_ID_INVALID),
        zip: (zipRequired: boolean, getGlobalizedText: (messageKey: string) => void) => {
            if (zipRequired) {
                let requiredMsg = getGlobalizedText("pi.zip.code") + " " + getGlobalizedText("message_information_required");
                return yup.string().trim().test("zip", INVALID_MESSAGES.ZIP_IS_INVALID, (v) => {
                    let reg = VALIDATION_REGEX.ZIP;
                    if (v != null && v.toString() !== "") {
                        if (v !== "" && reg.test(v.toString().trim())) {
                            return true;
                        }
                        return false;
                    } else {
                        return true;
                    }
                }).required(requiredMsg);
            } else {
                return yup.string().trim().test("zip", "common.lbl.zip.empty", (v) => {
                    if (v != null && v.trim() === "") {
                        return true;
                    }
                    return false;
                })
            }
        },
    },
    forgotPassword: {
        userID: yup.string().trim().required("forgpass.msg.err.user.id.empty"),
        answer: yup.string().trim().required("forgpass.msg.err.answer.emtpy"),
        password: (enableVerifyPwd: boolean, pwdMaxLength: any, pwdMinLength: any, validationRule: any, getGlobalizedText: (messageKey: string) => void) => {
            if (enableVerifyPwd) {
                if (pwdMinLength < 5) {
                    pwdMinLength = 5;
                }

                return yup.string().trim()
                    .matches(validationRule, "regist.msg.err.password.format.irregular")
                    .max(pwdMaxLength, `${getGlobalizedText("regist.msg.err.password.too.long")} ${pwdMaxLength} ${getGlobalizedText("regist.msg.err.password.suffix")}`)
                    .min(pwdMinLength, `${getGlobalizedText("regist.msg.err.password.too.short")} ${pwdMinLength} ${getGlobalizedText("regist.msg.err.password.suffix")}`)
                    .required("forgpass.msg.err.password.empty");

            } else {
                return yup.string().trim();
            }
        },
        confirmPassword: (password: any) => {
            return yup.string().trim().test("confirmPassword", "regist.msg.err.password.confirm.failured", (v) => {
                if (v != null && v.toString() !== "") {
                    if (v !== "" && v === password) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            }).required("forgpass.msg.err.confirm.password.empty");
        }
    },
    faq: {
        question: yup.string().trim().required("common.msg.question.required"),
        answer: yup.string().trim().required("common.msg.answer.required"),
        categories: yup.array().required("common.msg.categories.required")
    },
    updatePayrollSchedule: {
        group: yup.string().trim().required("addmember.group.required").notOneOf(["0"], "addmember.group.required"),
        account: yup.string().trim().required("addmember.account.required").notOneOf(["0"], "addmember.account.required"),
        memberID: yup.string().trim()
            .test("memberID", "addmember.memberid.required", (v) => {
                if (v !== undefined && v !== null) {
                    let reg2 = /\s+/g;
                    let select_memberid = v.replace(reg2, " ");
                    select_memberid = select_memberid.trim();
                    if (select_memberid === "") {
                        return false;
                    }
                    return true;
                }
                return true;
            }).test("memberID", "addmember.memberid.input.error", (v) => {
                if (v !== undefined && v !== null) {
                    let reg2 = /\s+/g;
                    let reg_alphanumeric = /^[a-zA-Z0-9]+$/;
                    let select_memberid = v.replace(reg2, " ");
                    select_memberid = select_memberid.trim();
                    if (!reg_alphanumeric.test(select_memberid))
                        return false;
                    return true;
                }
                return true;

            }).test("memberID", "addmember.memberidlength.input.error", (v) => {
                if (v !== undefined && v !== null) {
                    let reg2 = /\s+/g;
                    let select_memberid = v.replace(reg2, " ");
                    select_memberid = select_memberid.trim();
                    if (select_memberid.length > 10)
                        return false;
                    return true;
                }
                return true;
            }).test("memberID", "addmember.memberidallzeros.input.error", (v) => {
                if (v !== undefined && v !== null) {
                    if (/^0*$/.test(v)) {
                        return false;
                    }
                    return true;
                }
                return true;
            }).required("addmember.memberid.required"),
        payroll: yup.string().trim()
            .required("payroll.futurePayrollSchedule.required").notOneOf(["0"], "payroll.futurePayrollSchedule.required")
    },
    makePayment: {
        otherAmount: yup.string().trim().test("otherAmount", "common.msg.numericalOtherAmount", (v: any) => {
            let validAmount = new RegExp(/^[1-9](\d*)(\.\d{2})$/);
            let onlyInt = new RegExp(/^[1-9](\d*)$/);
            let onlyOneDecimal = new RegExp(/^[1-9](\d*)(\.\d{1})$/);

            if (v.search(validAmount) === -1 && v.search(onlyInt) === -1 && v.search(onlyOneDecimal) === -1) {
                return false;
            }
            return true;

        }).required("common.msg.pay.amt.is.required")
    },
    licensing: {
        selectAgent: yup.string().trim().required("diredep.chooseagent"),
        selectDPagent: yup.string().trim().test("selectDPagent", "diredep.chooseagent", (v) => {
            if (v !== null && v === "common.lbl.pleaseselect") {
                return false;
            } else {
                return true;
            }
        }).required("diredep.chooseagent")
    },
    memberList: {
        groupSelect: yup.string().trim().required("addmember.group.required").notOneOf(["0"], "addmember.group.required"),
        accountSelect: yup.string().trim().required("addmember.account.required").notOneOf(["0"], "addmember.account.required")
    },
    portalcategories: {
        content: yup.string().trim().required("common.msg.contentRequired"),
    },
    submitInquiry: {
        firstName: yup.string().trim().required("regist.msg.err.first.name.empty"),
        lastName: yup.string().trim().required("com.lastname.req"),
        phoneNumber: yup.string().trim().test('validate-custom-phone', INVALID_MESSAGES.PHONE_NUMBER_ID_INVALID, (value) => {
            const phone = value?.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
            const isValid = VALIDATION_REGEX.PHONE_NUMBER_PERSONALINFO.test(phone ? phone : '')
            if (!isValid)
                return false
            return true
        }).required("common.msg.phoneNumberReq"),
        email:yup.string().trim()
        .test("email", INVALID_MESSAGES.EMAIL_IS_INVALID, (v: any) => {
            // Validate email format as according to RFC5322
            var reg = new RegExp("^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$");
            if (reg.test(v.toString().trim())) {
                return true;
            }
            return false;
        }).required("common.msg.emailReq"),
        question: yup.string().trim().required("common.msg.inquiryQuestionReq")
    },
}

import { ThemeConfig } from "../shared/theme";

import * as theme from './slovene';
import * as emptyTheme from './empty';

let ThemeComponents: ThemeConfig = {
    'default': {
        component: theme,
        css: ['/static/themes/blue/css/extension.css', '/static/themes/blue/css/common.css', '/static/themes/blue/css/blue.css'],
        layouts: {
            'default': theme.DefaultLayout,
            'empty': theme.EmptyLayout
        }
    },
    'empty': {
        component: emptyTheme,
        css: ['/static/themes/blue/css/extension.css', '/static/themes/blue/css/common.css', '/static/themes/blue/css/blue.css', '/static/themes/empty/css/empty.css'],
        layouts: {
            'empty': theme.EmptyLayout
        }
    },
    'slovene': {
        component: theme,
        css: ['/static/themes/slovene/css/extension.css', '/static/themes/slovene/css/common.css', '/static/themes/slovene/css/slovene.css'],
        layouts: {
            'default': theme.DefaultLayout,
            'empty': theme.EmptyLayout
        }
    },
};

export default ThemeComponents;
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { TableCell, TableColumn, useTable, DEFAULT_FORMAT_TYPE } from "../shared/components";
import GlobalizedText from '../shared/globalization';
import { ajax, showTooltip, validate, isMobileDevice, removeLeftZero, isEmptyStr, isEmptyArray } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { YupSchema } from '../shared/yupschema';
import { useMessage } from "../shared/message";
import { FeatureFlags } from '../shared/featureFlags';
import './css/inforceBusiness.css';
import { useMemo } from 'react';
export const NAME_TYPE_PERSON = "1";
export const NAME_TYPE_COMPANY = "2";

type Level = 'myib' | 'drib'

interface InforceBusinessForm {
    filterBy: string,
    nameType: string,
    groupSelect: string,
    accountSelect: string,
    memberID: string,
    policyNumber: string,
    govtID: string,
    lastName: string,
    firstName: string,
    companyName: string,
    activeStatus: string,
    statusType: string,
    inActiveStatus: string,
    myAgent: string,
    mySelectedAgents: string,
    selectDPagent: string,
    selectedAgents: string,
    dialogName: string
    config: any,
    inforceBusinessList: any,
    groups: any,
    accounts: any,
    tab: Level
}

export const InforceBusinessComponent = withView((props: ViewComponentProps) => {

    const varFormPromp = useRef<any>();
    const selectetedRowData = useRef<any>();

    const messageService4Jump = useMessage("selectMultipleAgents_dr");

    const storedSelectedRow4Drib = useRef<Array<any>>([]);
    const storedSelectedRow4Myib = useRef<Array<any>>([]);
    const storedSelectedRowNumber = useRef<number[]>([]);

    let from = props.getQueryParam("fromPage");
    let companyCode = props.getQueryParam("companyCode");
    let agentNumber = props.getQueryParam("agentNumber");

    const lblServicingAgent = props.getGlobalizedText('ib.lbl.servicingAgent');
    const lblWritingAgent = props.getGlobalizedText('ib.lbl.writingAgent');

    const [config, setConfig] = useState<any | null>(null);
    const [configDashboard, setConfigDashboard] = useState<Array<any> | undefined>(undefined);

    const [inforceBusinessList, setInforceBusinessList] = useState<any | null>(null);
    const [dialogList, setDialogList] = useState<any | null>(null);

    const [accounts, setAccounts] = useState<Array<any>>([{ name: "common.lbl.pleaseselect", value: "0" }]);
    const [groups, setGroups] = useState<Array<any>>([{ name: "common.lbl.pleaseselect", value: "0" }]);
    const hiddenMemberID = useRef<boolean>(true)
    const hiddenCancelLink = useRef<boolean>(true)

    const [disableSaveBtn, setdisableSaveBtn] = useState<boolean>(true);
    const [showSelectDPagent, setShowSelectDPagent] = useState<boolean>(true);
    const showSelectDPagentFlag = useRef<boolean>(true);

    const selectedAgentsList = useRef<any | null>(null);
    const selecteAgentNum = useRef<number>(0);

    const selectedAgentNumFromDialog = useRef<any>();

    const selectedCompanyCodeFromDialog = useRef<any>();

    const hiddenMyCancelLink = useRef<boolean>(true);

    const showMySelectDPagent = useRef<boolean>(true);

    const selectedMyAgentNumFromDialog = useRef<any>();

    const selectedMyCompanyCodeFromDialog = useRef<any>();

    const [selectedMyAgentsList, setSelectedMyAgentsList] = useState<any | null>(null);
    const [selectMyAgentNum, setSelectMyAgentNum] = useState<number>(0);

    const [hidePremiumMessage, setHidePremiumMessage] = useState<boolean>(true);

    const tab = useRef<Level>('myib');

    const myibFilterByOptions = useRef<any | null>(null)
    const dribFilterByOptions = useRef<any | null>(null)

    const onSelectbySaveFlag = useRef<boolean>(false);

    const retrieveBtnValidate = useRef<boolean>(false);

    const showAllValidate = useRef<boolean>(false);

    const tablePageNum = useRef<number>(0);
    const isPolicyNotesFeature = props.isFeatureEnabled(FeatureFlags.POLICY_NOTES);
    const [filterByOptions, setFilterByOptions] = useState<any | null>([
        { name: "common.lbl.all", value: "all" },
        { name: "common.lbl.groupOrAccount", value: "byGroupAccount" },
        { name: "common.lbl.policyNumber", value: "byPolicyNumber" },
        { name: "ib.lbl.fiby.cn", value: "byCustomerName" },
        { name: "common.lbl.status", value: "byStatus" },
        { name: "common.lbl.agent", value: "byAgent" }
    ]);


    let statusTypeOption: Array<any> = [
        { name: "common.lbl.all", value: "0" },
        { name: "ib.lbl.st.ac", value: "a" },
        { name: "ib.lbl.st.iac", value: "i" }
    ];

    const multipleAgentsTableColumns: Array<TableColumn> = [
        { title: "common.lbl.name", name: "fullName", sortable: true },
        { title: "common.lbl.agentNumber", name: "agentNumber", sortable: true },
        { title: "common.lbl.streetAddress", name: "streetAddress", sortable: true },
        { title: "common.lbl.city", name: "city", sortable: true },
        { title: "common.lbl.stateCountry", name: "stateCountry", sortable: true }
    ]

    const [multipleAgentsTable, setMultipleAgentsTable] = useState<any | null>({
        columns: multipleAgentsTableColumns,
        data: []
    });

    const defaultInitialValues = {
        filterBy: "all",
        nameType: "0",
        groupSelect: "0",
        accountSelect: "0",
        memberID: "",
        policyNumber: "",
        govtID: "",
        lastName: "",
        firstName: "",
        companyName: "",
        activeStatus: "active",
        statusType: "",
        inActiveStatus: "inactive",
        myAgent: "",
        mySelectedAgents: "",
        selectDPagent: "",
        selectedAgents: "",
        dialogName: "",
        config: null,
        inforceBusinessList: null,
        groups: null,
        accounts: null,
        tab: 'myib'
    };

    const CSR = 10002;
    // const PA = 10003
    const defaultDashboardName = "Commission Summary";
    //props.getGlobalizedText("common.lbl.commissionSummary");


    useEffect(() => {
        if (config === null) {
            if (props.getQueryParam("name") !== undefined) {
                let nameObj = props.getQueryParam("name").replaceAll("<wbr/>", "").replaceAll("%20", " ");
                props.setTitle(`${props.getGlobalizedText("ib.lbl.module")} - ${nameObj}`);
            }

            let initParams = props.getInitParams();

            if (initParams !== undefined && initParams.filterBy !== undefined
                && 'inforceBusiness' === initParams.storedPage) {
                ajax({
                    url: '/api/inforceBusiness/view',
                    showMask: true,
                    params: {
                        companyCode: companyCode,
                        agentNumber: agentNumber,
                        fromAgentSearch: from !== undefined ? true : false
                    },
                    success: (res1: any) => {
                        loadDataWhenInitParams(res1, initParams)
                        if ("byGroupAccount" === initParams.filterBy) {
                            getInforceBusinessListByGroupAccount(varFormPromp.current.values, res1)
                        } else if ('byPolicyNumber' === initParams.filterBy) {
                            if (isEmptyStr(initParams.policyNumber)) {
                                setInforceBusinessList(null)
                            } else {
                                getInforceBusinessListByPolicyNumber(varFormPromp.current.values, res1)
                            }
                        } else if ('byGovtID' === initParams.filterBy) {
                            if (isEmptyStr(initParams.govtID)) {
                                setInforceBusinessList(null)
                            } else {
                                getInforceBusinessListByGovtID(varFormPromp.current.values, res1)
                            }
                        } else if ('byCustomerName' === initParams.filterBy) {
                            if ((isEmptyStr(initParams.lastName) && varFormPromp.current.values.nameType === NAME_TYPE_PERSON) || (isEmptyStr(initParams.companyName) && varFormPromp.current.values.nameType === NAME_TYPE_COMPANY)) {
                                setInforceBusinessList(null)
                            } else {
                                getInforceBusinessListByCustomerName(varFormPromp.current.values, res1)
                            }
                        } else if ('byStatus' === initParams.filterBy) {
                            getInforceBusinessListByStatus(varFormPromp.current.values, res1)
                        } else if ('byAgent' === initParams.filterBy) {
                            // varFormPromp.current.formValidate()
                            if (initParams.myAgent === '') {
                                setInforceBusinessList(null)
                            } else {
                                varFormPromp.current.setFieldValue("myAgent", initParams.myAgent);
                                if (initParams.selectedMyAgentsList !== null && initParams.selectedMyAgentsList.length > 0) {
                                    varFormPromp.current.setFieldValue("mySelectedAgents", initParams.selectedMyAgentsList[0].value);
                                }
                                hiddenMyCancelLink.current = initParams.hiddenMyCancelLink;
                                showMySelectDPagent.current = initParams.showMySelectDPagent;
                                selectedMyAgentNumFromDialog.current = initParams.selectedMyAgentNumFromDialog;
                                selectedMyCompanyCodeFromDialog.current = initParams.selectedMyCompanyCodeFromDialog;
                                setSelectMyAgentNum(initParams.selectMyAgentNum);
                                setSelectedMyAgentsList(initParams.selectedMyAgentsList);
                                getInforceBusinessListByAgent(varFormPromp.current.values, res1)
                            }

                        } else {
                            getInforceBusinessListByAll(varFormPromp.current.values, res1)
                        }
                        setConfig(res1);
                    }

                });

            } else {
                loadInforceBusinessView()
            }
            return () => {
            }
        }
    }, [config]);

    useEffect(() => {
        if (showSelectDPagent === true && hiddenCancelLink.current === true && tab.current === 'drib' && config !== null) {
            hiddenMemberID.current = true
            setGroups([{ name: "common.lbl.pleaseselect", value: "0" }]);
            setAccounts([{ name: "common.lbl.pleaseselect", value: "0" }]);
        } else if (showSelectDPagent === false && hiddenCancelLink.current === false && tab.current === 'drib' && config !== null) {
            let submitAgentNumber = ''
            let submitCompanyCode = ''
            submitAgentNumber = selectedAgentNumFromDialog.current;
            submitCompanyCode = selectedCompanyCodeFromDialog.current;
            retrieveGroup(config.clientNumber, submitCompanyCode, submitAgentNumber)
        }
    }, [showSelectDPagent]);

    useEffect(() => {
        if (!configDashboard) {
            ajax({
                url: '/api/quickSight/getDashboardByName',
                params: {
                    "dashboardName": defaultDashboardName
                },
                success: (res: any) => {
                    setConfigDashboard(res);
                }
            });
        }
    }, [configDashboard]);



    function loadDataWhenInitParams(res1: any, initParams: any) {
        myibFilterByOptions.current = res1.myibFilterByOptions
        dribFilterByOptions.current = res1.dribFilterByOptions

        if (res1.mybusinessAgentsJson !== undefined) {
            varFormPromp.current.setFieldValue("myAgent", res1.mybusinessAgentsJson[0].value);
        }
        setGroups(initParams.groups);
        setAccounts(initParams.accounts);
        tab.current = initParams.tab
        tablePageNum.current = initParams.pageNum;
        hiddenMemberID.current = initParams.hiddenMemberID

        if ("drib" === initParams.tab) {
            setFilterByOptions(res1.dribFilterByOptions);
            hiddenCancelLink.current = initParams.hiddenCancelLink;
            setShowSelectDPagent(initParams.showSelectDPagent);
            showSelectDPagentFlag.current = initParams.showSelectDPagentFlag;
            selectedAgentNumFromDialog.current = initParams.selectedAgentNumFromDialog;
            selectedCompanyCodeFromDialog.current = initParams.selectedCompanyCodeFromDialog;
            selecteAgentNum.current = initParams.selecteAgentNum;
            selectedAgentsList.current = initParams.selectedAgentsList;
        } else {
            setFilterByOptions(res1.myibFilterByOptions);
        }

        if (res1 !== undefined && res1.mybusinessAgentsJson !== undefined) {
            varFormPromp.current.setFieldValue("myAgent", res1.mybusinessAgentsJson[0].value);
        }
    }

    function loadInforceBusinessView() {
        ajax({
            url: '/api/inforceBusiness/view',
            params: {
                companyCode: companyCode,
                agentNumber: agentNumber,
                fromAgentSearch: from !== undefined ? true : false
            },
            success: (res1: any) => {
                setConfig(res1);
                myibFilterByOptions.current = res1.myibFilterByOptions
                dribFilterByOptions.current = res1.dribFilterByOptions
                setFilterByOptions(res1.myibFilterByOptions);

                if (res1.mybusinessAgentsJson !== undefined && res1.mybusinessAgentsJson.length > 0) {
                    varFormPromp.current.setFieldValue("myAgent", res1.mybusinessAgentsJson[0].value);
                }
                if (from !== undefined) {
                    retrieveGroup(res1.clientNumber, res1.companyCode);
                } else {
                    retrieveGroup(res1.clientNumber);
                }

                ajax({
                    url: '/api/inforceBusiness/getAllInforceBusiness',
                    params: {
                        companyCode: res1.mulCompany,
                        agentNumber: res1.mulAgent,
                        directReportFlag: false,

                    },
                    success: (res2) => {
                        setInforceBusinessList(res2);
                    }
                });

            }
        });
    }


    function convertAccountType(accountType: any): string {
        let val: string = "";
        if (accountType === "I") {
            val = "Individual";
        } else if (accountType === "E") {
            val = "Employer";
        } else if (accountType === "W") {
            val = "Worksite";
        }
        return val;
    }
    // save data for current page
    const saveDataBeforeRedirect = (): boolean => {
        props.setInitParams({
            storedPage: 'inforceBusiness',
            ...varFormPromp.current.values,
            groups: groups,
            accounts: accounts,
            tab: tab.current,
            pageNum: inforceBusinessTableObj.page(),
            hiddenMemberID: hiddenMemberID.current,

            hiddenMyCancelLink: hiddenMyCancelLink.current,
            showMySelectDPagent: showMySelectDPagent.current,
            selectedMyAgentNumFromDialog: selectedMyAgentNumFromDialog.current,
            selectedMyCompanyCodeFromDialog: selectedMyCompanyCodeFromDialog.current,
            selectedMyAgentsList: selectedMyAgentsList,
            selectMyAgentNum: selectMyAgentNum,

            hiddenCancelLink: hiddenCancelLink.current,
            showSelectDPagent: showSelectDPagent,
            showSelectDPagentFlag: showSelectDPagentFlag.current,
            selectedAgentNumFromDialog: selectedAgentNumFromDialog.current,
            selectedCompanyCodeFromDialog: selectedCompanyCodeFromDialog.current,
            selectedAgentsList: selectedAgentsList.current,
            selecteAgentNum: selecteAgentNum.current
        });
        return true;
    }

    function redoFilterSelector() {
        pageClearAll();

        varFormPromp.current.setFieldValue("statusType", '0');

        varFormPromp.current.setFieldValue("govtID", '');

        varFormPromp.current.setFieldValue("nameType", NAME_TYPE_PERSON);

        varFormPromp.current.setFieldValue("lastName", '');
        varFormPromp.current.setFieldValue("firstName", '');
        varFormPromp.current.setFieldValue("companyName", '');

        varFormPromp.current.setFieldValue("policyNumber", '');

        varFormPromp.current.setFieldValue("groupSelect", '0');
        setAccounts([{ name: "common.lbl.pleaseselect", value: "0" }]);
        varFormPromp.current.setFieldValue("memberID", '');

        varFormPromp.current.setFieldValue("dialogName", '');

        hiddenMemberID.current = true
        setdisableSaveBtn(true);
        setHidePremiumMessage(true);

        hiddenMyCancelLink.current = true;
        showMySelectDPagent.current = true
        selectedMyAgentNumFromDialog.current = "";
        selectedMyCompanyCodeFromDialog.current = "";
        setSelectMyAgentNum(0);
        setSelectedMyAgentsList(null);
    }

    function pageClearAll() {
        props.clearMessage();
        //clear data of table
        setInforceBusinessList(null);
    }

    function retrieveGroup(passClientNumber: any, passedCompanyCode?: any, passedAgentNumber?: any) {
        let g_pageAgents = "";
        let companyCode4GettingGroup = passedCompanyCode;
        if (passedCompanyCode !== undefined && passedAgentNumber !== undefined) {
            if (passedAgentNumber.indexOf(",") >= 0) {
                companyCode4GettingGroup = config.companyCode;
                let agentNumbers = passedAgentNumber.split(",");
                let companyCodes = passedCompanyCode.split(",");
                for (let i = 0; i < agentNumbers.length; i++) {
                    if (agentNumbers[i] != "") {
                        g_pageAgents += companyCodes[i].toString().trim() + '|' + agentNumbers[i].toString().trim() + ',';
                    }
                }
            } else {
                g_pageAgents = passedCompanyCode.toString().trim() + '|' + passedAgentNumber.toString().trim();
            }
        }

        ajax({
            url: '/api/inforceBusiness/getGroupList',
            showMask: false,
            params: passedCompanyCode === undefined && passedAgentNumber === undefined ? {
                fromAgentSearch: from !== undefined ? true : false,
                agentClientNumber: passClientNumber
            } : {
                fromAgentSearch: from !== undefined ? true : false,
                agentClientNumber: passClientNumber,
                companyCode: companyCode4GettingGroup,
                pageAgents: g_pageAgents
            },
            success: (res) => {
                setGroups(res);
                if (res.length === 1 && res[0].value !== '') {
                    varFormPromp.current.setFieldValue("groupSelect", res[0].value);
                }
                if (varFormPromp.current.values.filterBy !== 'byGroupAccount') {
                    varFormPromp.current.setFieldValue("groupSelect", res[0].value);
                }
                retrieveAccount(res[0].value);
            }
        });
    }

    function retrieveAccount(selectedGroupValue: any) {
        if ('0' === selectedGroupValue) {
            setAccounts([{ "name": "common.lbl.pleaseselect", "value": "0" }]);
            varFormPromp.current.setFieldValue("accountSelect", "0");
            hiddenMemberID.current = true
        } else {
            let tmp = selectedGroupValue.split("|");
            let pageAgents = "";
            if ("drib" === tab.current) {
                let submitAgentNumber = "";
                let submitCompanyCode = "";
                if (false === showSelectDPagent) {
                    submitAgentNumber = selectedAgentNumFromDialog.current;
                    submitCompanyCode = selectedCompanyCodeFromDialog.current;
                } else {
                    submitAgentNumber = varFormPromp.current.values.selectDPagent.substring(0, varFormPromp.current.values.selectDPagent.indexOf("-"));
                    submitCompanyCode = varFormPromp.current.values.selectDPagent.substring(varFormPromp.current.values.selectDPagent.indexOf("-") + 1);
                }
                if (submitAgentNumber.indexOf(",") >= 0) {
                    let agentNumbers = submitAgentNumber.split(",");
                    let companyCodes = submitCompanyCode.split(",");
                    for (var i = 0; i < agentNumbers.length; i++) {
                        if (agentNumbers[i] != "") {
                            pageAgents += companyCodes[i].toString().trim() + '|' + agentNumbers[i].toString().trim() + ',';
                        }
                    }
                } else {
                    pageAgents = submitCompanyCode.toString().trim() + '|' + submitAgentNumber.toString().trim();
                }
            }

            ajax({
                url: '/api/inforceBusiness/getAccountList',
                showMask: false,
                params: {
                    companyCode: tmp[0],
                    groupNumber: tmp[1],
                    fromAgentSearch: from !== undefined ? true : false,
                    pageAgents: pageAgents,
                    agentClientNumber: config.clientNumber
                },
                success: (res) => {
                    let accountType = res[0].accountType;
                    if (accountType === 'I') {
                        hiddenMemberID.current = false
                    } else {
                        hiddenMemberID.current = true
                    }
                    setAccounts(res.map((account: any) => { return { ...account, name: account.displayValue } }));
                    varFormPromp.current.setFieldValue("accountSelect", res[0].value);
                }
            });
        }
    }

    function saveAgents2Option() {
        onSelectbySaveFlag.current = true;
        let tmp: any[] = [];
        let selectedAgentNumFromDialogTmp = "";
        let selectedCompanyCodeFromDialogTmp = "";

        if (selectetedRowData.current.length === 0) {
            let tmpRow: string[] = [];
            if ("drib" === tab.current) {
                for (let i = 0; i < storedSelectedRow4Drib.current.length; i++) {
                    let tmpID = storedSelectedRow4Drib.current[i];
                    for (let j = 0; j < dialogList.length; j++) {
                        if (dialogList[j].id === tmpID) {
                            tmpRow.push(dialogList[j]);
                            break;
                        }
                    }
                }
            } else {
                for (let i = 0; i < storedSelectedRow4Myib.current.length; i++) {
                    let tmpID = storedSelectedRow4Myib.current[i];
                    for (let j = 0; j < dialogList.length; j++) {
                        if (dialogList[j].id === tmpID) {
                            tmpRow.push(dialogList[j]);
                            break;
                        }
                    }
                }
            }

            selectetedRowData.current = tmpRow;
        }

        for (let i = 0; i < selectetedRowData.current.length; i++) {
            tmp.push({ name: selectetedRowData.current[i].fullName + " - " + selectetedRowData.current[i].agentNumber, value: selectetedRowData.current[i].agentNumber + "|" + selectetedRowData.current[i].companyCode });
            selectedAgentNumFromDialogTmp += selectetedRowData.current[i].agentNumber + ',';
            selectedCompanyCodeFromDialogTmp += selectetedRowData.current[i].companyCode + ',';
        }

        props.closeModal('#selectMultipleAgents_dr');

        setMultipleAgentsTable({
            columns: multipleAgentsTableColumns,
            data: []
        });
        if ("drib" === tab.current) {
            selectedAgentsList.current = tmp;

            selectedAgentNumFromDialog.current = selectedAgentNumFromDialogTmp;
            selectedCompanyCodeFromDialog.current = selectedCompanyCodeFromDialogTmp;

            selecteAgentNum.current = selectetedRowData.current.length;
            hiddenCancelLink.current = false
            setShowSelectDPagent(false);
            showSelectDPagentFlag.current = false

            varFormPromp.current.setFieldValue("selectDPagent", "");
        } else {
            setSelectedMyAgentsList(tmp);

            selectedMyAgentNumFromDialog.current = selectedAgentNumFromDialogTmp
            selectedMyCompanyCodeFromDialog.current = selectedCompanyCodeFromDialogTmp;

            setSelectMyAgentNum(selectetedRowData.current.length);
            hiddenMyCancelLink.current = false;
            showMySelectDPagent.current = false;
            if (config.mybusinessAgentsJson !== undefined) {
                varFormPromp.current.setFieldValue("myAgent", config.mybusinessAgentsJson[0].value);
            }
        }

    }

    function cancelMultiAgents() {
        if ("drib" === tab.current) {
            selectedAgentsList.current = null;
            selecteAgentNum.current = 0;

            hiddenCancelLink.current = true
            setShowSelectDPagent(true);
            showSelectDPagentFlag.current = true

            storedSelectedRow4Drib.current = [];
            storedSelectedRowNumber.current = [];
        } else {
            setSelectedMyAgentsList(null);
            setSelectMyAgentNum(0);

            hiddenMyCancelLink.current = true;
            showMySelectDPagent.current = true;

            storedSelectedRow4Myib.current = [];
            storedSelectedRowNumber.current = [];
        }
    }

    // define Form Submitting function.
    const formSubmit = (values: any): void => {
        if (retrieveBtnValidate.current === true) {
            getDirectReportsAgents(values.dialogName);
        } else {
            props.clearMessage();
            tablePageNum.current = 0;
            if ('byGroupAccount' === values.filterBy) {
                getInforceBusinessListByGroupAccount(values, config, varFormPromp.current.setSubmitting);
            } else if ('byPolicyNumber' === values.filterBy) {
                getInforceBusinessListByPolicyNumber(values, config, varFormPromp.current.setSubmitting);
            } else if ('byGovtID' === values.filterBy) {
                getInforceBusinessListByGovtID(values, config, varFormPromp.current.setSubmitting);
            } else if ('byCustomerName' === values.filterBy) {
                getInforceBusinessListByCustomerName(values, config, varFormPromp.current.setSubmitting);
            } else if ('byStatus' === values.filterBy) {
                getInforceBusinessListByStatus(values, config, varFormPromp.current.setSubmitting);
            } else if ('byAgent' === values.filterBy) {
                getInforceBusinessListByAgent(values, config, varFormPromp.current.setSubmitting);
            } else {
                getInforceBusinessListByAll(values, config, varFormPromp.current.setSubmitting);
            }
        }

    }

    function getInforceBusinessListByGroupAccount(values: any, config: any, setSubmitting?: Function) {
        let selectedGroupNum = '0';
        if ('0' !== values.groupSelect) {
            let tmp = values.groupSelect.split("|");
            selectedGroupNum = tmp[1];
        }

        let directReportCompanyCode = "";
        let directReportAgent = "";
        let multipleSelectAgents = ""
        if ("drib" === tab.current) {
            if (false === showSelectDPagentFlag.current) {
                directReportAgent = selectedAgentNumFromDialog.current;
                directReportCompanyCode = selectedCompanyCodeFromDialog.current;
            } else {
                multipleSelectAgents = values.selectDPagent.substring(0, values.selectDPagent.indexOf("-"));
                directReportCompanyCode = values.selectDPagent.substring(values.selectDPagent.indexOf("-") + 1);
            }
        }

        ajax({
            url: '/api/inforceBusiness/getInforceBusinessByGroupAccount',
            params: {
                companyCode: config.mulCompany,
                agentNumber: config.mulAgent,
                directReportFlag: 'drib' === tab.current ? true : false,
                directReportCompanyCode: directReportCompanyCode,
                multipleSelectAgents: multipleSelectAgents,
                directReportAgent: directReportAgent,

                groupNumber: selectedGroupNum,
                accountNumber: values.accountSelect,
                participantIDNumber: values.memberID
            },
            success: (res) => {
                setInforceBusinessList(res);
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }

    function getInforceBusinessListByPolicyNumber(values: any, config: any, setSubmitting?: Function) {
        let directReportCompanyCode = "";
        let directReportAgent = "";
        let multipleSelectAgents = ""
        if ("drib" === tab.current) {
            if (false === showSelectDPagentFlag.current) {
                directReportAgent = selectedAgentNumFromDialog.current;
                directReportCompanyCode = selectedCompanyCodeFromDialog.current;
            } else {
                multipleSelectAgents = values.selectDPagent.substring(0, values.selectDPagent.indexOf("-"));
                directReportCompanyCode = values.selectDPagent.substring(values.selectDPagent.indexOf("-") + 1);
            }
        }

        ajax({
            url: '/api/inforceBusiness/getInforceBusinessByPolicyNumber',
            params: {
                companyCode: config.mulCompany,
                agentNumber: config.mulAgent,
                directReportFlag: 'drib' === tab.current ? true : false,
                directReportCompanyCode: directReportCompanyCode,
                multipleSelectAgents: multipleSelectAgents,
                directReportAgent: directReportAgent,
                policyNumber: values.policyNumber
            },
            success: (res) => {
                setInforceBusinessList(res);
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }

    function getInforceBusinessListByGovtID(values: any, config: any, setSubmitting?: Function) {
        let directReportCompanyCode = "";
        let directReportAgent = "";
        let multipleSelectAgents = ""
        if ("drib" === tab.current) {
            if (false === showSelectDPagentFlag.current) {
                directReportAgent = selectedAgentNumFromDialog.current;
                directReportCompanyCode = selectedCompanyCodeFromDialog.current;
            } else {
                multipleSelectAgents = values.selectDPagent.substring(0, values.selectDPagent.indexOf("-"));
                directReportCompanyCode = values.selectDPagent.substring(values.selectDPagent.indexOf("-") + 1);
            }
        }

        ajax({
            url: '/api/inforceBusiness/getInforceBusinessByGovtID',
            params: {
                companyCode: config.mulCompany,
                agentNumber: config.mulAgent,
                directReportFlag: 'drib' === tab.current ? true : false,
                directReportCompanyCode: directReportCompanyCode,
                multipleSelectAgents: multipleSelectAgents,
                directReportAgent: directReportAgent,
                govtID: values.govtID
            },
            success: (res) => {
                setInforceBusinessList(res);
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }

    function getInforceBusinessListByCustomerName(values: any, config: any, setSubmitting?: Function) {
        let directReportCompanyCode = "";
        let directReportAgent = "";
        let multipleSelectAgents = ""
        if ("drib" === tab.current) {
            if (false === showSelectDPagentFlag.current) {
                directReportAgent = selectedAgentNumFromDialog.current;
                directReportCompanyCode = selectedCompanyCodeFromDialog.current;
            } else {
                multipleSelectAgents = values.selectDPagent.substring(0, values.selectDPagent.indexOf("-"));
                directReportCompanyCode = values.selectDPagent.substring(values.selectDPagent.indexOf("-") + 1);
            }
        }

        ajax({
            url: '/api/inforceBusiness/getInforceBusinessByCustomerName',
            params: {
                companyCode: config.mulCompany,
                agentNumber: config.mulAgent,
                directReportFlag: 'drib' === tab.current ? true : false,
                directReportCompanyCode: directReportCompanyCode,
                multipleSelectAgents: multipleSelectAgents,
                directReportAgent: directReportAgent,
                lastName: values.lastName,
                firstName: values.firstName,
                companyName: values.companyName,
                nameType: values.nameType
            },
            success: (res) => {
                setInforceBusinessList(res);
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }

    function getInforceBusinessListByStatus(values: any, config: any, setSubmitting?: Function) {
        let status = '';

        if ("a" === values.statusType) {
            status = (values.activeStatus === undefined ? 'active' : values.activeStatus);
        } else if ("i" === values.statusType) {
            status = (values.inActiveStatus === undefined ? 'inactive' : values.inActiveStatus);
        }

        let directReportCompanyCode = "";
        let directReportAgent = "";
        let multipleSelectAgents = ""
        if ("drib" === tab.current) {
            if (false === showSelectDPagentFlag.current) {
                directReportAgent = selectedAgentNumFromDialog.current;
                directReportCompanyCode = selectedCompanyCodeFromDialog.current;
            } else {
                multipleSelectAgents = values.selectDPagent.substring(0, values.selectDPagent.indexOf("-"));
                directReportCompanyCode = values.selectDPagent.substring(values.selectDPagent.indexOf("-") + 1);
            }
        }

        ajax({
            url: '/api/inforceBusiness/getInforceBusinessByStatus',
            params: {
                companyCode: config.mulCompany,
                agentNumber: config.mulAgent,
                directReportFlag: 'drib' === tab.current ? true : false,
                directReportCompanyCode: directReportCompanyCode,
                multipleSelectAgents: multipleSelectAgents,
                directReportAgent: directReportAgent,
                status: status
            },
            success: (res) => {
                setInforceBusinessList(res);
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }

    function getInforceBusinessListByAgent(values: any, config: any, setSubmitting?: Function) {
        let selectedAgentNumber = values.myAgent.substring(0, values.myAgent.indexOf("-"));
        let selectedCompanyCode = values.myAgent.substring(values.myAgent.indexOf("-") + 1);
        if (true !== showMySelectDPagent.current) {
            selectedAgentNumber = selectedMyAgentNumFromDialog.current;
            selectedCompanyCode = selectedMyCompanyCodeFromDialog.current;
        }

        ajax({
            url: '/api/inforceBusiness/getInforceBusinessByAgent',
            params: {
                myAgentCompanyCode: selectedCompanyCode,
                myAgentNumber: selectedAgentNumber,
                companyCode: config.mulCompany.split(",")[0],
                agentNumber: config.mulAgent.split(",")[0]
            },
            success: (res) => {
                setInforceBusinessList(res);
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }

    function getInforceBusinessListByAll(values: any, config: any, setSubmitting?: Function) {
        let directReportCompanyCode = "";
        let directReportAgent = "";
        let multipleSelectAgents = ""
        if ("drib" === tab.current) {
            if (false === showSelectDPagentFlag.current) {
                directReportAgent = selectedAgentNumFromDialog.current;
                directReportCompanyCode = selectedCompanyCodeFromDialog.current;
            } else {
                multipleSelectAgents = values.selectDPagent.substring(0, values.selectDPagent.indexOf("-"));
                directReportCompanyCode = values.selectDPagent.substring(values.selectDPagent.indexOf("-") + 1);
            }
        }

        ajax({
            url: '/api/inforceBusiness/getAllInforceBusiness',
            params: {
                companyCode: config.mulCompany,
                agentNumber: config.mulAgent,
                directReportFlag: 'drib' === tab.current ? true : false,
                directReportCompanyCode: directReportCompanyCode,
                multipleSelectAgents: multipleSelectAgents,
                directReportAgent: directReportAgent
            },
            success: (res) => {
                setInforceBusinessList(res);
            },
            callback: () => {
                if (setSubmitting !== undefined) {
                    setSubmitting(false);
                }
            }
        });
    }

    function selfTrim(val: any): any {
        if (val !== undefined) {
            return val.trim();
        } else {
            return '';
        }
    }

    const daliogValidate = yup.object().shape({
        dialogName: YupSchema.inforceBusiness.dialogName
    });

    const byGroupAccount = yup.object().shape({
        groupSelect: YupSchema.inforceBusiness.groupSelect,
        accountSelect: YupSchema.inforceBusiness.accountSelect
    });

    const byPolicyNumber = yup.object().shape({
        policyNumber: YupSchema.policyNumber
    });

    const byCustomerNameCompany = yup.object().shape({
        companyName: YupSchema.companyName
    });

    const byCustomerNamePerson = yup.object().shape({
        lastName: YupSchema.lastName
    });

    const byAgent = yup.object().shape({
        myAgent: YupSchema.inforceBusiness.myAgent
    });

    const byGovtID = yup.object().shape({
        govtID: YupSchema.govtID(DEFAULT_FORMAT_TYPE, props.getGlobalizedText)
    });

    const byGroupAccountDr = yup.object().shape({
        groupSelect: YupSchema.inforceBusiness.groupSelect,
        accountSelect: YupSchema.inforceBusiness.accountSelect,
        selectDPagent: YupSchema.inforceBusiness.selectDPagent
    });

    const byPolicyNumberDr = yup.object().shape({
        policyNumber: YupSchema.policyNumber,
        selectDPagent: YupSchema.inforceBusiness.selectDPagent
    });

    const byCustomerNameCompanyDr = yup.object().shape({
        companyName: YupSchema.companyName,
        selectDPagent: YupSchema.inforceBusiness.selectDPagent
    });

    const byCustomerNamePersonDr = yup.object().shape({
        lastName: YupSchema.lastName,
        selectDPagent: YupSchema.inforceBusiness.selectDPagent
    });

    const byGovtIDDr = yup.object().shape({
        govtID: YupSchema.govtID(DEFAULT_FORMAT_TYPE, props.getGlobalizedText),
        selectDPagent: YupSchema.inforceBusiness.selectDPagent
    });

    const byAllDr = yup.object().shape({
        selectDPagent: YupSchema.inforceBusiness.selectDPagent
    });

    // define Form Validate function.
    const formValidate = (values: any): any => {
        if (retrieveBtnValidate.current === true) {
            return validate(daliogValidate, values, messageService4Jump.validateProps);
        }

        if ("drib" === tab.current && showSelectDPagent === true) {
            if ('byGroupAccount' === values.filterBy) {
                return validate(byGroupAccountDr, values, props);
            } else if ('byPolicyNumber' === values.filterBy) {
                return validate(byPolicyNumberDr, values, props);
            } else if ('byCustomerName' === values.filterBy && NAME_TYPE_COMPANY === values.nameType) {
                return validate(byCustomerNameCompanyDr, values, props);
            } else if ('byCustomerName' === values.filterBy && NAME_TYPE_PERSON === values.nameType) {
                return validate(byCustomerNamePersonDr, values, props);
            } else if ('byGovtID' === values.filterBy) {
                return validate(byGovtIDDr, values, props);
            } else {
                return validate(byAllDr, values, props);
            }
        } else {
            if ('byGroupAccount' === values.filterBy) {
                return validate(byGroupAccount, values, props);
            } else if ('byPolicyNumber' === values.filterBy) {
                return validate(byPolicyNumber, values, props);
            } else if ('byCustomerName' === values.filterBy && NAME_TYPE_COMPANY === values.nameType) {
                return validate(byCustomerNameCompany, values, props);
            } else if ('byCustomerName' === values.filterBy && NAME_TYPE_PERSON === values.nameType) {
                return validate(byCustomerNamePerson, values, props);
            } else if ('byGovtID' === values.filterBy) {
                return validate(byGovtID, values, props);
            } else if ('byAgent' === values.filterBy && showMySelectDPagent.current === true) {
                return validate(byAgent, values, props);
            }
        }
    }

    const filterByChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        redoFilterSelector();
        if ('byGroupAccount' === e.target.value) {
            let submitClientNumber = config.clientNumber;
            if ("drib" === tab.current) {
                let submitAgentNumber = "";
                let submitCompanyCode = "";
                if (false === showSelectDPagent) {
                    submitAgentNumber = selectedAgentNumFromDialog.current;
                    submitCompanyCode = selectedCompanyCodeFromDialog.current;
                } else {
                    submitAgentNumber = formValue.selectDPagent.substring(0, formValue.selectDPagent.indexOf("-"));
                    submitCompanyCode = formValue.selectDPagent.substring(formValue.selectDPagent.indexOf("-") + 1);
                }
                retrieveGroup(submitClientNumber, submitCompanyCode, submitAgentNumber);
            } else {
                if (groups.length === 1 && groups[0].value !== '0') {
                    varFormPromp.current.setFieldValue("groupSelect", groups[0].value);
                    retrieveAccount(groups[0].value)
                }
            }
        }
    };

    const directReportAgentChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        if (e.target.value === '' && showSelectDPagent === true) {
            setGroups([{ name: "common.lbl.pleaseselect", value: "0" }]);
            setAccounts([{ name: "common.lbl.pleaseselect", value: "0" }]);
        } else {
            props.clearMessage()
            varFormPromp.current.setErrors({})
            let selectedDRAgent = e.target.value;
            if ('byGroupAccount' === formValue.filterBy) {
                let submitClientNumber = config.clientNumber;
                let submitAgentNumber = "";
                let submitCompanyCode = "";
                if (false === showSelectDPagent) {
                    submitAgentNumber = selectedAgentNumFromDialog.current;
                    submitCompanyCode = selectedCompanyCodeFromDialog.current;
                } else {
                    submitAgentNumber = selectedDRAgent.substring(0, selectedDRAgent.indexOf("-"));
                    submitCompanyCode = selectedDRAgent.substring(selectedDRAgent.indexOf("-") + 1);
                }
                retrieveGroup(submitClientNumber, submitCompanyCode, submitAgentNumber);
            }
        }
    };

    const nameTypeChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        varFormPromp.current.setFieldValue("lastName", '');
        varFormPromp.current.setFieldValue("firstName", '');
        varFormPromp.current.setFieldValue("companyName", '');
        props.clearMessage();
    };

    const groupChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        varFormPromp.current.setFieldValue('memberID', '')
        hiddenMemberID.current = true

        retrieveAccount(e.target.value);
    };

    const accountChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        hiddenMemberID.current = true
        for (let i = 0; i < accounts.length; i++) {
            if (accounts[i].value === e.target.value) {
                if ('I' === accounts[i].accountType) {
                    hiddenMemberID.current = false
                }
            }
        }
    };

    function getDirectReportsAgents(dialogName: string) {
        if ("drib" === tab.current) {
            retrieveDirectReportsAgents(dialogName);
        } else {
            retrieveReportsAgents(dialogName);
        }
        retrieveBtnValidate.current = false;
    }

    function retrieveDirectReportsAgents(dialogName: string) {

        storedSelectedRowNumber.current.splice(0, storedSelectedRowNumber.current.length);

        if ('' === dialogName) {
            varFormPromp.current.setFieldValue("dialogName", '');
        }
        setdisableSaveBtn(true);

        ajax({
            url: '/api/inforceBusiness/getDirectReportsAgents',
            showMask: false,
            params: config.userType !== CSR ? {
                agentClientNumber: config.clientNumber,
                dialogName: dialogName
            } : {
                companyCode: companyCode !== undefined ? companyCode : config.mulCompany,
                agentClientNumber: config.clientNumber,
                dialogName: dialogName
            },
            success: (res: any) => {
                for (let i = 0; i < storedSelectedRow4Drib.current.length; i++) {
                    let storedRowID = storedSelectedRow4Drib.current[i];
                    for (let j = 0; j < res.length; j++) {
                        if (res[j].id === storedRowID) {
                            storedSelectedRowNumber.current.push(j);
                        }
                    }
                }
                setDialogList(res);
                setMultipleAgentsTable({
                    columns: multipleAgentsTableColumns,
                    data: res
                });
            }
        });
    }

    function retrieveReportsAgents(dialogName: string) {

        storedSelectedRowNumber.current.splice(0, storedSelectedRowNumber.current.length);

        if ('' === dialogName) {
            varFormPromp.current.setFieldValue("dialogName", '');
        }
        setdisableSaveBtn(true);

        ajax({
            url: '/api/inforceBusiness/getReportsAgents',
            showMask: false,
            params: {
                companyCode: companyCode !== undefined ? companyCode : config.companyCode,
                agentClientNumber: config.clientNumber,
                dialogName: dialogName,
                fromPage: from !== undefined ? from : ''
            },
            success: (res: any) => {

                for (let i = 0; i < storedSelectedRow4Myib.current.length; i++) {
                    let storedRowID = storedSelectedRow4Myib.current[i];
                    for (let j = 0; j < res.length; j++) {
                        if (res[j].id === storedRowID) {
                            storedSelectedRowNumber.current.push(j);
                        }
                    }
                }
                setDialogList(res);
                setMultipleAgentsTable({
                    columns: multipleAgentsTableColumns,
                    data: res
                });
            }
        });
    }

    function changeTab(tabName: Level) {
        // setTab(tabName);
        tab.current = tabName

        props.clearMessage();

        varFormPromp.current.setFieldValue("filterBy", 'all');

        setShowSelectDPagent(true);
        showSelectDPagentFlag.current = true
        hiddenCancelLink.current = true
        selecteAgentNum.current = 0;

        redoFilterSelector();

        cancelMultiAgents();

        if ("drib" === tabName) {
            setFilterByOptions(dribFilterByOptions.current);
            varFormPromp.current.setFieldValue("selectDPagent", config.businessAgentsJson[0].value);
            setGroups([{ name: "common.lbl.pleaseselect", value: "0" }]);
        } else {
            setFilterByOptions(myibFilterByOptions.current);

            retrieveGroup(config.clientNumber, config.companyCode, config.loginAgentNumber);

            loadInforceBusinessView();
        }
    }

    const openMultipleAgentsDialog = (): void => {
        setdisableSaveBtn(true);
        getDirectReportsAgents('');
        onSelectbySaveFlag.current = false;
        messageService4Jump.validateProps.clearMessage();
        props.showModal('#selectMultipleAgents_dr');
    };

    function saveSelectedValue(rows: number[], unSelectedRows: number[]) {
        if (onSelectbySaveFlag.current === true) {
            return;
        }
        let tmp: string[] = [];
        rows.forEach((row) => {
            if (multipleAgentsTable.data[row] !== undefined) {
                tmp.push(multipleAgentsTable.data[row]);
            }
        });
        selectetedRowData.current = tmp;

        if ("drib" === tab.current) {
            rows.forEach((row) => {
                let tmpStoreRow = multipleAgentsTable.data[row];
                let saved = false;
                for (let i = 0; i < storedSelectedRow4Drib.current.length; i++) {
                    let storedRowID = storedSelectedRow4Drib.current[i];
                    if (tmpStoreRow !== undefined && tmpStoreRow.id === storedRowID) {
                        saved = true;
                        break;
                    }
                }

                if (false === saved && tmpStoreRow !== undefined && tmpStoreRow.id !== undefined) {
                    storedSelectedRow4Drib.current.push(tmpStoreRow.id)
                }
            });

            unSelectedRows.forEach((unselectRow) => {
                let tmpUnStoreRow = multipleAgentsTable.data[unselectRow];
                let saved = false;
                let positionInArray = 0;
                for (let i = 0; i < storedSelectedRow4Drib.current.length; i++) {
                    let storedRowID = storedSelectedRow4Drib.current[i];
                    if (tmpUnStoreRow !== undefined && tmpUnStoreRow.id === storedRowID) {
                        saved = true;
                        positionInArray = i;
                        break;
                    }
                }
                if (true === saved) {
                    storedSelectedRow4Drib.current.splice(positionInArray, 1);
                }
            });

            if (rows.length > 0 && storedSelectedRow4Drib.current.length > 0) {
                setdisableSaveBtn(false);
            } else {
                setdisableSaveBtn(true);
            }

        } else {
            rows.forEach((row) => {
                let tmpStoreRow = multipleAgentsTable.data[row];
                let saved = false;
                for (let i = 0; i < storedSelectedRow4Myib.current.length; i++) {
                    let storedRowID = storedSelectedRow4Myib.current[i];
                    if (tmpStoreRow !== undefined && tmpStoreRow.id === storedRowID) {
                        saved = true;
                        break;
                    }
                }
                if (false === saved && tmpStoreRow !== undefined && tmpStoreRow.id !== undefined) {
                    storedSelectedRow4Myib.current.push(tmpStoreRow.id);
                }
            });

            unSelectedRows.forEach((unselectRow) => {
                let tmpUnStoreRow = multipleAgentsTable.data[unselectRow];
                let saved = false;
                let positionInArray = 0;
                for (let i = 0; i < storedSelectedRow4Myib.current.length; i++) {
                    let storedRowID = storedSelectedRow4Myib.current[i];
                    if (tmpUnStoreRow !== undefined && tmpUnStoreRow.id === storedRowID) {
                        saved = true;
                        positionInArray = i;
                        break;
                    }
                }
                if (true === saved) {
                    storedSelectedRow4Myib.current.splice(positionInArray, 1);
                }
            });

            if (rows.length > 0 && storedSelectedRow4Myib.current.length > 0) {
                setdisableSaveBtn(false);
            } else {
                setdisableSaveBtn(true);
            }
        }
    }

    function showBillingPaymentSummary(rowData: any): boolean {
        let showFlag: boolean = true;
        if (false === rowData.viewBillingAccessPermission) {
            showFlag = false;
        }
        return showFlag;
    }

    function showCoverageSummaryLink(rowData: any): boolean {
        let showFlag: boolean = true;
        if (false === rowData.viewCoverageAccessPermission) {
            showFlag = false;
        }
        return showFlag;
    }

    function showClaimInquiryLink(rowData: any): boolean {
        let showFlag: boolean = true;
        if (false === rowData.viewClaimsAccessPermission) {
            showFlag = false;
        }
        return showFlag;
    }

    function showCommissionSummaryByGroupLink(rowData: any): boolean {
        let showFlag: boolean = true;

        if (config.commissionReportFlag !== undefined) {
            showFlag = config.commissionReportFlag;
        }
        return showFlag;
    }

    function showMemberListLink(rowData: any): boolean {
        let showFlag: boolean = true;

        if ('TG' === rowData.status || 0 === rowData.countOfMember) {
            showFlag = false;
        }
        return showFlag;
    }

    function showViewPolicyDetail(rowData: any): boolean {
        let showFlag: boolean = true;

        if (config.policyDetailFlag !== undefined) {
            showFlag = config.policyDetailFlag;
        }
        return showFlag;
    }

    function showViewBillingPayment(rowData: any): boolean {
        let showFlag: boolean = true;

        if (config.billingPaymentFlag !== undefined) {
            showFlag = config.billingPaymentFlag;
        }
        return showFlag;
    }

    function showCommissionSumByPol(rowData: any): boolean {
        let showFlag: boolean = true;

        if (config.commissionReportFlag !== undefined) {
            showFlag = config.commissionReportFlag;
        }
        return showFlag;
    }

    function showViewPolicyComments(rowData: any): boolean {
        let showFlag: boolean = true;

        if (config.viewPolicyNotesInInforceBusi === undefined
            || config.viewPolicyNotesInInforceBusi === null
            || config.viewPolicyNotesInInforceBusi === 'false'
            || rowData.policyNotesCount === undefined
            || rowData.policyNotesCount === null
            || rowData.policyNotesCount === 0
            || isPolicyNotesFeature === false
        ) {
            showFlag = false;
        }
        return showFlag;
    }

    function showPaymentMethodField(rowData: any): boolean {
        let showFlag: boolean = true;

        if (rowData.paymentMethod === undefined
            || rowData.paymentMethod === null
            || rowData.paymentMethod === '') {
            showFlag = false;
        }
        return showFlag;
    }

    function showPaymentFrequency(rowData: any): boolean {
        let showFlag: boolean = true;

        if (rowData.paymentFrequency === undefined
            || rowData.paymentFrequency === null
            || rowData.paymentFrequency === '') {
            showFlag = false;
        }
        return showFlag;
    }

    function showAgentInTable(inforceBusiness: any): string {
        let result: string = '';
        if (inforceBusiness.agentCount > 0) {
            result = 'showAllAgentNames';
        } else {
            if (inforceBusiness.allAgentOfThisInforce !== undefined && inforceBusiness.allAgentOfThisInforce !== null) {
                let itemsAgent: any[] = [];
                var agentsOfColum = inforceBusiness.allAgentOfThisInforce.split('$');
                for (let i = 0; i < agentsOfColum.length; i++) {
                    let agentsDetails = agentsOfColum[i].split('|');
                    if (agentsDetails && agentsDetails.length == 3) {
                        let agentDetailJson: any = {};
                        agentDetailJson.agentNumber = agentsDetails[0];
                        agentDetailJson.agentName = agentsDetails[1];
                        agentDetailJson.agentType = agentsDetails[2];
                        itemsAgent = itemsAgent.concat(agentDetailJson);
                    }
                }
                if (itemsAgent.length > 1) {
                    result = 'showAllAgentNamesOfGroup';
                }
            }
        }

        return result
    }

    function showAllAgentNames(tipnode: any, inforceBusiness: any): void {
        let msg = '';
        ajax({
            url: '/api/inforceBusiness/getAgentNamesByPolicyNumber',
            params: {
                companyCode: inforceBusiness.companyCode,
                agentNumber: inforceBusiness.agentNumber,
                policyNumber: inforceBusiness.identifier,
            },
            showMask: false,
            success: (data) => {
                let anum;
                let aname;
                msg += "<label>" + lblWritingAgent + "</label><br/>";
                let lblServicing = false;

                for (let i = 0; i < data.length; i++) {
                    anum = data[i].agentNumber;
                    aname = data[i].agentName;
                    if (data[i].agentType === 'S' && !lblServicing) {
                        msg += "<label>" + lblServicingAgent + "</label><br/>";
                        lblServicing = true;
                    }
                    msg += (anum + " " + aname + "<br/>");
                }
                showTooltip(tipnode, msg);
            }
        });
    }

    function showAllAgentNamesOfGroup(tipnode: any, inforceBusiness: any): void {
        let servicingAgents: any[] = [];
        let writingAgents: any[] = [];
        let agentsOfColum = inforceBusiness.allAgentOfThisInforce.split('$');
        for (let i = 0; i < agentsOfColum.length; i++) {
            let agentsDetails = agentsOfColum[i].split('|');
            if (agentsDetails && agentsDetails.length == 3) {
                let agentDetailJson: any = {};
                agentDetailJson.agentNumber = agentsDetails[0];
                agentDetailJson.agentName = agentsDetails[1];
                agentDetailJson.agentType = agentsDetails[2];
                if (agentDetailJson.agentType.toUpperCase() == 'S') {
                    servicingAgents = servicingAgents.concat(agentDetailJson);
                } else if (agentDetailJson.agentType.toUpperCase() == 'W') {
                    writingAgents = writingAgents.concat(agentDetailJson);
                }
            }
        }

        let tipCnt = "<label>" + lblWritingAgent + "</label><br/>";
        for (let j = 0; j < writingAgents.length; j++) {
            tipCnt += (writingAgents[j].agentNumber + '&nbsp;' + writingAgents[j].agentName + '<br/>');
        }
        if (servicingAgents.length > 0) {
            tipCnt += "<label>" + lblServicingAgent + "</label><br/>";
            for (let k = 0; k < servicingAgents.length; k++) {
                tipCnt += (servicingAgents[k].agentNumber + '&nbsp;' + servicingAgents[k].agentName + '<br/>');
            }
        }

        showTooltip(tipnode, tipCnt);

    }

    function formatPremiumVal(inforceBusiness: any): any {
        let result: string = '';
        if ("P" === inforceBusiness.agentIndicator) {
            if (true === hidePremiumMessage) {
                setHidePremiumMessage(false);
            }
            return "P";
        }

        if ('' !== inforceBusiness.premium && inforceBusiness.premium !== undefined) {
            let premiumVal = Number(inforceBusiness.premium);
            return premiumVal.toFixed(2);
        }
        return result;
    }

    // const inforceBusinessTable = 
    const inforceBusinessTableObj = useMemo(() => {
        return useTable({
            id: 'inforceBusinessTable',
            onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element | null) => {
                childNode?.querySelectorAll("a")?.forEach((a: HTMLAnchorElement) => {
                    let href = a.getAttribute("data-href");
                    a.addEventListener("click", (e: any) => {
                        saveDataBeforeRedirect();
                        if (href !== null) {
                            props.next(href);
                        }
                        e.preventDefault();
                    });
                });
                let showAllAgentLink = childNode?.querySelector("a[id^=showAllAgentNamesTableCell]");
                showAllAgentLink?.addEventListener("click", (e: any) => {
                    let el: any = e.target;
                    if (el.nodeName !== 'A') {
                        el = el.parentNode;
                    }
                    let idx = parseInt(el.getAttribute("id").replace("showAllAgentNamesTableCell", ""));
                    showAllAgentNames(`.dtr-details #showAllAgentNamesTableCell${idx}`, inforceBusinessList[idx]);
                });

                let showAllGroupAgentLink = childNode?.querySelector("a[id^=showAllAgentNamesOfGroupTableCell]");
                showAllGroupAgentLink?.addEventListener("click", (e: any) => {
                    let el: any = e.target;
                    if (el.nodeName !== 'A') {
                        el = el.parentNode;
                    }
                    let idx = parseInt(el.getAttribute("id").replace("showAllAgentNamesOfGroupTableCell", ""));
                    showAllAgentNamesOfGroup(`.dtr-details #showAllAgentNamesOfGroupTableCell${idx}`, inforceBusinessList[idx]);
                });
            },
            children: [
                <thead key={0}>
                    <tr>
                        <th scope="col" data-sortable={false} className="gwp-dt-detail-control"  ></th>
                        <th scope="col" ><GlobalizedText message="ib.lbl.grid.id" /></th>
                        <th scope="col"><GlobalizedText message="common.lbl.name" /></th>
                        <th scope="col"><GlobalizedText message="common.lbl.status" /></th>
                        <th scope="col"><GlobalizedText message="common.lbl.premium" /></th>
                        <th scope="col"><GlobalizedText message="common.lbl.paidToDate" /></th>
                        <th scope="col"><GlobalizedText message="common.lbl.agent" /></th>
                        <th className="gwp-dt-child"></th>
                    </tr>
                </thead>,
                <tbody key={1}>
                    {inforceBusinessList !== null && inforceBusinessList?.map((inforceBusiness: any, index: number) => {
                        return <tr key={index}>
                            <td className="gwp-dt-detail-control"></td>
                            <td >{inforceBusiness.identifier}</td>
                            <td>{inforceBusiness.name.replaceAll("<wbr/>", "")}</td>
                            <td>{inforceBusiness.statusLabel}</td>
                            {
                                '' === formatPremiumVal(inforceBusiness) &&
                                <td className={isMobileDevice() === true ? "numberInTableMobile" : "numberInTable"}>{("" === inforceBusiness.premium || undefined === inforceBusiness.premium) ? "0.00" : inforceBusiness.premium}</td>
                            }
                            {
                                'P' === formatPremiumVal(inforceBusiness) &&
                                <td><div style={{ color: 'red', textAlign: 'center' }}>*</div></td>
                            }
                            {
                                'P' !== formatPremiumVal(inforceBusiness) && '' !== formatPremiumVal(inforceBusiness) &&
                                <td><div className={isMobileDevice() === true ? "numberInTableMobile" : "numberInTable"}>{formatPremiumVal(inforceBusiness)}</div></td>
                            }
                            <td>{inforceBusiness.paidToDate}</td>
                            {
                                '' === showAgentInTable(inforceBusiness) &&
                                <td>{inforceBusiness.agentNumber + ' ' + inforceBusiness.agentName}</td>
                            }
                            {
                                'showAllAgentNames' === showAgentInTable(inforceBusiness) &&
                                <td>{inforceBusiness.agentNumber + ' ' + inforceBusiness.agentName}&nbsp;<a id={`showAllAgentNamesTableCell${index}`} onClick={() => { showAllAgentNames(`#showAllAgentNamesTableCell${index}`, inforceBusiness) }}> <GlobalizedText message='common.lbl.showAllAgent' /></a></td>
                            }
                            {
                                'showAllAgentNamesOfGroup' === showAgentInTable(inforceBusiness) &&
                                <td>{inforceBusiness.agentNumber + ' ' + inforceBusiness.agentName}&nbsp;<a id={`showAllAgentNamesOfGroupTableCell${index}`} onClick={() => { showAllAgentNamesOfGroup(`#showAllAgentNamesOfGroupTableCell${index}`, inforceBusiness) }}> <GlobalizedText message='common.lbl.showAllAgent' /></a></td>
                            }
                            <td className="gwp-dt-child">
                                {
                                    inforceBusiness.agentIndicator !== null && "I" === inforceBusiness.accountType &&
                                    <div id="detailGroupITemplate" >
                                        <div className="row expandTemplate">
                                            <div className="col-md-4 col-sm-4 col-xs-6">
                                                <dl className="gwp-dl">
                                                    <dt><GlobalizedText message='common.lbl.group' /></dt>
                                                    <dd>{removeLeftZero(inforceBusiness.groupNumber)} - {inforceBusiness.groupName}</dd>
                                                    <dt><GlobalizedText message='common.lbl.account' /></dt>
                                                    <dd>{inforceBusiness.accountNumber} - {inforceBusiness.accountName}</dd>
                                                    <dt><GlobalizedText message='common.lbl.member' /></dt>
                                                    <dd>{removeLeftZero(inforceBusiness.participantIDNumber)} - {inforceBusiness.memberName}</dd>
                                                </dl>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-6">
                                                <dl className="gwp-dl">
                                                    <dt><GlobalizedText message='payment.lbl.accountType' /></dt>
                                                    <dd>{convertAccountType(inforceBusiness.accountType)}</dd>
                                                    <dt><GlobalizedText message='common.lbl.lastBillingDate' /></dt>
                                                    <dd>{inforceBusiness.lastBillingDate}</dd>
                                                    <dt><GlobalizedText message='common.lbl.billedtoDate' /></dt>
                                                    <dd>{inforceBusiness.billedToDate}</dd>
                                                    <dt><GlobalizedText message='common.lbl.paidToDate' /></dt>
                                                    <dd>{inforceBusiness.paidToDate}</dd>
                                                </dl>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                                <div className="panel panel-default panelInTable">
                                                    <div className="panel-heading">
                                                        <h3 className="panel-title"><GlobalizedText message='common.lbl.relatedLink' /></h3>
                                                    </div>
                                                    <div className="panel-body">
                                                        {
                                                            showBillingPaymentSummary(inforceBusiness) === true &&
                                                            <div className='billingPaymentSummary'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="billingPaymentSummary_detailGroupITemplate" to={`/billingPaymentSummary/${selfTrim(inforceBusiness.companyCode)}/${selfTrim(inforceBusiness.groupNumber)}/${inforceBusiness.accountNumber}${inforceBusiness.participantIDNumber !== undefined ? '/' + selfTrim(inforceBusiness.participantIDNumber) : ''}`}><GlobalizedText message='common.lbl.viewBillingPayment' /></props.Link></div>
                                                        }
                                                        {
                                                            showClaimInquiryLink(inforceBusiness) === true &&
                                                            <div className='claimInquiry'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="claimInquiry_detailGroupITemplate" to={`/memberClaimInquiry?companyCode=${selfTrim(inforceBusiness.companyCode)}&accountNumber=${inforceBusiness.accountNumber}&groupNumber=${selfTrim(inforceBusiness.groupNumber)}&agentNumber=${selfTrim(inforceBusiness.agentNumber)}&participantIDNumber=${selfTrim(inforceBusiness.participantIDNumber)}&from=from_inforcebusiness`}><GlobalizedText message='common.lbl.claims' /></props.Link></div>
                                                        }

                                                        <div><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="personalInformation_detailGroupITemplate" to={`/personalInformation?companyCode=${selfTrim(inforceBusiness.companyCode)}&accountNumber=${inforceBusiness.accountNumber}&groupNumber=${selfTrim(inforceBusiness.groupNumber)}&participantIDNumber=${selfTrim(inforceBusiness.participantIDNumber)}&dependentSequenceNumber=${inforceBusiness.dependentSequenceNumber !== undefined && inforceBusiness.dependentSequenceNumber !== '' ? inforceBusiness.dependentSequenceNumber : 0}&personalInfoType=1&baseRiderCode=0&isUpdateMode=false&roleTypeCode=${inforceBusiness.ownerRoleType === 'OW2' ? 'OW2' : 'OW1'}`}><GlobalizedText message='pi.contact.info' /></props.Link></div>
                                                        {
                                                            showCoverageSummaryLink(inforceBusiness) === true &&
                                                            <div className='coverageSummary'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="coverageSummary_detailGroupITemplate" to={`/coverageSummary?companyCode=${selfTrim(inforceBusiness.companyCode)}&accountNumber=${inforceBusiness.accountNumber}&groupNumber=${selfTrim(inforceBusiness.groupNumber)}&participantIDNumber=${selfTrim(inforceBusiness.participantIDNumber)}&agentNumber=${selfTrim(inforceBusiness.agentNumber)}&from=from_inforcebusiness`}><GlobalizedText message='common.lbl.coverageSummary' /></props.Link></div>
                                                        }
                                                        {
                                                            (showCommissionSummaryByGroupLink(inforceBusiness) === true && configDashboard !== undefined && configDashboard?.length > 0) &&
                                                            <div className='commissionSummaryByGroup'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="commissionReportsGroup_detailGroupITemplate" to={`/dashboard?dashboardID=${configDashboard[0].dashboardID}&dashboardName=${configDashboard[0].dashboardName}&fromAgentSearch=false&companyCode=${selfTrim(inforceBusiness.companyCode)}&agentNumber=${selfTrim(inforceBusiness.agentNumber)}&isDir=${tab.current === 'myib' ? 'N' : 'Y'}`} ><GlobalizedText message='common.lbl.commissionSumByGroup' /></props.Link></div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    inforceBusiness.agentIndicator !== undefined && inforceBusiness.accountType !== "I" &&
                                    <div id="detailGroupWETemplate" >
                                        <div className="row expandTemplate">
                                            <div className="col-md-4 col-sm-4 col-xs-6">
                                                <dl className="gwp-dl">
                                                    <dt><GlobalizedText message='common.lbl.group' /></dt>
                                                    <dd>{removeLeftZero(inforceBusiness.groupNumber)} - {inforceBusiness.groupName}</dd>
                                                    <dt><GlobalizedText message='common.lbl.account' /></dt>
                                                    <dd>{inforceBusiness.accountNumber} - {inforceBusiness.accountName}</dd>
                                                    <dt><GlobalizedText message='payment.lbl.accountType' /></dt>
                                                    <dd>{convertAccountType(inforceBusiness.accountType)}</dd>
                                                </dl>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-6">
                                                <dl className="gwp-dl">
                                                    <dt><GlobalizedText message='common.lbl.lastBillingDate' /></dt>
                                                    <dd>{inforceBusiness.lastBillingDate}</dd>
                                                    <dt><GlobalizedText message='common.lbl.billedtoDate' /></dt>
                                                    <dd>{inforceBusiness.billedToDate}</dd>
                                                </dl>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                                <div className="panel panel-default panelInTable">
                                                    <div className="panel-heading">
                                                        <h3 className="panel-title"><GlobalizedText message='common.lbl.relatedLink' /></h3>
                                                    </div>
                                                    <div className="panel-body">
                                                        {
                                                            showBillingPaymentSummary(inforceBusiness) === true &&
                                                            <div className='billingPaymentSummary'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="billingPaymentSummary_detailGroupWETemplate" to={`/billingPaymentSummary/${selfTrim(inforceBusiness.companyCode)}/${selfTrim(inforceBusiness.groupNumber)}/${inforceBusiness.accountNumber}${inforceBusiness.participantIDNumber !== undefined ? '/' + selfTrim(inforceBusiness.participantIDNumber) : ''}?agentIndicator=${selfTrim(inforceBusiness.agentIndicator)}&agentNumber=${selfTrim(inforceBusiness.agentNumber)}&from=from_inforcebusiness`}><GlobalizedText message='common.lbl.viewBillingPayment' /></props.Link></div>
                                                        }
                                                        {
                                                            showMemberListLink(inforceBusiness) === true &&
                                                            <div className='memberList'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="memberList_detailGroupWETemplate" to={`/memberList?companyCode=${selfTrim(inforceBusiness.companyCode)}&groupNumber=${selfTrim(inforceBusiness.groupNumber)}&accountNumber=${inforceBusiness.accountNumber}&agentNumber=${selfTrim(inforceBusiness.agentNumber)}`}><GlobalizedText message='memlist.titlename' /></props.Link></div>
                                                        }
                                                        {
                                                            (showCommissionSummaryByGroupLink(inforceBusiness) === true && configDashboard !== undefined && configDashboard?.length > 0) &&
                                                            <div className='commissionSummaryByGroup'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="commissionReportsGroup_detailGroupWETemplate" to={`
                                                            /dashboard?dashboardID=${configDashboard[0].dashboardID}&dashboardName=${configDashboard[0].dashboardName}&fromAgentSearch=false&companyCode=${selfTrim(inforceBusiness.companyCode)}&policyNumber=${selfTrim(inforceBusiness.identifier)}&agentNumber=${selfTrim(inforceBusiness.agentNumber)}&isDir=${tab.current === 'myib' ? 'N' : 'Y'}`}><GlobalizedText message='common.lbl.commissionSumByGroup' /></props.Link></div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    inforceBusiness.agentIndicator === undefined &&
                                    <div id="detailIndividualTemplate" >
                                        <div className="row expandTemplate">
                                            <div className="col-md-4 col-sm-4 col-xs-6">
                                                <dl className="gwp-dl">
                                                    <dt><GlobalizedText message='common.lbl.policyNumber' /></dt>
                                                    <dd>{inforceBusiness.identifier}</dd>
                                                    <dt><GlobalizedText message='common.lbl.owner' /></dt>
                                                    <dd>{inforceBusiness.jointName.replaceAll("<wbr/>", "")}</dd>
                                                    <dt><GlobalizedText message='common.lbl.insured' /></dt>
                                                    <dd>{inforceBusiness.formatInsured.replaceAll("<wbr/>", "")}</dd>
                                                    <dt><GlobalizedText message='common.lbl.payor' /></dt>
                                                    <dd>{inforceBusiness.payor.replaceAll("<wbr/>", "")}</dd>
                                                </dl>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-6">
                                                <dl className="gwp-dl">
                                                    <dt><GlobalizedText message='common.lbl.plan' /></dt>
                                                    <dd>{inforceBusiness.policyPlan}</dd>
                                                    <dt><GlobalizedText message='common.lbl.effectiveDate' /></dt>
                                                    <dd>{inforceBusiness.effectiveDate}</dd>
                                                    {
                                                        showPaymentMethodField(inforceBusiness) &&
                                                        <dt className='paymentMethod'><GlobalizedText message='common.lbl.paymentMethod' /></dt>
                                                    }
                                                    {
                                                        showPaymentMethodField(inforceBusiness) &&
                                                        <dd className='paymentMethod'>{inforceBusiness.paymentMethod}</dd>
                                                    }
                                                    {
                                                        showPaymentFrequency(inforceBusiness) &&
                                                        <dt className='paymentFrequency'><GlobalizedText message='common.lbl.paymentFrequency' /></dt>
                                                    }
                                                    {
                                                        showPaymentFrequency(inforceBusiness) &&
                                                        <dd className='paymentFrequency'>{inforceBusiness.paymentFrequency}</dd>
                                                    }
                                                </dl>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                                <div className="panel panel-default panelInTable">
                                                    <div className="panel-heading">
                                                        <h3 className="panel-title"><GlobalizedText message='common.lbl.relatedLink' /></h3>
                                                    </div>
                                                    <div className="panel-body">
                                                        {
                                                            showViewPolicyDetail(inforceBusiness) === true &&
                                                            <div className='viewPolicyDetail'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="policyDetail_detailIndividualTemplate" to={`/policyDetail/${selfTrim(inforceBusiness.companyCode)}/${selfTrim(inforceBusiness.identifier)}?from=from_inforcebusiness`}><GlobalizedText message='common.lbl.viewPolicyDetail' /></props.Link></div>
                                                        }
                                                        <div><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="personalInformation_detailIndividualTemplate" to={`/personalInformation?companyCode=${inforceBusiness.companyCode}&policyNumber=${selfTrim(inforceBusiness.identifier)}&clientNumber=${selfTrim(config.clientNumber)}&isUpdateMode=false&personalInfoType=2&baseRiderCode=0&roleTypeCode=${inforceBusiness.ownerRoleType === 'OW2' ? 'OW2' : 'OW1'}`} ><GlobalizedText message='pi.contact.info' /></props.Link></div>
                                                        {
                                                            showViewBillingPayment(inforceBusiness) === true &&
                                                            <div className='viewBillingPayment'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="billingPaymentDetail_detailIndividualTemplate" to={`/billingPaymentDetail/${selfTrim(inforceBusiness.companyCode)}/${selfTrim(inforceBusiness.identifier)}`}><GlobalizedText message='common.lbl.viewBillingPayment' /></props.Link></div>
                                                        }
                                                        {
                                                            (showCommissionSumByPol(inforceBusiness) === true && configDashboard !== undefined && configDashboard?.length > 0) &&
                                                            <div className='commissionSumByPol'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="commissionReports_detailIndividualTemplate" to={`/dashboard?dashboardID=${configDashboard[0].dashboardID}&dashboardName=${configDashboard[0].dashboardName}&fromAgentSearch=false&companyCode=${selfTrim(inforceBusiness.companyCode)}&policyNumber=${selfTrim(inforceBusiness.identifier)}&agentNumber=${selfTrim(inforceBusiness.agentNumber)}&isDir=${tab.current === 'myib' ? 'N' : 'Y'}`}><GlobalizedText message='common.lbl.commissionSumByPol' /></props.Link></div>
                                                        }
                                                        {
                                                            showViewPolicyComments(inforceBusiness) === true &&
                                                            <div className='viewPolicyComments'><div className="gwp-icon gwp-icon-view-all gwp-icon-xs"></div>&nbsp;<props.Link id="policyNotes_detailIndividualTemplate" to={`/policyComments?companyCode=${selfTrim(inforceBusiness.companyCode)}&policyNumber=${selfTrim(inforceBusiness.identifier)}&groupNumber=${selfTrim(inforceBusiness.groupNumber)}&from=from_inforcebusiness`}><GlobalizedText message='inforce.view.policy.comments' /></props.Link></div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </td>
                        </tr>
                    })
                    }
                </tbody >
            ],
            onRendered: () => {
                inforceBusinessTableObj.page(tablePageNum.current)
            }
        })
    }, [inforceBusinessList])

    let initialValue: InforceBusinessForm = props.getInitParams() !== undefined ? props.getInitParams() : defaultInitialValues;


    return <>

        {/* Tab Control Panel*/}
        <div>
            <ul id="ibTab" className="nav nav-tabs" role="tablist">
                <li id="INB_myApp" role="presentation" className={tab.current === 'myib' ? 'active' : ''}>
                    <a href="#myib" id="tab_myib" aria-controls="myib" role="tab" data-toggle="tab" onClick={() => { changeTab("myib") }} ><GlobalizedText message="ib.lbl.myib" /></a>
                </li>
                {config !== null && config.hasSugAgent !== undefined && config.hasSugAgent === 'Y' &&
                    <li id="INB_dRepApp" role="presentation" className={tab.current === 'drib' ? 'active' : ''} >
                        <a href="#drib" id="tab_drib" aria-controls="drib" role="tab" data-toggle="tab" onClick={() => { changeTab("drib") }} ><GlobalizedText message="ib.lbl.drib" /></a>
                    </li>
                }
            </ul>
        </div>

        {/* My inforce business Tab*/}

        <Formik initialValues={initialValue}
            validate={formValidate}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={formSubmit}
        >
            {formProps => {
                varFormPromp.current = formProps;
                return <Form id='inforceBusiness'>
                    {/* direct report agent tab */}
                    <div className="tab-content" >
                        <div role="tabpanel" className={`tab-pane fade in ${tab.current === 'drib' ? 'active' : ''}`} id="drib">
                            <div className="row">
                                <div className="col-md-4 col-sm-5 col-xs-12 form-group">
                                    <props.SelectControl name="selectDPagent" label="common.lbl.directReport" disabled={showSelectDPagent ? false : true}
                                        sort={false} onChange={(e: any) => { directReportAgentChangeHandler(e, formProps.values, formProps.setFieldValue) }}
                                        options={(config === null || config.businessAgentsJson === null) ? [{ name: "common.lbl.pleaseselect", value: "" }] : config.businessAgentsJson} />
                                </div>
                                <div id="dribmultipleDirectReportDiv" className={hiddenCancelLink.current === true ? "col-md-4 col-sm-4 col-xs-12 form-group hidden" : "col-md-4 col-sm-4 col-xs-12 form-group"}>
                                    <label>
                                        <GlobalizedText message="ib.lbl.mdr" />(<span id="selectedMultiNumber" className="gwp-required">{selecteAgentNum.current}</span>)
                                    </label>
                                    <props.SelectControl name="selectedAgents" style={{ marginTop: '-14px' }} label=""
                                        options={selectedAgentsList.current === null ? [{ name: "", value: "" }] : selectedAgentsList.current} />
                                </div>
                                <div className="col-md-4 col-sm-4 col-xs-12">
                                    <div className="btn-container" >
                                        <props.LinkButton onClick={() => { openMultipleAgentsDialog() }}>
                                            <GlobalizedText message="common.lbl.selectFromAgentList" />
                                        </props.LinkButton>
                                        <br />
                                        <props.LinkButton className={hiddenCancelLink.current === true ? "hidden" : ""} onClick={() => { cancelMultiAgents() }} id="INB_cancelMulti_dr">
                                            <GlobalizedText message="common.lbl.cancelMulti" />
                                        </props.LinkButton>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    </div>
                    {/* my inforce tab */}
                    <div role="tabpanel" className="tab-pane fade in active" id="myib">
                        <props.Row>
                            <props.Col md="4" sm="4" xs="12">
                                <props.SelectControl name="filterBy" sort={true} defaultOption={"common.lbl.all"} label="ib.lbl.fiby" id="INB_filterSel" options={filterByOptions}
                                    onChange={(e: any) => { filterByChangeHandler(e, formProps.values, formProps.setFieldValue) }} />

                                <props.NameTypeSelectControl onChange={(e: any) => { nameTypeChangeHandler(e, formProps.values, formProps.setFieldValue) }}
                                    condition={formProps.values.filterBy === 'byCustomerName'} name="nameType" />
                            </props.Col>

                            {formProps.values.filterBy === 'byGroupAccount' && <>
                                <props.Col md="8" sm="8" xs="12">
                                    <props.SelectControl name="groupSelect" label="common.lbl.group" required={true} sort={false}
                                        options={groups} onChange={(e: any) => { groupChangeHandler(e, formProps.values, formProps.setFieldValue) }} />
                                    <props.SelectControl name="accountSelect" label="common.lbl.account" required={true} sort={false} options={accounts}
                                        onChange={(e: any) => { accountChangeHandler(e, formProps.values, formProps.setFieldValue) }} />
                                    <props.TextControl id="memberID" name="memberID" label="common.lbl.memberID" maxLength={10} condition={hiddenMemberID.current !== true}></props.TextControl>
                                </props.Col>
                            </>}
                            {formProps.values.filterBy === 'byPolicyNumber' && <>
                                <props.Col md="8" sm="8" xs="12">
                                    <props.TextControl id="policyNumber" name="policyNumber" label="common.lbl.policyNumber" required={true}></props.TextControl>
                                </props.Col>
                            </>}
                            {formProps.values.filterBy === 'byGovtID' && <>
                                <props.Col md="8" sm="8" xs="12">
                                    <props.AutoFormatControl id="govtID" name="govtID" label="common.lbl.govID" required={true}></props.AutoFormatControl>
                                </props.Col>
                            </>}
                            {formProps.values.filterBy === 'byCustomerName' && formProps.values.nameType === NAME_TYPE_PERSON && <>
                                <props.Col md="8" sm="8" xs="12">
                                    <props.LastNameAutoCompleteControl roleTypeCodes={["OW1", "OW2", "PAY", "MBR"]} required={true} htmlFor="lastName_input" id="lastName" name="lastName" />
                                    <props.FirstNameAutoCompleteControl roleTypeCodes={["OW1", "OW2", "PAY", "MBR"]} htmlFor="firstName_input" id="firstName" name="firstName" />
                                </props.Col>
                            </>}
                            {formProps.values.filterBy === 'byCustomerName' && formProps.values.nameType === NAME_TYPE_COMPANY && <>
                                <props.Col md="8" sm="8" xs="12">
                                    <props.OrgNameAutoCompleteControl required={true} roleTypeCodes={["OW1", "OW2", "PAY"]} htmlFor="companyName_input" id="companyName" name="companyName" />
                                </props.Col>
                            </>}
                            {formProps.values.filterBy === 'byStatus' && <>
                                <props.Col md="8" sm="8" xs="12">
                                    <props.SelectControl name="statusType" label="ib.lbl.st" sort={false} options={statusTypeOption} />
                                    {formProps.values.statusType === "a" && config !== null &&
                                        < props.SelectControl name="activeStatus" sort={false} label="common.lbl.status" options={config.activeOptions} />
                                    }
                                    {formProps.values.statusType === "i" && config !== null &&
                                        <props.SelectControl name="inActiveStatus" label="common.lbl.status" options={config.inactiveOptions} />
                                    }
                                </props.Col>
                            </>}

                            {formProps.values.filterBy === 'byAgent' && config !== null && <>
                                <props.Col md="8" sm="8" xs="12">
                                    <props.Row>
                                        <props.Col md="4" sm="6" xs="12" className="agentDropDown">
                                            <props.SelectControl name="myAgent" id="myAgent" required={true} label="common.lbl.agent" sort={false} disabled={showMySelectDPagent.current ? false : true}
                                                options={config.mybusinessAgentsJson} />
                                            <div className={hiddenMyCancelLink.current === true ? "hidden" : ""} id="myMultiDirReportDiv" >
                                                <label >
                                                    <GlobalizedText message="ib.lbl.mulan" />(<span id="myselectedMultiNumber" className="gwp-required">{selectMyAgentNum}</span>)
                                                </label>
                                                <props.SelectControl name="mySelectedAgents" id="mySelectedAgents" style={{ marginTop: '-14px' }} label=""
                                                    options={selectedMyAgentsList === null ? [{ name: "", value: "" }] : selectedMyAgentsList} />
                                            </div>
                                        </props.Col>
                                        <props.Col md="4" sm="6" xs="12">
                                            <div className={'Y' === config.myAgents ? "btn-container" : "btn-container hidden"} >
                                                <props.LinkButton onClick={() => { openMultipleAgentsDialog() }}>
                                                    <GlobalizedText message="common.lbl.selectFromAgentList" />
                                                </props.LinkButton>
                                                <br />
                                                <props.LinkButton className={hiddenMyCancelLink.current === true ? "hidden" : ""} onClick={() => { cancelMultiAgents() }} id="INB_cancelMulti_my">
                                                    <GlobalizedText message="common.lbl.cancelMulti" />
                                                </props.LinkButton>
                                            </div>
                                        </props.Col>
                                    </props.Row>
                                </props.Col>
                            </>}

                            <props.Col md="4" sm="4" xs="12"></props.Col>
                            <props.Col md="8" sm="8" xs="12">
                                <props.Button type="submit" containerClassName="retriveBtn" ><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                            </props.Col>
                        </props.Row>


                        {/* Modal for selecting multiple agents_dr */}
                        <props.Modal title="ib.lbl.diatitle" size='large' id="selectMultipleAgents_dr" footer={() => {
                            return <>
                                <props.Button onClick={() => { saveAgents2Option() }} disabled={disableSaveBtn}><GlobalizedText message="common.lbl.save" /></props.Button>
                                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
                            </>
                        }} messageService={messageService4Jump.messageService}>
                            <props.Row>
                                <props.Col >
                                    <props.Information message="ib.lbl.dia.h" className='modalInfo'></props.Information>
                                </props.Col>
                                <props.Col xs="8" sm="5" md="5">
                                    <props.TextControl id="dialogName" name="dialogName" label="common.lbl.jumpTo" required={true} placeholder="common.msg.typeAgentNameOrNumber"  ></props.TextControl>
                                </props.Col>
                                <props.Col xs="4" sm="7" md="7">
                                    <props.Button style={{ marginTop: 19 }} type='submit' onClick={() => { retrieveBtnValidate.current = true; }}> <GlobalizedText message="common.lbl.retrieve" /></props.Button>
                                </props.Col>
                                <props.Col xs="12">
                                    <br /><props.LinkButton type='submit' onClick={() => { showAllValidate.current = true; retrieveBtnValidate.current = false; getDirectReportsAgents('') }}><GlobalizedText message='common.lbl.showall' /></props.LinkButton>
                                </props.Col>
                            </props.Row>
                            <props.Table id="multipleAgentsTable" modal="selectMultipleAgents_dr" table={multipleAgentsTable} select={{ type: 'multiple', selected: storedSelectedRowNumber.current, onSelect: (e: any, rows: number[], unSelectedRows: number[]) => { saveSelectedValue(rows, unSelectedRows) } }} ></props.Table>
                        </props.Modal>
                    </div>
                </Form>
            }
            }
        </Formik>

        <props.Row>
            <props.Col xs="12">
                <div className="m-line" id="middleLine"></div>
            </props.Col>
            <props.Col xs="12" condition={hidePremiumMessage !== true}>
                <props.Information message="common.msg.premiumNotDisplay"></props.Information>
            </props.Col>
            <props.Col xs="12">
                <div className="gwp-innertitle  gwp-bold">
                    <GlobalizedText message="ib.lbl.module" />
                </div>
            </props.Col>
            <props.Col xs="12">
                <inforceBusinessTableObj.Component />
            </props.Col>
        </props.Row>
    </>
});
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SecurityContext } from '../../context/securityContext';
import { Button, DL, DLI, Modal, ContentLink, GoogleTranslate } from '../../shared/components';
import GlobalizedText from '../../shared/globalization';
import { FooterProps } from '../../shared/theme';
import { ajax, getContextPath } from '../../shared/utils';
import facebook from './images/facebook.png';
import googlePlus from './images/google-plus.png';
import { SubmitYourInquiry } from './submitinquiry';

const PORTAL_ADMIN_USER_ROLE = "01";

export function Footer(props: FooterProps) {
    const {showEnquiryForm} = useContext(SecurityContext);

    const [version, setVersion] = useState<any>();

    useEffect(() => {
        if (version === undefined) {
            ajax({
                url: '/api/version',
                success: (res) => {
                    setVersion(res);
                },
                error: (error: any) => {
                    setVersion({});
                },
                showMask: false,
            });
        }


    }, [version]);

    if (version === undefined) {
        return <></>
    }



    return <>
        <footer className="foot-container">
            <div className="container">
                <div className="col-wrap row">
                    <div className="foot-col col-1 col-md-12 col-lg-12 hidden-xs hidden-sm">
                        <div>
                            <div className="col-md-5">
                                <div className="row">
                                    <div className="col-md-6"> <a href="https://snpj.org/"><img width="50%" src={`${getContextPath()}/static/themes/slovene/images/logo-small.png`} /></a></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="col-box1">
                                            <li className="foot-hd"><a target="_blank" href="https://snpj.org/life-insurance/">INSURANCE</a></li>
                                            <li><a target="_blank" href="https://snpj.org/membership/">Membership</a></li>
                                            <li><a target="_blank" href=" https://snpj.org/annuities-iras/">Annuities & IRAs</a></li>
                                            <li><a target="_blank" href="https://snpj.org/policy-services/">Policy Services</a></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="col-box">
                                            <li className="foot-hd"><a target="_blank" href="https://snpj.org/about/">ABOUT SNPJ</a></li>
                                            <li><a target="_blank" href="https://snpj.org/events/">Events</a></li>
                                            <li><a target="_blank" href="https://snpj.org/policy-services/member-services-forms/">Member Services & Forms</a></li>
                                            <li><a target="_blank" href="https://snpj.org/contact/">Contact</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <img width="100%" src={`${getContextPath()}/static/themes/slovene/images/SNPJ-footer-image-cropped.jpg`} />
                            </div>
                        </div>



                    </div>
                </div>
                <div className="col-wrap row" border-top="100%">
                    <div className="foot-col col-1 col-md-12 col-lg-12 hidden-xs hidden-sm">
                        <hr />

                        <div className="row">
                        <div className="col-md-6">
                                <div className="col-md-10 snpjcompanydetails">
                                    <div>Slovene National Benefit Society</div>
                                    <div>A Fraternal Benefit Society</div >
                                    <div>Affiliated with the American Fraternal Alliance</div>                                    
                                    <br/>
                                    <div>
                                    <a target="_blank" href="https://snpj.org/site-terms-of-use/">Site Terms of Use</a>
                                    <a target="_blank" href="https://snpj.org/privacy-policy/">Privacy Policy</a>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-md-6">                                
                                    <div className="col align-self-end facebook">                                                     
                                        <a className="facebook" target="_blank" href="https://www.facebook.com/TheSNPJ/"><strong> f</strong></a>
                                    </div>
                                    <br/>
                                    <br/>
                                    <div className="col align-self-end foot-right-reserved">
                                        <p>©2023 - SNPJ | All Rights Reserved</p>
                                    </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {showEnquiryForm && <SubmitYourInquiry user={props.user} supportedFileTypes={props.userService.rootComponent.state.supportedFileTypes
        } />}
        <Modal title="common.lbl.portalversion" id="version_popup" footer={() => {
            return <>
                <Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></Button></>
        }}>
            <DL>
                <DLI condition={version.productVersion !== undefined} title="common.lbl.productversion" desc={version.productVersion} descId="productVersion"></DLI>
                <DLI condition={version.buildNumber !== undefined} title="common.lbl.buildnumber" desc={version.buildNumber} descId="buildNumber"></DLI>
                <DLI condition={version.buildTime !== undefined} title="common.lbl.buildtime" desc={version.buildTime} descId="buildTimestamp"></DLI>
                <dt><span>History</span></dt>
                <dd><a href="/delta">Delta File Link</a></dd>
            </DL>
        </Modal>
    </>
}


import React from 'react';
import { ajax } from '../../utils';
export interface EOBViewerObject {
    Component: () => JSX.Element;
    showPDF: (url?: string) => void;
}

export const EOBViewer = (props: any): EOBViewerObject => {
	const pdfViewer = props.usePDFViewer({ ...props, ...{ url: '/api/eob/download/aaa' } });	

	return {
        Component: () => {
            return <pdfViewer.Component></pdfViewer.Component>
        },
        showPDF: (claimProfile: any) => {
			ajax({
				url: `/api/eob/download/${claimProfile.companyCode}/${claimProfile.groupNumber}/${claimProfile.accountNumber}/${claimProfile.memberID}/${claimProfile.claimNumber}`,
				params: {},
				success: (data: any) => { 
					pdfViewer.showPDF(data.pdfUrl);
				}
			});
        }
    }
}
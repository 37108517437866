import React from 'react';
import GlobalizedText from '../../shared/globalization';


const UserInquiryAssignmentComponent = (props: any) => {
    const { setAgentSupp, agentSupp, setDependentSupp, dependentSupp, setGroupAdminSupp, groupAdminSupp, setGuestSupp, guestSupp, setMemberSupp, memberSupp, setProviderSupp, providerSupp, isEmptyStr, userTypeRegCode, userID } = props;
    return <>
        <props.Panel condition={userTypeRegCode === '05' && !isEmptyStr(userID)}>
            <props.PanelHeading>
                <span className="panel-title"><GlobalizedText message="profile.lbl.usr.inq.assign" /></span>
            </props.PanelHeading>
            <props.PanelBody>
                <div className="marginbottom10px"><props.Icon type="warning" size="xs" /><span className="information gwp-information gwp-icon-warning marginbottom10px"><GlobalizedText message="profile.msg.usr.type.to.csr" /></span></div>

                <props.CheckboxControl name="agentSupport" onChange={() => { setAgentSupp(!agentSupp); }} checked={agentSupp ? true : false} /><GlobalizedText className="gwp-label margintop5px fontgray" message="common.lbl.agent" />
                <props.CheckboxControl name="dependentSupport" onChange={() => { setDependentSupp(!dependentSupp); }} checked={dependentSupp ? true : false} /><GlobalizedText className="gwp-label margintop5px fontgray" message="dependent_title" />
                <props.CheckboxControl name="groupAdminSupport" onChange={() => { setGroupAdminSupp(!groupAdminSupp); }} checked={groupAdminSupp ? true : false} /><GlobalizedText className="gwp-label margintop5px fontgray" message="common.lbl.groupAdministrator" />
                <props.CheckboxControl name="guestSupport" onChange={() => { setGuestSupp(!guestSupp); }} checked={guestSupp ? true : false} /><GlobalizedText className="gwp-label margintop5px fontgray" message="profile.lbl.guest" />
                <props.CheckboxControl name="memberSupport" onChange={() => { setMemberSupp(!memberSupp); }} checked={memberSupp ? true : false} /><GlobalizedText className="gwp-label margintop5px fontgray" message="regist.role.label.individual.and.member" />
                <props.CheckboxControl name="providerSupport" onChange={() => { setProviderSupp(!providerSupp); }} checked={providerSupp ? true : false} /><GlobalizedText className="gwp-label margintop5px fontgray" message="common.lbl.provider" />
            </props.PanelBody>
        </props.Panel>
    </>
}

export default UserInquiryAssignmentComponent;
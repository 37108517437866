import {
    LoginComponent,
    CoverageInquiryComponent,
    SecurityAuthorizationComponent,
    BillingPaymentDetailComponent,
    WelcomeComponent,
    PolicyDetailComponent,
    PersonalInformationComponent,
    BillingPaymentInquiryComponent,
    ProfileComponent,
    InsuranceComponent,
    InforceBusinessComponent,
    NewBusinessApplicationComponent,
    ApplicationDetailComponent,
    ApplicationActivitiesComponent,
    MemberListComponent,
    ChangeBeneficiaryComponent,
    ClaimInquiryComponent,
    PolicyDocumentComponent,
    CoverageSummaryComponent,
    ClaimInquiry4MemberComponent,
    ClaimDetailComponent,
    AddPersonComponent,
    ManageFAQComponent,
    ManageFAQCategoryComponent,
    CreateOrEditFAQCategoryComponent,
    ManageFAQQuestionComponent,
    QuestionsForCategoryComponent,
    BenefitDetailComponent,
    MakePaymentComponent,
    DirectDepositComponent,
    LicensingComponent,
    BenefitClaimDetailComponent,
    ChangePaymentMethodComponent,
    ContentViewerComponent,
    HelpBannerEditorComponent,
    SystemConfigurationComponent,
    TerminatedCoverageRecordDisplayComponent,
    NoMatchComponent,
    BulkRegistrationComponent, RegisterNewUsersComponent, ManageUserProfilesComponent, AccessPermissionComponent, AccessPermissionCompaniesComponent, AdminPendingTransComponent, FirstLogUpdateComponent, AgentSearchComponent, PendingTransactionsComponent, UpdateEnrollmentComponent, AuditTrailComponent, InitialBenefitSelectionperiodComponent, HelpComponent, HelpLocationComponent, LabelsAndMessagesComponent, PortalCategoriesComponent, MockSaveCardComponent,
    CreditCardSuccessComponent, CreditCardCancelComponent, CreditCardFailComponent,
    TransactionListComponent,
    RegistrationComponent,
    RegistrationAdminComponent,
    BillingPaymentSummaryComponent,
    PagesAndFieldsConfigComponent,
    UpdatePayrollScheduleComponent,
    OpenEnrollmentPeriodComponent,
    BillingSummaryComponent,
    TestComponent,
    SampleComponent,
    ForgotUserIdComponent,
    ManagePortalCategoriesComponent,
    AddNewMemberComponent,
    EnrollmentComponent,
    PortalCategoriesApprovementComponent,
    ChangeOwnerComponent,
    AddSecondaryAddresseeComponent,
    ChangeSecondaryAddresseeComponent,
    UpdateSecondaryAddresseeComponent,
    BeneficiaryViewInformationComponent,
    FaqComponent,
    AddBeneficiaryComponent,
    PolicyCommentsComponent,
    ForgotPasswordComponent,
    CommissionReportsComponent,
    ProductionReportsComponent,
    FaqQuestionComponent,
    AnnuityPayee,
    CognosReportsComponent,
    TransactionReview,
    OtherAdjustments,
    RedirectComponent,
    ToleranceAdjustment,
    AgentPersonalInformationComponent,
    CommissionStatementComponent,
    ReleaseInformationComponent,
    AwsReportsComponent,
    AwsDashboardListComponent,
    AwsDashboardComponent,
    DashboardListComponent,
    DashboardComponent,
    MockCashierComponent,
    MockSaveCreditCardComponent,
    MakePaymentSuccessComponent,
    MakePaymentFailureComponent,
    MakePaymentCancelComponent,
    AddCreditCardSuccessComponent,
    AddCreditCardFailureComponent,
    AddCreditCardCancelComponent,
    PortalContentComponent,
    ExportUserListComponent,
    AccessAuthorization,
    Carriers,
    OnlineFormsComponent,
    GroupReportsComponent
} from "../pages";
import { PremiumBilledComponent } from "../pages/premiumbilled";
import { AdjustmentsBilledComponent } from "../pages/adjustmentsbilled";
import { PaymentAppliedComponent } from "../pages/paymentapplied";
import { UserInquiryComponent } from "../pages/userinquiry";

export interface ComponentProps {
    /**
     * ID of the component.
     */
    id: string;
    /**
     * Online help ID of component, if not defined, then use id instead.
     */
    helpId?: string;
    /**
     * Name of the component, this can be a labels and messages key.
     */
    name: string;
    /**
     * The component would be rendered to the body position.
     */
    component: any;
    /**
     * Indicate the component would have a back to button or not right to the component title, default is false.
     */
    hasBackTo?: boolean;
    /**
     * Indicate the component woudl have breadcrumb or not, default is true.
     */
    hasBreadcrumb?: boolean;
    /**
     * Indicate that the breadcrumb would be displayed or not, default is true.
     */
    showBreadcrumb?: boolean;
    /**
     * Indicate the component would have a title displayed on the top, default is true.
     */
    hasTitle?: boolean;
    /**
     * Indicate the component would have a help banner displayed on the top right, default is false.
     */
    hasHelpBanner?: boolean;
    /**
     * Indicate the component would have a online help icon right to the page title, default is true.
     */
    hasOnlineHelp?: boolean;
    /**
     * Indicate the component would have a portal content displayed on the left, default is false.
     */
    hasPortalContent?: boolean;
    /**
     * Indicate whether the component is protected or not.
     */
    isProtected?: boolean;
    /**
     * Fully match the url
     */
    exact?: boolean;

    /**
     * Regex to match the URL
     */
    regex?: RegExp;
}

export interface ComponentConfig {
    [url: string]: ComponentProps
}

export interface RouterConfig {
    authenticated: ComponentConfig,
    unauthenticated: ComponentConfig,
    all: ComponentConfig
}

let authenticated: ComponentConfig = {
    '/coverageInquiry': { id: 'coverageInquiry', name: 'sc.search.inquiry', component: CoverageInquiryComponent },
    '/searchForCoverage': { id: 'searchForCoverage', name: 'common.lbl.searchForCoverage', component: CoverageInquiryComponent },
    '/securityAuthorization': { id: 'securityAuthorization', name: 'security.lbl.authorization', component: SecurityAuthorizationComponent },
    '/billingPaymentDetail/:companyCode/:policyNumber': { id: 'paymentDetail', name: 'payment.lbl.paymentDetialTitle', component: BillingPaymentDetailComponent, hasBackTo: true },
    '/policyDetail/:companyCode/:policyNumber': { id: 'policyDetail', name: 'pd.lbl.policydetail', component: PolicyDetailComponent, hasBackTo: true },
    '/personalInformation': { id: 'personalInformation', helpId: 'personalInformation', name: 'common.lbl.personalInformation', component: PersonalInformationComponent },
    '/agentPersonalInformation': { id: 'agentpersonalinformation', helpId: 'personalInformation', name: 'common.lbl.personalInformation', component: AgentPersonalInformationComponent },
    '/billingPaymentInquiry': { id: 'billingPaymentInquiry', name: 'common.lbl.billingPaymentInquiry', component: BillingPaymentInquiryComponent },
    '/myprofile': { id: 'myprofile', name: 'common.lbl.myProfile', component: ProfileComponent, hasBreadcrumb: false },
    '/profile/:userID': { id: 'profile', name: 'common.lbl.editProfile', component: ProfileComponent, hasBreadcrumb: true },
    '/insurance': { id: 'insurance', name: 'my.ins.title', component: InsuranceComponent },
    '/inforceBusiness': { id: 'inforceBusiness', name: 'ib.lbl.module', component: InforceBusinessComponent, hasBackTo: true },
    '/newBusinessApplications': { id: 'newBusinessApplications', name: 'common.nbapplications', component: NewBusinessApplicationComponent, hasBackTo: true, hasTitle: true },
    '/applicationDetail': { id: 'applicationDetail', name: 'app.details.title', component: ApplicationDetailComponent, hasBackTo: true },
    '/applicationActivities': { id: 'applicationActivities', name: 'app.activities.title', component: ApplicationActivitiesComponent, hasBackTo: true },
    '/memberList': { id: 'memberList', name: 'memlist.titlename', component: MemberListComponent, hasBackTo: true },
    '/claimInquiry': { id: 'claimInquiry', name: 'common.lbl.claimInquiry', helpId: 'claimInquiry', component: ClaimInquiryComponent },
    '/memberClaimInquiry': { id: 'claimInquiryMember', name: 'common.lbl.claimInquiry', helpId: 'claimInquiry', component: ClaimInquiry4MemberComponent, hasBackTo: true },
    '/policyDocument/:policyNumber': { id: 'policyDocument', name: 'common.lbl.searchpolicydocuments', component: PolicyDocumentComponent, hasBackTo: true },
    '/coverageSummary': { id: 'coverageSummary', name: 'common.lbl.coverageSummary', component: CoverageSummaryComponent, hasBackTo: true },
    '/claimDetail': { id: 'claimDetail', name: 'common.lbl.claim.details', component: ClaimDetailComponent, hasBackTo: true },
    '/addPerson/:role': { id: 'addPerson', name: '', component: AddPersonComponent },
    '/directDeposit': { id: 'directDeposit', name: 'diredep.titlename', component: DirectDepositComponent, hasBackTo: true },
    '/licensing': { id: 'licensing', name: 'licensing.titlename', component: LicensingComponent, hasBackTo: true },
    '/helpBanner': { id: 'helpBanner', name: 'common.lbl.helpbanner', component: HelpBannerEditorComponent },
    '/benefitClaimDetail': { id: 'benefitClaimDetail', name: 'common.lbl.claimDetail', component: BenefitClaimDetailComponent, hasBackTo: true },
    '/faqQuestion': { id: 'faqQuestion', name: 'Frequently Asked Questions', component: FaqQuestionComponent, hasTitle: true, hasBreadcrumb: false, hasOnlineHelp: false },
    '/faq': { id: 'faq', name: 'Frequently Asked Questions', component: FaqComponent, hasTitle: true, hasBreadcrumb: false, hasOnlineHelp: false },
    '/manageFaq': { id: 'manageFAQ', name: 'common.lbl.faq', component: ManageFAQComponent },
    '/manageFaqCategory': { id: 'manageFaqCategory', name: 'common.lbl.manageCategory', component: ManageFAQCategoryComponent },
    '/createFaqCategory': { id: 'createFaqCategory', name: 'common.lbl.createFaqCategory', component: CreateOrEditFAQCategoryComponent },
    '/editFaqCategory/:categoryId': { id: 'editFaqCategory', name: 'common.lbl.editFaqCategory', component: CreateOrEditFAQCategoryComponent },
    '/manageFaqQuestion': { id: 'manageFaqQuestion', name: 'common.lbl.manaQuestions', component: ManageFAQQuestionComponent },
    '/questionsForCategory': { id: 'questionsForCategory', name: 'Questions For Category', component: QuestionsForCategoryComponent, hasTitle: false, hasOnlineHelp: false },
    '/benefitDetail': { id: 'benefitDetail', name: 'common.lbl.benefitDetail', component: BenefitDetailComponent, hasBackTo: true },
    '/changePaymentMethod/:companyCode/:policyNumber': { id: 'changePaymentMethod', name: 'common.lbl.payment.information', component: ChangePaymentMethodComponent, hasTitle: false },

    '/makePayment/:companyCode/:policyNumber': { id: 'makePayment', name: 'common.lbl.makePayment', component: MakePaymentComponent, hasBackTo: false, hasTitle: false },
    '/systemConfiguration': { id: 'systemConfiguration', name: 'sysconf.title', component: SystemConfigurationComponent },
    '/terminatedCoverageRecordDisplay': { id: 'terminatedCoverageRecordDisplay', name: 'common.lbl.terminatedCoverageRecordDisplay', component: TerminatedCoverageRecordDisplayComponent, hasBackTo: true },
    '/registraterNewUsers': { id: 'registerNewUsers', name: 'regist.label.register.users', component: RegisterNewUsersComponent },
    '/bulkRegistration': { id: 'bulkRegistration', name: 'common.lbl.bulkregistration', component: BulkRegistrationComponent, hasBackTo: false },

    '/manageUserProfiles': { id: 'manageUserProfiles', name: 'common.lbl.manageUserProfiles', component: ManageUserProfilesComponent },
    '/accessPermission/:userId': { id: 'accessPermission', name: 'labelMessage.accessPermission', component: AccessPermissionComponent },
    '/accessPermissionCompanies/:userId': { id: 'accessPermissionCompanies', name: 'labelMessage.accessPermission', component: AccessPermissionCompaniesComponent },
    '/adminPendingTrans': { id: 'adminPendingTrans', name: 'common.lbl.transactionList', component: AdminPendingTransComponent },
    '/firstLogUpdate': { id: 'firstLogUpdate', name: 'firupd.welcome', component: FirstLogUpdateComponent, hasBreadcrumb: true, showBreadcrumb: false },
    '/agentSearch': { id: 'agentSearch', name: 'as.lbl.agentsearch', component: AgentSearchComponent },
    '/userInquiry': { id: 'userInquiry', name: 'homepage.userInquiry', component: UserInquiryComponent },
    '/enrollmentTransactionList': { id: 'transactionList', name: 'common.lbl.enrollmenttransactionlist', component: TransactionListComponent },
    '/pendingTransactions': { id: 'pendingTransactions', name: 'common.lbl.pendingtransactions', component: PendingTransactionsComponent, hasTitle: false, hasBreadcrumb: false },
    /**mock save credit for wallet config */
    '/mocksavecard': { id: 'mocksavecard', name: 'method.mock.title', component: MockSaveCardComponent, hasTitle: false, hasBreadcrumb: false },
    '/mockcashier': { id: 'mockcashier', name: 'method.mock.title', component: MockCashierComponent, hasTitle: false, hasBreadcrumb: false },
    '/mocksavecreditcard': { id: 'mocksavecreditcard', name: 'method.mock.title', component: MockSaveCreditCardComponent, hasTitle: false, hasBreadcrumb: false },
    '/creditcardsuccess': { id: 'creditcardsuccess', name: 'method.mock.title', component: CreditCardSuccessComponent, hasTitle: false, hasBreadcrumb: false },
    '/creditcardcancel': { id: 'creditcardcancel', name: 'method.mock.title', component: CreditCardCancelComponent, hasTitle: false, hasBreadcrumb: false },
    '/creditcardfail': { id: 'creditcardfail', name: 'method.mock.title', component: CreditCardFailComponent, hasTitle: false, hasBreadcrumb: false },
    '/updateEnrollment': { id: 'updateEnrollment', name: 'common.lbl.updateEnrollment', component: UpdateEnrollmentComponent },
    '/auditTrail': { id: 'auditTrail', name: 'common.lbl.auditTrail', component: AuditTrailComponent },
    '/initialBenefitSelectionperiod': { id: 'initialBenefitSelectionperiod', name: 'initial.benefit.section.period', component: InitialBenefitSelectionperiodComponent },
    '/help': { id: 'help', name: 'common.lbl.onlineHelp', component: HelpComponent },
    '/helpLocation': { id: 'helpLocation', name: 'common.lbl.helpLocation', component: HelpLocationComponent },
    '/labelsMessages': { id: 'labelsMessages', name: 'common.lbl.labelsmessages', component: LabelsAndMessagesComponent },
    '/portalCategories': { id: 'portalCategories', helpId: 'portalCategories', name: 'common.lbl.portalcategories', component: PortalCategoriesComponent },
    '/portalCategoriesPendingApprove': { id: 'portalCategoriesPendingApprove', helpId: 'portalCategories', name: 'common.lbl.portalcategories', component: PortalCategoriesApprovementComponent },
    '/userRegistration': { id: 'userRegistration', helpId: 'registration', name: 'regist.label.register.admin', component: RegistrationComponent, hasBackTo: false },
    '/adminRegistration': { id: 'adminRegistration', helpId: 'registration', name: 'regist.label.register.administrator', component: RegistrationAdminComponent, hasBackTo: false },
    '/billingPaymentSummary/:companyCode/:groupNumber/:accountNumber/:participantIDNumber': { id: 'billingPaymentSummary1', helpId: 'billingPaymentSummary', name: 'billpay.summary', component: BillingPaymentSummaryComponent, hasBackTo: true },
    '/billingPaymentSummary/:companyCode/:groupNumber/:accountNumber': { id: 'billingPaymentSummary', helpId: 'billingPaymentSummary', name: 'billpay.summary', component: BillingPaymentSummaryComponent, hasBackTo: true },
    '/pagesAndFieldsConfig': { id: 'pagesAndFieldsConfig', name: 'hidefields.lbl.title', component: PagesAndFieldsConfigComponent },
    '/updatePayrollSchedule': { id: 'updatePayrollSchedule', name: 'common.lbl.updatepayrollschedule', component: UpdatePayrollScheduleComponent },
    '/openEnrollmentPeriod': { id: 'openEnrollmentPeriod', name: 'open.enrollment.period', component: OpenEnrollmentPeriodComponent },
    '/billingSummary': { id: 'billingSummary', name: 'billpay.summary.title', component: BillingSummaryComponent, hasBackTo: true },
    '/managePortalCategories': { id: 'managePortalCategories', name: 'Manage.Portal.Categories', component: ManagePortalCategoriesComponent },
    '/NewEnrollment': { id: 'addNewMember', name: 'common.lbl.addNewMember', component: AddNewMemberComponent },
    '/enrollment': { id: 'enrollment', name: 'Enrollment', component: EnrollmentComponent },
    '/premiumBilled': { id: 'premiumBilled', name: 'common.lbl.premiumBilled', component: PremiumBilledComponent, hasBackTo: true },
    '/adjustmentsBilled': { id: 'adjustmentsBilled', name: 'common.lbl.adjbilled', component: AdjustmentsBilledComponent, hasBackTo: true },
    '/paymentApplied': { id: 'paymentApplied', name: 'billpay.paymentApplied.title', component: PaymentAppliedComponent, hasBackTo: true },
    '/addSecondaryAddressee': { id: 'addSecondaryAddressee', helpId: 'addUpdateSecondaryAddressee', name: 'addsecaddr.lbl.title', component: AddSecondaryAddresseeComponent },
    '/changeSecondaryAddressee': { id: 'changeSecondaryAddressee', name: 'addsecaddr.lbl.change', component: ChangeSecondaryAddresseeComponent },
    '/updateSecondaryAddressee': { id: 'updateSecondaryAddressee', helpId: 'addUpdateSecondaryAddressee', name: 'addsecaddr.lbl.title', component: UpdateSecondaryAddresseeComponent },
    '/changeOwner': { id: 'changeOwner', name: 'changeow.lbl.title', component: ChangeOwnerComponent },
    '/beneficiaryViewInformation': { id: 'beneficiaryViewInformation', helpId: 'beneficiaryInformation', name: 'common.lbl.beneficiaryInfo', component: BeneficiaryViewInformationComponent, hasBackTo: true },
    '/beneficiary/:companyCode/:policyNumber': { id: 'beneficiary', helpId: 'beneficiaryInformation', name: 'common.lbl.beneficiaryInfo', component: ChangeBeneficiaryComponent, hasBackTo: true },
    '/addBeneficiary': { id: 'addBeneficiary', name: 'addbene.lbl.title', component: AddBeneficiaryComponent },
    '/policyComments': { id: 'policyComments', name: 'lbl.policy.comments', component: PolicyCommentsComponent, hasBackTo: true },
    '/commissionReports': { id: 'commissionReports', name: 'common.lbl.commissionReports', component: CommissionReportsComponent, hasBackTo: true },
    '/productionReports': { id: 'productionReports', name: 'common.lbl.productionReports', component: ProductionReportsComponent, hasBackTo: true },
    '/annuityPayee': { id: 'annuityPayee', name: 'vap.lbl.ap', component: AnnuityPayee, hasBackTo: true },
    '/cognosReport': { id: 'cognosReport', name: '', component: CognosReportsComponent, hasBackTo: true },
    '/toleranceAdjustments': { id: 'toleranceAdjustments', name: 'billpay.toladjust', component: ToleranceAdjustment, hasBackTo: true },
    '/transactionReview': { id: 'transactionReview', name: 'common.lbl.reviewTran', component: TransactionReview, hasBackTo: false },
    '/commissionStatement': { id: 'commissionStatement', name: 'common.lbl.commissionStatements', component: CommissionStatementComponent, hasBackTo: true },
    '/otherAdjustments': { id: 'otherAdjustments', name: 'billpay.summary.otherAdjustments', component: OtherAdjustments, hasBackTo: true },
    '/awsReport': { id: 'awsReport', name: '', component: AwsReportsComponent, hasBackTo: true },
    '/awsDashboardList': { id: 'awsDashboardList', name: 'Dashboard List', component: AwsDashboardListComponent, hasBackTo: true },
    '/dashboardList': { id: 'dashboardList', name: 'Dashboards List', component: DashboardListComponent, hasBackTo: true },
    '/awsDashboard': { id: 'awsDashboardList', name: 'Dashboard', component: AwsDashboardComponent, hasBackTo: true },
    '/Dashboard': { id: 'Dashboard', name: 'Dashboard', component: DashboardComponent, hasBackTo: true },
    '/makePaymentSuccess': { id: 'makePaymentSuccess', name: '', component: MakePaymentSuccessComponent, hasTitle: false, hasBreadcrumb: false },
    '/makePaymentFailure': { id: 'makePaymentFailure', name: '', component: MakePaymentFailureComponent, hasTitle: false, hasBreadcrumb: false },
    '/makePaymentCancel': { id: 'makePaymentCancel', name: '', component: MakePaymentCancelComponent, hasTitle: false, hasBreadcrumb: false },
    '/addCreditCardSuccess': { id: 'addCreditCardSuccess', name: '', component: AddCreditCardSuccessComponent, hasTitle: false, hasBreadcrumb: false },
    '/addCreditCardFailure': { id: 'addCreditCardFailure', name: '', component: AddCreditCardFailureComponent, hasTitle: false, hasBreadcrumb: false },
    '/addCreditCardCancel': { id: 'addCreditCardCancel', name: '', component: AddCreditCardCancelComponent, hasTitle: false, hasBreadcrumb: false },
    '/portalcontent': { id: 'help', name: 'common.lbl.portalContentManagement', component: PortalContentComponent },
    '/exportUserList': { id: 'exportUserList', helpId: 'exportUserList', name: 'common.lbl.exportUserList', component: ExportUserListComponent },
    '/accessAuthorization': { id: 'accessAuthorization', name: 'my.access.title', component: AccessAuthorization },
    '/groupreports': { id: 'groupreports', name: 'Group Reports', hasOnlineHelp: true, helpId: 'groupreportsId', component: GroupReportsComponent },
    '/carriers': { id: 'carriers', name: 'common.carriers.title', hasOnlineHelp: true, helpId: 'carriersId', component: Carriers },
    '/onlineforms': { id: 'onlineforms', name: 'Online Forms', component: OnlineFormsComponent, hasBackTo: true },
};
let unauthenticated: ComponentConfig = {

    '/registration': { id: 'registration', name: 'regist.label.register', component: RegistrationComponent, hasBackTo: false, isProtected: false },
    '/login': { id: 'login', name: 'Login', component: LoginComponent, isProtected: false, hasBreadcrumb: false, hasTitle: false, hasHelpBanner: false },
    '/forgotuserid': { id: 'forgotuserid', name: 'common.lbl.forgotUserID', component: ForgotUserIdComponent, isProtected: false, hasBackTo: false },
    '/forgotpassword': { id: 'forgotPassword', name: 'forgpass.label.title', component: ForgotPasswordComponent, isProtected: false, hasBackTo: false }
};
let all: ComponentConfig = {
    '/inlineContentViewer': { id: 'inlineContentViewer', name: '', component: ContentViewerComponent, hasTitle: false, showBreadcrumb: false },
    '/': { id: 'welcome', exact: true, name: 'Welcome to Slovene', isProtected: false, component: WelcomeComponent, hasBreadcrumb: false, hasHelpBanner: false, hasTitle: false },
    '/content': { id: 'contentViewer', name: 'Content Viewer', component: ContentViewerComponent, hasTitle: false, showBreadcrumb: false },
    '/index.html': { id: 'welcome', exact: true, name: 'Welcome to Slovene', isProtected: false, component: WelcomeComponent, hasBreadcrumb: false, hasHelpBanner: false, hasTitle: false },
    '/welcome': { id: 'welcome', exact: true, name: 'Welcome to Slovene', isProtected: false, component: WelcomeComponent, hasBreadcrumb: false, hasHelpBanner: false, hasTitle: false },
    '/test': { id: 'test', name: 'Annuity', component: TestComponent },
    '/faqQuestion': { id: 'faqQuestion', name: 'Frequently Asked Questions', component: FaqQuestionComponent, hasTitle: true, hasBreadcrumb: false, hasOnlineHelp: false },
    '/faq': { id: 'faq', name: 'Frequently Asked Questions', component: FaqComponent, hasTitle: true, hasBreadcrumb: false, hasOnlineHelp: false },
    '/redirect': { id: 'redirect', name: 'Redirect', component: RedirectComponent, hasTitle: false, hasBreadcrumb: false, hasOnlineHelp: false },
    '/releaseInformation': { id: 'releaseInformation', name: 'Release Information', component: ReleaseInformationComponent, hasTitle: false },
    '/sample/sample': { id: 'sample', name: 'Sample Page', component: SampleComponent },
    '/onlineHelp': { id: 'onlineHelp', name: 'common.lbl.onlineHelp', component: ContentViewerComponent, hasTitle: false, showBreadcrumb: false },
    '*': { id: '404', name: '404 - Page not found.', isProtected: false, component: NoMatchComponent, hasBreadcrumb: false, hasHelpBanner: false, hasTitle: false },
};

function generateRegex(componentConfig: ComponentConfig) {
    for (let cc in componentConfig) {
        componentConfig[cc].regex = new RegExp("^" + cc.split("/").map((cc1) => {
            return cc1 === "*" ? "\\.*" : (cc1.indexOf(":") !== -1 ? '[a-z0-9]+' : cc1)
        }).join("/") + "$", "i")
    }
}

generateRegex(authenticated);
generateRegex(unauthenticated);
generateRegex(all);

let RouteConfig: RouterConfig = {
    authenticated: { ...authenticated },
    unauthenticated: { ...unauthenticated },
    all: { ...all }
}


export default RouteConfig;
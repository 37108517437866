import React, { useState, ErrorInfo } from 'react';
import GlobalizedText from './globalization';
import { $ } from './utils';
interface ErrorBoundaryState {
  error: Error | null,
  errorInfo: ErrorInfo | null;
}
export class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export function showErrorModal(error: string, message?: string) {
  $("#globalErrorModal .modal-body pre").html(error + "<br/>" + message);
  $("#globalErrorModal").modal('show');
}

export function ErrorModal(props: any) {
  const [message] = useState(null);
  return (
    <div className="modal fade" id="globalErrorModal" role="dialog" aria-labelledby="errorModalTitle">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 className="modal-title" id="errorModalTitle"><GlobalizedText message="benefit.error" /></h4>
          </div>
          <div className="modal-body">
            <pre>{message}</pre>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></button>
          </div>
        </div>
      </div>
    </div>
  );
}
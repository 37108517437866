import React from 'react'

const ContactMethodsDropdown = (props: any) => {
    return (
        <props.SelectControl id="conMeth" name="conMeth" required={false} defaultOption={"Undecided"} label="pi.prefered.contract.method" options={props.contactMethods}>
        </props.SelectControl>
    )
}

export default ContactMethodsDropdown;

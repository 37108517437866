import React from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';


export const CommissionStatementComponent = withView((props: ViewComponentProps) => {

    return (
        <object style={{ width: "100%", height: "400px" }} type='application/pdf' data={`/api/commissionStatement/showPdf`} />
    );
});

import React, { useEffect, useRef, useState } from 'react';
import { UserContext } from '../../../../shared/authentication';
import { Link } from '../../../../shared/components';
import GlobalizedText from '../../../../shared/globalization';
import { $, ajax, win } from '../../../../shared/utils';
import logo from './images/logo.png';
import Menu from './menu';
import '../css/header.css';
function LoginButton(props: any) {
    if (props.user !== null) {
        return <></>;
    }
    return <Link to="/login" className="btn-utility btn-uti-l"><GlobalizedText message="common.lbl.login" /></Link>;
}
function LogoutButton(props: any) {
    if (props.user === null) {
        return <></>;
    }
    return <Link to="/login" onClick={() => { props.userService.signout() }} className="btn-utility btn-uti-l"><GlobalizedText message="common.lbl.logout" /></Link>;
}
function RegistrationButton(props: any) {
    if (props.user !== null) {
        return <></>;
    }
    return <Link to={"/registration"} forceRefresh={true} className="btn-utility btn-uti-l"><span><GlobalizedText message="login.lbl.signup" /></span></Link>;
}

function Name(props: any) {
    if (props.user === null) {
        return <></>;
    }
    return <Link to="/myprofile" className="u-split">{props.user.firstName} {props.user.lastName}</Link>
}

function Search(props: any) {
    return <></>
    /*
    return <>
            <form className="gwp-header-collaps-search" action=".">
                <input value="searchCenter:query" name="uri" type="hidden" />
                <input type="hidden" name="contentNode" value="com.concentrix.webportal.search" />
                <input type="hidden" name="layoutNode" value="com.concentrix.webportal.Search Portlet Window" />
                <div className="input-group">
                    <input type="text" className="form-control form-search" name="query" placeholder="Search..." />
                    <span className="input-group-btn">
                        <button className="btn btn-default form-search" type="button">
                            <span className="glyphicon glyphicon-search" aria-hidden="true"></span>
                        </button>
                    </span>
                </div>
            </form>
        </>
    */
}

export function adjustHeaderHeight() {
    if ($(win).width() < 768) {
        $(".gwp-portlet-container").css("margin-top", ($("header").height() + "px"));
        $("#root>.gwp-page-content").css("margin-top", ($("header").height() + "px"));
    } else {
        $(".gwp-portlet-container").css("margin-top", "");
        $("#root>.gwp-page-content").css("margin-top", "");
    }
}

export function autoAdjustHeaderHeight() {
    $(win).on("resize", (e: any) => {
        adjustHeaderHeight();
    })
}

export function Header(props: any) {
    const timer = useRef<any>(undefined);
    const [showRegistrationButton, setShowRegistrationButton] = useState<boolean>(true);
    useEffect(() => {
        if (timer.current === undefined) {
            timer.current = win.setInterval(() => {
                if ($("header").height() !== 0) {
                    adjustHeaderHeight();
                    if (timer.current !== undefined) {
                        win.clearInterval(timer.current);
                    }
                }
            }, 10);
            autoAdjustHeaderHeight();
            ajax({
                url: '/api/registrationSingle/view',
                success: (viewConfig) => {
                    if (viewConfig.userTypeRegCodes.length === 0) {
                        setShowRegistrationButton(false);
                    }
                }
            });
        }
    })    
    return (
        <header className="gwp-header">
            <div className="gwp-header-logobar">
                <div className="container ">
                    <div className="navbar-uptop">
                        <div className="nav-container">
                            <div className="navbar-logo">
                                <a href="https://snpj.org/" rel="noopener noreferrer" target="_blank"><img src={logo} alt="Slovene National Benefit Society" /></a>
                            </div>
                            <div className="navbar-utility">


                                <UserContext.Consumer>
                                    {user => (
                                        <Name user={user} />
                                    )}
                                </UserContext.Consumer>
                                <UserContext.Consumer>
                                    {user => (
                                        <React.Fragment>
                                            {
                                                /*<SSOButton user={user} />*/
                                            }
                                            <LoginButton user={user} userService={props.userService} />
                                            <LogoutButton user={user} userService={props.userService} />
                                            {
                                                showRegistrationButton === true && <>
                                                    <RegistrationButton user={user} />
                                                </>}
                                        </React.Fragment>
                                    )}
                                </UserContext.Consumer>
                                <a className="u-split u-social hidden-xs hidden-sm" href="http://www.facebook.com"><span className="u-facebook"></span><div className="hidden">facebook</div></a>
                             </div>
                         </div>
                    </div>
                </div>
            </div>


            <div className="container gwp-header-menubar">
                <div className="gwp-header-menubar-header hidden-sm hidden-md hidden-lg">
                    <div className="row">
                        <div className="col-xs-9">
                            <Search />
                        </div>


                        <div className="col-xs-3">
                            <button type="button" className="navbar-toggle collapsed" onClick={() => { $("#navbar").collapse('toggle') }} aria-expanded="false" aria-controls="navbar">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                    </div>
                </div>

                <div id="navbar" className="collapse navbar-collapse gwp-header-menubar-body">
                    <div className="row">
                        <Menu breadcrumb={props.breadcrumb} isShowHome={props.userService.rootComponent.state.isShowHome} />
                        <div className="col-md-4 col-sm-5 hidden-xs">
                            <Search />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

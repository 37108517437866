import { Form, Formik } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import { INIT_PARAM_PREFIX_TABLE, TableCell, TableColumn, useTable } from '../shared/components';
import GlobalizedText from '../shared/globalization';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import './css/claiminquirymember.css';
import { ajax, copyObjectExcept, isEmptyStr, saveToStorage } from '../shared/utils';
import { Config } from '../config/config';
import claimInquiry4MemberViewResponse from '../shared/interfaces/claimInquiry4MemberViewResponse';
import claimInquiryForm from '../shared/interfaces/claimInquiryForm'


const retrieve = (values: any, config: any, columns: any, setData: any, props: any): void => {
    let params = copyObjectExcept(values, true, INIT_PARAM_PREFIX_TABLE);
    ajax({
        url: '/api/claiminquirymember/fetchForMember',
        params: params,
        success: (data: any) => {
            setData(data);
        },
        error: (error: any) => {

        }
    });
}

export const ClaimInquiry4MemberComponent = withView((props: ViewComponentProps) => {

    const [config, setConfig] = useState<claimInquiry4MemberViewResponse>();
    const [tableData, setData] = useState<Array<any>>();
    const pdfViewer = props.usePDFViewer({ ...props, ...{ url: '/api/claiminquiry/downloadEob/aaa' } });
    const eobViewer = props.EOBViewer({ ...props});
    const columns: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "2%" },
        {
            title: "common.lbl.claimNumber", name: "claimNumber", width: "8%", render: (data: any, type: any, row: any, meta: any): string => {
                return `<a dt-href='/claimDetail?companyCode=${row.companyCode.trim()}&groupNumber=${row.groupNumber}&accountNumber=${row.accountNumber}&participantIDNumber=${row.memberID}&julianYear=${row.julianYear}&sequenceNumber=${row.sequenceNumber}&suffix=${row.suffix}&claimNumber=${row.claimNumber}&adjustmentNumber=${isEmptyStr(row.adjustmentNumber) ? '-999' : row.adjustmentNumber}&providerPaymentNumber=${isEmptyStr(row.providerPaymentNumber) ? '-999' : row.providerPaymentNumber}&dependentSequenceNumber=${row.dependentSequenceNumber}&julianDay=${row.julianDay}'>${row.claimNumber}</a>`
            }, onClick: (e: any, row: any): void => {
                let orderTableData = [];
                for (var i = 0; i < claimInquiryResult.getOrder().length; i++) {
                    if (tableData !== undefined) {
                        orderTableData.push(tableData[claimInquiryResult.getOrder()[i]]);
                    }
                }
                saveToStorage("claimresultlist", orderTableData, true);
            }
        },
        { title: "common.lbl.patient", name: "patient", width: "13%" },
        { title: "common.lbl.memberIdentifier", name: "memberID", width: "10%" },
        { title: "common.lbl.doctorfacility", name: "doctorFacility", width: "20%" },
        { title: "common.lbl.servicedate", name: "serviceDate", width: "15%", type: 'date' },
        { title: "common.lbl.submitted", name: "total", width: "8%", type: 'numeric' },
        { title: "common.lbl.paid", name: "paid", width: "8%", type: 'numeric' },
        { title: "common.lbl.status", name: "status", width: "8%" },
        {
            title: "common.lbl.eob", name: "eob", width: "8%", render: (data: any, type: any, row: any, meta: any): string => {
                var eobFlag = row.eobFlag;
                if (eobFlag) {
                    return "<span class='gwp-icon gwp-icon-view-statement gwp-icon-sm' style='cursor:pointer'></span>";
                } else {
                    return "";
                }
            }, onClick: (e: any, row: any): void => {
                eobViewer.showPDF(row);
            }
        },
    ];

    let fromPage = props.getQueryParam("from");
    useEffect(() => {
        if (config === undefined) {
            ajax({
                url: '/api/claiminquirymember/view',
                params: {
                    fromPage: props.getQueryParam("from"),
                    companyCode: props.getQueryParam("companyCode"),
                    groupNumber: props.getQueryParam("groupNumber"),
                    accountNumber: props.getQueryParam("accountNumber"),
                    participantIDNumber: props.getQueryParam("participantIDNumber"),
                    dependentSequenceNumber: props.getQueryParam("dependentSequenceNumber"),
                    agentNumber: props.getQueryParam("agentNumber")
                },
                success: (res: claimInquiry4MemberViewResponse) => {
                    setConfig(res);
                    if (props.getInitParams() !== undefined) {
                        let values: claimInquiryForm = props.getInitParams();
                        retrieve(values, config, columns, setData, props);
                    } else {
                        let initValues = {
                            patient: res.patientList[0].value,
                            status: res.statusList[0].value,
                            serviceDate: '',
                            fromPage: res.fromPage,
                            companyCode: res.companyCode,
                            groupNumber: res.groupNumber,
                            accountNumber: res.accountNumber,
                            participantIDNumber: res.participantIDNumber,
                            dependentSequenceNumber: res.dependentSequenceNumber,
                            agentNumber: res.agentNumber
                        }
                        retrieve(initValues, config, columns, setData, props);
                    }

                }
            });
        }
    }, [config]);

    const claimInquiryResult = useMemo(() => useTable({
        id: 'claimInquiryResult',
        table: {
            columns: columns,
            data: tableData
        },
        onResponsiveDisplay: (rowNumber: number, rowData: any, cells: Array<TableCell>, childNode: Element) => {
            childNode?.querySelector(".gwp-icon.gwp-icon-view-statement")?.addEventListener("click", (e: any) => {
                eobViewer.showPDF(rowData);
            });
        },
        dateformat: props.getDateFormat(),
        setInitParams: props.setInitParams,
        getInitParams: props.getInitParams
    }), [tableData]);

    if (config === undefined) {
        return <></>
    }

    const defaultInitialValues = {
        patient: config.patientList[0].value,
        status: config.statusList[0].value,
        serviceDate: '',
        fromPage: config.fromPage,
        companyCode: config.companyCode,
        groupNumber: config.groupNumber,
        accountNumber: config.accountNumber,
        participantIDNumber: config.participantIDNumber,
        dependentSequenceNumber: config.dependentSequenceNumber,
        agentNumber: config.agentNumber
    }

    let initialValue: claimInquiryForm = props.getInitParams() !== undefined ? props.getInitParams() : defaultInitialValues;

    // define Form Submitting function.
    const formSubmit = (values: any): void => {
        props.setInitParams(values);
        retrieve(values, config, columns, setData, props);
    }

    return <>
        <Formik initialValues={initialValue}
            onSubmit={formSubmit}
        >
            {formProps => {
                return <Form>
                    <props.Row>
                        <props.Col sm="12">
                            <props.Label><GlobalizedText message="filter.claim.by"></GlobalizedText></props.Label>
                        </props.Col>
                    </props.Row>
                    <props.Row>
                        <props.Col sm="3" md="3" xs="12">
                            <props.SelectControl sort={false} disabled={fromPage === 'coveragesummary' || props.getQueryParam("dependentSequenceNumber")} label="common.lbl.patient" name="patient" options={config.patientList}></props.SelectControl>
                        </props.Col>
                        <props.Col sm="3" md="3" xs="12">
                            <props.SelectControl label="common.lbl.status" name="status" options={config.statusList}></props.SelectControl>
                        </props.Col>
                        <props.Col sm="4" md="4" xs="12">
                            <props.DateTimePickerControl label="common.lbl.servicedate" name="serviceDate" dateformat={props.getDateFormat()}></props.DateTimePickerControl>
                        </props.Col>
                        <props.Col sm="2" md="2" xs="12" className="retrieveBtnDIV">
                            <props.Button type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                        </props.Col>
                    </props.Row>
                    <props.HR />
                    <props.Row>
                        <props.Col sm="12">
                            <claimInquiryResult.Component />
                            <pdfViewer.Component></pdfViewer.Component>
                            <eobViewer.Component></eobViewer.Component>
                        </props.Col>
                    </props.Row>
                </Form>
            }}
        </Formik>
    </>
});

import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { ProgressBarPagesConfig } from '../shared/components';
import PolicyNumber from '../shared/components/policyNumberRegistrationComponent';
import GlobalizedText from '../shared/globalization';
import { VALIDATION_REGEX } from "../shared/regex";
import { ajax, isEmptyObject, isEmptyStr, trim, trimFields, validate } from '../shared/utils';
import { ViewComponentProps, withView } from '../shared/viewcomponent';
import { INVALID_MESSAGES, YupSchema } from '../shared/yupschema';
import { FORMAT_MAPPER, FORMAT_TYPE } from '../shared/components';
import './css/forgotuserid.css';
interface preMethod {
    name: string,
    value: string
}
interface ForgotUserIdBO {
    firstMatched: boolean,
    firstErrorCode: string,
    firstMessage: string,
    encryUserID: string,
    question1: string,
    question2: string,
    question3: string,
    emailAddress: string,
    emailEnabled: boolean,
    questionEnabled: boolean,
    selMethod: string,
    preMethods: Array<preMethod>,

    userTypeRegCode: string,
    nameType: string,
    firstName: string,
    lastName: string,
    orgName: string,
    govtID: string,
    nationProvideID: string,
    agentNumber: string,
    agencyName: string,
    agencyNumber: string,
    retriveByEmail: string,
    retriveByOnline: string,
    step: number
};
interface Step {
    first: () => void;
    second: () => void;
    step: () => number;
    setStep: (step: number) => void;
};
interface PageProps extends ViewComponentProps {
    step: Step;
    forgotUserIdBO: ForgotUserIdBO;
    setForgotUserIdBO: any;
    forConfig: any;
};
const cfgProcessBar1: ProgressBarPagesConfig = {
    pages: [
        { name: 'forguid.lbl.step1', status: "current" },
        { name: 'forguid.lbl.step2', status: "unset" }
    ]
};
const cfgProcessBar2: ProgressBarPagesConfig = {
    pages: [
        { name: 'forguid.lbl.step1', status: "unset" },
        { name: 'forguid.lbl.step2', status: "current" }
    ]
};

let initforgotUserIdBO: ForgotUserIdBO = {
    firstMatched: false,
    firstErrorCode: "",
    firstMessage: "",
    encryUserID: "",
    question1: "",
    question2: "",
    question3: "",
    emailAddress: "",
    emailEnabled: false,
    questionEnabled: false,
    selMethod: "",
    preMethods: [],
    userTypeRegCode: "",
    nameType: "",
    firstName: "",
    lastName: "",
    orgName: "",
    govtID: "",
    nationProvideID: "",
    agentNumber: "",
    agencyName: "",
    agencyNumber: "",
    retriveByEmail: "",
    retriveByOnline: "",
    step: 1

};
const useStep = (props: any) => {
    const [step, setStep] = useState<number>(1);
    return {

        second: function () {
            this.setStep(2);
            props.clearMessage();
        },
        first: function () {
            this.setStep(1);
            props.clearMessage();
        },
        step: function () {
            return step;
        },
        setStep: function (s: number) {
            if (s !== undefined) {
                setStep(s);
                window.scrollTo({ left: 0, top: 0 });
            }
        }
    }
};
export const ForgotUserIdComponent = withView((props: ViewComponentProps) => {
    const step: Step = useStep(props);
    const [forgotUserIdBO, setForgotUserIdBO] = useState<ForgotUserIdBO>(initforgotUserIdBO);
    const [config, setConfig] = useState<any>();
    useEffect(() => {
        let user: ForgotUserIdBO = props.getInitParams();
        if (user !== undefined && !isEmptyObject(user)) {
            setForgotUserIdBO(user);
            step.setStep(user.step);
        }
        if (config === undefined || props.isForceRefresh()) {
            ajax({
                url: '/api/forgotuserid/view',
                success: (viewConfig) => {
                    setConfig({ ...viewConfig, ...{ retriveByEmail: "email", retriveByOnline: "online" } });
                    if (viewConfig.userTypes.length === 0) {
                        props.showMessage("error", props.getGlobalizedText("regist.label.no.register"));
                    }
                    if (props.isForceRefresh()) {
                        props.setInitParams(undefined);
                        setForgotUserIdBO(initforgotUserIdBO);
                        step.first();
                        props.refreshed();
                    }

                }
            });
        }
    }, [config, props.isForceRefresh()]);

    if (config === undefined || step.step() !== forgotUserIdBO.step) {
        return <></>
    }

    return <>
        {config.userTypes.length !== 0 && <>
            <props.Row>
                <props.Col md="12" sm="12" xs="12">
                    {step.step() === 1 &&
                        <props.ProgressBar id="forgotUserIdPB1" config={cfgProcessBar1}></props.ProgressBar>
                    }
                    {step.step() === 2 &&
                        <props.ProgressBar id="forgotUserIdPB2" config={cfgProcessBar2}></props.ProgressBar>
                    }
                </props.Col>
            </props.Row>

            <props.Row>

                <props.Col>
                    <props.Container>
                        {step.step() === 1 &&
                            <FirstPage {...props} forConfig={config} step={step} forgotUserIdBO={forgotUserIdBO} setForgotUserIdBO={setForgotUserIdBO} />
                        }
                        {step.step() === 2 &&
                            <SecondPage {...props} forConfig={config} step={step} forgotUserIdBO={forgotUserIdBO} setForgotUserIdBO={setForgotUserIdBO} />
                        }
                    </props.Container>
                </props.Col>
            </props.Row>
        </>
        }
    </>
});
export const FirstPage = (props: PageProps) => {
    const [result, setResult] = useState<any>({ title: "", message: "" });
    let initialValues = {
        ...{
            ...props.forgotUserIdBO,
            userTypeRegCode: props.forgotUserIdBO.userTypeRegCode !== '' ? props.forgotUserIdBO.userTypeRegCode : props.forConfig.userTypes[0].value,
            nameType: props.forgotUserIdBO.nameType !== '' ? props.forgotUserIdBO.nameType : props.forConfig.nameTypePerson
        }
    }
    let validIndiPerson = yup.object().shape({
        lastName: YupSchema.registration.lastName
    });
    let validIndiCompany = yup.object().shape({
        orgName: YupSchema.registration.companyName,
        govtID: YupSchema.govtID("EIN", props.getGlobalizedText)
    });
    let validAgent = yup.object().shape({
        lastName: YupSchema.registration.lastName,
        govtID: YupSchema.govtID("SSN", props.getGlobalizedText),
        agentNumber: YupSchema.registration.agentNumber
    });
    let validAgency = yup.object().shape({
        agencyName: YupSchema.registration.agencyName,
        govtID: YupSchema.govtID("EIN", props.getGlobalizedText),
        agencyNumber: YupSchema.registration.agencyNumber
    });
    let validDependent = yup.object().shape({
        lastName: YupSchema.registration.lastName,
        govtID: YupSchema.govtID("SSN", props.getGlobalizedText)
    });
    let validDependentWithMemberIDAndGroup = yup.object().shape({
        firstName: YupSchema.registration.firstName,
        lastName: YupSchema.registration.lastName,
        memberID: YupSchema.registration.dependentId,
        groupNumber: YupSchema.registration.groupNumber
    });
    
    let byProvider = (values: any) => {
        let res: any = {};        
        if(!isEmptyStr(values.govtID)){
            const govID = values.govtID.replaceAll("-","");
            let format = FORMAT_MAPPER['SSN'];
            if(govID.match(VALIDATION_REGEX.GOVT_ID) === null || isNaN(govID) ){
                res = { ...res, ...{ govtID:  props.getGlobalizedText(INVALID_MESSAGES.GOVT_ID_IS_INVALID) + " " + format.toLowerCase() + "." } };
            }                      
        }
        if ((isEmptyStr(values.govtID) || values.govtID.trim() === "0") && (isEmptyStr(values.nationProvideID) || values.nationProvideID.trim() === "0")) {
            res = { ...res, ...{ govtID: INVALID_MESSAGES.NATIONAL_PROVIDER_ID_OR_PROVIDER_GOVERNMENT_ID_IS_REQUIRED, nationProvideID: INVALID_MESSAGES.NATIONAL_PROVIDER_ID_OR_PROVIDER_GOVERNMENT_ID_IS_REQUIRED } }
        }
        if (values.nameType === props.forConfig.nameTypePerson) {
            if (isEmptyStr(values.firstName)) {
                res = { ...res, ...{ firstName: INVALID_MESSAGES.FIRSTNAME_IS_EMPTY } };
            }

            if (isEmptyStr(values.lastName)) {
                res = { ...res, ...{ lastName: INVALID_MESSAGES.LASTNAME_IS_EMPTY } };
            }

            if (!isEmptyStr(values.firstName) && trim(values.firstName).length > 20) {
                res = { ...res, ...{ firstName: INVALID_MESSAGES.FIRSTNAME_IS_TOO_LONG } };
            }
            if (!isEmptyStr(values.lastName) && trim(values.lastName).length > 40) {
                res = { ...res, ...{ lastName: INVALID_MESSAGES.LASTNAME_IS_TOO_LONG } };
            }

        }

        if (values.nameType === props.forConfig.nameTypeCompany) {
            if (isEmptyStr(values.orgName)) {
                res = { ...res, ...{ orgName: INVALID_MESSAGES.ORG_NAME_IS_EMPTY } };
            }

            if (trim(values.orgName).length > 80) {
                res = { ...res, ...{ orgName: INVALID_MESSAGES.COMPANY_NAME_IS_TOO_LONG } };
            }
        }
        if (isEmptyStr(values.govtID) && isEmptyStr(values.nationProvideID)) {
            res = { ...res, ...{ govtID: INVALID_MESSAGES.NATIONAL_PROVIDER_ID_OR_PROVIDER_GOVERNMENT_ID_IS_REQUIRED } };
            res = { ...res, ...{ nationProvideID: INVALID_MESSAGES.NATIONAL_PROVIDER_ID_OR_PROVIDER_GOVERNMENT_ID_IS_REQUIRED } };
        }

        if (!isEmptyStr(values.nationProvideID) && VALIDATION_REGEX.NUMBER.test(trim(values.nationProvideID)) === false) {
            res = { ...res, ...{ nationProvideID: INVALID_MESSAGES.NATIONAL_ID_MUST_BEDIGIT } };
        }

        return res;
    }
    let byMember = (values: any) => {
        let res: any = {};
        if (isEmptyStr(values.lastName)) {
            res = { ...res, ...{ lastName: INVALID_MESSAGES.LASTNAME_IS_EMPTY } }                
        }
        
        if(props.forConfig.indMemGovtIdIsRequired && !isEmptyStr(values.govtID)){
            const govID = values.govtID.replaceAll("-","");
            let format = FORMAT_MAPPER['SSN'];
            if(govID.match(VALIDATION_REGEX.GOVT_ID) === null || isNaN(govID) ){
                res = { ...res, ...{ govtID:  props.getGlobalizedText(INVALID_MESSAGES.GOVT_ID_IS_INVALID) + " " + format.toLowerCase() + "." } };
            }                      
        }
        if (props.forConfig.indMemGovtIdIsRequired && isEmptyStr(values.govtID)) {
            res = { ...res, ...{ govtID: INVALID_MESSAGES.GOVT_ID_IS_EMPTY } }                
        }
        if ((isEmptyStr(values.memberID) && isEmptyStr(values.groupNumber) && isEmptyStr(values.policyNumber)) || ((!isEmptyStr(values.memberID) || !isEmptyStr(values.groupNumber)) && !isEmptyStr(values.policyNumber))) {
            res = { ...res, ...{ memberID: INVALID_MESSAGES.MEMBERID_GROUP_NUMBER_OR_POLICY_NUMBER_REQUIRED, groupNumber: INVALID_MESSAGES.MEMBERID_GROUP_NUMBER_OR_POLICY_NUMBER_REQUIRED, policyNumber: INVALID_MESSAGES.MEMBERID_GROUP_NUMBER_OR_POLICY_NUMBER_REQUIRED} };
        } else {
            if (isEmptyStr(values.policyNumber)) {
                if (isEmptyStr(values.memberID)) {
                    res = { ...res, ...{ memberID: INVALID_MESSAGES.MEMBERID_OR_POLICYNUMBER_IS_EMPTY } }
                }
                if (!isEmptyStr(values.memberID) && values.memberID.trim().length > 10) {
                    res = { ...res, ...{ memberID: INVALID_MESSAGES.MEMBERID_POLICYNUMBER_TOO_LONG } }
                }
                res = validateGroupNumber(values, res);
            }
            else if (values.policyNumber.trim().length > 10) {
                res = { ...res, ...{ policyNumber: INVALID_MESSAGES.MEMBERID_POLICYNUMBER_TOO_LONG } }
            }            
        }

        return res;
    }
    let defaultOptions = {
        userTypeRegCode: props.forConfig.individual,
        nameType: props.forConfig.nameTypePerson,
    }
    return (<React.Fragment>
        <Formik initialValues={initialValues}
            {...{ ...props.generateNoramlFormikProps(), ...{ onReset: props.clearMessage } }}
            validate={values => {
                if (values.userTypeRegCode === props.forConfig.individual) {
                    if (values.nameType === props.forConfig.nameTypePerson) {                       
                        return validate(byMember, values, props);
                    } else if (values.nameType === props.forConfig.nameTypeCompany) {
                        return validate(validIndiCompany, values, props);
                    }
                } else if (values.userTypeRegCode === props.forConfig.agent) {
                    return validate(validAgent, values, props);
                } else if (values.userTypeRegCode === props.forConfig.agency) {
                    return validate(validAgency, values, props);
                } else if (values.userTypeRegCode === props.forConfig.provider) {
                    return validate(byProvider, values, props);
                } else if (values.userTypeRegCode === props.forConfig.dependent) {
                    if(!props.forConfig.depGovtIdIsRequired) {
                        return validate(validDependentWithMemberIDAndGroup, values, props);
                    }
                    return validate(validDependent, values, props);
                }
            }}

            onSubmit={(values: any, formikHelpers: any) => {
                ajax({
                    url: '/api/forgotuserid/validFirst',
                    params: {
                        userTypeRegCode: trim(values.userTypeRegCode),
                        nameType: trim(values.nameType),
                        orgName: trim(values.orgName),
                        firstName: trim(values.firstName),
                        lastName: trim(values.lastName),
                        govtID: trim(values.govtID),
                        agentNumber: trim(values.agentNumber),
                        agencyName: trim(values.agencyName),
                        agencyNumber: trim(values.agencyNumber),
                        nationProvideID: trim(values.nationProvideID),
                        participantId: trim(values.polNumberOrMemberID != undefined ? values.polNumberOrMemberID.split("-")[0] : ""),
                        groupNumber: trim(values.groupNumber),
                        memberID: trim(values.memberID),
                        policyNumber: trim(values.policyNumber),
                        dependentSequenceNumber: trim(values.polNumberOrMemberID != undefined ? values.polNumberOrMemberID.split("-")[1] : "")
                    },
                    method: 'get',
                    success: (res: any | null) => {
                        if (res.emailEnabled === true) {
                            res.selMethod = res.preMethods[0].value;
                        } else if (res.emailEnabled === false && res.questionEnabled === true) {
                            res.selMethod = res.preMethods[1].value;
                        }
                        props.setForgotUserIdBO({ ...props.forgotUserIdBO, ...trimFields(values), ...trimFields(res), ...{ step: 2 } });

                        props.step.second();

                    },
                    fail: (res: any) => {
                        let title: string = "";
                        let message: string = "";
                        if (res.firstMessage.indexOf(".") != -1) {
                            title = res.firstMessage.substr(0, res.firstMessage.indexOf(".") + 1);
                            message = res.firstMessage.substr(res.firstMessage.indexOf(".") + 1);
                        } else {
                            title = res.firstMessage;
                        }
                        setResult({ title: title, message: message });
                        props.showModal("#validErrorDlg");
                    }
                });
            }}>
            {formProps => <Form>
                <props.Row>
                    <props.Col md="12" sm="12" xs="12">
                        <props.SelectControl name="userTypeRegCode"
                            onChange={
                                (e: any) => {
                                    props.resetForm(formProps, { ...initforgotUserIdBO, ...defaultOptions, ...{ userTypeRegCode: e.target.value } });

                                }
                            }
                            label="regist.label.chooserole" required={true} options={props.forConfig.userTypes} sort={true} />
                        {//Individual & Member 02
                            (formProps.values.userTypeRegCode === props.forConfig.individual || !formProps.values.userTypeRegCode) && <>
                                <props.SelectControl name="nameType"
                                    onChange={(e: any) => {
                                        props.resetForm(formProps, { ...initforgotUserIdBO, ...defaultOptions, ...{ userTypeRegCode: formProps.values.userTypeRegCode, nameType: e.target.value } });

                                    }
                                    }
                                    label="addbene.lbl.choseType" required={true} options={props.forConfig.nameType} />
                                {
                                    formProps.values.nameType === props.forConfig.nameTypePerson && <>
                                        <props.TextControl id="lastName" name="lastName" required={true} label="common.lbl.lastName" />
                                        <props.AutoFormatControl formatType="SSN" autoFormat={false} id="govtID" name="govtID" condition={props.forConfig.indMemGovtIdIsRequired} required={props.forConfig.indMemGovtIdIsRequired} label="common.lbl.govID" />
                                        {(formProps.values.userTypeRegCode === props.forConfig.individual && formProps.values.nameType !== props.forConfig.nameTypeCompany) && <>
                                            <props.TextControl id="memberID" name="memberID" label="common.lbl.memberIdentifier" />
                                        </>}

                                        {((formProps.values.userTypeRegCode === props.forConfig.individual && formProps.values.nameType !== props.forConfig.nameTypeCompany) || formProps.values.userTypeRegCode === props.forConfig.dependent) && <>
                                            <props.TextControl id="groupNumber" name="groupNumber" label="common.lbl.groupNumber" />
                                            <PolicyNumber props={props} />
                                        </>}
                                    </>
                                }
                                {
                                    formProps.values.nameType === props.forConfig.nameTypeCompany && <>
                                        <props.TextControl id="orgName" name="orgName" required={true} label="changeow.lbl.companyName" />
                                        <props.AutoFormatControl formatType="EIN" autoFormat={false} id="govtID" name="govtID" required={true} label="common.lbl.govID" />
                                    </>
                                }
                            </>
                        }
                        {//Agent & Broker 06 
                            formProps.values.userTypeRegCode === props.forConfig.agent && <>
                                <props.TextControl id="lastName" name="lastName" required={true} label="common.lbl.lastName" />
                                <props.AutoFormatControl formatType="SSN" id="govtID" name="govtID" required={true} label="common.lbl.govID" />
                                <props.TextControl id="agentNumber" name="agentNumber" required={true} label="common.lbl.agentNumber" />
                            </>
                        }
                        {//Agency 08
                            formProps.values.userTypeRegCode === props.forConfig.agency && <>
                                <props.TextControl id="agencyName" name="agencyName" required={true} label="regist.label.agency.name" />
                                <props.AutoFormatControl formatType="EIN" id="govtID" name="govtID" required={true} label="common.lbl.govID" />
                                <props.TextControl id="agencyNumber" name="agencyNumber" required={true} label="regist.label.agency.number" />
                            </>
                        }
                        {//Dependent 13
                            formProps.values.userTypeRegCode === props.forConfig.dependent && <>
                                <props.TextControl id="firstName" name="firstName" condition={!props.forConfig.depGovtIdIsRequired} required={!props.forConfig.depGovtIdIsRequired} label="common.lbl.firstName" />
                                <props.TextControl id="lastName" name="lastName" required={true} label="common.lbl.lastName" />
                                <props.AutoFormatControl formatType="SSN" autoFormat={false} id="govtID" name="govtID" condition={props.forConfig.depGovtIdIsRequired} required={props.forConfig.depGovtIdIsRequired} label="common.lbl.govID" />
                            	<props.TextControl id="memberID" name="memberID" condition={!props.forConfig.depGovtIdIsRequired} required={!props.forConfig.depGovtIdIsRequired} label="common.lbl.dependentID" />
                                <props.TextControl id="groupNumber" name="groupNumber" condition={!props.forConfig.depGovtIdIsRequired} required={!props.forConfig.depGovtIdIsRequired} label="common.lbl.groupNumber" />
                            </>
                        }
                        {//Provider 04
                            formProps.values.userTypeRegCode === props.forConfig.provider && <>
                                <props.SelectControl name="nameType"
                                    onChange={(e: any) => {
                                        props.resetForm(formProps, { ...initforgotUserIdBO, ...defaultOptions, ...{ userTypeRegCode: formProps.values.userTypeRegCode, nameType: e.target.value } });

                                    }
                                    }
                                    label="addbene.lbl.choseType" required={true} options={props.forConfig.providerNameTypes} />
                                {
                                    formProps.values.nameType === props.forConfig.nameTypePerson && <>
                                        <props.TextControl id="firstName" name="firstName" required={true} label="common.lbl.firstName" />
                                        <props.TextControl id="lastName" name="lastName" required={true} label="common.lbl.lastName" />
                                        <props.TextControl id="nationProvideID" name="nationProvideID" required={false} label="regist.label.national.provider.id" />
                                        <Or {...props} />
                                        <props.AutoFormatControl id="govtID" name="govtID" showFormat={false} formatType="PGI" required={false} label="regist.label.provider.government.id" />
                                    </>
                                }
                                {
                                    formProps.values.nameType === props.forConfig.nameTypeCompany && <>
                                        <props.TextControl id="orgName" name="orgName" required={true} label="regist.label.national.company.name" />
                                        <props.TextControl id="nationProvideID" name="nationProvideID" required={false} label="regist.label.national.provider.id" />
                                        <Or {...props} />
                                        <props.AutoFormatControl id="govtID" name="govtID" showFormat={false} formatType="PGI" required={false} label="regist.label.provider.government.id" />
                                    </>
                                }

                            </>
                        }
                    </props.Col>
                </props.Row>
                <props.Row>
                    <props.Col sm='12'>
                        <props.Button type="submit" ><GlobalizedText message="common.button.next" /> </props.Button>
                        <props.Button type="button" onClick={() => { props.next("/login") }}><GlobalizedText message="common.lbl.cancel" /> </props.Button>
                    </props.Col>
                </props.Row>
            </Form>
            }
        </Formik>
        <props.Modal id="validErrorDlg" title="common.lbl.forgotUserID" size="large" footer={() => {
            return <>

                <props.Button data-dismiss="modal" type="button"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                <props.Col xs="12" sm="12" md="12" className='mt-2'>
                    <props.Icon size="xs" type="warning"></props.Icon>{result.title}
                </props.Col>
                <props.Col xs="12" sm="12" md="12" className="wideTop">
                    {result.message}
                </props.Col>
            </props.Row>

        </props.Modal>
    </React.Fragment >
    );
}




export const SecondPage = (props: PageProps) => {
    const [result, setResult] = useState<any>({ title: "", message: "" });
    let previous = (e: any) => {
        props.setForgotUserIdBO({ ...props.forgotUserIdBO, ...{ step: 1 } });
        props.step.first();
    };
    let initialValues = {
        ...{
            ...props.forgotUserIdBO,
            ...{ selMethod: props.forgotUserIdBO.selMethod }
        }
    }
    let sendValid = (values: any) => {
        let res: any = {};
        if (isEmptyStr(values.selMethod)) {
            res = { ...res, ...{ selMethod: INVALID_MESSAGES.PRE_SEND_METHOD_IS_EMPTY } }
        }
        if (trim(values.selMethod) === props.forConfig.retriveByOnline) {
            if (!isEmptyStr(props.forgotUserIdBO.question1) && isEmptyStr(trim(values.answer1))) {
                res = { ...res, ...{ answer1: INVALID_MESSAGES.MY_ANSWER_IS_EMPTY } }
            }
            if (!isEmptyStr(props.forgotUserIdBO.question2) && isEmptyStr(trim(values.answer2))) {
                res = { ...res, ...{ answer2: INVALID_MESSAGES.MY_ANSWER_IS_EMPTY } }
            }
            if (!isEmptyStr(props.forgotUserIdBO.question3) && isEmptyStr(trim(values.answer3))) {
                res = { ...res, ...{ answer3: INVALID_MESSAGES.MY_ANSWER_IS_EMPTY } }
            }
        }
        return res;
    }
    function closeButton() {
        props.next(`/login`);
    }
    return (<React.Fragment>
        <Formik initialValues={initialValues}
            {...{ ...props.generateNoramlFormikProps(), ...{ onReset: props.clearMessage } }}
            validate={values => {
                return validate(sendValid, values, props);
            }}

            onSubmit={(values: any, formikHelpers: any) => {
                ajax({
                    url: '/api/forgotuserid/retriveUserId',
                    params: {
                        encryUserID: trim(props.forgotUserIdBO.encryUserID),
                        selMethod: trim(values.selMethod),
                        answer1: trim(values.answer1),
                        answer2: trim(values.answer2),
                        answer3: trim(values.answer3)

                    },
                    method: 'get',
                    success: (res: any | null) => {
                        if (values.selMethod === props.forConfig.retriveByOnline) {
                            props.next("/login?userID=" + res.userId + "&showRetriveSuc=yes");
                        } else if (values.selMethod === props.forConfig.retriveByEmail) {
                            props.showModal("#sendEmailDlg");
                        }
                    },
                    fail: (res: any) => {
                        let title: string = "";
                        let message: string = "";
                        if (res.secMessage.indexOf(".") != -1) {
                            title = res.secMessage.substr(0, res.secMessage.indexOf(".") + 1);
                            message = res.secMessage.substr(res.secMessage.indexOf(".") + 1);
                        } else {
                            title = res.secMessage;
                        }
                        setResult({ title: title, message: message });
                        props.showModal("#sendErrorDlg");
                    }
                });
            }}>
            {formProps => <Form>
                <props.Row>
                    <props.Col md="12" sm="12" xs="12">
                        {(props.forgotUserIdBO.emailEnabled === true || props.forgotUserIdBO.questionEnabled === true) && <>
                            <props.Span className="gwp-title-message"><GlobalizedText message="forguid.msg.preMethod"></GlobalizedText></props.Span>
                        </>
                        }
                        {props.forgotUserIdBO.emailEnabled === true && <>
                            <props.RadioControl title="Get user ID by email" name="selMethod" className="radioMethod" value={props.forgotUserIdBO.preMethods[0].value}
                                onChange={(e: any) => {
                                    props.resetForm(formProps, { ...{ selMethod: props.forConfig.retriveByEmail, answer1: "", answer2: "", answer3: "" } });
                                }
                                }
                            /><props.Span className="radText">{props.forgotUserIdBO.preMethods[0].name} <props.Link to={`mailto:${props.forgotUserIdBO.emailAddress}`}>{props.forgotUserIdBO.emailAddress}</props.Link></props.Span>
                        </>
                        }
                        {props.forgotUserIdBO.emailEnabled === false && <>
                            <props.RadioControl title="No email on file" name="selMethod" className="radioMethod" value={props.forgotUserIdBO.preMethods[0].value} disabled="disabled" /><props.Span className="radText">{props.forgotUserIdBO.preMethods[0].name} <GlobalizedText message="forguid.msg.noemailonfile"></GlobalizedText></props.Span>
                        </>
                        }
                        {props.forgotUserIdBO.questionEnabled === true && <>
                            <props.RadioControl title="Get user ID by answer questions" name="selMethod" className="radioMethod" value={props.forgotUserIdBO.preMethods[1].value}

                                onChange={(e: any) => {
                                    props.resetForm(formProps, { ...{ selMethod: props.forConfig.retriveByOnline, answer1: "", answer2: "", answer3: "" } });
                                }
                                }
                            /><props.Span className="radText">{props.forgotUserIdBO.preMethods[1].name}</props.Span>
                        </>
                        }
                    </props.Col>
                </props.Row>
                <props.Row>
                    <props.Col md="12" sm="12" xs="12">
                        <hr className="line"></hr>
                    </props.Col>
                </props.Row>
                {props.forgotUserIdBO.questionEnabled === true && formProps.values.selMethod === props.forConfig.retriveByOnline && <>
                    <props.Row>
                        <props.Col md="12" sm="12" xs="12">
                            {!isEmptyStr(props.forgotUserIdBO.question1) && <>
                                <div className="form-group">
                                    <props.Label for="sec_question1"><GlobalizedText message="regist.label.question1"></GlobalizedText></props.Label>
                                    <div id="sec_question1">{props.forgotUserIdBO.question1}</div>
                                </div>

                                <div className="form-group widebuttom">
                                    <props.TextControl id="answer1" name="answer1" label="forguid.lbl.answer" required={true} />
                                </div>

                            </>}
                            {!isEmptyStr(props.forgotUserIdBO.question2) && <>
                                <div className="form-group">
                                    <props.Label for="sec_question2"><GlobalizedText message="regist.label.question2"></GlobalizedText></props.Label>
                                    <div id="sec_question2">{props.forgotUserIdBO.question2}</div>
                                </div>
                                <div className="form-group widebuttom">
                                    <props.TextControl id="answer2" name="answer2" label="forguid.lbl.answer" required={true} />
                                </div>

                            </>}
                            {!isEmptyStr(props.forgotUserIdBO.question3) && <>
                                <div className="form-group">
                                    <props.Label for="sec_question3"><GlobalizedText message="regist.label.question3"></GlobalizedText></props.Label>
                                    <div id="sec_question3">{props.forgotUserIdBO.question3}</div>
                                </div>
                                <div className="form-group widebuttom">
                                    <props.TextControl id="answer3" name="answer3" label="forguid.lbl.answer" required={true} />
                                </div>

                            </>}
                        </props.Col>
                    </props.Row>
                </>}
                <props.Row>
                    <props.Col sm='12'>
                        <props.Button type="submit" ><GlobalizedText message="common.button.continue" /> </props.Button>
                        <props.Button type="button" onClick={previous}><GlobalizedText message="common.lbl.cancel" /> </props.Button>
                    </props.Col>
                </props.Row>
            </Form>
            }
        </Formik>
        <props.Modal id="sendErrorDlg" title="common.lbl.forgotUserID" size="large" footer={() => {
            return <>

                <props.Button data-dismiss="modal"><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                <props.Col xs="12" sm="12" md="12" className='mt-2'>
                    <props.Icon size="xs" type="warning"></props.Icon>{result.title}
                </props.Col>
                <props.Col xs="12" sm="12" md="12" className="wideTop">
                    {result.message}
                </props.Col>
            </props.Row>

        </props.Modal>
        <props.Modal id="sendEmailDlg" title="common.lbl.forgotUserID" size="large" footer={() => {
            return <>

                <props.Button data-dismiss="modal" onClick={() => { closeButton() }}><GlobalizedText message="common.lbl.close" /></props.Button>
            </>
        }}>
            <props.Row>
                <props.Col xs="12" sm="12" md="12" className='mt-2'>
                    <props.Icon size="xs" type="warning"></props.Icon><GlobalizedText message="forguid.msg.sendemailpre" /> '{props.forgotUserIdBO.emailAddress}' <GlobalizedText message="forguid.msg.sendemailsux" />
                </props.Col>
            </props.Row>

        </props.Modal>
    </React.Fragment >
    );
}

const Or = (props: ViewComponentProps) => {
    return <props.Div style={{ marginTop: '10px' }}><GlobalizedText message="common.lbl.or" /></props.Div>
}

function validateGroupNumber(values: any, res: any) {
        if (isEmptyStr(values.groupNumber)) {
            res = { ...res, ...{ groupNumber: INVALID_MESSAGES.GROUPNUMBER_IS_REQUIRED } };
        }
        if (!isEmptyStr(values.groupNumber) && values.groupNumber.trim().length > 10) {
            res = { ...res, ...{ groupNumber: INVALID_MESSAGES.GROUPNUMBER_IS_TO_LONG } };
        }
        return res;
    }
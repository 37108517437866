import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withView, ViewComponentProps } from '../shared/viewcomponent';
import { Formik, Form } from 'formik';
import { WouldSelectOption, INIT_PARAM_PREFIX_TABLE } from '../shared/components';
import GlobaliziedText from '../shared/globalization';
import './css/insurance.css';
import { ajax, getFromStorage, isEmptyStr, removeFromStorage, trim, copyObjectExcept } from '../shared/utils';
import { TableColumn, useTable } from "../shared/components";
import GlobalizedText from '../shared/globalization';
import { date } from 'yup';
import { useHistory } from "react-router-dom";
import IndividualInsurance from "../shared/interfaces/individualInsurance";
import GroupInsurance from "../shared/interfaces/groupInsurance";
import ClaimInquiry4MemberViewResponse from "../shared/interfaces/claimInquiry4MemberViewResponse";
import ClaimInquiryForm from '../shared/interfaces/claimInquiryForm';
import QuickLink from '../shared/quickLink';
import { SendMessagePopup } from '../shared/components/sendMessagePopup';

const retrieve = (values: any, columns: any, setTableData: any, props: any): void => {
    let params = copyObjectExcept(values, true, INIT_PARAM_PREFIX_TABLE);
    ajax({
        url: '/api/claiminquirymember/fetchForMember',
        params: params,
        success: (data: any) => {
            let issuedClaims: Array<any> = [];
            let tempClaimsObj: { [key: string]: boolean } = {};

            data.sort((claim1: any, claim2: any) => (
                new Date(claim2.checkIssuedDate).getTime() - new Date(claim1.checkIssuedDate).getTime()
            ));

            data.forEach((claimEntry: any) => {
                if (!tempClaimsObj[claimEntry.claimNumber]) {
                    issuedClaims.push(claimEntry);
                    tempClaimsObj[claimEntry.claimNumber] = true;
                }
            });

            data = issuedClaims.filter((claim: any) => claim.checkType === "I" || claim.checkType === "B");

            if (data.length > claimsLength) {
                data = data.slice(0, claimsLength);
            }
            setTableData(data);
        },
        error: (error: any) => {

        }
    });
}
let varFormPromp: any;
let selectetedRowData: any = null;
let passedCompanyCode: any;
let claimsLength: number = 5;
let limitTableOption = {
    bPaginate: false,
    bSort: false,
    bInfo: false
};
export const InsuranceComponent = withView((props: ViewComponentProps) => {

    passedCompanyCode = props.getQueryParam("companyCode") === undefined ? '' : props.getQueryParam("companyCode");
    let makePaymentCallBack = getFromStorage('makepaymentcallback', true)
    let changePaymentMethodMsg = getFromStorage('changePaymentMethodSuccess', true)

    let addSecondaryAddresseeCallBack = getFromStorage('addSecondaryAddresseeCallBack', true)
    let changeSecondaryAddresseeCallBack = getFromStorage('changeSecondaryAddresseeCallBack', true)

    const [clientNumber, setClientNumber] = useState<any>(props.getQueryParam("clientNumber") === undefined ? '' : props.getQueryParam("clientNumber"));
    const savedComponyCode = useRef<any>();
    const [data, setData] = useState<any>();
    const [openEnrollmentFlag, setOpenEnrollmentFlag] = useState<boolean>(false);
    const [pendingTransactionFlag, setPendingTransactionFlag] = useState<boolean>(false);

    const [accounts, setAccounts] = useState<Array<any>>();
    const [groups, setGroups] = useState<Array<any>>();
    const [groupNumber, setGroupNumber] = useState<string>("");
    const [companyCode, setCompanyCode] = useState<string>("");
    const [accountNumber, setAccountNumber] = useState<number>(0);
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [effectiveDate, setEffectiveDate] = useState<any>();
    const [anniversaryDate, setAnniversaryDate] = useState<any>();

    const [allowOpenEnroll, setAllowOpenEnroll] = useState<boolean>(true);
    const [dispalyOpenEnroll, setDispalyOpenEnroll] = useState<boolean>(false);
    const [existMsg, setExistMsg] = useState<any>();
    const [numberOfPendingTrans, setNumberOfPendingTrans] = useState<any>();
    const [disablePendingBtnFlag, setDisablePendingBtnFlag] = useState<boolean>(true);

    const [changeSecAddrBtnVisible, setChangeSecAddrBtnVisible] = useState<boolean>(true);
    const [eidtSecAddrBtnVisible, setEidtSecAddrBtnVisible] = useState<boolean>(true);
    const [deleteSecAddrBtnVisible, setDeleteSecAddrBtnVisible] = useState<boolean>(true);

    const [validateContinueBtnVisible, setValidateContinueBtnVisible] = useState<boolean>(true);
    const [validateCloseBtnVisible, setValidateCloseBtnVisible] = useState<boolean>(false);

    const [secAddrName, setSecAddrName] = useState<any>('');
    const [secAddrAddress, setSecAddrAddress] = useState<any>('');

    const [validationDigLabel, setValidationDigLabel] = useState<any>('');
    const [validationContent, setValidationContent] = useState<any>('');

    const [continuePage, setContinuePage] = useState<any>('');
    const [closeFlag, setCloseFlag] = useState<boolean>(true);

    const [next4SecdAdd, setNext4SecdAdd] = useState<any>();

    const [tableData, setTableData] = useState<Array<any>>();
    const [viewClaims, setViewClaims] = useState<any>();
    const [balanceSummary, setBalanceSummary] = useState<any>([]);
    const [isDependent, setIsDependent] = useState<boolean>(false);
    const [personalDetails, setPersonalDetails] = useState<any>([]);



    const tableColumns: Array<TableColumn> = [
        {
            className: "gwp-dt-detail-control", title: ""
        },
        {
            title: "common.lbl.transNumber", name: "transactionNumber",
            sortable: true
        },
        { title: "common.lbl.memberID", name: "memberId", sortable: true },
        { title: "common.lbl.memberName", name: "memberName", sortable: true },
        { title: "common.lbl.group", name: "groupNumber", sortable: true },
        { title: "common.lbl.account", name: "accountNumber", sortable: true },
        { title: "common.lbl.submitDate", name: "submitDate", sortable: true, type: "date" },
        { title: "common.lbl.type", name: "type", sortable: true },
        { title: "common.lbl.status", name: "status", sortable: true }
    ]

    const columns: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "6%" },
        {
            sortable: false, title: "common.lbl.claimNumber", name: "claimNumber", width: "14%", render: (data: any, type: any, row: any, meta: any): string => {
                return `<a dt-href='/claimDetail?companyCode=${row.companyCode.trim()}&groupNumber=${row.groupNumber}&accountNumber=${row.accountNumber}&participantIDNumber=${row.memberID}&julianYear=${row.julianYear}&sequenceNumber=${row.sequenceNumber}&suffix=${row.suffix}&claimNumber=${row.claimNumber}&adjustmentNumber=${isEmptyStr(row.adjustmentNumber) ? '-999' : row.adjustmentNumber}&providerPaymentNumber=${isEmptyStr(row.providerPaymentNumber) ? '-999' : row.providerPaymentNumber}&dependentSequenceNumber=${row.dependentSequenceNumber}&julianDay=${row.julianDay}'>${row.claimNumber}</a>`
            },
        },
        { sortable: false, title: "common.lbl.servicedate", name: "serviceDate", width: "21%", type: 'date' },
        { sortable: false, title: "common.lbl.patientName", name: "patient", width: "19%" },
        { sortable: false, title: "common.lbl.claimAmount", name: "paid", width: "14%", type: 'numeric' },
        {
            sortable: false, title: "common.lbl.provider", name: "doctorFacility", width: "26%", render: (data: any, type: any, row: any, meta: any): string => {
                return (
                    data?.length > 40
                        ? `<span data-toggle="tooltip" title='${data}'>${data.slice(0, 37)}...</span>`
                        : data
                );
            },
        }
    ];
    const balanceSumaryColumns: Array<TableColumn> = [
        { sortable: false, className: "gwp-dt-detail-control", width: "4%" },
        { title: "common.lbl.description", name: "description", width: "30%", className: "fontBold" },
        { title: "common.lbl.limit", name: "limit", width: "20%", type: 'numeric', className: "text-right insurance-column"},
        { title: "common.lbl.claim.accum.amt", name: "accumulatedAmount", width: "20%", type: 'numeric', className: "text-right insurance-column"},
        { title: "common.lbl.percent.met", name: "Percent Met", width: "30%", type: 'progressBar', className: "text-center" },
    ];

    const [pendingTranstable, setPendingTranstable] = useState<any | null>({ columns: tableColumns, data: [] });


    let history = useHistory();
    useEffect(() => {

        if (data === undefined) {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
            if (props.getQueryParam("name") !== undefined) {
                let nameObj = decodeURIComponent(props.getQueryParam("name").replaceAll("<wbr/>", ""));
                props.setTitle(`${nameObj}${props.getGlobalizedText("my.ins.ones.title")}`);
            }

            ajax({
                url: '/api/insurance/view',
                showMask: true,
                params: {
                    fromPage: props.getQueryParam("fromPage") === undefined ? '' : props.getQueryParam("fromPage"),
                    clientNumber: clientNumber,
                    agentClientNum: props.getQueryParam("agentClientNum") === undefined ? '' : props.getQueryParam("agentClientNum"),
                    companyCode: passedCompanyCode
                },
                success: (res: any) => {
                    props.clearMessage();
                    setPersonalDetails(res);
                    if (res.member || res.dependent) {
                        props.setTitle(props.getGlobalizedText("my.ins.title"));
                        setClientNumber(res.clientNum);
                    } else {
                        let on = res.ownerName.replaceAll("<wbr/>", "");
                        props.setTitle(`${on}${props.getGlobalizedText("my.ins.ones.title")}`);
                    }

                    bulidInsuranceList(res);

                    if (res.groupInsurances.length !== 0) {
                        passedCompanyCode = res.groupInsurances[0].companyCode;
                        savedComponyCode.current = res.groupInsurances[0].companyCode;
                    } else if (res.individualInsurances.length !== 0) {
                        passedCompanyCode = res.individualInsurances[0].companyCode;
                        savedComponyCode.current = res.individualInsurances[0].companyCode;
                    }
                    if (res.dependent) {
                        setIsDependent(true);
                    }
                    if (res.groupInsurances !== undefined
                        && res.groupInsurances.length >= 1) {
                        const params = res.groupInsurances[0];
                        let url = '/api/eligibilitySummary/fetchBenefitLimitContents';
                        const { companyCode, account, groupNumber, memberIdWithLeftZero, agentNumber } = params;
                        const sequenceNumber = res.dependent ? res.groupInsurances[0].memberId.split("-")?.[1] : 0 ;                        
                        ajax({
                            url: url,
                            params: {
                                companyCode: companyCode ? companyCode : '',
                                groupNumber: groupNumber ? groupNumber.trim() : '',
                                accountNumber: isNaN(account) ? 0 : account,
                                participantIDNumber: memberIdWithLeftZero ? memberIdWithLeftZero : '',
                                dependentSequenceNumber: isNaN(sequenceNumber) ? 0 : sequenceNumber,
                                agentNumber: agentNumber ? agentNumber : '',
                                asOfDate: props.getParam("asOfDate") === undefined ? new date().toString() : props.getParam("asOfDate"),
                                fromPage: (props.getQueryParam("fromPage") === undefined) ? '' : props.getQueryParam("fromPage"),
                            },
                            success: (response: any) => {
                                const { benefitLimitContents } = response;
                                setBalanceSummary(benefitLimitContents);
                            }
                        })
                    }
                    if (res.groupInsurances !== undefined
                        && res.groupInsurances.length >= 1
                        && res.groupInsurances[0].viewClaimsAccessPermission === true) {
                        const params = res.groupInsurances[0];
                        const isDependent = res.dependent;
                        const sequenceNumber = isDependent ? res.groupInsurances[0].memberId.split("-")?.[1] : 0;
                        setViewClaims(`/memberClaimInquiry?from=from_insurance&companyCode=${selfTrim(params.companyCode)}&accountNumber=${params.account}&groupNumber=${selfTrim(params.groupNumberWithLeftZero)}&participantIDNumber=${params.memberIdWithLeftZero}&dependentSequenceNumber=${sequenceNumber}`);
                        ajax({
                            url: '/api/claiminquirymember/view',
                            params: {
                                // fromPage: props.getQueryParam("fromPage") === undefined ? 'from_insurance' : props.getQueryParam("fromPage"),
                                fromPage: 'from_insurance',
                                companyCode: selfTrim(params.companyCode),
                                accountNumber: params.account,
                                groupNumber: selfTrim(params.groupNumberWithLeftZero),
                                participantIDNumber: params.memberIdWithLeftZero,
                                dependentSequenceNumber: sequenceNumber,
                                agentNumber: params.agentNumber
                            },
                            success: (res: ClaimInquiry4MemberViewResponse) => {
                                if (props.getInitParams() !== undefined) {
                                    let values: ClaimInquiryForm = props.getInitParams();
                                    retrieve(values, columns, setTableData, props);
                                } else {
                                    let initValues = {
                                        patient: res.patientList[0].value,
                                        status: 'I', //Only 'Issued' claims
                                        serviceDate: '',
                                        fromPage: res.fromPage,
                                        companyCode: res.companyCode,
                                        groupNumber: res.groupNumber,
                                        accountNumber: res.accountNumber,
                                        participantIDNumber: res.participantIDNumber,
                                        dependentSequenceNumber: res.dependentSequenceNumber,
                                        agentNumber: res.agentNumber
                                    }
                                    if(isDependent) {
                                        const sequenceNumber = params?.memberId?.split("-")
                                        initValues.dependentSequenceNumber = sequenceNumber?.length >= 1 ? sequenceNumber[1] : ""
                                        initValues.patient =  params?.memberName;
                                    }
                                    retrieve(initValues, columns, setTableData, props);
                                }
                            }
                        });
                    }


                    setOpenEnrollmentFlag(res.hasOpenEnrollment);
                    if (res.hasOpenEnrollment === true) {
                        setGroups(res.groupsList);
                        setAccounts(res.accountList);
                        varFormPromp.setFieldValue("groupNumber", res.groupsList[0].value);
                        varFormPromp.setFieldValue("account", res.accountList[0].value);

                        let parseValue = parseFormatSelGroupOptionValue(res.groupsList[0].value);
                        fetchOpenEnrollmentPeriodInfo(parseValue.companyCode, parseValue.groupNumber, res.accountList[0].value);
                    }
                    if (res.pendingTransactionList === undefined || res.pendingTransactionList.length === 0) {
                        setPendingTransactionFlag(false);
                    } else {
                        setPendingTransactionFlag(true);
                        setNumberOfPendingTrans(res.pendingTransactionList.length);
                        setDisablePendingBtnFlag(true);
                        setPendingTranstable({
                            columns: tableColumns,
                            data: res.pendingTransactionList
                        });
                    }

                    if (makePaymentCallBack !== null && makePaymentCallBack.makepayment === 'success') {
                        let message = '<span class="gwp-bold">'
                        message += props.getGlobalizedText('payment.tlt.paysuccesst')
                        message += '</span>'
                        message += '<br/>'
                        message += '<div>'
                        message += props.getGlobalizedText('payment.tlt.paysuccessc')
                        message += '</div>'
                        props.showMessage("success", message);
                        removeFromStorage("makepaymentcallback", true);
                    }
                    if (changePaymentMethodMsg !== null && changePaymentMethodMsg.changeMethod === 'success') {
                        let message = '<span class="gwp-bold">'
                        message += props.getGlobalizedText('common.msg.payment.info.update')
                        message += '</span>'
                        message += '<br/>'
                        message += '<div>'
                        message += props.getGlobalizedText('common.msg.payment.info.furtherChanges')
                        message += '</div>'
                        props.showMessage("success", message);
                        removeFromStorage("changePaymentMethodSuccess", true);
                    }
                    if (addSecondaryAddresseeCallBack !== null && addSecondaryAddresseeCallBack.result === 'success') {
                        props.showMessage("success", 'addsecaddr.msg.add.success');
                        removeFromStorage("addSecondaryAddresseeCallBack", true);
                    }
                    if (changeSecondaryAddresseeCallBack !== null && changeSecondaryAddresseeCallBack.result === 'success') {
                        props.showMessage("success", 'addsecaddr.msg.update.success');
                        removeFromStorage("changeSecondaryAddresseeCallBack", true);
                    }

                    removeFromStorage('hasPBDistributeEvent', true)
                    removeFromStorage('hasCBDistributeEvent', true)
                }
            });
            return () => {
            }
        }
    }, [data]);

    const claimInquiryResult = useMemo(() => useTable({
        id: 'claimInquiryResult',
        option: { paging: false, language: { emptyTable: props.getGlobalizedText("common.no.claims"), infoEmpty: "" } },
        isDataLoaded: true,
        table: {
            columns: columns,
            data: tableData
        },
        dateformat: props.getDateFormat(),
        setInitParams: props.setInitParams,
        getInitParams: props.getInitParams
    }), [tableData]);
    const balanceSummaryResult = useMemo(() => useTable({
        id: 'balance_summary_result',
        option: limitTableOption,
        table: {
            columns: balanceSumaryColumns,
            data: balanceSummary
        },
    }), [balanceSummary]);

    if (data === undefined) {
        return <></>
    }

    function saveSelectedValue(row: any) {
        selectetedRowData = row;
        setDisablePendingBtnFlag(false);
    }
    function bulidInsuranceList(res: any) {
        //construct group insurance list
        let groupInsurancesList: Array<GroupInsurance> = [];
        for (let i = 0; res.groupInsurances !== undefined && i < res.groupInsurances.length; i++) {
            let tmpResult = res.groupInsurances[i];
            let tmpGroupInsurances: GroupInsurance = {
                companyCode: tmpResult.companyCode,
                groupNumber: tmpResult.groupNumber,
                groupName: tmpResult.groupName,
                accountNumber: tmpResult.account,
                accountName: tmpResult.accountName,
                accountType: tmpResult.accountType,
                memberName: tmpResult.memberName,
                memberId: tmpResult.memberId,
                effectiveDate: tmpResult.effectiveDate,
                terminationDate: tmpResult.terminationDate,
                wouldSelectOptions: constructWouldLikeOptionGroup(res, tmpResult)
            };
            if (i == 0) {
                setCompanyCode(selfTrim(tmpResult.companyCode));
                setGroupNumber(selfTrim(tmpResult.groupNumber));
                setAccountNumber(selfTrim(tmpResult.account));
            }
            groupInsurancesList.push(tmpGroupInsurances);
        }

        //construct group insurance list
        let individualInsurancesList: Array<IndividualInsurance> = [];
        for (let i = 0; res.individualInsurances !== undefined && i < res.individualInsurances.length; i++) {
            let tmpResult = res.individualInsurances[i];
            let tmpIndividualInsurances: IndividualInsurance = {
                policyNumber: tmpResult.policyNumber,
                clientNumber: res.clientNum,
                companyCode: tmpResult.companyCode,
                name: tmpResult.name,
                insuredName: tmpResult.insuredName,
                ownerName: tmpResult.ownerName,
                owner2Name: tmpResult.owner2Name,
                jointInsured: tmpResult.jointInsured,
                status: tmpResult.status,
                effectiveDate: tmpResult.effectiveDate,
                coverageAmount: tmpResult.coverageAmount,
                premium: tmpResult.premium,
                wouldSelectOptions: constructWouldLikeOptionIndividual(res, tmpResult)
            };
            individualInsurancesList.push(tmpIndividualInsurances);
        }
        setData({
            insurances: individualInsurancesList,
            groupInsurances: groupInsurancesList
        });
    }

    const detelteTitle = (messageKey: string) => {
        return <>
            <span className="gwp-icon gwp-icon-warning gwp-icon-sm " ></span>&nbsp;
            <GlobaliziedText message={messageKey} />
        </>
    }

    function constructWouldLikeOptionGroup(res: any, rowData: any): Array<WouldSelectOption> {
        let wouldSelectOptionList: Array<WouldSelectOption> = [];
        let transactionID = '';
        if (res.dependent) {
            const dependent = res.groupInsurances[0];
            if (rowData.onlineFormsAccessPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'of.lbl.oforms',
                    to: `/onlineforms?companyCode=${selfTrim(rowData.companyCode)}&groupNumber=${rowData.groupNumberWithLeftZero}&accountNumber=${rowData.account}&participantIDNumber=${rowData.memberIdWithLeftZero}&personalInfoType=1&roleTypeCode=${convertRoleTypeCode(rowData)}&baseRiderCode=0&isUpdateMode=true&dependentSequenceNumber=0`
                };
                wouldSelectOptionList.push(tmpOption);
            }
            if (rowData.memberEditPersonalInfoPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'common.lbl.updatePersonalInfo',
                    to: `/personalInformation?isUpdateMode=true`
                        + `&companyCode=${selfTrim(dependent.companyCode)}`
                        + `&groupNumber=${selfTrim(dependent.groupNumber)}`
                        + `&accountNumber=${dependent.account}`
                        + `&participantIDNumber=${dependent.memberId.split('-')[0]}`
                        + `&personalInfoType=1`
                        + `&dependentSequenceNumber=${dependent.memberId.split('-')[1]}`
                };
                wouldSelectOptionList.push(tmpOption);
            }
            if (rowData.viewClaimsAccessPermission === true) {
                let tmpOption = {
                    message: 'my.ins.act.opt.view.claims',
                    to: `/memberClaimInquiry?from=from_insurance`
                        + `&companyCode=${selfTrim(dependent.companyCode)}`
                        + `&accountNumber=${dependent.account}`
                        + `&groupNumber=${selfTrim(dependent.groupNumber)}`
                        + `&participantIDNumber=${dependent.memberId.split('-')[0]}`
                        + `&dependentSequenceNumber=${dependent.memberId.split('-')[1]}`
                };
                wouldSelectOptionList.push(tmpOption);
            }
            if (rowData.viewCoverageAccessPermission === true) {
                let tmpOption = {
                    message: 'my.ins.act.opt.view.coverage',
                    to: `/coverageSummary?companyCode=${selfTrim(dependent.companyCode)}`
                        + `&accountNumber=${dependent.account}`
                        + `&groupNumber=${selfTrim(dependent.groupNumber)}`
                        + `&participantIDNumber=${dependent.memberId.split('-')[0]}`
                        + `&dependentSequenceNumber=${dependent.memberId.split('-')[1]}`
                };
                wouldSelectOptionList.push(tmpOption);
            }
            if (rowData.viewCarriersAccessPermission === true) {
                let tmpOption = {
                    message: 'my.ins.act.opt.view.carriers',
                    to: `/carriers?companyCode=${selfTrim(dependent.companyCode)}&groupNumber=${selfTrim(dependent.groupNumber)}&participantIDNumber=${dependent.memberId.split('-')[0]}`
                };
                wouldSelectOptionList.push(tmpOption);
            }
        }
        else if (res.member !== true) {
            if (rowData.updateExistingMemberAccessPermission === true) {
                transactionID = isEmptyStr(rowData.updateTransactionNumber) ? transactionID : rowData.updateTransactionNumber;
                let tmpOption: WouldSelectOption = {
                    message: 'common.lbl.updateEnrollment',
                    to: `/enrollment/open?formType=updateEnrollment&companyCode=${rowData.companyCode}&formAction=updateEnrollment&groupNumber=${rowData.groupNumberWithLeftZero}&clientNumber=${res.clientNum}&accountNumber=${rowData.account}&anniversaryDate=${rowData.anniversaryDate}&participantIDNumber=${rowData.memberIdWithLeftZero}&transactionID=${transactionID}`,
                    disabled: rowData.type === "grp" && rowData.existPendingTransaction === true

                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.updateLifeEventAccessPermission === true) {
                transactionID = isEmptyStr(rowData.lifeTransactionNumber) ? transactionID : rowData.lifeTransactionNumber;
                let tmpOption: WouldSelectOption = {
                    message: 'my.ins.act.opt.update.life.event',
                    to: `/enrollment/open?formType=lifeEvent&companyCode=${rowData.companyCode}&formAction=lifeEvent&groupNumber=${rowData.groupNumberWithLeftZero}&clientNumber=${res.clientNum}&accountNumber=${rowData.account}&anniversaryDate=${rowData.anniversaryDate}&participantIDNumber=${rowData.memberIdWithLeftZero}&transactionID=${transactionID}`,
                    disabled: rowData.type === "grp" && rowData.existPendingTransaction === true

                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.updatePayrollScheduleAccessPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'common.lbl.updatepayrollschedule',
                    to: `/updatePayrollSchedule?companyCode=${rowData.companyCode}&from=from_insurance&accountNumber=${rowData.account}&groupNumber=${rowData.groupNumberWithLeftZero}&participantIDNumber=${rowData.memberIdWithLeftZero}`,
                    disabled: rowData.type === "grp" && rowData.existPendingTransaction === true
                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.memberEditPersonalInfoPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'common.lbl.updatePersonalInfo',
                    to: `/personalInformation?companyCode=${selfTrim(rowData.companyCode)}&groupNumber=${rowData.groupNumberWithLeftZero}&accountNumber=${rowData.account}&participantIDNumber=${rowData.memberIdWithLeftZero}&personalInfoType=1&roleTypeCode=${convertRoleTypeCode(rowData)}&baseRiderCode=0&isUpdateMode=true&dependentSequenceNumber=0`
                };
                wouldSelectOptionList.push(tmpOption);
            }
            if (rowData.viewBillingAccessPermission === true
                && (rowData.memberAccountType == 'W' || rowData.memberAccountType === 'I')) {
                let tmpOption: WouldSelectOption = {
                    message: 'my.ins.act.opt.view billing.or.payments',
                    to: `/billingPaymentSummary/${selfTrim(rowData.companyCode)}/${selfTrim(rowData.groupNumberWithLeftZero)}/${rowData.account}/${rowData.memberIdWithLeftZero}`
                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.viewClaimsAccessPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'my.ins.act.opt.view.claims',
                    to: `/memberClaimInquiry?from=from_insurance&companyCode=${selfTrim(rowData.companyCode)}&accountNumber=${rowData.account}&groupNumber=${selfTrim(rowData.groupNumberWithLeftZero)}&participantIDNumber=${rowData.memberIdWithLeftZero}`
                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.viewCoverageAccessPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'my.ins.act.opt.view.coverage',
                    to: `/coverageSummary?companyCode=${selfTrim(rowData.companyCode)}&accountNumber=${rowData.account}&groupNumber=${selfTrim(rowData.groupNumberWithLeftZero)}&participantIDNumber=${rowData.memberIdWithLeftZero}`
                };
                wouldSelectOptionList.push(tmpOption);
            }

			if (rowData.viewCarriersAccessPermission === true) {
                let tmpOption = {
                    message: 'my.ins.act.opt.view.carriers',
                    to: `/carriers?companyCode=${selfTrim(rowData.companyCode)}&groupNumber=${selfTrim(rowData.groupNumber)}&participantIDNumber=${rowData.memberId.split('-')[0]}&clientNumber=${res.clientNum}`
                };
                wouldSelectOptionList.push(tmpOption);
            }
        } else {
            if (rowData.openEnrollmentAccessPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'common.lbl.editEnrollment',
                    to: `/enrollment/open?formType=editEnrollmentM&companyCode=${selfTrim(rowData.companyCode)}&groupNumber=${selfTrim(rowData.groupNumberWithLeftZero)}&clientNumber=${res.clientNum}&formAction=editEnrollmentM&accountNumber=${rowData.account}&anniversaryDate=${rowData.anniversaryDate}&participantIDNumber=${rowData.memberIdWithLeftZero}`,
                    disabled: (rowData.type === "grp" && (rowData.inOpenEnrollmentPeriod === true || rowData.existPendingTransaction === true)) ? true : false
                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.onlineFormsAccessPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'of.lbl.oforms',
                    to: `/onlineforms?companyCode=${selfTrim(rowData.companyCode)}&groupNumber=${rowData.groupNumberWithLeftZero}&accountNumber=${rowData.account}&participantIDNumber=${rowData.memberIdWithLeftZero}&personalInfoType=1&roleTypeCode=${convertRoleTypeCode(rowData)}&baseRiderCode=0&isUpdateMode=true&dependentSequenceNumber=0`
                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.initialBenefitSelectionAccessPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'my.ins.act.opt.request.initial.benefit.selection',
                    to: `/enrollment/open?formType=initBenefitSelection&companyCode=${selfTrim(rowData.companyCode)}&formAction=initBenefitSelection&groupNumber=${selfTrim(rowData.groupNumberWithLeftZero)}&clientNumber=${selfTrim(res.clientNum)}&accountNumber=${rowData.account}&anniversaryDate=${rowData.anniversaryDate}&participantIDNumber=${rowData.memberIdWithLeftZero}`,
                    disabled: (rowData.type === "grp" && (rowData.inEffectHireDate !== true || rowData.existPendingTransaction === true)) ? true : false
                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.openEnrollmentAccessPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'common.lbl.requestOpenEnrollment',
                    to: `/enrollment/open?formType=openEnrollment&companyCode=${selfTrim(rowData.companyCode)}&groupNumber=${selfTrim(rowData.groupNumberWithLeftZero)}&clientNumber=${selfTrim(res.clientNum)}&formAction=openEnrollment&accountNumber=${rowData.account}&anniversaryDate=${rowData.anniversaryDate}&participantIDNumber=${rowData.memberIdWithLeftZero}`,
                    disabled: (rowData.type === "grp" && (rowData.inOpenEnrollmentPeriod !== true || rowData.existPendingTransaction === true)) ? true : false
                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.updateLifeEventAccessPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'my.ins.act.opt.update.life.event',
                    to: `/enrollment/open?formType=lifeEvent&companyCode=${selfTrim(rowData.companyCode)}&formAction=lifeEvent&groupNumber=${selfTrim(rowData.groupNumberWithLeftZero)}&clientNumber=${selfTrim(res.clientNum)}&accountNumber=${rowData.account}&anniversaryDate=${rowData.anniversaryDate}&participantIDNumber=${rowData.memberIdWithLeftZero}&eventName=&transactionNumber=`,
                    disabled: (rowData.type === "grp" && rowData.existPendingTransaction === true) ? true : false
                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.memberEditPersonalInfoPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'common.lbl.updatePersonalInfo',
                    to: `/personalInformation?companyCode=${selfTrim(rowData.companyCode)}&groupNumber=${selfTrim(rowData.groupNumberWithLeftZero)}&accountNumber=${rowData.account}&participantIDNumber=${rowData.memberIdWithLeftZero}&personalInfoType=1&roleTypeCode=${convertRoleTypeCode(rowData)}&baseRiderCode=0&isUpdateMode=true&dependentSequenceNumber=0`
                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.viewBillingAccessPermission === true
                && (rowData.memberAccountType === 'W' || rowData.memberAccountType === 'I')) {
                let tmpOption: WouldSelectOption = {
                    message: 'my.ins.act.opt.view billing.or.payments',
                    to: `/billingPaymentSummary/${selfTrim(rowData.companyCode)}/${rowData.groupNumberWithLeftZero}/${rowData.account}/${rowData.memberIdWithLeftZero}`
                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.viewClaimsAccessPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'my.ins.act.opt.view.claims',
                    to: `/memberClaimInquiry?from=from_insurance&companyCode=${selfTrim(rowData.companyCode)}&accountNumber=${rowData.account}&groupNumber=${selfTrim(rowData.groupNumberWithLeftZero)}&participantIDNumber=${rowData.memberIdWithLeftZero}`
                };
                wouldSelectOptionList.push(tmpOption);
            }

            if (rowData.viewCoverageAccessPermission === true) {
                let tmpOption: WouldSelectOption = {
                    message: 'my.ins.act.opt.view.coverage',
                    to: `/coverageSummary?companyCode=${selfTrim(rowData.companyCode)}&accountNumber=${rowData.account}&groupNumber=${selfTrim(rowData.groupNumberWithLeftZero)}&participantIDNumber=${rowData.memberIdWithLeftZero}`
                };
                wouldSelectOptionList.push(tmpOption);
            }
            if (rowData.viewCarriersAccessPermission === true) {
                let tmpOption = {
                    message: 'my.ins.act.opt.view.carriers',
                    to: `/carriers?companyCode=${selfTrim(rowData.companyCode)}&groupNumber=${selfTrim(rowData.groupNumberWithLeftZero)}&participantIDNumber=${rowData.memberIdWithLeftZero}&clientNumber=${res.clientNum}`
                };
                wouldSelectOptionList.push(tmpOption);
            }
        }
        return wouldSelectOptionList;
    }

    function constructWouldLikeOptionIndividual(res: any, rowData: any): Array<WouldSelectOption> {
        let wouldSelectOptionList: Array<WouldSelectOption> = [];
        if (rowData.accessSecAddresseePermission === true
            && rowData.updateSecAddresseePermission === true
            && (rowData.secAddrClientNumber === undefined || rowData.secAddrClientNumber === '')
            && rowData.addSecAddresseePermission === true) {
            let tmpOption: WouldSelectOption = {
                message: 'addsecaddr.lbl.title',
                onClick: () => {
                    setNext4SecdAdd({ ...rowData, "companyCode": rowData.companyCode, "groupNumber": rowData.groupNumber, "participantIDNumber": rowData.memberId });
                    secAddrValidation("add", rowData.personalInfoUpdateAble,
                        rowData.updateSecAddresseePermission, rowData.deleteSecAddresseePermission);
                }
            };
            wouldSelectOptionList.push(tmpOption);
        }

        if (rowData.viewChangeOwnerAccessPermission === true) {
            let tmpOption: WouldSelectOption = {
                message: 'changeow.lbl.title',
                onClick: () => {
                    setValidationDigLabel("changeow.lbl.title");
                    ajax({
                        url: '/api/insurance/additionalValidation',
                        params: {
                            companyCode: trim(rowData.companyCode),
                            policyNumber: trim(rowData.policyNumber)
                        },
                        success: (resSuccess: any) => {
                            setValidationContent("changeow.msg.warn.changeOwner");
                            setValidateContinueBtnVisible(true)
                            setValidateCloseBtnVisible(false);
                            setContinuePage(`/changeOwner?companyCode=${selfTrim(rowData.companyCode)}&policyNumber=${selfTrim(rowData.policyNumber)}`);
                            props.showModal("#validationDig");
                        },
                        fail: (resFail: any, errorMsg: any) => {
                            setValidationContent(errorMsg);
                            setValidateContinueBtnVisible(false)
                            setValidateCloseBtnVisible(true);
                            setCloseFlag(true);
                            props.showModal("#validationDig");
                        }
                    });
                }
            };
            wouldSelectOptionList.push(tmpOption);
        }

        if (rowData.viewChangePaymentMethod === true && rowData.flag4changePayment === 'Y') {
            let tmpOption: WouldSelectOption = {
                message: 'common.lbl.changePaymentMethod',
                to: `/changePaymentMethod/${selfTrim(rowData.companyCode)}/${selfTrim(rowData.policyNumber)}`
            };
            wouldSelectOptionList.push(tmpOption);
        }

        if (rowData.accessSecAddresseePermission === true
            && rowData.updateSecAddresseePermission === true
            && (rowData.secAddrClientNumber !== undefined && rowData.secAddrClientNumber !== '')) {
            let tmpOption: WouldSelectOption = {
                message: 'addsecaddr.lbl.change',
                onClick: () => {
                    ajax({
                        url: '/api/insurance/getAddress',
                        params: {
                            companyCode: rowData.companyCode,
                            policyNumber: rowData.policyNumber,
                            roleTypeCode: 'SEC',
                            clientNumber: clientNumber
                        },
                        success: (response) => {
                            setNext4SecdAdd({ ...rowData, "companyCode": rowData.companyCode, "groupNumber": rowData.groupNumber, "participantIDNumber": rowData.memberId });
                            setSecAddrAddress('<div class="gwp-address secondaryAddress">' + response.address + '</div>');
                            setSecAddrName(rowData.secAddrName);
                            secAddrValidation("update", rowData.personalInfoUpdateAble,
                                rowData.updateSecAddresseePermission, rowData.deleteSecAddresseePermission);
                        }
                    });
                }
            };
            wouldSelectOptionList.push(tmpOption);
        }

        if (res.makePaymentAble === true && rowData.flag4makePayment === 'Y' && 'Y' !== rowData.suspendCode) {
            let tmpOption: WouldSelectOption = {
                message: 'common.lbl.makePayment',
                to: `/makePayment/${selfTrim(rowData.companyCode)}/${selfTrim(rowData.policyNumber)}`,
                disabled: disableMakePayment(res, rowData)
            };
            wouldSelectOptionList.push(tmpOption);
        }

        if (res.personalInfoUpdateAble === true) {
            let tmpOption: WouldSelectOption = {
                message: 'common.lbl.updatePersonalInfo',
                to: `/personalInformation?companyCode=${selfTrim(rowData.companyCode)}&policyNumber=${selfTrim(rowData.policyNumber)}&clientNumber=${selfTrim(res.clientNum)}&isUpdateMode=true&personalInfoType=2&baseRiderCode=0&roleTypeCode=${convertRoleTypeCode(rowData)}&from='My Insurance'`,
                disabled: disableUpdatePersonalInfo(res, rowData)
            };
            wouldSelectOptionList.push(tmpOption);
        }

        if (res.accessBillingPaymentAble === true) {
            let tmpOption: WouldSelectOption = {
                message: 'my.ins.act.opt.view billing.or.payments',
                to: `/billingPaymentDetail/${selfTrim(rowData.companyCode)}/${selfTrim(rowData.policyNumber)}`,
                disabled: disableViewBillingPayment(res, rowData)
            };
            wouldSelectOptionList.push(tmpOption);
        }

        if (res.policyDetailViewAble === true) {
            let tmpOption: WouldSelectOption = {
                message: 'my.ins.act.opt.view.policy.details',
                to: `/policyDetail/${selfTrim(rowData.companyCode)}/${selfTrim(rowData.policyNumber)}?from=from_insurance`
            };
            wouldSelectOptionList.push(tmpOption);
        }

        if (res.viewPolicyDocumentsAble === true) {
            let tmpOption: WouldSelectOption = {
                message: 'common.lbl.viewPolicyDocuments',
                to: `/policyDocument/${selfTrim(rowData.policyNumber)}`
            };
            wouldSelectOptionList.push(tmpOption);
        }

        if (rowData.accessSecAddresseePermission === true
            && rowData.updateSecAddresseePermission !== true
            && (rowData.secAddrClientNumber !== undefined && rowData.secAddrClientNumber !== '')) {
            let tmpOption: WouldSelectOption = {
                message: 'addsecaddr.lbl.view',
                to: `/personalInformation?companyCode=${selfTrim(rowData.companyCode)}&policyNumber=${selfTrim(rowData.policyNumber)}&personalInfoType=2&roleTypeCode=SEC&baseRiderCode=0`
            };
            wouldSelectOptionList.push(tmpOption);
        }

        if (rowData.changeBeneficiaryPermission === true) {
            let tmpOption: WouldSelectOption = {
                message: 'pd.lbl.updateallbeneficiary',
                onClick: () => {
                    setValidationDigLabel("pd.lbl.changebeneficiary");
                    ajax({
                        url: '/api/insurance/beneficiaryValidations',
                        params: {
                            companyCode: selfTrim(rowData.companyCode),
                            policyNumber: selfTrim(rowData.policyNumber)
                        },
                        success: (resSuccess: any) => {
                            props.next(`/beneficiaryViewInformation?companyCode=${selfTrim(rowData.companyCode)}&policyNumber=${selfTrim(rowData.policyNumber)}&canChangeBeneficiary=${rowData.changeBeneficiaryPermission}&fromPage=from_insurance`);
                        },
                        fail: (resFail: any, errorMsg: any) => {
                            setContinuePage(`/beneficiaryViewInformation?companyCode=${selfTrim(rowData.companyCode)}&policyNumber=${selfTrim(rowData.policyNumber)}&canChangeBeneficiary=${rowData.changeBeneficiaryPermission}&fromPage=from_insurance`);
                            setValidationContent(errorMsg);
                            setValidateContinueBtnVisible(false)
                            setValidateCloseBtnVisible(true);
                            setCloseFlag(false);
                            props.showModal("#validationDig");
                        }
                    });
                }
            };
            wouldSelectOptionList.push(tmpOption);
        }

        return wouldSelectOptionList;
    }

    const groupChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let parseValue = parseFormatSelGroupOptionValue(e.target.value);

        ajax({
            url: '/api/insurance/getAccountList',
            params: {
                companyCode: parseValue.companyCode,
                groupNumber: parseValue.groupNumber,
                clientNumber: clientNumber
            },
            success: (res) => {
                setAccounts(res.accountList);
                setFieldValue("account", res.accountList[0].value);
                fetchOpenEnrollmentPeriodInfo(parseValue.companyCode, parseValue.groupNumber, res.accountList[0].value);
            }
        });
    }

    const accountChangeHandler = (e: any, formValue: any, setFieldValue: (fieldName: string, value: any) => void) => {
        let parseValue = parseFormatSelGroupOptionValue(formValue.groupNumber);
        fetchOpenEnrollmentPeriodInfo(parseValue.companyCode, parseValue.groupNumber, e.target.value);
    }

    function fetchOpenEnrollmentPeriodInfo(companyCode: any, groupNumber: any, accountNumber: any) {
        ajax({
            url: '/api/insurance/fetchOpenEnrollmentPeriodInfo',
            showMask: false,
            params: {
                companyCode: companyCode,
                groupNumber: groupNumber,
                accountNumber: accountNumber,
                clientNumber: clientNumber
            },
            success: (res4OpenEnroll) => {
                setDispalyOpenEnroll(false);
                if (res4OpenEnroll.beginDate !== undefined && res4OpenEnroll.beginDate !== "") {
                    setDispalyOpenEnroll(true);
                }
                setStartDate(res4OpenEnroll.beginDate);
                setEndDate(res4OpenEnroll.endDate);
                setEffectiveDate(res4OpenEnroll.effectiveDate);
                setAnniversaryDate(res4OpenEnroll.formatBeginDate);

                setAllowOpenEnroll(true);
                setExistMsg("");
                let tmpAllowOpenEnroll: boolean = true;
                if (res4OpenEnroll.inOpenEnrollmentPeriod != "1" || res4OpenEnroll.inOpenEnrollmentSecurity != "1") {
                    tmpAllowOpenEnroll = false;
                    setAllowOpenEnroll(false);
                }

                if (res4OpenEnroll.existTransType !== "" && tmpAllowOpenEnroll) {
                    setAllowOpenEnroll(false);
                    if (res4OpenEnroll.existPendingTran == '1') {
                        setExistMsg("myIns.openenrollment.message.pending");
                    } else if (res4OpenEnroll.existTransType === 'Update') {
                        setExistMsg("myIns.openenrollment.message.admin");
                    } else {
                        setExistMsg("myIns.openenrollment.message.self");
                    }
                }
            }
        });
    }

    function selfTrim(val: any): any {
        if (val !== undefined) {
            return val.trim();
        } else {
            return '';
        }
    }

    function disableMakePayment(res: any, rowData: any): boolean {
        if (rowData.type === "NB") {
            return true;
        }
        if ('Y' === rowData.suspendCode) {
            return true;
        }
        if (rowData.type === "Inf") {
            if ('E' === rowData.statusCode
                || 'P' === rowData.statusCode
                || 'R' === rowData.statusCode
                || 'V' === rowData.statusCode
                || 'C' === rowData.statusCode
                || 'S' === rowData.statusCode
                || 'D' === rowData.statusCode
                || 'X' === rowData.statusCode
                || 'L' === rowData.statusCode
                || 'M' === rowData.statusCode
                || 'N' === rowData.statusCode
                || 'T' === rowData.statusCode) {
                return true;
            }
        }
        return false;
    }

    function disableUpdatePersonalInfo(res: any, rowData: any): boolean {
        if (rowData.type === "NB" && res.member === true) {
            return true;
        } else if (rowData.type === "Inf") {
            if ('C' === rowData.statusCode
                || 'S' === rowData.statusCode
                || 'D' === rowData.statusCode
                || 'X' === rowData.statusCode
                || 'L' === rowData.statusCode
                || 'M' === rowData.statusCode
                || 'N' === rowData.statusCode
                || 'T' === rowData.statusCode) {
                return true;
            }
        }
        return false;
    }

    function disableViewBillingPayment(res: any, rowData: any): boolean {
        if (rowData.type === "NB") {
            return true;
        }
        if ('Y' === rowData.suspendCode) {
            return true;
        }
        return false;
    }

    function deleteTransaction() {
        props.showModal('#delPendingTrans');
    }

    function continueTransaction() {
        let type = selectetedRowData.type;
        let formAction = "";
        if (type === "Add New Member") {
            formAction = "addMember";
        } else if (type === "Life Event Update") {
            formAction = "lifeEvent";

        } else if (type === "Open Enrollment") {
            formAction = "openEnrollment";

        } else if (type === "Initial Benefit Selection") {
            formAction = "initBenefitSelection";

        } else if (type === "Update Enrollment") {
            formAction = "updateEnrollment";

        } else if (type === "Edit Enrollment") {
            formAction = "editEnrollmentM";

        }

        props.next(`/enrollment/open?formType=${formAction}&eventName=continueForm&formAction=${formAction}&transactionID=${selfTrim(selectetedRowData.transactionNumber)}&companyCode=${selfTrim(savedComponyCode.current)}&groupNumber=${selfTrim(selectetedRowData.groupNumber)}&accountNumber=${selfTrim(selectetedRowData.accountNumber)}&participantIDNumber=${selfTrim(selectetedRowData.memberId)}&clientNumber=${clientNumber}`);
    }

    function deleteSecAddr() {
        props.closeModal('#secAddrDig');
        props.showModal('#deleteSecAddrDig');
    }

    function secAddrValidation(type: string, personalInfoUpdateAble: boolean, updateSecAddresseePermission: boolean, deleteSecAddresseePermission: boolean) {
        if (type === 'update') {
            secAddr(personalInfoUpdateAble, updateSecAddresseePermission, deleteSecAddresseePermission);
        } else {
            changeSecAddr('popup');
        }
    }
    function secAddr(personalInfoUpdateAble: boolean, updateSecAddresseePermission: boolean, deleteSecAddresseePermission: boolean) {
        if (personalInfoUpdateAble === false) {
            setEidtSecAddrBtnVisible(false);
        }
        if (updateSecAddresseePermission === false) {
            setChangeSecAddrBtnVisible(false);
        }
        if (deleteSecAddresseePermission === false) {
            setDeleteSecAddrBtnVisible(false);
        }
        if (updateSecAddresseePermission === false && deleteSecAddresseePermission === false && personalInfoUpdateAble === true) {
            eidtSecAddr();
        } else {
            props.showModal('#secAddrDig');
        }
    }

    function convertRoleTypeCode(rowData: any): any {
        let roleTypeCode = '';
        if (rowData.ownerName !== undefined && rowData.ownerName !== '') {
            roleTypeCode = 'OW1';
        } else if (rowData.owner2Name !== undefined && rowData.owner2Name !== '') {
            roleTypeCode = 'OW2';
        } else if (rowData.insuredName !== undefined && rowData.insuredName !== '') {
            roleTypeCode = 'INS';
        } else if (rowData.secAddrName !== undefined && rowData.secAddrName !== '') {
            roleTypeCode = 'SEC';
        } else if (rowData.jointInsured !== undefined && rowData.jointInsured !== '') {
            roleTypeCode = 'INS';
        }

        return roleTypeCode;
    }

    function eidtSecAddr() {
        props.next(`/personalInformation?companyCode=${selfTrim(next4SecdAdd.companyCode)}&policyNumber=${selfTrim(next4SecdAdd.policyNumber)}&personalInfoType=2&roleTypeCode=SEC&isUpdateMode=true&baseRiderCode=0`);
    }

    function changeSecAddr(from: string) {
        if (from === "popup") {
            props.showModal('#addSecAddrDig');
        } else {
            props.next(`/changeSecondaryAddressee?companyCode=${selfTrim(next4SecdAdd.companyCode)}&policyNumber=${selfTrim(next4SecdAdd.policyNumber)}&clientNumber=${clientNumber}&fromPage=insurance`);
        }
    }

    function addSecAddr() {
        props.next(`/addSecondaryAddressee?companyCode=${selfTrim(next4SecdAdd.companyCode)}&policyNumber=${selfTrim(next4SecdAdd.policyNumber)}&clientNumber=${clientNumber}&fromPage=insurance`);
    }

    function confirmDeleteSecAddr() {
        ajax({
            url: `/api/insurance/deleteSecondaryAddressee`,
            method: 'delete',
            params: {
                companyCode: selfTrim(next4SecdAdd.companyCode),
                policyNumber: selfTrim(next4SecdAdd.policyNumber),
                clientNumber: (next4SecdAdd.memberId !== undefined && next4SecdAdd.memberId !== null) ? next4SecdAdd.memberId : clientNumber
            },
            success: (res1: any) => {
                props.showMessage("success", 'addsecaddr.msg.delete.success');
                props.closeModal('#deleteSecAddrDig');
                ajax({
                    url: '/api/insurance/view',
                    showMask: true,
                    params: {
                        fromPage: props.getQueryParam("fromPage") === undefined ? '' : props.getQueryParam("fromPage"),
                        clientNumber: clientNumber,
                        agentClientNum: props.getQueryParam("agentClientNum") === undefined ? '' : props.getQueryParam("agentClientNum"),
                        companyCode: passedCompanyCode
                    },
                    success: (res: any) => {

                        if (res.member === true) {
                            props.setTitle(props.getGlobalizedText("my.ins.title"));
                            setClientNumber(res.clientNum);
                        } else {
                            let on = res.ownerName.replaceAll("<wbr/>", "")
                            props.setTitle(`${on}${props.getGlobalizedText("my.ins.ones.title")}`);
                        }

                        bulidInsuranceList(res);

                        if (res.groupInsurances.length !== 0) {
                            passedCompanyCode = res.groupInsurances[0].companyCode;
                        } else if (res.individualInsurances.length !== 0) {
                            passedCompanyCode = res.individualInsurances[0].companyCode;
                        }

                        setOpenEnrollmentFlag(res.hasOpenEnrollment);
                        if (res.hasOpenEnrollment === true) {
                            setGroups(res.groupsList);
                            setAccounts(res.accountList);
                            varFormPromp.setFieldValue("groupNumber", res.groupsList[0].value);
                            varFormPromp.setFieldValue("account", res.accountList[0].value);

                            let parseValue = parseFormatSelGroupOptionValue(res.groupsList[0].value);
                            fetchOpenEnrollmentPeriodInfo(parseValue.companyCode, parseValue.groupNumber, res.accountList[0].value);
                        }
                        if (res.pendingTransactionList === undefined || res.pendingTransactionList.length === 0) {
                            setPendingTransactionFlag(false);
                        } else {
                            setPendingTransactionFlag(true);
                            setNumberOfPendingTrans(res.pendingTransactionList.length);
                            setDisablePendingBtnFlag(true);
                            setPendingTranstable({
                                columns: tableColumns,
                                data: res.pendingTransactionList
                            });
                        }
                    }
                });
                return () => {
                }
            },
            callback: () => {
            }
        });
    }

    function confirmDeletePendingTrans() {
        ajax({
            url: `/api/insurance/deleteTransaction`,
            method: 'delete',
            params: {
                transactionID: selectetedRowData.transactionNumber
            },
            success: (res2: any) => {
                props.showMessage("success", "common.msg.transactionDeletedSuccessfully");
                props.closeModal('#delPendingTrans');

                ajax({
                    url: '/api/insurance/view',
                    showMask: true,
                    params: {
                        fromPage: props.getQueryParam("fromPage") === undefined ? '' : props.getQueryParam("fromPage"),
                        clientNumber: clientNumber,
                        agentClientNum: props.getQueryParam("agentClientNum") === undefined ? '' : props.getQueryParam("agentClientNum"),
                        companyCode: passedCompanyCode
                    },
                    success: (res: any) => {

                        if (res.member === true) {
                            props.setTitle(props.getGlobalizedText("my.ins.title"));
                            setClientNumber(res.clientNum);
                        } else {
                            let on = res.ownerName.replaceAll("<wbr/>", "")
                            props.setTitle(`${on}${props.getGlobalizedText("my.ins.ones.title")}`);
                        }

                        bulidInsuranceList(res);

                        if (res.groupInsurances.length !== 0) {
                            passedCompanyCode = res.groupInsurances[0].companyCode;
                        } else if (res.individualInsurances.length !== 0) {
                            passedCompanyCode = res.individualInsurances[0].companyCode;
                        }

                        setOpenEnrollmentFlag(res.hasOpenEnrollment);
                        if (res.hasOpenEnrollment === true) {
                            setGroups(res.groupsList);
                            setAccounts(res.accountList);
                            varFormPromp.setFieldValue("groupNumber", res.groupsList[0].value);
                            varFormPromp.setFieldValue("account", res.accountList[0].value);

                            let parseValue = parseFormatSelGroupOptionValue(res.groupsList[0].value);
                            fetchOpenEnrollmentPeriodInfo(parseValue.companyCode, parseValue.groupNumber, res.accountList[0].value);
                        }
                        if (res.pendingTransactionList === undefined || res.pendingTransactionList.length === 0) {
                            setPendingTransactionFlag(false);
                        } else {
                            setPendingTransactionFlag(true);
                            setNumberOfPendingTrans(res.pendingTransactionList.length);
                            setDisablePendingBtnFlag(true);
                            setPendingTranstable({
                                columns: tableColumns,
                                data: res.pendingTransactionList
                            });
                        }
                    }
                });
            }
        });
    }

    function linkClickEx() {
        if (allowOpenEnroll) {
            let value = varFormPromp.values.groupNumber;
            let parseValue = parseFormatSelGroupOptionValue(value);
            let group = parseValue.groupNumber;
            let companyCode = parseValue.companyCode;
            let account = varFormPromp.values.account;

            props.next(`/enrollment/open?formType=openEnrollment&formAction='openEnrollment'&accountNumber=${account}&groupNumber=${group}&languageCode='en'&companyCode=${companyCode}&anniversaryDate=${anniversaryDate}&clientNumber=${clientNumber}`);
        }
    }

    function parseFormatSelGroupOptionValue(value: string): any {
        let parseValue: any = {};
        let items = value.split(",");
        if (items && items.length > 0) {
            parseValue.groupNumber = items[0];
            if (items.length > 1) {
                parseValue.companyCode = items[1];
            } else {
                parseValue.companyCode = "";
            }
        }
        return parseValue;
    }

    function continueTo() {
        props.next(`${continuePage}`);
    }

    function closeButton() {
        if (closeFlag === true) {
            props.closeModal("#validationDig");
        } else {
            props.next(`${continuePage}`);
        }
    }

    function reformatName(memberName: string): string {
        let names = memberName.split("/");
        if (names.length > 1) {
            return names[1] + " " + names[0];
        } else {
            return memberName;
        }
    }
    return <>

        <Formik initialValues={{
            groupNumber: '',
            account: '',
        }}
            validate={() => { }}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={() => { }}
        >

            {formProps => {
                varFormPromp = formProps;
                return <Form>
                    {/* Open Enrollment Part */}
                    <props.Div id="openEnrollment" condition={openEnrollmentFlag}>
                        <props.Div className="gwp-page-title">
                            <GlobaliziedText message="common.lbl.openEnrollment" />
                        </props.Div>
                        <div className="pfoeclass1">
                            <props.Div className="gwp-page-content">
                                <props.Row >
                                    <props.Col xs="12" sm="6" md="6">
                                        <props.SelectControl label="common.lbl.group" sort={false} name="groupNumber" options={groups} onChange={(e: any) => { groupChangeHandler(e, formProps.values, formProps.setFieldValue) }} >
                                        </props.SelectControl>
                                    </props.Col>
                                    <props.Col xs="12" sm="6" md="6">
                                        <props.SelectControl label="common.lbl.account" sort={false} name="account" options={accounts} onChange={(e: any) => { accountChangeHandler(e, formProps.values, formProps.setFieldValue) }}>
                                        </props.SelectControl>
                                    </props.Col>
                                </props.Row>
                                <props.Row condition={dispalyOpenEnroll} >
                                    <props.Col xs="12" sm="12" md="12">
                                        <props.Div className="pfoeclass2 gwp-bold">
                                            Open Enrollment begins<props.Span> {startDate} </props.Span>and ends<props.Span> {endDate}</props.Span> .<GlobaliziedText message="homepage.requestEnrolBegin" />
                                        </props.Div>
                                        <props.Div className="pfoeclass2 gwp-bold">
                                            <GlobaliziedText message="homepage.eligibleBenefitsEffective" /><props.Span> {effectiveDate}</props.Span>
                                        </props.Div>
                                        <props.Div condition={allowOpenEnroll !== true} className="pfoeclass2 gwp-bold gwp-required" id="errorMessageForOpenEnrollment"><GlobaliziedText message={existMsg} /></props.Div>
                                    </props.Col>
                                    <props.Col xs="12" sm="10" md="10">
                                        <props.Button disabled={allowOpenEnroll === true ? false : true} onClick={() => { linkClickEx() }}><GlobaliziedText message="common.lbl.requestOpenEnrollment" /></props.Button>
                                    </props.Col>
                                    <props.Col xs="12" sm="2" md="2">
                                        <props.Div className="enrollment-icon">
                                            <img alt="icon_OpenEnrollment.png" src={process.env.PUBLIC_URL + "/static/themes/blue/images/icon_OpenEnrollment.png"}></img>
                                        </props.Div>
                                    </props.Col>
                                </props.Row>
                            </props.Div>
                        </div>
                    </props.Div>

                    {/* Pending Transaction Part */}
                    <props.Div id="pendingTransaction" condition={pendingTransactionFlag}>
                        <div className="pfoeclass1">
                            <props.Div className="gwp-page-title">
                                <GlobaliziedText message="common.lbl.pendingtransactions" />
                            </props.Div>
                            <props.Div className="information gwp-information">
                                <span className="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>&nbsp;
                                <GlobaliziedText message="homepage.thereAre" />
                                <props.Span className="gwp-bold gwp-required"> {numberOfPendingTrans} </props.Span>
                                <GlobaliziedText message="common.lbl.pendingtransactions" />.
                            </props.Div>
                            <props.Div >
                                <props.Button disabled={disablePendingBtnFlag} onClick={() => { continueTransaction() }}><GlobaliziedText message="common.button.continue" /></props.Button>
                                <props.Button disabled={disablePendingBtnFlag} onClick={() => { deleteTransaction() }}><GlobaliziedText message="common.lbl.delete" /></props.Button>
                            </props.Div>
                            <props.Table id="pendingTransTable" table={pendingTranstable} select={{
                                type: 'single',
                                disabled: (row: any) => {
                                    let result = false;
                                    let status = row['status'];
                                    if (status !== "Incomplete" && status !== "Error") {
                                        result = true;
                                    } else {
                                        if (row['inperiod'] === undefined) {
                                            result = true;
                                        }
                                    }
                                    return result;
                                },
                                onSelect: (e: any, rows: number[]) => { saveSelectedValue(pendingTranstable.data[rows[0]]) }
                            }} dateformat="MM/dd/yyyy"></props.Table>
                        </div>
                    </props.Div>

                    {/* {Left Section} */}
                    <props.Row>
                        <props.Col sm={12} md={9}>
                            {/* Insurance List Part */}
                            <div id="insurance_comp">
                                {(data.insurances.length === 0 && data.groupInsurances.length === 0) &&
                                    <div><span className="glyphicon glyphicon-info-sign"></span>&nbsp;<GlobaliziedText message="common.msg.noInsuranceAvailable" /></div>
                                }
                                {(data.insurances.length !== 0 || data.groupInsurances.length !== 0) &&
                                    <props.Row>
                                        <props.Col sm="12" md="12">
                                            <div className="bg-info mi-divider"></div>
                                        </props.Col>
                                    </props.Row>
                                }
                                {data.groupInsurances !== undefined && data.groupInsurances.map((insur: GroupInsurance, index: number) => {
                                    return <props.Row key={index} className="mi-list-row">
                                        <props.Col sm="12" md="9">
                                            <div className="mi-list-row-title">{insur.groupNumber} - {insur.groupName}</div>
                                            <props.Row>
                                                <props.Col md="6" sm="6">
                                                    <props.DL className="dl-horizontal">
                                                        <props.DLI title="common.lbl.account" desc={` ${insur.accountNumber} - ${insur.accountName}`}></props.DLI>
                                                        <props.DLI title={`common.lbl.${isDependent ? 'dependent' : 'member'}ID`} desc={`${insur.memberId}`}></props.DLI>
                                                        <props.DLI title={`common.lbl.${isDependent ? 'dependent' : 'member'}Name`} desc={reformatName(insur.memberName)}></props.DLI>
                                                    </props.DL>
                                                </props.Col>
                                                <props.Col md="6" sm="6">
                                                    <props.DL className="dl-horizontal">
                                                        <props.DLI title="common.lbl.effectiveDate" desc={insur.effectiveDate}></props.DLI>
                                                        <props.DLI title="common.lbl.terminationDate" desc={insur.terminationDate}></props.DLI>
                                                    </props.DL>
                                                </props.Col>
                                            </props.Row>
                                        </props.Col>
                                        <props.Col md="3" sm="12">
                                            <div className="btn-group dropdown-insurance">
                                                <props.WouldSelect next={props.next} options={insur.wouldSelectOptions}></props.WouldSelect>
                                            </div>
                                        </props.Col>
                                    </props.Row>
                                })
                                }
                                {data.insurances !== undefined && data.insurances.map((insur: IndividualInsurance, index: number) => {
                                    return <props.Row key={index} className="mi-list-row">
                                        <props.Col sm="12" md="9">
                                            <div className="mi-list-row-title">{insur.policyNumber} - {insur.name}</div>
                                            <props.Row>
                                                <props.Col md="6" sm="6">
                                                    <props.DL className="dl-horizontal">
                                                        <props.DLI title="my.ins.insured.name" isHtml={true} desc={` ${insur.insuredName}${insur.jointInsured !== undefined ? ', ' + insur.jointInsured : ''}`}></props.DLI>
                                                        <props.DLI title="my.ins.owner.name" isHtml={true} desc={`${insur.ownerName}${insur.owner2Name !== undefined ? ', ' + insur.owner2Name : ''}`}></props.DLI>
                                                        <props.DLI title="common.lbl.status" desc={insur.status}></props.DLI>
                                                    </props.DL>
                                                </props.Col>
                                                <props.Col md="6" sm="6">
                                                    <props.DL className="dl-horizontal">
                                                        <props.DLI title="common.lbl.effectiveDate" desc={insur.effectiveDate}></props.DLI>
                                                        <props.DLI title="common.lbl.coverageAmount" desc={insur.coverageAmount}></props.DLI>
                                                        <props.DLI title="common.lbl.premium" desc={insur.premium}></props.DLI>
                                                    </props.DL>
                                                </props.Col>
                                            </props.Row>
                                        </props.Col>
                                        <props.Col md="3" sm="12">
                                            <div className="btn-group dropdown-insurance">
                                                <props.WouldSelect next={props.next} options={insur.wouldSelectOptions}></props.WouldSelect>
                                            </div>
                                        </props.Col>
                                    </props.Row>
                                })
                                }


                                {/* Validation Dialog */}
                                <props.Modal title={validationDigLabel} id="validationDig" footer={() => {
                                    return <>
                                        <props.Button data-toggle="modal" condition={validateContinueBtnVisible} onClick={() => { continueTo() }} ><GlobaliziedText message="common.button.continue" /></props.Button>
                                        <props.Button data-dismiss="modal" condition={validateContinueBtnVisible}  ><GlobaliziedText message="common.lbl.cancel" /></props.Button>
                                        <props.Button data-toggle="modal" condition={validateCloseBtnVisible} onClick={() => { closeButton() }} ><GlobaliziedText message="common.lbl.close" /></props.Button>
                                    </>
                                }} >
                                    <props.Row>
                                        <props.Col xs="12" sm="12" md="12" className="mt-2">
                                        <span className="gwp-icon gwp-icon-warning gwp-icon-sm gwp-icon-validate"></span>
                                        <GlobaliziedText message={validationContent} />
                                        </props.Col>
                                    </props.Row>
                                </props.Modal>

                                {/* Secondary Address Dialog */}
                                <props.Modal title="pd.lbl.secaddr.name" id="secAddrDig" footer={() => {
                                    return <>
                                        <props.Button data-toggle="modal" condition={changeSecAddrBtnVisible} id="changeSecAddrBtn" onClick={() => { changeSecAddr('update') }} ><GlobaliziedText message="common.lbl.change" /></props.Button>
                                        <props.Button data-toggle="modal" condition={eidtSecAddrBtnVisible} id="eidtSecAddrBtn" onClick={() => { eidtSecAddr() }} ><GlobaliziedText message="common.lbl.update" /></props.Button>
                                        <props.Button data-toggle="modal" condition={deleteSecAddrBtnVisible} id="deleteSecAddrBtn" onClick={() => { deleteSecAddr() }} ><GlobaliziedText message="common.lbl.delete" /></props.Button>
                                        <props.Button data-dismiss="modal"><GlobaliziedText message="common.lbl.cancel" /></props.Button>
                                    </>
                                }} >
                                    <props.Row>
                                        <props.Col xs="12" sm="12" md="12" className='mt-2'>
                                            <span className="gwp-icon gwp-icon-warning gwp-icon-sm gwp-icon-validate"></span>
                                            <GlobaliziedText message="addsecaddr.msg.buttontip" />
                                        </props.Col>
                                    </props.Row>
                                    <props.Row className='changSecondaryAddressee'>
                                        <props.Col xs="12" sm="12" md="12">
                                            <props.DL className='dl-horizontal'>
                                                <props.DLI title="pd.lbl.secaddr.name" desc={secAddrName}></props.DLI>
                                                <props.DLI title="pd.lbl.address" desc={secAddrAddress} isHtml={true}></props.DLI>
                                            </props.DL>
                                        </props.Col>
                                    </props.Row>
                                </props.Modal>

                                {/* Delete Secondary Address Dialog */}
                                <props.Modal title="common.lbl.wpdelete" id="deleteSecAddrDig" footer={() => {
                                    return <>
                                        <props.Button data-toggle="modal" data-target="#deleteSecAddrDig" onClick={() => { confirmDeleteSecAddr() }}><GlobaliziedText message="common.lbl.wpdelete" /></props.Button>
                                        <props.Button onClick={() => { props.closeModal('#deleteSecAddrDig'); props.showModal('#secAddrDig'); }}><GlobaliziedText message="common.lbl.cancel" /></props.Button>
                                    </>
                                }} >
                                    <span className="gwp-icon gwp-icon-warning gwp-icon-sm gwp-icon-validate"></span>
                                    <GlobaliziedText message="addsecaddr.msg.deleteConfirm" />
                                </props.Modal>

                                {/* Add Secondary Address Dialog */}
                                <props.Modal title="addsecaddr.lbl.title" id="addSecAddrDig" footer={() => {
                                    return <>
                                        <props.Button data-toggle="modal" onClick={() => { addSecAddr() }} data-target="#addSecAddrDig" ><GlobaliziedText message="common.button.continue" /></props.Button>
                                        <props.Button data-dismiss="modal"><GlobaliziedText message="common.lbl.cancel" /></props.Button>
                                    </>
                                }} >
                                    <p><GlobaliziedText message="my.ins.msg.nosecaddr" /></p>
                                </props.Modal>

                                {/* Delete Pending Transcation Dialog */}
                                <props.Modal title={() => {
                                    return detelteTitle('common.lbl.deleteTransactions');
                                }
                                } id="delPendingTrans" footer={() => {
                                    return <>
                                        <props.Button data-toggle="modal" data-target="#delPendingTrans" onClick={() => { confirmDeletePendingTrans() }} ><GlobaliziedText message="common.lbl.delete" /></props.Button>
                                        <props.Button data-dismiss="modal"><GlobaliziedText message="common.lbl.cancel" /></props.Button>
                                    </>
                                }} >
                                    <props.Row>
                                        <props.Col xs="12" sm="12" md="12">
                                            <GlobaliziedText message="trans.lbl.all.trans.delete" />
                                        </props.Col>
                                    </props.Row>
                                    <props.Row>
                                        <props.Col xs="12" sm="12" md="12">
                                            <GlobaliziedText message="openenrol.lbl.confirmDelete" />.
                                        </props.Col>
                                    </props.Row>
                                </props.Modal>
                            </div>                            
                               {data.groupInsurances !== undefined && data.groupInsurances.length >= 1  &&
                                    <div id="balance_summary">
                                        <props.HR />
                                        <props.Div className="gwp-page-title">
                                            <GlobaliziedText message="common.lbl.balanceSummary" />
                                        </props.Div>
                                        <props.Row>
                                            <props.Col sm="12">
                                                <balanceSummaryResult.Component />
                                            </props.Col>
                                        </props.Row>
                                    </div>
                               }

                            {/* Recent Claims Part */}
                            {viewClaims &&
                                <div id="recent_claims">
                                    <props.HR />
                                    <props.Div className="gwp-page-title">
                                        <GlobaliziedText message="common.lbl.recentClaims" />
                                    </props.Div>
                                    <props.Row>
                                        <props.Col sm="12">
                                            <claimInquiryResult.Component />
                                        </props.Col>
                                    </props.Row>
                                    <props.Div className="mt-2">
                                        <props.Link className="gwp-bold" to={viewClaims}><GlobalizedText message="common.lbl.viewAllClaims" /></props.Link>
                                        <props.HR />
                                    </props.Div>
                                    </div>}
                        </props.Col>
                        <props.Col sm={12} md={3}>
                            <QuickLink personalDetails={personalDetails} />
                        </props.Col>
                    </props.Row>
                </Form>
            }
            }
        </Formik >
        <SendMessagePopup user={personalDetails} supportedFileTypes={props.userService.rootComponent.state.supportedFileTypes} />
    </>

});
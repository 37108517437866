import React, { useState, useEffect } from 'react'
import { Form, Formik } from 'formik';
import { ViewComponentProps } from '../shared/viewcomponent';
import GlobalizedText from '../shared/globalization';
import { ajax, mergeObject, validate, isEmptyStr, copyObjectExcept } from '../shared/utils';
import { INVALID_MESSAGES } from '../shared/yupschema';
import { onlineforms } from '../pages/onlineforms';

const defaultInitialValues: onlineforms = {
    groupNumber: ''
};

const byGroupSchema = (values: onlineforms): any => {
    let result: any = {};
    if (values.groupNumber === "0" || isEmptyStr(values.groupNumber)) {
        result.groupNumber = INVALID_MESSAGES.GRUOP_IS_EMPTY;
    }

     return result;
}

interface AdminOnlineFormsComponentProps {
    props: ViewComponentProps,
    getOnlineFormData: (values: onlineforms) => void
}

export const AdminOnlineFormsComponent = ({ getOnlineFormData, props }: AdminOnlineFormsComponentProps) => {

    const [config, setConfig] = useState<any | null>(null);

    const formValidate = (values: any): any => {
        if (values.filterBy === config.byGroup) {
            return validate(byGroupSchema, values, props);
        }
    }     

    const submitHandler = (values: onlineforms) => {
        getOnlineFormData(values)
    }

    useEffect(() => {
        if (config === null) {
            ajax({
                url: '/api/onlineForms/view',
                success: (res: any) => {                    
                    let initParams = props.getInitParams();
                    if (initParams !== undefined) {
                        setConfig(res);                        
                    } else {
                        setConfig(res);
                    }
                }
            });
        }
    }, [config])
    if (config === null) {
        return <></>;
    }

    return (
        <React.Fragment>
            <Formik initialValues={defaultInitialValues} validate={formValidate} onSubmit={submitHandler}>
                {formProps => {
                    return (
                        <Form>
                            <props.Row>
                                <props.Col xs="12" sm="6" md="6">
                                    <props.SelectControl
                                        sort={false}
                                        name="groupNumber"
                                        label="common.lbl.group"
                                        required={true}
                                        options={config.groups}
                                        onChange={(e: any) => {
                                            props.resetForm(formProps, mergeObject(defaultInitialValues, { groupNumber: e.target ? e.target.value : e }));
                                        }}
                                    >
                                    </props.SelectControl>                                   
                                    <props.Button  type="submit"><GlobalizedText message="common.lbl.retrieve" /></props.Button>
                                </props.Col>
                            </props.Row>
                        </Form>
                    )
                }}
            </Formik>
        </React.Fragment>

    )
}
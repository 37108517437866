import React, { useState } from 'react';
import { User, UserService } from './authentication';
import { BreadCrumbService } from './breadcrumb';
import { MessageService } from './message';
import { ajax, loadCss, unloadCss } from './utils';

export function loadMyWorkspace(setMenuGroups: (result: Array<any>) => void) {
    ajax({
        url: '/api/menu',
        success: (menus: any) => {
            let result = [];
            let group: any;
            for (var i = 0; i < menus.length; i++) {
                if (i % 2 === 0 || (menus.length === 2 && i === 1)) {
                    if (group != null) {
                        result.push({ menus: group });
                    }
                    group = [];
                }
                group.push(menus[i]);
            }
            if (group != null) {
                result.push({ menus: group });
            }
            setMenuGroups(result);
        },
        showMask: false
    });
}


export function loadCategories(setCategories: (categories: any[]) => void, showMask?: boolean) {
    ajax({
        url: '/api/cms/portalcategory/listPublished',
        showMask: showMask !== false,
        success: (res) => {
            setCategories(res);
        }
    });
}

export const EMPTY_THEME = "empty";
export const DEFAULT_THEME = 'default';
export const DEFAULT_LAYOUT = 'default';
export const EMPTY_LAYOUT = 'empty';

export interface ThemeConfig {
    [name: string]: ThemeProps;
}

interface ThemeProps {
    component: any;
    css: Array<string>;
    layouts: LayoutConfig;
}

export interface HeaderProps {
    themeConfigs: ThemeConfig;
    breadcrumb: BreadCrumbService;
    userService: UserService;
    themeService: ThemeService;
    siteTheme: string;
}

export const Header = (props: HeaderProps) => {
    const [themeName, setThemeName] = useState<string | undefined>();
    const [themeConfig, setThemeConfig] = useState<ThemeConfig | undefined>();
    props.themeService.setSetThemeNameForHeader(setThemeName);
    props.themeService.setSetThemeConfigForHeader(setThemeConfig);
    if (themeName === undefined) {
        return <></>
    }
    let tc = props.themeConfigs ? props.themeConfigs : themeConfig;
    let theme = undefined;
    if (tc !== undefined) {
        let tn = tc[themeName] === undefined ? props.siteTheme : themeName;

        theme = tc[tn];
        if (theme === undefined) {
            return <></>
        }
        unloadCss(tn);
        theme.css.forEach((href: string) => {
            loadCss(tn, href);
        })
    }
    if (theme === undefined) {
        return <></>
    }
    return <theme.component.Header breadcrumb={props.breadcrumb} userService={props.userService} />
}

export interface FooterProps {
    themeConfigs: ThemeConfig;
    themeService: ThemeService;
    messageService: MessageService;
    siteTheme: string;
    user: User | null;    
    userService: UserService;
}

export const Footer = (props: FooterProps) => {
    const [themeName, setThemeName] = useState<string>();
    const [themeConfig, setThemeConfig] = useState<ThemeConfig | undefined>();
    props.themeService.setSetThemeNameForFooter(setThemeName);
    props.themeService.setSetThemeConfigForFooter(setThemeConfig);
    if (themeName === undefined || themeName === EMPTY_THEME) {
        return <></>
    }
    let theme = undefined;
    let tc = props.themeConfigs ? props.themeConfigs : themeConfig;
    if (tc !== undefined) {
        theme = (tc[themeName] === undefined ? tc[props.siteTheme] : tc[themeName]);
    }
    if (theme === undefined) {
        return <></>
    }

    return <theme.component.Footer {...props} />;
}

export interface LayoutConfig {
    [name: string]: any
}

export interface LayoutProps {
    themeConfigs: ThemeConfig;
    themeService: ThemeService;
    siteTheme: string;
    layout?: string;
    children?: any;
}

interface LayoutState {
    themeName?: string
    layout?: string
    themeConfig?: ThemeConfig
}

export const Layout = (props: LayoutProps) => {
    const [layoutState, setLayoutState] = useState<LayoutState>({});
    props.themeService.setSetLayoutState(setLayoutState);
    if (layoutState.themeName === undefined) {
        return <></>
    }
    let tc = props.themeConfigs ? props.themeConfigs : layoutState.themeConfig;
    let theme = undefined;
    if (tc !== undefined) {
        theme = tc[layoutState.themeName] === undefined ? tc[DEFAULT_THEME] : tc[layoutState.themeName];
    }
    if (theme === undefined) {
        return <></>
    }
    let LayoutComp;
    if (layoutState.layout !== undefined) {
        LayoutComp = theme.layouts[layoutState.layout] === undefined ? theme.layouts[DEFAULT_LAYOUT] : theme.layouts[layoutState.layout];
    } else {
        LayoutComp = theme.layouts[DEFAULT_LAYOUT];
    }
    return <LayoutComp>{props.children}</LayoutComp>
}

export interface ThemeState {
    themeName: string
    layout: string
    themeConfigs: ThemeConfig
}

export class ThemeService {
    private setThemeNameForFooter: any;
    private setThemeNameForHeader: any;
    private setThemeConfigForFooter: any;
    private setThemeConfigForHeader: any;
    private setLayoutState: any;
    private themeState: ThemeState | null;

    constructor() {
        this.themeState = null;
    }

    setSetThemeNameForFooter(setThemeNameForFooter: any) {
        this.setThemeNameForFooter = setThemeNameForFooter;
    }

    setSetThemeNameForHeader(setThemeNameForHeader: any) {
        this.setThemeNameForHeader = setThemeNameForHeader;
    }

    setSetThemeConfigForFooter(setThemeConfigForFooter: any) {
        this.setThemeConfigForFooter = setThemeConfigForFooter;
    }

    setSetThemeConfigForHeader(setThemeConfigForHeader: any) {
        this.setThemeConfigForHeader = setThemeConfigForHeader;
    }

    setSetLayoutState(setLayoutState: any) {
        this.setLayoutState = setLayoutState;
    }

    setTheme(themeState: ThemeState) {
        this.setThemeNameForHeader(themeState.themeName);
        this.setThemeConfigForHeader(themeState.themeConfigs);
        this.setThemeNameForFooter(themeState.themeName);
        this.setThemeConfigForFooter(themeState.themeConfigs);
        this.setLayoutState(themeState);
    }

    getTheme(): ThemeState | null {
        return this.themeState;
    }
}